import { AppTypeEnum } from './../../../models/enum/AppTypeEnum';
import { Component, OnInit, Input } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { PatchRequest } from "src/app/models/PatchRequest.Model";
import { PatchDto } from "src/app/models/PatchDto.Model";
import { CommandService } from "src/app/services/command.service";
import { CommunicationService } from "src/app/services/communication.service";
import { datepickerAnimation } from "ngx-bootstrap/datepicker/datepicker-animations";
import { ProductModel } from "src/app/models/Product.Model";

@Component({
  selector: "app-product-patch-add",
  templateUrl: "./product-patch-add.component.html",
  styleUrls: ["./product-patch-add.component.scss"],
})
export class ProductPatchAddComponent implements OnInit {
  minDate: Date = new Date();
  errMsg: string = "";
  error: boolean;
  showLoading: boolean;
  title: string;
  patchRequest: PatchRequest = new PatchRequest();
  patch: PatchDto = new PatchDto();
  @Input() pat: PatchDto;
  @Input() product: ProductModel;
  productPatchRequest: PatchRequest = new PatchRequest();

  constructor(
    private _commandService: CommandService,
    private _communcationService: CommunicationService,
    private router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private titleService: Title
  ) { }

  ngOnInit() {
  }

  validatePatch(patch: PatchDto) {
    switch (patch.IsRetail) {
      case AppTypeEnum.Assar:
        return patch.ExpireDate != undefined &&
          patch.Quantity > 0 &&
          patch.OriginalPrice >= 0 &&
          patch.Discount < patch.OriginalPrice &&
          patch.Discount >= 0 &&
          patch.Quantity >= patch.Booked;

      case AppTypeEnum.Shine:
        return patch.ExpireDate != undefined &&
          patch.Quantity > 0 &&
          patch.OriginalPriceShine >= 0 &&
          patch.DiscountShine < patch.OriginalPriceShine &&
          patch.DiscountShine >= 0 &&
          patch.Quantity >= patch.Booked

      case AppTypeEnum.BothApp:
        return patch.ExpireDate != undefined &&
          patch.Quantity > 0 &&
          patch.OriginalPrice >= 0 &&
          patch.OriginalPriceShine >= 0 &&
          patch.Discount < patch.OriginalPrice &&
          patch.DiscountShine < patch.OriginalPriceShine &&
          patch.Discount >= 0 &&
          patch.Quantity >= patch.Booked

    }

  }

  SavePatch(patch: PatchDto) {
    try {
      this.errMsg = "";
      patch.submitted = true;
      if (!this.validatePatch(patch)) {
        this.error = true;
        this.errMsg = this.translateService.instant("errorPatch");
        return;
      }
      this.showLoading = true;

      patch.ExpireDate.setHours(2);
      //patch.OriginalPrice=patch.Price
      patch.Price = patch.OriginalPrice - patch.Discount;
      patch.PriceShine = patch.OriginalPriceShine - patch.DiscountShine;
      patch.IsRetail = this.pat.IsRetail;
      this.productPatchRequest.Patch = patch;

      this._commandService
        .AddEditProductPatch(this.productPatchRequest)
        .subscribe((res) => {
          this.showLoading = false;
          if (res.HasError) {
            this.errMsg = res.ErrorMessage;
            this.showLoading = false;
            return;
          }
          res.Result.ExpireDate = res.Result.ExpireDate;
          this.pat = res.Result;
          this._communcationService.updateProductPatch(this.pat);
        });
    } catch (error) {
      this.errMsg = error;
      this.showLoading = false;
    }
  }

  DeletePatch(patch: PatchDto) {
    debugger;
    try {
      this.errMsg = "";

      this.showLoading = true;

      this.productPatchRequest.Patch = patch;
      this.productPatchRequest.Patch.IsDeleted = true;
      this._commandService
        .AddEditProductPatch(this.productPatchRequest)
        .subscribe((res) => {
          this.showLoading = false;
          if (res.HasError) {
            if (res.ErrorMessage == "NotDeleted") {
              var check = confirm(this.translateService.instant("DeletePatch"));
              return;
            }
            this.errMsg = res.ErrorMessage;
            this.showLoading = false;
            return;
          }
          res.Result.ExpireDate = res.Result.ExpireDate;
          this.pat = res.Result;
          this._communcationService.updateProductPatch(this.pat);
        });
    } catch (error) {
      this.errMsg = error;
      this.showLoading = false;
    }
  }
}
