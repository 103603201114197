import { ProductBrandDTO } from './ProductBrandDTO.model';
import { LookupsModel } from './Lookups.model';
import { PatchDto } from './PatchDto.Model';

export class ProductPatchModel {
  PatchID: number;
  ProductID: number;
  NameEN: string;
  NameAR: string;
  Balance : number;
  OriginalPrice:number
  Price : number;
  Discount : number;
  DescriptionEN: string;
  DescriptionAR: string;
  Image: string;
  ImageExist: boolean;
  AddedToCart: boolean;
  AddedToFav: boolean;
  IsAvailable: boolean;
  Category: LookupsModel = new LookupsModel();
  Brand: LookupsModel = new LookupsModel();
  Type: LookupsModel = new LookupsModel();
  Color: LookupsModel = new LookupsModel();
  LastModifiedTime: Date;
  LocationTackingNumber: string;
  InventoryShelfNumber: string;

  Quantity: number;
  OrderId: number;
  OrderItemId: number;
  OrderStatus: number;
  BrandDto: ProductBrandDTO[];
  ProductsBrandsSliderIds: number[];
}
