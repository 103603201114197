<div class="row">
    <div class="col-6">
        <h4 class="d-block  "> {{ "MainShineImage" | translate }} </h4>
    </div>
</div>

<div class="row">

    <div class="card p-2 m-2" style="width: 18rem;">
        <label for="text text-danger align-self-center">{{"TodayOffers" | translate}}</label>
        <img id="imgup" class="img-thumbnail align-self-center border-0 img-fluid mx-4 p-1" [src]=" imageSourceTodaySpecial" />
        <div class="card-body p-1">
            <input class="form-control p-1" placeholder="{{'UploadFile' | translate}}" id="upload-file" type="file" accept="image/png, .jpeg, .jpg, .bmp" (change)="fileChangeEvent($event)" />
            <!-- <input type="text" class="form-control p-1 mt-1" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1"> -->
        </div>

        <div *ngIf="showProductImageReqMesg" class="text text-danger px-3">
            {{ "ProductImageisrequired" | translate }}
        </div>
        <div class="card-footer text-info text-center p-1">
            <button class="btn btn-default p-1 mt-1" [disabled]="showLoadingTodaySpecial  " (click)="SaveTodaySpecial()">{{"Save" | translate}}</button>
        </div>
        <span *ngIf="isSuccessTodaySpecial" class="card-img-bottom text-success text-center p-1">{{ "successMsg"| translate}}</span>
    </div>

    <div class="card p-2 m-2" style="width: 18rem;">
        <label for="text text-danger align-self-center">{{"SalesDiscount" | translate}}</label>
        <img id="imgup" class="img-thumbnail align-self-center border-0 img-fluid mx-4 p-1" [src]="imageSourceSalesDiscount" />
        <div class="card-body p-1">
            <input class="form-control p-1" placeholder="{{'UploadFile' | translate}}" id="upload-file" type="file" accept="image/png, .jpeg, .jpg, .bmp" (change)="fileChangeEventSales($event)" />
            <!-- <input type="text" class="form-control p-1 mt-1" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1"> -->
        </div>

        <div *ngIf="showProductImageReqMesg" class="text text-danger px-3">
            {{ "ProductImageisrequired" | translate }}
        </div>
        <div class="card-footer text-info text-center p-1">
            <button class="btn btn-default p-1 mt-1" [disabled]="showLoading" (click)="SaveSalesDiscount()">{{"Save" | translate}}</button>
        </div>
        <span *ngIf="isSuccessSalesDiscount" class="card-img-bottom text-success text-center p-1">{{ "successMsg"| translate}}</span>
    </div>
</div>