export class QuantityDiscountRequest {
    ID: number;
    ProductID: number;
    QuantityPrices: QuantityPriceRequest[];
    CreatedBy: string;
    CreatedTime: string ;
    LastModifiedBy: string;
    LastModifiedTime: string ;
}

export class QuantityPriceRequest {
    QuantityFrom: number;
    PercentageType: boolean;
    Amount: number;
}

