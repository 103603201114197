export class ProductCategoryModel
{
    ID: number;
    NameAR : string;
    NameEN : string;
    Image : string;
    ImageExist: boolean;
    LastModifiedTime: Date;
    IsDeleted : boolean = false;
    IsRetail?:number;
    CategoryOrder: number;

}

