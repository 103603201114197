import { Component, OnInit } from '@angular/core';
import { ProductTypeDTO } from 'src/app/models/ProductTypeDTO.model';
import { AddEditProductTypeRequest } from 'src/app/models/AddEditProductTypeRequest.model';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { QueryService } from 'src/app/services/query.services';
import { CommandService } from 'src/app/services/command.service';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { ProductTypeRequest } from 'src/app/models/ProductTypeRequest.model';

@Component({
  selector: 'app-product-type-add-edit',
  templateUrl: './product-type-add-edit.component.html',
  styleUrls: ['./product-type-add-edit.component.scss']
})
export class ProductTypeAddEditComponent implements OnInit {

  IsArabic = true;
  title: string;
  productType: ProductTypeDTO = new ProductTypeDTO();

  _AddEditProductCategoryRequst: AddEditProductTypeRequest = new AddEditProductTypeRequest();
  errMsg: string;
  showLoading: boolean;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private queryService: QueryService,
    private commandService: CommandService,
    private translateService: TranslateService,
    private titleService: Title
  ) { }

  ngOnInit() {
    this.loadAfterLang(this.translateService.currentLang);
    this.translateService.onLangChange.subscribe(res => this.loadAfterLang(res.lang));
    this.getProductTypeById();
  }

  loadAfterLang(lang: string) {
    this.IsArabic = (lang === 'ar');

    this.route.params.subscribe((params: Params) => {
      let id = params['ID'];
      if (id == 0) {
        this.title = this.translateService.instant("AddProductType");
      }
      else {
        this.title = this.translateService.instant("EditProductType");
      }

      this.titleService.setTitle(this.title);
    });


  }

  getProductTypeById() {
    try {
      this.route.params.subscribe((params: Params) => {
        this.productType.ID = params['ID'];

        if (!this.productType.ID || this.productType.ID <= 0) {
          return;
        }
        this.errMsg = "";
        this.showLoading = true;
        let productRequest = new ProductTypeRequest();
        productRequest.ID = this.productType.ID;

        this.queryService.SearchProductType(productRequest).subscribe(res => {
          this.showLoading = false;
          if (res.HasError) {
            this.errMsg = res.ErrorMessage;
            return;
          }
          this.productType = res.Result.ItemList[0];

        }, err => {
          this.errMsg = err;
          this.showLoading = false;
        });

      });

    } catch (err) {
      this.errMsg = err;
      this.showLoading = false;

    }
  }

  saveProductType() {
    try {
      this.errMsg = "";
      this.showLoading = true;
      this._AddEditProductCategoryRequst.ProductTypeDTO = this.productType;
      this.commandService.AddEditProductType(this._AddEditProductCategoryRequst).subscribe(res => {
        this.showLoading = false;
        if (res.HasError) {
          if(res.ErrorMessage=="NotUniquo")
          {
            var check = confirm(this.translateService.instant("Namealreadyexists"))
            return;
          }
          this.errMsg = res.ErrorMessage;
          return;
        }

        this.router.navigateByUrl('adminhome/producttypeadminlist')

      }, err => {
        this.errMsg = err;
        this.showLoading = false;
      });
    } catch (err) {
      this.errMsg = err;
      this.showLoading = false;

    }

  }

}
