<div class="h-100 overflow-auto">
  <div class="bd-highlight p-2">
    <h4 class="p-2 mb-0">{{ title }}</h4>
  </div>

  <div class="text text-danger px-3">
    <span *ngFor="let o of errors">
      {{ o }}
    </span>
  </div>

  <div class="alert alert-success fixed-top text-center m-1" *ngIf="showInfo">
    <app-alert-message [msg]="infoMsg | translate"></app-alert-message>
  </div>
  <div class="alert alert-danger fixed-top text-center m-1" *ngIf="showAlert">
    <app-alert-message [msg]="errMsg | translate"></app-alert-message>
  </div>

  <!-- start this to make loading center in page on any screen size -->
  <div
    *ngIf="showLoading"
    class="
      d-flex
      flex-column
      z-index-3
      position-fixed
      w-100
      h-100
      justify-content-center
      align-items-center
      bg-white
    "
    style="opacity: 0.5"
  >
    <div class="p-2 align-self-center">
      <span class="load-more-List text-center vw-100 vh-100">
        <span class="spinner-border text-primary" role="status"> </span>
      </span>
    </div>
  </div>

  <form
    #AddEditFrom="ngForm"
    (ngSubmit)="AddEditFrom.form.valid && SaveUserPassword()"
    autocomplete="off"
  >
    <div class="row mt-1 mb-1">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <label> {{ "FullName" | translate }} : {{ userDto.FullName }}</label>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <label>
          {{ "PhoneNumber" | translate }} : {{ userDto.PhoneNumber }}</label
        >
      </div>

      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <label> {{ "password" | translate }} </label>

        <input
          name="Password"
          type="password"
          class="form-control mb-2 rounded"
          [(ngModel)]="userDto.Password"
          placeholder="{{ 'password' | translate }}"
          #Password="ngModel"
          required
        />
        <div
          *ngIf="AddEditFrom.submitted && Password.errors?.required"
          class="text text-danger px-3"
        >
          {{ "passwordisrequired" | translate }}
        </div>
      </div>
      <!-- <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <label> {{ "confrimpassword" | translate }} </label>

        <input name="ConfirmPassword" type="password" class="form-control mb-2 rounded"
          [(ngModel)]="userDto.ConfirmPassword" placeholder="{{ 'confrimpassword' | translate }}"
          #ConfirmPasswords="ngModel" [required]=" userDto.Password != '' " />
        <div *ngIf="AddEditFrom.submitted && ConfirmPasswords.errors?.required" class="text text-danger px-3">
          {{ "ConfirmPasswordisrequired" | translate }}
        </div>
        <div *ngIf="AddEditFrom.submitted && ConfirmPasswords.errors?.mustMatch" class="text text-danger px-3">
          {{"PasswordNotmatched" | translate}}
        </div>

      </div> -->
    </div>
    <div class="row mb-1">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <button class="btn btn-primary bg-red border-danger" type="submit">
          {{ "Save" | translate }}
        </button>
        <button
          *ngIf="userDto.UserCategoryId === UserCategory.Client"
          class="btn btn-primary bg-blue border-0 m-1"
          routerLink="/adminhome/adminmobileuserlist"
        >
          {{ "Cancel" | translate }}
        </button>
        <button
          *ngIf="userDto.UserCategoryId === UserCategory.Sales"
          class="btn btn-primary bg-blue border-0 m-1"
          routerLink="/adminhome/adminuserlist"
        >
          {{ "Cancel" | translate }}
        </button>
      </div>
    </div>
  </form>
</div>
