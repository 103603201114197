import { LookupsModel } from 'src/app/models/Lookups.model';
import { ZoneDto } from './ZoneDto';
import { OrdersSummaryDto } from './OrdersSummaryDto';
import { ProductModel } from "./Product.Model";
import { UserAddressDto } from "./UserAddressDto.Model";
import { ProductPatchModel } from "./ProductPatch.model";
import { AspNetUser } from "./AspNetUser";
import { ProductAvilability } from './ProductAvilability';

export class OrderModel {
  ID: number;
  OrderDate: string;
  DeliveryDate: string;
  UserID: string;
  OrderStatus: number;
  CreatedTime: string;
  LastModifiedTime: string;
  Address: UserAddressDto = new UserAddressDto();
  AspNetUser: AspNetUser = new AspNetUser();
  MobileLong: number;
  MobileLat: number;
  AddressLocation: string;
  Expanded: boolean = false;
  OrderItems: OrderItemModel[] = [];
  Total: number;
  PrmoCode: string;
  Discount: number;
  DiscountAmount: number;
  TotalAfterDiscount: number;
  HasDiscount: boolean;
  OrdersSummaryDto: OrdersSummaryDto[] = [];
  DeliveryFees: number;
  TotalAfterDeliveryFees: number;
  Zone: LookupsModel;
  City: LookupsModel;
  ProductsAvilability: ProductAvilability[];
  PromoCodeType: number;

  GrossAmount: number;
  NetAmount: number;
  PromoCodeAmount: number;

}

export class OrderItemModel {
  ID: number;
  OrderID: number;
  ProductID: number;
  PatchId: number;
  Product: ProductPatchModel = new ProductPatchModel();
  AspNetUser: AspNetUser = new AspNetUser();
  Quantity: number;
  Price: number;
  Total: number;
  isDeleted: boolean = false;

  TotalPriceItem: number;
  DiscountValue: number;
  DiscountPercentage: number;
  TotalDiscountLine: number;

}

export class OrderExcelModel {
  OrderDate: Date;
  OrderNumber: number;
  Package_Serial: number;
  Description: string;
  Total_Weight: string;
  Service: string;
  Service_Type: string;
  Service_Category: string;
  Payment_Type: string;
  COD_Value: number;
  COD_Total: number;
  Quantity: number;
  Weight: string;
  Dimensions: string;
  Item_Category: string;
  Item_Special_Notes: string;
  Customer_Name: string;
  Customer_Mobile_No: string;
  Reciver_Mobile_No: string;
  Building_No: string;
  Street: string;
  Floor_No: string;
  Apartment_No: string;
  City: string;
  Neighborhood: string;
  District: string;
  Address_Category: string;
  RefNumber: string;
  OrderTotal: number;
  ProductNameEN: string;
  ProductNameAR: string;
  ReciverName: string;
  DeliveryDate: string;
  LocationTackingNumber: string;
  InventoryShelfNumber: string;
  DeliveryFees: number;
  DiscountType: string;
  DiscountAmount: number;
  DiscountCode: string;
  ProductId: number;
}

export class OrderExcelMylerzModel {
  OrderDate: Date;
  OrderNumber: number;
  Address_Description: string;
  Customer_Name: string;
  Customer_Mobile_No: string;
  Reciver_Mobile_No: string;
  Quantity: number;
  ReciverName: string;
  DeliveryFees: number;
  TotalAfterDeliveryFees: number;

  DeliveryDate: string;
  Building_No: string ;
  Street: string ;
  Floor_No: string;
  Apartment_No: string;
  City: string ;
  Neighborhood: string; 
}