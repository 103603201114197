<div class="d-flex flex-column h-100 w-100 p-2">
    <div class="bd-highlight py-2">
        <div class="row">
            <div class="col-md-4">
                <div class="bd-highlight">
                    <h4>{{ title }}</h4>
                </div>
            </div>
            <div class="col-md-2 offset-md-6">
                <button class="btn d-flex btn-primary ml-auto bg-red bg-blue border-0" routerLink="/adminhome/productbrandaddedit/0">
          {{ "AddProductBrand" | translate }}
        </button>
            </div>
        </div>
    </div>
    <!-- #region Search Criteria -->
    <div class="row mb-2 w-100">
        <div class="col-lg-8 col-sm-6">
            <div class="row">
                <label class="col-2 p-0 d-flex align-self-center"> {{ "ProductBrand" | translate }}</label>
                <input class="form-control col-3 mr-2 rounded" placeholder="{{ 'SearchByArabic' | translate }}" [(ngModel)]="ProductBrandRequest.NameAR" />
                <input class="form-control col-3 ml-2 rounded" placeholder="{{ 'SearchByEnglish' | translate }}" [(ngModel)]="ProductBrandRequest.NameEN" />
                <input class="form-control col-3 ml-2 rounded" placeholder="{{ 'SearchByID' | translate }}" [(ngModel)]="ProductBrandRequest.ID" />
            </div>

            <div class="row m-2">
                <div class="col-2">
                    <label for="isRetail">{{ "IsRetail"| translate}}</label>
                </div>
                <div class="col-2">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" #IsRetailInput (ngModelChange)="onChanges($event)" name="isRetail" id="Salon" value="1" [(ngModel)]="ProductBrandRequest.IsRetail" checked>
                        <label class="form-check-label" for="salon">{{"Shine" | translate}}</label>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" #IsRetailInput (ngModelChange)="onChanges($event)" name="isRetail" id="Retail" value="2" [(ngModel)]="ProductBrandRequest.IsRetail">
                        <label class="form-check-label" for="retail">{{"2assar" | translate}}</label>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" #IsRetailInput (ngModelChange)="onChanges($event)" name="isRetail" id="Retail" value="3" [(ngModel)]="ProductBrandRequest.IsRetail">
                        <label class="form-check-label" for="retail">{{"BothAppType" | translate}}</label>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-3 d-flex justify-content-end">
                    <button class="btn btn-primary bg-red border-0" (click)="onSearchSubmit()">{{ "Search" | translate }}</button>
                </div>
            </div>

        </div>

    </div>
    <!-- header -->
    <!-- <div class="w-100"> -->
    <!-- <div class="d-flex flex-row bg-secondary text-white mb-1">
      <div class="p-2 w-15">{{ "ProductBrandAr" | translate }}</div>
      <div class="p-2 w-15">{{ "ProductBrandEn" | translate }}</div>
      <div class="p-2 w-15">{{ "BrandImage" | translate }}</div>
      <div class="p-2 w-15">{{ "ID" | translate }}</div>
      <div class="p-2 w-15">{{ "Edit" | translate }}</div>
      <div class="px-3 py-2 w-10">{{ "Delete" | translate }}</div>
    </div>
  </div> -->
    <!--#endregion  Search -->
    <!-- start this to make loading center in page on any screen size -->
    <div *ngIf="showListLoading" class="d-flex flex-column z-index-3 position-fixed w-100 h-100 justify-content-center align-items-center bg-white" style="opacity: 0.5;">

        <div class="p-2 align-self-center">
            <span class="load-more-List text-center vw-100 vh-100">
        <span class="spinner-border text-primary" role="status"> </span>
            </span>
        </div>
    </div>
    <!--#endregion table -->
    <!-- <div class="h-100 w-100 overflow-auto">
    <div *ngFor="let item of productBrands.ItemList"
      class="d-flex flex-row row border-bottom border-info bg-white overflow-hidden p-1 mb-1 position-relative">
      <div class="px-1 py-1 w-15">
        <a class="text-dark">
          {{ item.NameAR }}
        </a>
      </div>
      <div class="px-3 py-1 w-15">
        <a class="text-dark">
          {{ item.NameEN }}
        </a>
      </div>

      <div class="px-3 py-1 w-15">
        <a class="text-dark">
          <img height="30" width="30" src="{{getImageFullPath(item.ImageExist, item.Image, item.LastModifiedTime)}}" />
        </a>
      </div>

      <div class="px-3 py-1 w-15">
        <a class="text-dark">
          {{ item.ID }}
        </a>
      </div>
      <div class="px-4 py-1 w-15">
        <a class="px-2" routerLink="/adminhome/productbrandaddedit/{{ item.ID }}">
          <i class="fa fa-pencil" aria-hidden="true"></i>
        </a>
      </div>
      <div class="px-5 py-1 w-10">
        <a (click)="Delete(item)">
          <i class="fa fa-trash-o" aria-hidden="true"></i>
        </a>
      </div>
    </div>
    <div *ngIf="productBrands.ItemList?.length==0"
      class="d-flex flex-column z-index-3  w-100 h-100 justify-content-center align-items-center bg-white">
      <label class="la">{{"NoDataFound"|translate}}</label>
    </div>
  </div> -->
    <!--#endregion table -->

    <div class="h-100 products__table">
        <table class="table table-striped table-sm" id="header-fixed">
            <thead>
                <tr>
                    <th scope="col">{{ "ProductBrandAr" | translate }}
                        <i (click)="onSortClick($event, 'NameAR')" class="my-icon fa fa-chevron-down"></i>
                    </th>
                    <th scope="col">{{ "ProductBrandEn" | translate }}
                        <i (click)="onSortClick($event, 'NameEN')" class="my-icon fa fa-chevron-up"></i>
                    </th>
                    <th scope="col">{{ "BrandImage" | translate }}
                    </th>
                    <th scope="col">{{ "ID" | translate }}
                        <i (click)="onSortClick($event, 'ID')" class="my-icon fa fa-chevron-up"></i>
                    </th>
                    <th scope="col">{{ "IsRetail" | translate }}
                        <i (click)="onSortClick($event, 'IsRetail')" class="my-icon fa fa-chevron-up"></i>
                    </th>
                    <th scope="col">{{ "Actions" | translate }}
                        <i class="fa fa-bolt" aria-hidden="true"></i>
                    </th>

                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of productBrands.ItemList">
                    <td>{{ item.NameAR }}</td>
                    <td>{{ item.NameEN }}</td>
                    <td><img src="{{getImageFullPath(item.ImageExist, item.Image, item.LastModifiedTime)}}" width="60" height="60"></td>
                    <td><a style="color: blue" routerLink="/adminhome/productbrandaddedit/{{ item.ID }}" target="_blank">{{item.ID }}</a></td>
                    <td>
                        <span *ngIf="item?.IsRetail ==1 ">{{ "Shine" | translate}}</span>
                        <span *ngIf="item?.IsRetail ==2 ">{{ "2assar" | translate}}</span>
                        <span *ngIf="item?.IsRetail ==3 ">{{ "BothAppType" | translate}}</span>
                    </td>
                    <td>
                        <div class="dropdown">
                            <a class="btn btn-primary" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fa fa-bars" aria-hidden="true" style="color:white !important;"></i>
                            </a>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item" (click)="Delete(item)">
                                    <i class="fa fa-info-circle" aria-hidden="true" style="color: slategray !important"></i> {{ "Delete" | translate }}
                                </a>

                                <a class="dropdown-item" routerLink="/adminhome/productbrandaddedit/{{ item.ID }}">
                                    <i class="fa fa-lg fa-pencil-square-o" aria-hidden="true" style="color: slategray !important"></i> {{ "Edit" | translate }}
                                </a>

                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

        <div *ngIf="productBrands.ItemList?.length==0" class="d-flex flex-column z-index-3  w-100 h-100 justify-content-center align-items-center bg-white">
            <label class="la">{{"NoDataFound"|translate}}</label>
        </div>

        <!-- #region Pagination -->
        <div class="row mt-2">
            <div class="col-xs-12 col-12 p-0">
                <app-pagination-component [ItemsCount]="productBrands.Total" (changePage)="onPagingChange($event)">
                </app-pagination-component>
            </div>
        </div>
        <!--#endregion Pagination -->
    </div>
</div>