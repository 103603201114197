import { environment } from './../../environments/environment.prod';
import { EditUserDto } from './../models/EditUserDto';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserCategoryEnum } from '../models/enum/usercategory.enum';
import { TranslateService } from '@ngx-translate/core';
import { RegisterBindingModel } from '../models/register.model';
import { configration } from '../shared/config';
import { userLogin } from '../models/userlogin.Model';
import { GlobalResponse } from '../models/GlobalResponse.Model';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { UserCategoryPagesDto } from '../models/UserCategoryPages';
import { DeleteUserModel } from '../models/DeleteUserModel';

@Injectable({
  providedIn: 'root'
})
export class LoginAdminService implements CanActivate {

  userCategoryPages: UserCategoryPagesDto[] = [];

  constructor(public translate: TranslateService, private router: Router, private _http: HttpClient) { }

  canActivate(route: ActivatedRouteSnapshot) {
    const pageId = parseInt(route.data.pageId)

    this.userCategoryPages = [];
    this.userCategoryPages = this.getUserCategoryPages();
    let userCatPage = this.userCategoryPages.find(x => x.PageId === pageId);
    if (userCatPage != null) {
      if (userCatPage.Allow === false) {
        this.router.navigateByUrl('/adminhome/accessdenied');
        return false;
      }
    }

    if (localStorage.getItem('userToken') == null || parseInt(this.getUserCategoryId()) == UserCategoryEnum.Client) {

      this.router.navigateByUrl('loginadmin');
      return false;
    }
    else {

      return true;
    }

  }

  getUserCategoryId = () => (localStorage.getItem('UserCategoryId') == null) ? "0" : localStorage.getItem('UserCategoryId');


  setCurrentCulture(key) {
    this.translate.use(key);
    localStorage.setItem('CurrentCulture', key);
  }

  getCurrentCulture() {
    if (localStorage.getItem("CurrentCulture") === null)
      this.setCurrentCulture('ar')
    return localStorage.getItem('CurrentCulture')
  }

  registerForm(register: RegisterBindingModel) {
    return this._http.post(environment.webApiEndPoint + configration.urls.Accounts.apiRegisterUrl, register)
    //.pipe(catchError(err => of(this.errorResponse(err))));
  }
  editUser = (register: EditUserDto) => this._http.post(environment.webApiEndPoint + configration.urls.Accounts.apiEditUserUrl, register)
    .pipe(catchError(err => of(this.errorResponse(err))));


  deleteUser = (deleteUserModel: DeleteUserModel): Observable<GlobalResponse<Boolean>> => this._http.post<GlobalResponse<Boolean>>
    (environment.webApiEndPoint + configration.urls.Accounts.apiUserDeleteUserUrl, deleteUserModel)
    .pipe(catchError((err) => of(this.errorResponse(err))));


  loginUser(LoginCredentials: userLogin) {
    let headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/x-www-form-urlencoded');
    let urlSearchParams = new URLSearchParams();
    urlSearchParams.set('grant_type', 'password');
    urlSearchParams.set('username', LoginCredentials.UserName);
    urlSearchParams.set('password', LoginCredentials.Password);
    let body = urlSearchParams.toString();
    return this._http.post(environment.webApiEndPoint + configration.urls.Accounts.apiTokenUrl, body, {
      headers: headers
    });
  }

  getUserToken = () => localStorage.getItem('userToken');

  setUserToken = (token) => localStorage.setItem('userToken', token);

  getUserCategoryPages = () => JSON.parse(localStorage.getItem("allowedUserCategoryPages") || "[]") as UserCategoryPagesDto[];

  setUserCategoryPages = (userCategoryPages: UserCategoryPagesDto[]) => localStorage.setItem('allowedUserCategoryPages', JSON.stringify(userCategoryPages));

  isLoggIn = () => (this.getUserToken() != null && parseInt(this.getUserCategoryId()) == UserCategoryEnum.Client) ? true : false;


  getUserInfo = () => this._http.get(environment.webApiEndPoint + configration.urls.Accounts.apiUserInfoUrl)
    .pipe(catchError(err => of(this.errorResponse(err))));


  errorResponse(err) {

    var response = new GlobalResponse<any>();
    response.Result = err;
    response.ErrorMessage = (err.status === 0 || err.status === 504 ? '' : err.message);
    response.HasError = true;
    return response;
  }

  setUserCategoryId = (Id) => localStorage.setItem('UserCategoryId', Id);


  setUserName = (username) => localStorage.setItem('userName', username)

  getUserName() {
    if (localStorage.getItem('userName') == null)
      return "";
    return localStorage.getItem('userName');
  }

  removeLocalStorage() {
    localStorage.removeItem('userToken');
    localStorage.removeItem('imgName');
    localStorage.removeItem('UserCategoryId');
    localStorage.removeItem('userName');
    localStorage.removeItem('CurrentCulture');
    localStorage.removeItem('allowedUserCategoryPages');
    localStorage.removeItem('isActive');
  }


  setUserIsActive = (isActive) => localStorage.setItem("isActive", isActive);
}
