<div class="h-100 overflow-auto">
    <div class="bd-highlight p-2">
        <h4 class="p-2 mb-0">{{ title }}</h4>
    </div>

    <div class="text text-danger px-3">
        <span *ngFor="let o of errors">{{ o }}</span>
    </div>

    <!-- start this to make loading center in page on any screen size -->
    <div *ngIf="showLoading" class="  d-flex  flex-column  z-index-3  position-fixed  w-100  h-100  justify-content-center  align-items-center  bg-white" style="opacity: 0.5">
        <div class="p-2 align-self-center">
            <span class="load-more-List text-center vw-100 vh-100">
                <span class="spinner-border text-primary" role="status"> </span>
            </span>
        </div>
    </div>

    <form #AddEditFrom="ngForm" (ngSubmit)="AddEditFrom.form.valid && SavesalesPerson()" autocomplete="off">
        <div class="row mt-1 mb-5">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <label> {{ "FullName" | translate }} </label>
                <input name="FullName" #FullName="ngModel" type="text" class="form-control mb-2 rounded" [(ngModel)]="mobileUser.Name" placeholder="{{ 'FullName' | translate }}" />
                <div *ngIf="AddEditFrom.submitted && FullName.errors?.required" class="text text-danger pl-2 pr-2">
                    {{ "Nameisrequired" | translate }}
                </div>
            </div>

            <div *ngIf="!mobileUser.IsRetail" class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <label> {{ "SalonName" | translate }} </label>
                <input name="SalonName" #SalonName="ngModel" type="text" class="form-control mb-2 rounded" [(ngModel)]="mobileUser.SalonName" placeholder="{{ 'SalonName' | translate }}" />

            </div>

            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <label> {{ "phonenumber" | translate }} </label>

                <input name="PhoneNumber" onlynumber type="text" class="form-control mb-2 rounded" [(ngModel)]="mobileUser.PhoneNumber" maxlength="11" placeholder="{{ 'phonenumber' | translate }}" #PhoneNumber="ngModel" required />
                <div *ngIf="AddEditFrom.submitted && PhoneNumber.errors?.required" class="text text-danger px-3">
                    {{ "phonenumberisrequired" | translate }}
                </div>
            </div>

            <div *ngIf="!mobileUser.IsRetail" class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="form-group">
                    <label>{{ "SalonType" | translate }}</label>
                    <div class="form-control">
                        <label class="radio-inline ml-3 mr-3">
                            <input type="radio" name="SalonType" value="0" checked [(ngModel)]="salonType" />
                            {{ "Men" | translate }}
                        </label>
                        <label class="radio-inline ml-3">
                            <input type="radio" name="SalonType" value="1" [(ngModel)]="salonType" />
                            {{ "Female" | translate }}
                        </label>
                    </div>
                </div>
            </div>

            <div *ngIf="!mobileUser.IsRetail" class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <label> {{ "VoucherID" | translate }} </label>
                <input name="VoucherID" type="text" class="form-control mb-2 rounded" [(ngModel)]="mobileUser.VoucherID" maxlength="11" placeholder="{{ 'VoucherID' | translate }}" #VoucherID="ngModel" />
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <label class="d-block w-100 hold-checkbox position-relative align-self-end">
                    <input class="form-control rounded" type="checkbox" name="IsAvailable"
                        [(ngModel)]="mobileUser.IsActive" />
                    <span class="checkmark"></span>
                    <span class="d-inline-block">{{ "Active" | translate }}</span>
                </label>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="!mobileUser.IsRetail">
                <div class="col-4">
                    <label for="isRetail">{{ "IsRetail"| translate}}</label>
                </div>
                <div class="col-3">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="IsRetail" id="Retail" required #IsRetailInput="ngModel" [value]="true" [(ngModel)]="mobileUser.IsRetail">
                        <label class="form-check-label" for="Retail">{{"Shine" | translate}}</label>
                    </div>
                </div>
                <div class="col-3">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="IsRetail" id="Salon" required #IsRetailInput="ngModel" [value]="false" [(ngModel)]="mobileUser.IsRetail">
                        <label class="form-check-label" for="Salon">{{"2assar" | translate}}</label>
                    </div>
                </div>
            </div>


            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="isPromoCode && !mobileUser.IsRetail">
                <label> {{ "PromoCode" | translate }} </label>
                <input name="PromoCode" type="text" disabled="true" class="form-control mb-2 rounded" [(ngModel)]="mobileUser.PromoCode" placeholder="{{ 'PromoCode' | translate }}" #Password="ngModel" />
                <div *ngIf="AddEditFrom.submitted && Password.errors?.required" class="text text-danger px-3">
                    {{ "PromoCodeisrequired" | translate }}
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="isPromoCode && !mobileUser.IsRetail">
                <div class="form-group">
                    <label>{{ "PromoCodeType" | translate }}</label>
                    <div class="form-control">
                        <label class="radio-inline ml-3 mr-3">
                            <input type="radio" name="PromoCodeType" value="0" checked [(ngModel)]="promoCodeType"
                                (change)="changePromoCodeLabel('PromoCodePercentage')" />
                            {{ "Percentage" | translate }}
                        </label>
                        <label class="radio-inline ml-3">
                            <input type="radio" name="PromoCodeType" value="1" [(ngModel)]="promoCodeType"
                                (change)="changePromoCodeLabel('PromoCodeAmount')" />
                            {{ "Amount" | translate }}
                        </label>
                    </div>
                </div>

                <label> {{ promoCodeTypeText | translate }} </label>
                <input name="PromoCodeAmount" onlynumber type="text" class="form-control mb-2 rounded" [(ngModel)]="mobileUser.PromoCodeAmount" placeholder="{{ 'PromoCodeAmount' | translate }}" #PromoCodeType="ngModel" />
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-4" *ngIf="isPromoCode && !mobileUser.IsRetail">
                <button class="btn btn-danger bd-highlight" type="button" (click)="DeletePromoCode()">{{ "Delete" |translate }}</button>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-4" *ngIf="!isPromoCode && !mobileUser.IsRetail">
                <button class="btn btn-primary bd-highlight" type="button" (click)="GeneratePromoCode()">{{
                    "GeneratePromoCode" | translate }}</button>
            </div>

            <!-- <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-4">
                <a class="btn btn-primary bd-highlight" [routerLink]="['../../addneworder', 5555]">{{ "CreateOrder" | translate }}</a>
            </div> -->


            <!-- user address -->
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-4">
                <button *ngIf="!addAddress" class="btn btn-primary bd-highlight" type="button" (click)="addAddress = true">{{ "AddAddress" | translate }}</button>
                <button *ngIf="addAddress" class="btn btn-danger bd-highlight" type="button" (click)="addAddress = false">{{ "RemoveAddress" | translate }}</button>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-4"></div>


            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 " *ngIf="addAddress">
                <label> {{ "ReciverName" | translate }} </label>
                <input name="ReceiverNames" type="text" class="form-control mb-2 rounded" [(ngModel)]="mobileUser.UserAddress.ReceiverName" placeholder="{{ 'ReciverName' | translate }}" #ReceiverNames="ngModel" />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 " *ngIf="addAddress">
                <label> {{ "ReciverMobile" | translate }} </label>
                <input name="ReceiverNumber" type="text" class="form-control mb-2 rounded" [(ngModel)]="mobileUser.UserAddress.ReciverMobile" placeholder="{{ 'ReciverMobile' | translate }}" #ReceiverNumber="ngModel" />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="addAddress">
                <label> {{ "Area" | translate }} </label>

                <a (click)="EditCityZone()">
                    <input #CityZones="ngModel" readonly name="City" type="text" placeholder="{{ 'CityZone' | translate }}" class="form-control mb-2 rounded" [(ngModel)]="cityZone" />
                </a>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="addAddress">
                <label> {{ "AddressDescription" | translate }} </label>
                <input name="Description" type="text" class="form-control mb-2 rounded" #AddressDescription="ngModel" [(ngModel)]="mobileUser.UserAddress.Description" placeholder="{{ 'AddressDescription' | translate }}" />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="addAddress">
                <label> {{ "BuildNumber" | translate }} </label>
                <input name="BuildNumber" type="text" class="form-control mb-2 rounded" [(ngModel)]="mobileUser.UserAddress.BuildNumber" placeholder="{{ 'BuildNumber' | translate }}" />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="addAddress">
                <label> {{ "Street" | translate }} </label>
                <input name="StreetName" type="text" class="form-control mb-2 rounded" [(ngModel)]="mobileUser.UserAddress.Street" placeholder="{{ 'Street' | translate }}" />
            </div>

        </div>
        <div class="row mb-1">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <button class="btn btn-primary bg-red border-danger" type="submit">
                    {{ "Save" | translate }}
                </button>
                <button class="btn btn-primary bg-blue border-0 m-1" routerLink="/adminhome/adminmobileuserlist">
                    {{ "Cancel" | translate }}
                </button>
            </div>
        </div>
    </form>

    <div bsModal #cityModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
        <div class="modal-dialog modal-lg h-100 w-100 m-0 p-0">
            <div class="modal-content h-100 w-100">
                <div class="modal-body h-100 w-100">
                    <app-city-zone-lookup [target]="'UserAddressADDEditComponent'"></app-city-zone-lookup>
                </div>
            </div>
        </div>
    </div>
</div>