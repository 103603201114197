import { OrderModel } from './../../../models/Order.Model';
import { OrderRequestNew } from './../../../models/OrderRequest.Model';
import { OrderRequest } from 'src/app/models/OrderRequest.Model';
import { GlobalResponse } from './../../../models/GlobalResponse.Model';
import { ProductModel } from './../../../models/Product.Model';
import { SearchResult } from './../../../models/SearchResult.Model';
import { QueryService } from './../../../services/query.services';
import { CommandService } from './../../../services/command.service';
import { Component, OnInit, Output, ElementRef, ViewChild, AfterViewInit } from "@angular/core";
import { CommunicationService } from "src/app/services/communication.service";
import { ActionType } from "src/app/models/Action";
import { ActivatedRoute, Params } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ProductRequest } from "src/app/models/ProductRequest.Model";
import { fromEvent } from "rxjs";
import { debounce, debounceTime, filter, map, tap } from "rxjs/operators";
// import { OrderModelNew } from 'src/app/models/Orders';

@Component({
  selector: "app-add-new-order",
  templateUrl: "./add-new-order.component.html",
  styleUrls: ["./add-new-order.component.scss"],
})
export class AddNewOrderComponent implements OnInit, AfterViewInit {
  showList: boolean = false;
  isArabic: boolean = false;
  showMenu: boolean = false;
  showAlert: boolean = false;
  showFilter: boolean = false;
  showListLoading = false;
  errMsg: string;
  searchName: string;
  orderId: string = "";
  userId:string;
  ProductRequest: ProductRequest;
  products: SearchResult<ProductModel> = new SearchResult<ProductModel>();
  order: OrderModel = new OrderModel();

  @ViewChild('searchProductInput', { static: false }) inputProductSearch: ElementRef;

  constructor(
    private queryService: QueryService,
    private _commandService: CommandService,
    private _comm: CommunicationService,
    private _trans: TranslateService,
    private activatedRoute: ActivatedRoute) {
    _trans.onLangChange.subscribe(() => this.isArabic = this._trans.currentLang == "ar");
  }

  ngAfterViewInit() {
    fromEvent(this.inputProductSearch.nativeElement, 'keydown')
      .pipe(filter((event: any) => event.keyCode == 13), map(event => event['target'].value))
      .subscribe(text => this.searchProduct(text));
  }

  searchProduct(productName: string) {
    this.showList = true;
    // this.getFilterState().SearchText = "";
    this.getFilterState().SearchText = productName;
    this._comm.queryProducts();
  }


  showProductFilterPoup=()=> this.showFilter = !this.showFilter;
  

  getFilterState=(): ProductRequest => this._comm.state.productListState.filter;
  

  ngOnInit() {
    // this.productId = route.snapshot.paramMap.get('id');

    this.activatedRoute.params.subscribe((params: Params) => this.orderId =  params["id"]);
    this.getUserDetail(this.orderId);
    this._comm.getAction().subscribe((a) => {
      if (a.actionType == ActionType.QueryProducts) this.showList = true;
    });
  }

  searchProductByName(productName: string) {
    try {
      this.errMsg = "";
      this.showListLoading = true;
      let request = this.setProductRequest(productName);
      this.queryService.getProductsAdmin(request).subscribe(res => {
        this.products = this.getProductListResponse(res);
        this.showListLoading = false;
      },
        (err) => {
          this.errMsg = err;
          this.showListLoading = false;
        });
    } catch (error) {
      this.errMsg = error;
      this.showListLoading = false;
    }
  }

  setProductRequest(text: string, pageIndex: number = 1): ProductRequest {
    let response = new ProductRequest();
    response.Lang = this._trans.currentLang;
    response.PageIndex = pageIndex;
    response.isAdminPage = true;
    if (response.Lang == 'ar')
      response.NameAR = text;
    else
      response.NameEN = text;
    return response;
  }

  getProductListResponse(res: GlobalResponse<SearchResult<ProductModel>>): SearchResult<ProductModel> {
    let result = new SearchResult<ProductModel>();
    if (res.HasError == false && res.Result != null)
      result = res.Result;
    else {
      result.ItemList = [];
      result.Total = 0;
      this.errMsg = res.ErrorMessage;
    }
    return result;
  }


  getOrder(request: OrderRequest) {
    try {
      this.showListLoading = true;
      this.queryService.getSearchOrder(request).subscribe(res => {
        this.showListLoading = false;
        if (res.HasError) {
          this.showAlert = true;
          this.errMsg = res.ErrorMessage;
          return;
        }
        this.order = res.Result;
        if (this.order.Address != null)
          this.order.Address = res.Result.Address;
      }, (err)=>{
        this.errMsg = err;
        this.showListLoading = false;
      });
    } catch (error) {
      this.errMsg = error;
      this.showListLoading = false;
    }
  }

  getUserDetail(orderId:string){
    let request = new OrderRequest();
    request.ID = + orderId;
    this.queryService.getSearchOrder(request).subscribe(res=> {
      if(res.HasError) return;
      this.userId = res.Result.UserID;
    })
  }
}
