<div class="container-fluid p-0 m-0  ">
  <div (swipeleft)="closealertMessage()" (swiperight)="closealertMessage()" *ngIf="showAlert"
    class="alert alert-danger fixed-top text-center m-1">
    <app-alert-message [msg]="msg"></app-alert-message>
  </div>

  <div class="container-fluid vh-100 p-0 m-0">
    <div class="row w-100 h-100">
      <div class="col-12 w-100  h-100   p-0">
        <div class="d-flex flex-column h-100   ">


          <!-- start   navbar  -->
          <!-- <div class="d-flex w-100      header    flex-column bd-highlight">
          <div class="  bd-highlight">
            <div class="navbar navbar-expand-lg navbar-light bg-light flex-row  shadow-lg  d-flex ">
              <div [class]="(isCollapsed ? ' w-15 ' : ' w-5 ') +  '  bg-info'">
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01"
                  aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
                  <div class="row  w-100  ">
                    <div class="col-12  p-3 ">

                    </div>
                  </div>

                </div>
              </div>
              <div [class]="(isCollapsed ? ' w-85' : ' w-95 ') +   '  d-flex justify-content-end  h-100 bd-highlight'">
                <div class="p-2 bd-highlight"></div>
                <div class="p-2 bd-highlight"></div>
                <div class="p-2 bd-highlight"></div>

              </div>
            </div>
          </div>
        </div> -->
          <!-- end   navbar  -->

          <!-- the content body -->
          <div class="d-flex w-100  h-100     flex-row bd-highlight   ">
            <div [class]="(isCollapsed ? ' w-15 ' : ' w-5 ') +  '  bd-highlight vh-100'">
              <side-menu-portal></side-menu-portal>
            </div>
            <div [class]="(isCollapsed ? ' w-85' : ' w-95 ') +   ' bd-highlight  h-100  overflow-auto '">
              <!-- admin home koko -->
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
