import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { QueryService } from "src/app/services/query.services";
import { TranslateService } from "@ngx-translate/core";
import { SearchResult } from "src/app/models/SearchResult.Model";
import { ProductColorDTO } from "src/app/models/ProductColorDTO.model";
import { ProductColorRequest } from "src/app/models/ProductColorRequest.model";
import { PageChangedEvent } from "ngx-bootstrap/pagination";
import { AddEditProductColorRequest } from "src/app/models/AddEditProductColorRequest.model";
import { CommandService } from "src/app/services/command.service";

@Component({
  selector: "app-product-color-admin-list",
  templateUrl: "./product-color-admin-list.component.html",
  styleUrls: ["./product-color-admin-list.component.scss"],
})
export class ProductColorAdminListComponent implements OnInit {
  _AddEditProductColorRequst: AddEditProductColorRequest = new AddEditProductColorRequest();
  productColors: SearchResult<ProductColorDTO> = new SearchResult<ProductColorDTO>();
  ProductColorRequest: ProductColorRequest;
  IsArabic = true;
  searchName = "";
  showListLoading = false;
  errMsg = "";
  pathOfAPI: string;
  title: string;
  sortDir: number = 0;

  constructor(
    private commandService: CommandService,
    private queryService: QueryService,
    private translateService: TranslateService,
    private titleService: Title
  ) { }

  ngOnInit() {
    this.setDefaultSearchValues();
    this.loadAfterLang(this.translateService.currentLang);
    this.translateService.onLangChange.subscribe((res) => this.loadAfterLang(res.lang));
    this.searchCaller(this.ProductColorRequest);
  }

  loadAfterLang(lang: string) {
    this.IsArabic = lang === "ar";
    this.title = this.translateService.instant("ProductColor");
    this.titleService.setTitle(this.title);
  }

  //#region "table Pagination and Search"
  setDefaultSearchValues = () => this.ProductColorRequest = new ProductColorRequest();


  onSearchSubmit() {
    this.ProductColorRequest.PageIndex = 1;
    this.searchCaller(this.ProductColorRequest);
  }

  onChanges = (data: any) => {
    this.ProductColorRequest.IsRetail = data
    this.searchCaller(this.ProductColorRequest);
  }

  onPagingChange(event: PageChangedEvent) {
    this.ProductColorRequest.PageIndex = event.page;
    this.ProductColorRequest.PageSize = event.itemsPerPage;

    this.searchCaller(this.ProductColorRequest);
  }

  searchCaller(request: ProductColorRequest) {
    try {
      this.errMsg = "";
      this.showListLoading = true;

      // if (this.IsArabic) {
      //   this.ProductColorRequest.NameAR = this.searchName;
      //   this.ProductColorRequest.NameEN = '';
      // } else {
      //   this.ProductColorRequest.NameEN = this.searchName;
      //   this.ProductColorRequest.NameAR = '';
      // }
      request.Lang = this.translateService.currentLang;

      this.queryService.SearchProductColor(request).subscribe(
        (res) => {
          if (res.HasError == false && res.Result != null)
            this.productColors = res.Result;
          else {
            this.productColors.ItemList = [];
            this.productColors.Total = 0;

            this.errMsg = res.ErrorMessage;
          }
        },
        (err) => this.errMsg = err,
        () => this.showListLoading = false
      );
    } catch (err) {
      this.errMsg = err;
      this.showListLoading = false;
    }
  }

  clearSearch() {
    this.setDefaultSearchValues();
    this.searchCaller(this.ProductColorRequest);
  }

  Delete(item: ProductColorDTO) {
    var check = confirm(this.translateService.instant("AreYouSureDeleteColors"));
    if (check) {
      try {
        this.errMsg = "";
        this.showListLoading = true;
        this._AddEditProductColorRequst.ProductColorDTO = item;
        this._AddEditProductColorRequst.ProductColorDTO.IsDeleted = true;
        this.commandService
          .AddEditProductColor(this._AddEditProductColorRequst)
          .subscribe(
            (res) => {
              this.showListLoading = false;
              if (res.HasError) {
                if (res.ErrorMessage == "NotDeleted") {
                  var check = confirm(
                    this.translateService.instant("DeleteColor")
                  );
                  return;
                }
                this.errMsg = res.ErrorMessage;
                return;
              }

              this.searchCaller(this.ProductColorRequest);
            },
            (err) => this.errMsg = err,
            () => this.showListLoading = false
          );
      } catch (err) {
        this.errMsg = err;
        this.showListLoading = false;
      }
    }
  }

  // on Clcik Sort
  onSortClick(event: any, colName: string) {
    let classList = event.currentTarget.classList;
    this.changeStyleOnSortCLicked(classList)
    let request = this.changeRequestOnSortClicked(this.ProductColorRequest, colName);
    this.searchCaller(request);
  }

  changeRequestOnSortClicked(request: ProductColorRequest, columnName: string) {
    let changedRequest = request;
    changedRequest.Lang = this.translateService.currentLang;
    changedRequest.SortColumn = columnName;
    changedRequest.SortDirection = this.sortDir;
    return changedRequest;
  }

  changeStyleOnSortCLicked(classList: any) {
    /* sort DESC */
    if (classList.contains('fa-chevron-up')) {
      classList.remove('fa-chevron-up');
      classList.add('fa-chevron-down');
      this.sortDir = 0;

      /* sort ASC */
    } else {
      classList.add('fa-chevron-up');
      classList.remove('fa-chevron-down');
      this.sortDir = 1;
    }
  }
  //#endregion "table Pagination and Search"
}
