<div class="d-flex flex-column w-100 h-100 p-2">
    <div *ngIf="errMsg" class="text-danger mr-auto p-2 bd-highlight">
        {{ errMsg }}
    </div>
    <div *ngIf="showLoading" class="d-flex flex-column z-index-3 position-fixed w-100 h-100 justify-content-center align-items-center bg-white" style="opacity: 0.5">
        <div class="p-2 align-self-center">
            <span class="load-more-List text-center vw-100 vh-100">
        <span class="spinner-border text-primary" role="status"> </span>
            </span>
        </div>
    </div>
    <div class="bd-highlight py-2">
        <div class="row">
            <div class="col-md-4">
                <div class="bd-highlight">
                    <h4>{{ title }}</h4>
                </div>
            </div>
        </div>
    </div>
    <form #pushnotification="ngForm" (ngSubmit)="pushnotification.form.valid && onSubmit()" autocomplete="off">
        <div class="bd-highlight">
            <!-- #region Search  -->
            <!-- #region Search Criteria -->
            <div class="row mb-2">
                <label class="col-2"> {{ "Titel" | translate }} </label>
                <div class="col-9">
                    <input name="titelinput" #titelinput="ngModel" class="form-control col-9 rounded" [(ngModel)]="request.Title" required />
                    <div *ngIf=" titelinput.errors?.required && titelinput.touched" class="text text-danger px-3">
                        {{ "NotificationTitelisrequired" | translate }}
                    </div>
                </div>
            </div>

            <div class="col-8 d-inline-flex bd-highlight align-items-center ">
                <div class="col-3">
                    <label for="isRetail">{{ "IsRetail"| translate}}</label>
                </div>
                <div class="col-3 ">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="IsRetail" id="Retail" required #IsRetailInput="ngModel" [value]="false" [(ngModel)]="request.IsRetail">
                        <label class="form-check-label" for="Retail">{{"2assar" | translate}}</label>
                    </div>
                </div>
                <div class="col-3">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="IsRetail" id="Salon" required #IsRetailInput="ngModel" [value]="true" [(ngModel)]="request.IsRetail">
                        <label class="form-check-label" for="Salon">{{"Shine" | translate}}</label>
                    </div>
                </div>

                <div *ngIf="pushnotification.submitted && IsRetailInput.errors?.required" class="text text-danger px-3">{{ "Retailisrequired" | translate }}</div>
            </div>

            <!-- <div class="row mt-2">
                <div class="col-2">
                    <label for="isRetail">{{ "IsRetail"| translate}}</label>
                </div>
                <div class="col-2">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" #IsRetailInput name="isRetail" id="Salon" value="false" [(ngModel)]="request.IsRetail" checked required>
                        <label class="form-check-label" for="salon">{{"2assar" | translate}}</label>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" #IsRetailInput name="isRetail" id="Retail" value="true" [(ngModel)]="request.IsRetail" required>
                        <label class="form-check-label" for="retail">{{"Shine" | translate}}</label>
                    </div>
                </div>

            </div> -->

            <div class="row mb-2">
                <label class="col-2"> {{ "Body" | translate }} </label>
                <div class="col-9">
                    <!-- <input name="bodyinput" #bodyinput="ngModel" class="form-control col-9 rounded" [(ngModel)]="request.Body"
                required /> -->
                    <textarea name="bodyinput" #bodyinput="ngModel" class="form-control col-9 rounded" [(ngModel)]="request.Body" required></textarea>
                    <div *ngIf="bodyinput.errors?.required && bodyinput.touched" class="text text-danger px-3">
                        {{ "NotificationBodyisrequired" | translate }}
                    </div>
                </div>
            </div>

            <div class="row mb-2">
                <label class="col-2"> {{ "NotificationType" | translate }} </label>
                <div class="col-9">
                    <select class="form-control col-9 rounded" *ngIf="isArabic()" (change)="onChangeNotificationType($event.target.value)">
                        <option  *ngFor="let item of NotificationTypeList" [value]="item.ID">{{item.NameAR}}</option>
                    </select>
                    <select class="form-control col-9 rounded" *ngIf="!isArabic()" (change)="onChangeNotificationType($event.target.value)">
                        <option  *ngFor="let item of NotificationTypeList" [value]="item.ID">{{item.NameEN}}</option>
                    </select>
                </div>

                <!-- <label class="d-block w-100 hold-checkbox position-relative align-self-end">
                    <input class="form-control rounded" type="checkbox" [(ngModel)]="item.ID"/>
                    <span class="checkmark"></span>
                    <span class="d-inline-block" *ngIf="isArabic()"> {{ item.NameAR }}</span>
                    <span class="d-inline-block" *ngIf="!isArabic()"> {{ item.NameEN }}</span>
                  </label> -->
            </div>


            <div class="row mb-2" *ngIf="IsOtherNotificationType">
                <label class="col-2"> {{ "ItemTypeId" | translate }} </label>
                <div class="col-9">
                    <!-- <input name="bodyinput" #bodyinput="ngModel" class="form-control col-9 rounded" [(ngModel)]="request.Body"
          required /> -->
                    <input name="ItemTypeId" #ItemTypeIdInput="ngModel" onlynumber class="form-control col-9 rounded" [(ngModel)]="request.ItemId" required/>
                    <div *ngIf=" ItemTypeIdInput.errors?.required &&ItemTypeIdInput.touched " class="text text-danger px-3">
                        {{ "NotificationBodyisrequired" | translate }}
                    </div>
                </div>
            </div>


            <div class="text-center m-3">
                <span class="text text-success" *ngIf="isSuccessPush">{{error | translate}}</span>
                <span class="text text-danger" *ngIf="!isSuccessPush">{{error  | translate}}</span>
            </div>

            <div class="row">
                <div class="col-9 d-flex justify-content-end">
                    <button class="btn btn-primary bg-blue border-0 mr-1 ml-1" [disabled]="pushnotification.invalid">
            {{ "Publish" | translate }}
          </button>
                    <button class="btn btn-primary bg-red border-0 mr-1 ml-1" routerLink="/adminhome/ProductAdminList">
            {{ "Cancel" | translate }}
          </button>
                </div>
            </div>
        </div>
    </form>
</div>