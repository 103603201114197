export class  ProductSupplierDto {
    ID: number;
    NameEN: string;
    NameAR: string;
    Image: string;
    IsDeleted: boolean;
    ImageExist: boolean;
    PhoneNumber: string;
    Email: string;
    IsRetail?: number;
}
