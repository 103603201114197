import { configration } from "../shared/config";

export class ProductCategoryRequest {
  NameAR: string;
  NameEN: string;
  ID: number;
  inAdminPage: boolean = true;
  PageIndex: number = 1;
  PageSize: number = configration.pageSize;
  Lang: string;
  IsRetail?: number;
}
