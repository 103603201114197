import { UserNotesRequest } from './../models/UserNotes/UserNotesRequest';
import { environment } from './../../environments/environment.prod';
import { OrdersConfigurationsDto } from './../models/OrdersConfigurationsDto';
import { OrdersConfigurationsRequest } from 'src/app/models/OrdersConfigurationsRequest';
import { UpdateUserCategoryPagesRequest } from './../models/UpdateUserCategoryPagesRequest';
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { GlobalResponse } from "../models/GlobalResponse.Model";
import { AddEditUserAddressRequst } from "../models/AddEditUserAddressRequst.Model";
import { configration } from "../shared/config";
import { catchError } from "rxjs/operators";
import { OrderModel } from "../models/Order.Model";
import { ConfirmOrderRequest } from "../models/ConfirmOrderRequest.Model";
import { CancelOrderRequest } from "../models/CancelOrderRequest.Model";
import { AddItemToCartRequest } from "../models/AddItemToCartRequest.Model";
import { ProductModel } from "../models/Product.Model";
import { DeleteUserAdressRequest } from "../models/DeleteUserAdressRequest.Model";
import { OrderItemRequest } from "../models/OrderItemRequest.Model";
import { AddEditProductRequst } from "../models/AddEditProductRequst.model";
import { OrderAddressRequest } from "../models/orderAddressRequest.model";
import { AddEditProductCategoryRequest } from "../models/AddEditProductCategoryRequest.model";
import { AddEditProductBrandRequest } from "../models/AddEditProductBrandRequest.model";
import { AddEditProductTypeRequest } from "../models/AddEditProductTypeRequest.model";
import { AddEditProductColorRequest } from "../models/AddEditProductColorRequest.model";
import { AddItemToFavouritRequest } from "../models/AddItemToFavouritRequest";
import { PatchDto } from "../models/PatchDto.Model";
import { PatchRequest } from "../models/PatchRequest.Model";
import { PushNotificationRequest } from "../models/PushNotificationRequest";
import { PromoCodeRequest } from "../models/PromoCodeRequest";
import { PromoCodeDto } from "../models/PromoCodeDto";
import { UpdateOrderRquest } from "../models/UpdateOrderRquest";
import { SearchResult } from "../models/SearchResult.Model";
import { ProductListRequest } from "../models/ProductRequest.Model";
import { DeleteProductPatchFromCartRequest } from "../models/DeleteProductPatchFromCartRequest";
import { ChangePasswordByUserIdModel } from '../models/ChangePasswordByUserIdModel';
import { ProductSupplierRequest } from '../models/ProductSupplierRequest';
import { ProductSupplierDto } from '../models/ProductSupplierDto';
import { DiscountDTO } from '../models/Discount/DiscountDto';
import { PushNotificationResponse } from '../models/PushNotificationResponse';
import { QuantityDiscountRequest } from '../models/Discount/QuantityDiscountRequest';
import { IDDto } from '../models/IDDto';
import { VoucherDiscountRequest } from '../models/Discount/VoucherDiscountRequest';
import { GeneralDiscount } from '../models/Discount/GeneralDiscount';
import { shineImageRequest } from '../models/Shine/shineImage';

@Injectable({
  providedIn: "root",
})
export class CommandService {
  constructor(private _http: HttpClient) { }

  UpdateProductPatchInOrder(request: OrderItemRequest): Observable<GlobalResponse<OrderModel>> {
    return this._http.post<GlobalResponse<OrderModel>>(
      environment.webApiEndPoint + configration.urls.apiUpdateProductPathcInOrder, request)
      .pipe(catchError(err => of(this.errorResponse(err))));
  }



  ApplyPromoCode(
    request: PromoCodeRequest
  ): Observable<GlobalResponse<PromoCodeDto>> {
    return this._http
      .post<GlobalResponse<PromoCodeDto>>(
        environment.webApiEndPoint + configration.urls.apiApplyPromoCode,
        request
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }

  AddEditUserAddress(
    request: AddEditUserAddressRequst
  ): Observable<GlobalResponse<boolean>> {
    return this._http
      .post<GlobalResponse<boolean>>(
        environment.webApiEndPoint +
        configration.urls.apiAddEditUserAddressURL,
        request
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }

  PublishPushNotification(request: PushNotificationRequest): Observable<GlobalResponse<boolean>> {
    return this._http
      .post<GlobalResponse<boolean>>(environment.webApiEndPoint + configration.urls.apiPuplishPushNotificationURL, request)
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }
  SaveNotification(
    request: PushNotificationRequest
  ): Observable<GlobalResponse<boolean>> {
    return this._http
      .post<GlobalResponse<boolean>>(
        environment.webApiEndPoint + configration.urls.apiSaveNotificationURL,
        request
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }
  CancelOrderFromCart(
    request: CancelOrderRequest
  ): Observable<GlobalResponse<OrderModel>> {
    return this._http
      .post<GlobalResponse<OrderModel>>(
        environment.webApiEndPoint + configration.urls.apiCancelOrderFromCart,
        request
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }

  AddEditProductPatch(
    request: PatchRequest
  ): Observable<GlobalResponse<PatchDto>> {
    return this._http
      .post<GlobalResponse<PatchDto>>(
        environment.webApiEndPoint + configration.urls.apiAddEditProductPatch,
        request
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }

  UpdateUserCategoryPages(request: UpdateUserCategoryPagesRequest): Observable<GlobalResponse<boolean>> {
    return this._http.post<GlobalResponse<boolean>>(
      environment.webApiEndPoint + configration.urls.apiUpdateUserCategoryPages,
      request
    )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }

  DeleteUserAddress(
    request: DeleteUserAdressRequest
  ): Observable<GlobalResponse<boolean>> {
    return this._http
      .post<GlobalResponse<boolean>>(
        environment.webApiEndPoint + configration.urls.apiDeleteUserAddressURL,
        request
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }

  ConfirmOrder(
    request: ConfirmOrderRequest
  ): Observable<GlobalResponse<OrderModel>> {
    return this._http
      .post<GlobalResponse<OrderModel>>(
        environment.webApiEndPoint + configration.urls.apiConfirmOrder,
        request
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }
  UpdateAddressOrder(
    request: OrderAddressRequest
  ): Observable<GlobalResponse<OrderModel>> {
    return this._http
      .post<GlobalResponse<OrderModel>>(
        environment.webApiEndPoint + configration.urls.apiUpdateAddressOrder,
        request
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }
  UpdateOrder(
    request: OrderItemRequest
  ): Observable<GlobalResponse<OrderModel>> {
    return this._http
      .post<GlobalResponse<OrderModel>>(
        environment.webApiEndPoint + configration.urls.apiUpdateOrder,
        request
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }
  UpdateOrderWithPromo(
    request: UpdateOrderRquest
  ): Observable<GlobalResponse<OrderModel>> {
    return this._http
      .post<GlobalResponse<OrderModel>>(
        environment.webApiEndPoint + configration.urls.apiUpdateOrderWithPromo,
        request
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }

  CancelOrder(
    request: CancelOrderRequest
  ): Observable<GlobalResponse<OrderModel>> {
    return this._http
      .post<GlobalResponse<OrderModel>>(
        environment.webApiEndPoint + configration.urls.apiCancelOrder,
        request
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }

  AddItemToCart(
    request: AddItemToCartRequest
  ): Observable<GlobalResponse<OrderModel>> {
    return this._http
      .post<GlobalResponse<OrderModel>>(
        environment.webApiEndPoint + configration.urls.apiAddItemToCart,
        request
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }
  AddItemToFav(
    request: AddItemToFavouritRequest
  ): Observable<GlobalResponse<ProductModel>> {
    return this._http
      .post<GlobalResponse<ProductModel>>(
        environment.webApiEndPoint + configration.urls.apiAddItemToFav,
        request
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }

  AddEditProduct(
    request: AddEditProductRequst
  ): Observable<GlobalResponse<boolean>> {
    return this._http
      .post<GlobalResponse<boolean>>(
        environment.webApiEndPoint + configration.urls.apiAddEditProduct,
        request
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }

  AddOrderConfiguration(
    request: OrdersConfigurationsRequest
  ): Observable<GlobalResponse<OrdersConfigurationsDto>> {
    return this._http
      .post<GlobalResponse<OrdersConfigurationsDto>>(
        environment.webApiEndPoint + configration.urls.apiAddOrdersConfigurations,
        request
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }

  DeleteOrderConfiguration(
    request: OrdersConfigurationsRequest
  ): Observable<GlobalResponse<boolean>> {
    return this._http
      .post<GlobalResponse<boolean>>(
        environment.webApiEndPoint + configration.urls.apiDeleteOrdersConfigurations,
        request
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }

  ChangeUserPasswordByUserId(
    request: ChangePasswordByUserIdModel
  ): Observable<GlobalResponse<boolean>> {
    return this._http
      .post<GlobalResponse<boolean>>(
        environment.webApiEndPoint + configration.urls.Accounts.apiChangeUserPasswordByIdUrl,
        request
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }

  SetProductAvailability(
    request: AddEditProductRequst
  ): Observable<GlobalResponse<boolean>> {
    return this._http
      .post<GlobalResponse<boolean>>(
        environment.webApiEndPoint +
        configration.urls.apiSetProductAvailability,
        request
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }

  AddEditProductCategory(
    request: AddEditProductCategoryRequest
  ): Observable<GlobalResponse<boolean>> {
    return this._http
      .post<GlobalResponse<boolean>>(
        environment.webApiEndPoint +
        configration.urls.apiAddEditProductCategory,
        request
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }

  AddEditProductBrand(
    request: AddEditProductBrandRequest
  ): Observable<GlobalResponse<boolean>> {
    return this._http
      .post<GlobalResponse<boolean>>(
        environment.webApiEndPoint + configration.urls.apiAddEditProductBrand,
        request
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }
  AddEditProductType(
    request: AddEditProductTypeRequest
  ): Observable<GlobalResponse<boolean>> {
    return this._http
      .post<GlobalResponse<boolean>>(
        environment.webApiEndPoint + configration.urls.apiAddEditProductType,
        request
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }
  AddEditProductColor(
    request: AddEditProductColorRequest
  ): Observable<GlobalResponse<boolean>> {
    return this._http
      .post<GlobalResponse<boolean>>(
        environment.webApiEndPoint + configration.urls.apiAddEditProductColor,
        request
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }
  saveProductsExcelURL(
    filter: ProductListRequest
  ): Observable<GlobalResponse<SearchResult<ProductModel>>> {
    return this._http
      .post<GlobalResponse<SearchResult<ProductModel>>>(
        environment.webApiEndPoint + configration.urls.apiSaveProductsExcelURL,
        filter
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }

  addEditSuppliers(request: ProductSupplierRequest): Observable<GlobalResponse<boolean>> {
    return this._http.post<GlobalResponse<boolean>>(
      environment.webApiEndPoint + configration.urls.apiAddEditProductSupplier, request
    ).pipe(catchError((err) => of(this.errorResponse(err))));
  }
  deleteSuppliers(request: ProductSupplierRequest): Observable<GlobalResponse<boolean>> {
    return this._http.post<GlobalResponse<boolean>>(
      environment.webApiEndPoint + configration.urls.apiDeleteProductSupplier, request
    ).pipe(catchError((err) => of(this.errorResponse(err))));
  }

  DeleteProductFromCart(request: DeleteProductPatchFromCartRequest): Observable<GlobalResponse<boolean>> {
    return this._http.post<GlobalResponse<boolean>>(
      environment.webApiEndPoint + configration.urls.apiDeleteProductFromCart, request)
      .pipe(catchError(err => of(this.errorResponse(err))));
  }

  AddEditDiscountVoucher = (request: VoucherDiscountRequest): Observable<GlobalResponse<boolean>> => this._http.
    post<GlobalResponse<boolean>>(environment.webApiEndPoint + configration.urls.apiAddEditDiscountVoucher, request).
    pipe(catchError(err => of(this.errorResponse(err))));

  DeleteDiscountVoucher = (request: IDDto): Observable<GlobalResponse<boolean>> => this._http.
    post<GlobalResponse<boolean>>(environment.webApiEndPoint + configration.urls.apiDeleteDiscountVoucher, request).
    pipe(catchError(err => of(this.errorResponse(err))));

  AddDiscount = (request: GeneralDiscount): Observable<GlobalResponse<boolean>> => this._http.
    post<GlobalResponse<boolean>>(environment.webApiEndPoint + configration.urls.apiAddDiscount, request).
    pipe(catchError(err => of(this.errorResponse(err))));

  DeleteDiscount = (request: GeneralDiscount): Observable<GlobalResponse<boolean>> => this._http.
    post<GlobalResponse<boolean>>(environment.webApiEndPoint + configration.urls.apiDeleteDiscount, request).
    pipe(catchError(err => of(this.errorResponse(err))));

  AddEditQuantityDiscount = (request: QuantityDiscountRequest): Observable<GlobalResponse<boolean>> => this._http.
    post<GlobalResponse<boolean>>(environment.webApiEndPoint + configration.urls.apiAddEditQuantityDiscount, request).
    pipe(catchError(err => of(this.errorResponse(err))));

  DeleteQuantityPatchPrice = (request: IDDto): Observable<GlobalResponse<boolean>> => this._http.
    post<GlobalResponse<boolean>>(environment.webApiEndPoint + configration.urls.apiDeleteQuantityPatchPrice, request).
    pipe(catchError(err => of(this.errorResponse(err))));

  AddEditUserNotes = (request: UserNotesRequest): Observable<GlobalResponse<boolean>> => this._http.
    post<GlobalResponse<boolean>>(environment.webApiEndPoint + configration.urls.apiAddEditUserNotes, request).
    pipe(catchError(err => of(this.errorResponse(err))));

  DeleteUserNotes = (request: IDDto): Observable<GlobalResponse<boolean>> => this._http.
    post<GlobalResponse<boolean>>(environment.webApiEndPoint + configration.urls.apiDeleteUserNotes, request).
    pipe(catchError(err => of(this.errorResponse(err))));

  SaveShineImage = (request: shineImageRequest): Observable<GlobalResponse<boolean>> => this._http.
    post<GlobalResponse<boolean>>(environment.webApiEndPoint + configration.urls.apiAddShineImage, request).
    pipe(catchError(err => of(this.errorResponse(err))));

  // SetDiscountCodeToAllUser = (request: DiscountDTO): Observable<GlobalResponse<boolean>> => this._http.
  // post<GlobalResponse<boolean>>(environment.webApiEndPoint + configration.urls.apiDeleteUserNotes, request).
  // pipe(catchError(err => of(this.errorResponse(err))));

  errorResponse(err) {
    var response = new GlobalResponse<any>();
    response.Result = err;
    response.ErrorMessage = err.status === 0 || err.status === 504 ? "" : err.message;
    response.HasError = true;
    return response;
  }
}
