<div class="row hold-pagination">
  <div class="col-sm-7 p-0">
    <pagination
      [ngModel]="pageIndex"
      [totalItems]="_itemsCount"
      [itemsPerPage]="PageSize"
      (pageChanged)="onPageChanged($event)"
      (numPages)="pagescount = $event"
      [boundaryLinks]="true"
      [rotate]="true"
      previousText="{{ 'Previous' | translate }}"
      nextText="{{ 'Next' | translate }}"
      [maxSize]="10"
      firstText="{{ 'First' | translate }}"
      lastText="{{ 'Last' | translate }}"
      [ngModelOptions]="{ standalone: true }"
    ></pagination>
  </div>

  <div class="col-sm-5">
    <div class="pagination-options">
      <label class="d-inline-block p-3">
        {{ _itemsCount }} {{ "itemsin" | translate }} {{ pagescount }}
        {{ "page" | translate }}
      </label>

      <select
        class="form-control mr-1 rounded d-inline-block w-50"
        (change)="changePageSize($event)"
        [value]="PageSize"
      >
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
        <option value="200">200</option>
        <option value="100000">100000</option>
        <!-- <option value="99999999999999">{{ "AllData" | translate }}</option> -->
      </select>
    </div>
  </div>
</div>
