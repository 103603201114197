<div class="row image-back">
    <!-- start this to make loading center in page on any screen size -->
    <div *ngIf="showLoading" class="d-flex flex-column opacity-5 z-index-4 position-fixed w-100 h-100 justify-content-center align-items-center bg-white">
        <div class="p-2 align-self-center">
            <span class="load-more-List text-center vw-100 vh-100">
        <span class="spinner-border text-primary" role="status"> </span>
            </span>
        </div>
    </div>
    <div class="col-md-4 z-index-3 d-flex align-self-center h-50 col-sm-12 col-xs-12 col-lg-4 mx-auto p-0 bg-white rounded">
        <div class="d-inline-flex w-100 h-100 justify-content-center flex-column p-2 bd-highlight">
            <div class="p-2 align-self-center text-center bd-highlight w-75">
                <img class="w-100 register-logo border-0 img-fluid" src="assets/images/mylerz-logo-colored.png" />
            </div>
            <div class="p-2 align-self-center bd-highlight w-100">
                <form #loginForm="ngForm" (ngSubmit)="loginForm.form.valid && Login()" autocomplete="off">
                    <input placeholder="{{ 'username' | translate }}" type="text" name="Usernamess" class="rounded form-control border-right-0 border-bottom border-left-0 border-top-0 mb-2" [(ngModel)]="userLogin.UserName" #UserNames="ngModel" required />
                    <div *ngIf="loginForm.submitted && UserNames.errors?.required" class="text text-danger pl-2 pr-2">
                        {{ "usernameisrequired" | translate }}
                    </div>

                    <label> </label>
                    <input placeholder="{{ 'password' | translate }}" type="password" name="passwordss" class="rounded form-control border-right-0 border-bottom border-left-0 border-top-0 mb-2" [(ngModel)]="userLogin.Password" #passwords="ngModel" required />
                    <div *ngIf="loginForm.submitted && passwords.errors?.required" class="text text-danger pl-2 pr-2">
                        {{ "passwordisrequired" | translate }}
                    </div>

                    <div class="text-center mt-3">
                        <span class="text-danger">{{ error }}</span>
                    </div>
                    <div class="text-center mt-3">
                        <button class="btn btn-primary bg-orange border-0 w-50" type="submit">
              {{ "Login" | translate }}
            </button>
                    </div>

                    <!-- <div>
              <button  class="btn btn-link"   *ngIf="_login.getCurrentCulture() == 'ar'" (click)="changelang('en')">English</button>
              <button  class="btn btn-link"  *ngIf="_login.getCurrentCulture() == 'en'" (click)="changelang('ar')">عربي</button>
            </div> -->
                </form>
            </div>
            <!-- <div class="p-2 align-self-center text-center mt-2  bd-highlight w-100">
            <p> not have account ?  <a class="text-primary">  Register now </a></p>
           
      </div> -->
        </div>
    </div>

    <!-- overlay for background -->
    <div class="d-flex flex-column opacity-5 position-fixed w-100 h-100 justify-content-center align-items-center bg-white"></div>
</div>