import { CommunicationService } from 'src/app/services/communication.service';
import { CommandService } from 'src/app/services/command.service';
import { UserRequest } from './../../../models/UserRequest.Model';
import { UserCategoryEnum } from 'src/app/models/enum/usercategory.enum';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MobileUserDto } from 'src/app/models/MobileUserDto';
import { LoginAdminService } from 'src/app/services/login-admin.service';
import { QueryService } from 'src/app/services/query.services';
import { UserDto } from 'src/app/models/UserDto';
import { ChangePasswordByUserIdModel } from 'src/app/models/ChangePasswordByUserIdModel';
import { ActionType } from 'src/app/models/Action';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  _userId: string;
  errMsg: string;
  errors: string[] = [];
  showLoading: boolean = false;
  showAlert: boolean = false;
  showInfo: boolean = false;
  infoMsg: "";
  userDto: UserDto = new UserDto();
  promoCodeType = "0";
  IsArabic: boolean = true;
  title: string;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _translate: TranslateService,
    private _queryService: QueryService,
    private _commandService: CommandService,
    private _communcationServices: CommunicationService,
    private titleService: Title
  ) {

  }

  ngOnInit() {
    this._communcationServices.getAction().subscribe((res) => {
      if (res.actionType == ActionType.ShowAlert) {
        this.showAlert = true;
        this.errMsg = res.Result;
      } else if (res.actionType == ActionType.ColseAlert) {
        this.showAlert = false;
      } else if (res.actionType == ActionType.ShowAlertInfo) {
        this.showInfo = true;
        this.infoMsg = res.Result;
      } else if (res.actionType == ActionType.CloseAlertInof) {
        this.showInfo = false;
      }

    });

    this.loadAfterLang(this._translate.currentLang);
    this._translate.onLangChange.subscribe((res) => {
      this.loadAfterLang(res.lang);
    });

    this.Init();
  }
  Init() {
    this._activatedRoute.params.subscribe((params: Params) => {
      this._userId = params["ID"];
    });
    this.getUserById();
  }

  loadAfterLang(lang: string) {
    this.IsArabic = lang === "ar";

    this._activatedRoute.params.subscribe((params: Params) => {
      let id = params["ID"];
      this.title = this._translate.instant("ChangePassword");
      this.titleService.setTitle(this.title);
    });
  }

  getUserById() {
    if (this._userId) {
      let userRequest = new UserRequest();
      userRequest.UserId = this._userId;
      try {
        this.errMsg = "";
        this.showLoading = true;
        this._queryService.getUserById(userRequest).subscribe(
          (res) => {
            this.showLoading = false;
            if (res.HasError) {
              this.errMsg = res.ErrorMessage;
              return;
            }

            this.userDto = res.Result;

          },
          (err) => {
            this.errMsg = err;
            this.showLoading = false;
          }
        );
      } catch (error) {
        this.errMsg = error;
        this.showLoading = false;
      }
    }
  }

  SaveUserPassword() {
    try {
      this.errMsg = "";
      this.showLoading = true;
      let userChangePasswordRequest = new ChangePasswordByUserIdModel();
      userChangePasswordRequest.UserId = this._userId;
      userChangePasswordRequest.NewPassword = this.userDto.Password;
      this._commandService.ChangeUserPasswordByUserId(userChangePasswordRequest).subscribe(
        (res) => {
          this.showLoading = false;
          if (res.HasError) {
            this._communcationServices.showAlert(this._translate.instant(res.ErrorMessage));
          }



          if (res.Result) {

            this._communcationServices.showAlertInof(
              this._translate.instant("PasswordChangedSuccessfully")
            );

            if (this.userDto.UserCategoryId === this.UserCategory.Client) {
              this._router.navigateByUrl("adminhome/adminmobileuserlist");
            }
            else {
              this._router.navigateByUrl("adminhome/adminuserlist");
            }

          }



        },
        (err) => {
          this.errMsg = err;
          this.showLoading = false;
        }
      );
    } catch (error) {
      this.errMsg = error;
      this.showLoading = false;
    }
  }


  get UserCategory() {
    return UserCategoryEnum;
  }
}
