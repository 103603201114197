<div class="d-flex flex-column h-100 w-100 p-2">
    <div class="bd-highlight">
        <div class="row">
            <div class="col-6">
                <h4>{{ title }}</h4>
            </div>
        </div>
    </div>

    <div class="d-flex h-100 flex-column overflow-hidden mt-1">
        <div *ngIf="showLoading" class="  d-flex  flex-column  z-index-3  position-fixed  w-100  h-100  justify-content-center  align-items-center  bg-white" style="opacity: 0.5">
            <div class="p-2 align-self-center">
                <span class="load-more-List text-center vw-100 vh-100">
                    <span class="spinner-border text-primary" role="status"> </span>
                </span>
            </div>
        </div>

        <form #AddEditFrom="ngForm" autocomplete="off" (ngSubmit)="AddEditFrom.form.valid && saveSupplier()">
            <div class="row mt-2">

                <div class="col-4">
                    <label> {{ "SupplierNameAR" | translate }} </label>
                    <input class="form-control rounded" name="NameAR" type="text" [(ngModel)]="supplier.NameAR" #NameAR="ngModel" placeholder="{{ 'SupplierNameAR' | translate }}" required />
                    <div *ngIf="AddEditFrom.submitted && NameAR.errors?.required" class="text text-danger px-3">
                        {{ "inputisrequired" | translate }}
                    </div>
                </div>

                <div class="col-4">
                    <label> {{ "SupplierNameEN" | translate }} </label>
                    <input class="form-control rounded" name="NameAR" type="text" [(ngModel)]="supplier.NameEN" #NameEN="ngModel" placeholder="{{ 'SupplierNameEN' | translate }}" required />
                    <div *ngIf="AddEditFrom.submitted && NameEN.errors?.required" class="text text-danger px-3">
                        {{ "inputisrequired" | translate }}
                    </div>
                </div>

                <div class="col-4">
                    <label> {{ "PhoneNumber" | translate }} </label>
                    <input class="form-control rounded" name="phoneNumber" type="text" [(ngModel)]="supplier.PhoneNumber" #phoneNumber="ngModel" placeholder="{{ 'PhoneNumber' | translate }}" />
                    <!-- <div
          *ngIf="AddEditFrom.submitted && phoneNumber.errors?.required"
          class="text text-danger px-3"
        >
          {{ "inputisrequired" | translate }}
        </div> -->
                </div>

                <div class="col-4">
                    <label> {{ "Email" | translate }} </label>
                    <input class="form-control rounded" name="email" type="text" [(ngModel)]="supplier.Email" #email="ngModel" placeholder="{{ 'Email' | translate }}" />
                    <!-- <div
          *ngIf="AddEditFrom.submitted && email.errors?.required"
          class="text text-danger px-3"
        >
          {{ "inputisrequired" | translate }}
        </div> -->
                </div>

            </div>

            <div class="row mt-2">
                <div class=" d-inline-flex bd-highlight align-items-center">
                    <div class="col-4">
                        <label for="isRetail">{{ "IsRetail"| translate}}</label>
                    </div>
                    <div class="col-3">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="IsRetail" id="Retail" required #IsRetailInput="ngModel" [value]="1" [(ngModel)]="supplier.IsRetail">
                            <label class="form-check-label" for="Retail">{{"Shine" | translate}}</label>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="IsRetail" id="Salon" required #IsRetailInput="ngModel" [value]="2" [(ngModel)]="supplier.IsRetail">
                            <label class="form-check-label" for="Salon">{{"2assar" | translate}}</label>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="IsRetail" id="Retail" required #IsRetailInput="ngModel" [value]="3" [(ngModel)]="supplier.IsRetail">
                            <label class="form-check-label" for="Retail">{{"BothAppType" | translate}}</label>
                        </div>
                    </div>
                    <div *ngIf="AddEditFrom.submitted && IsRetailInput.errors?.required" class="text text-danger px-3">
                        {{ "Retailisrequired" | translate }}
                    </div>
                </div>
            </div>


            <div class="row mt-2">
                <div class="col-6">
                    <label class="d-block"> {{ "ProductImage" | translate }} </label>
                    <div class="file-input-wrapper">
                        <label for="upload-file" class="file-input-button">{{"UploadFile" | translate}}</label>
                        <input id="upload-file" type="file" name="image" accept="image/png, .jpeg, .jpg, .bmp" (change)="fileChangeEvent($event)" />
                    </div>
                    <img id="imgup" class="img-thumbnail align-self-center border-0 img-fluid mx-3" [src]="imageSource" />
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-6">
                    <button class="btn btn-success border-0 m-1" type="submit">
                        {{ "Save" | translate }}
                    </button>

                    <!-- <button
          (click)="forceSaveChanges(AddEditFrom.dirty); $event.preventDefault()"
          class="btn btn-danger border-0 m-1"
        >
          {{ "Cancel" | translate }}
        </button> -->
                    <button routerLink="/adminhome/product-suppliers" class="btn btn-danger border-0 m-1">
                        {{ "Cancel" | translate }}
                    </button>
                </div>
            </div>
        </form>


    </div>
</div>