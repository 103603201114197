<div class="d-flex flex-column h-100 w-100 p-2">
    <div *ngIf="errMsg" class="text-danger mr-auto p-2 bd-highlight">{{ errMsg }}</div>
    <!-- titel -->
    <div class="row">
        <div class="col-md-12 p-0">
            <div class="bd-highlight py-2">
                <h4>{{ title }}</h4>
            </div>
        </div>
    </div>
    <!-- loading-->
    <div *ngIf="showLoading" class="  d-flex  flex-column  z-index-3  position-fixed  w-100  h-100  justify-content-center  align-items-center  bg-white" style="opacity: 0.5">
        <div class="p-2 align-self-center">
            <span class="load-more-List text-center vw-100 vh-100">
                <span class="spinner-border text-primary" role="status"> </span>
            </span>
        </div>
    </div>
    <!-- Search Bar -->
    <!-- <div class="d-flex">
    <div class="h-100 w-100">
      <form (keydown)="keyDownFunction($event)">
        <div class="form-group has-search row">
          <input placeholder="{{ 'SearchByArabic' | translate }}" type="search" [(ngModel)]="productRequest.NameAR"
            name="searchName" class="form-control w-40 border rounded p-1 m-1" />

          <input placeholder="{{ 'SearchByEnglish' | translate }}" type="search" [(ngModel)]="productRequest.NameEN"
            name="searchName" class="form-control w-40 border rounded p-1 m-1" />
        </div>
      </form>
    </div>

    <div class="d-flex justify-content-end">
      <button class="btn btn-primary m-1 bg-red border-0" (click)="searchProduct()">
        {{ "Search" | translate }}
      </button>
    </div>
  </div> -->

    <!-- #region Search  -->

    <div class="row">
        <div class="col-6">
            <a class="btn btn-primary mr-3" data-toggle="collapse" href="#advancedFilter" role="button" aria-expanded="false" aria-controls="advancedFilter">{{ "Filter" | translate }}</a>

            <button class="btn btn-danger" (click)="searchProduct()">{{ "Search" | translate }} </button>

            <button class="btn btn-danger m-3" (click)="exportToExcel()">
                <i class="fa fa-file-excel-o" aria-hidden="true"></i>
                {{ "Export" | translate }}
            </button>
        </div>
    </div>

    <div class="row mb-1">
        <div class="col-4">
            <label>{{ "NameAR" | translate }}</label>
            <input class="form-control rounded" [(ngModel)]="productRequest.NameAR" />
        </div>

        <div class="col-4">
            <label> {{ "NameEN" | translate }} </label>
            <input class="form-control rounded" [(ngModel)]="productRequest.NameEN" />
        </div>

        <div class="col-4">
            <label> {{ "ProductID" | translate }} </label>
            <input type="number" class="form-control rounded" [(ngModel)]="productRequest.ID" />
        </div>
    </div>

    <div class="row mb-2">
        <div class="col">
            <div class="collapse multi-collapse" id="advancedFilter">
                <div class="card card-body">
                    <div class="container mb-1">
                        <div class="row">
                            <div class="col-3">
                                <label>
                                    {{ "ProductDate" | translate }} {{ "From" | translate }}
                                </label>
                                <input class="form-control rounded" type="text" [(ngModel)]="productRequest.ProductCreatedTimeFrom" bsDatepicker [bsConfig]="{
                    isAnimated: true,
                    dateInputFormat: 'DD-MM-YYYY'
                  }" />
                            </div>
                            <div class="col-3">
                                <label>
                                    {{ "ProductDate" | translate }} {{ "To" | translate }}
                                </label>
                                <input class="form-control rounded" type="text" [(ngModel)]="productRequest.ProductCreatedTimeTo" bsDatepicker [bsConfig]="{
                    isAnimated: true,
                    dateInputFormat: 'DD-MM-YYYY'
                  }" />
                            </div>

                            <div class="col-3">
                                <label>
                                    {{ "QuantityFrom" | translate }}
                                </label>
                                <input type="text" pattern="[0-9]+" class="form-control rounded" [(ngModel)]="productRequest.ProductPatchQuantatityFrom" />
                            </div>

                            <div class="col-3">
                                <label>
                                    {{ "QuantityTo" | translate }}
                                </label>
                                <input type="text" pattern="[0-9]+" class="form-control rounded" [(ngModel)]="productRequest.ProductPatchQuantatityTo" />
                            </div>

                            <div class="col-3">
                                <label>
                                    {{ "BookedFrom" | translate }}
                                </label>
                                <input type="text" pattern="[0-9]+" class="form-control rounded" [(ngModel)]="productRequest.ProductPatchBookedFrom" />
                            </div>
                            <div class="col-3">
                                <label>
                                    {{ "BookedTo" | translate }}
                                </label>
                                <input type="text" pattern="[0-9]+" class="form-control rounded" [(ngModel)]="productRequest.ProductPatchBookedTo" />
                            </div>

                            <div class="col-3">
                                <label>
                                    {{ "BalanceFrom" | translate }}
                                </label>
                                <input type="text" pattern="[0-9]+" class="form-control rounded" [(ngModel)]="productRequest.ProductPatchBalanceFrom" />
                            </div>

                            <div class="col-3">
                                <label>
                                    {{ "BalanceTo" | translate }}
                                </label>
                                <input type="text" pattern="[0-9]+" class="form-control rounded" [(ngModel)]="productRequest.ProductPatchBalanceTo" />
                            </div>
                            <div class="col-3">
                                <label>
                                    {{ "PurchasePrice" | translate }} {{ "From" | translate }}
                                </label>
                                <input type="text" pattern="[0-9]+" class="form-control rounded" [(ngModel)]="productRequest.ProductPurchasePriceFrom" />
                            </div>
                            <div class="col-3">
                                <label>
                                    {{ "PurchasePrice" | translate }} {{ "To" | translate }}
                                </label>
                                <input type="text" pattern="[0-9]+" class="form-control rounded" [(ngModel)]="productRequest.ProductPurchasePriceTo" />
                            </div>

                            <div class="col-3">
                                <label>
                                    {{ "SellPrice" | translate }} {{ "From" | translate }}
                                </label>
                                <input type="text" pattern="[0-9]+" class="form-control rounded" [(ngModel)]="productRequest.ProductPatchPriceFrom" />
                            </div>
                            <div class="col-3">
                                <label>
                                    {{ "SellPrice" | translate }} {{ "To" | translate }}
                                </label>
                                <input type="text" pattern="[0-9]+" class="form-control rounded" [(ngModel)]="productRequest.ProductPatchPriceTo" />
                            </div>
                            <div class="col-3">
                                <label>
                                    {{ "ExpireDate" | translate }} {{ "From" | translate }}
                                </label>
                                <input class="form-control rounded" type="text" [(ngModel)]="productRequest.ProductPatchExpireFrom" bsDatepicker [bsConfig]="{
                    isAnimated: true,
                    dateInputFormat: 'DD-MM-YYYY'
                  }" />
                            </div>
                            <div class="col-3">
                                <label>
                                    {{ "ExpireDate" | translate }} {{ "To" | translate }}
                                </label>
                                <input class="form-control rounded" type="text" [(ngModel)]="productRequest.ProductPatchExpireTo" bsDatepicker [bsConfig]="{
                    isAnimated: true,
                    dateInputFormat: 'DD-MM-YYYY'
                  }" />
                            </div>
                            <div class="col-3">
                                <label>
                                    {{ "Status" | translate }}
                                </label>
                                <select class="form-control rounded" [(ngModel)]="productRequest.IsAvailable">
                                    <option value="null" selected>{{ "All" | translate }}</option>
                                    <option value="true">{{ "Available" | translate }}</option>
                                    <option value="false">
                                        {{ "NotAvailable" | translate }}
                                    </option>
                                </select>
                            </div>

                            <div class="col-3">
                                <label>
                                    {{ "ProductType" | translate }}
                                </label>
                                <kendo-multiselect kendoMultiSelectSummaryTag class="form-control rounded" [data]="productTypes" [valueField]="'ID'" [textField]="IsArabic ? 'NameAR' : 'NameEN'" [(ngModel)]="productRequest.productTypeIds" [loading]="typeLoading" [valuePrimitive]="true"
                                    [filterable]="true" (filterChange)="getProductType($event)" [autoClose]="false">
                                    <ng-template kendoMultiSelectNoDataTemplate>
                                        <h4>
                                            <span class="k-icon k-i-warning"></span><br /><br /> {{ "NotAvailable" | translate }}
                                        </h4>
                                    </ng-template>
                                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                                        <span class="k-icon k-i-arrow-s"></span> {{ dataItems.length }} {{ "itemsSelected" | translate }}
                                    </ng-template>
                                </kendo-multiselect>
                            </div>
                            <div class="col-3">
                                <label>
                                    {{ "ProductCategory" | translate }}
                                </label>
                                <kendo-multiselect kendoMultiSelectSummaryTag class="form-control rounded" [data]="CategoriesList" [valueField]="'ID'" [textField]="IsArabic ? 'NameAR' : 'NameEN'" [(ngModel)]="productRequest.productCatIds" [loading]="categoryLoading" [valuePrimitive]="true"
                                    [filterable]="true" (filterChange)="getCategories($event)" [autoClose]="false">
                                    <ng-template kendoMultiSelectNoDataTemplate>
                                        <h4>
                                            <span class="k-icon k-i-warning"></span><br /><br /> {{ "NotAvailable" | translate }}
                                        </h4>
                                    </ng-template>
                                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                                        <span class="k-icon k-i-arrow-s"></span> {{ dataItems.length }} {{ "itemsSelected" | translate }}
                                    </ng-template>
                                </kendo-multiselect>
                            </div>
                            <div class="col-3">
                                <label>
                                    {{ "ProductBrand" | translate }}
                                </label>
                                <kendo-multiselect kendoMultiSelectSummaryTag class="form-control rounded" #brandListScroll [data]="BrandsList" [valueField]="'ID'" [textField]="IsArabic ? 'NameAR' : 'NameEN'" [(ngModel)]="productRequest.productBrandIds" [loading]="brandLoading" [valuePrimitive]="true"
                                    [filterable]="true" (filterChange)="getBrands($event)" [autoClose]="false">
                                    <ng-template kendoMultiSelectNoDataTemplate>
                                        <h4>
                                            <span class="k-icon k-i-warning"></span><br /><br /> {{ "NotAvailable" | translate }}
                                        </h4>
                                    </ng-template>
                                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                                        <span class="k-icon k-i-arrow-s"></span> {{ dataItems.length }} {{ "itemsSelected" | translate }}
                                    </ng-template>
                                </kendo-multiselect>
                            </div>
                            <div class="col-3">
                                <label> {{ "ProductColor" | translate }}</label>
                                <kendo-multiselect kendoMultiSelectSummaryTag maxSelectedItems="3" class="form-control" [data]="ColorsList" [valueField]="'ID'" [textField]="IsArabic ? 'NameAR' : 'NameEN'" [(ngModel)]="productRequest.productColorIds" [loading]="colorLoading" [valuePrimitive]="true"
                                    [filterable]="true" (filterChange)="getColors($event)" [autoClose]="false">
                                    <ng-template kendoMultiSelectNoDataTemplate>
                                        <h4><span class="k-icon k-i-warning"></span><br /><br />{{ "NotAvailable" | translate }}</h4>
                                    </ng-template>
                                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                                        <span class="k-icon k-i-arrow-s"></span> {{ dataItems.length }} {{ "itemsSelected" | translate }}
                                    </ng-template>
                                </kendo-multiselect>
                            </div>
                            <div class="col-3">
                                <label> {{ "ProductSuppliers" | translate }} </label>
                                <kendo-multiselect kendoMultiSelectSummaryTag maxSelectedItems="3" class="form-control" [data]="SuppliersList" [valueField]="'ID'" [textField]="IsArabic ? 'NameAR' : 'NameEN'" [(ngModel)]="productRequest.suppliersIds" [loading]="colorLoading" [valuePrimitive]="true"
                                    [filterable]="true" [autoClose]="false">
                                    <ng-template kendoMultiSelectNoDataTemplate>
                                        <h4><span class="k-icon k-i-warning"></span><br /><br /> {{ "NotAvailable" | translate }}</h4>
                                    </ng-template>
                                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                                        <span class="k-icon k-i-arrow-s"></span> {{ dataItems.length }} {{ "itemsSelected" | translate }}
                                    </ng-template>
                                </kendo-multiselect>
                            </div>

                            <div class="col-6">
                                <div class="row mt-2">
                                    <div class="col-2">
                                        <label for="isRetail">{{ "IsRetail"| translate}}</label>
                                    </div>
                                    <div class="col-2">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" #IsRetailInput name="isRetail" id="Salon" value="2" [(ngModel)]="productRequest.IsRetail" checked>
                                            <label class="form-check-label" for="salon">{{"2assar" | translate}}</label>
                                        </div>
                                    </div>
                                    <div class="col-2">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" #IsRetailInput name="isRetail" id="Retail" value="1" [(ngModel)]="productRequest.IsRetail">
                                            <label class="form-check-label" for="retail">{{"Shine" | translate}}</label>
                                        </div>
                                    </div>

                                    <div class="col-2">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" #IsRetailInput name="isRetail" id="Retail" value="3" [(ngModel)]="productRequest.IsRetail">
                                            <label class="form-check-label" for="retail">{{"BothAppType" |
                                                translate}}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3 align-self-end">
                                <button class="btn btn-warning" (click)="clearSearchFilter()"> {{ "Clear" | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="w-100">
        <!-- header -->
        <div class="d-flex flex-row bg-secondary text-white">
            <div class="p-1 w-5">{{ "ProductImage" | translate }}</div>
            <div class="p-1 w-5">
                <i (click)="onSortClick($event, 'ID')" class="my-icon fa fa-chevron-down"></i> {{ "id" | translate }}
            </div>
            <div class="p-1 w-10">
                <i (click)="onSortClick($event, 'NameAR')" class="my-icon fa fa-chevron-up"></i> {{ "Name" | translate }}
            </div>
            <div class="p-1 w-5">
                <i (click)="onSortClick($event, 'NameEN')" class="my-icon fa fa-chevron-up"></i> {{ "Name" | translate }}
            </div>
            <div class="p-1 w-10">
                <i (click)="onSortClick($event, 'ProductDate')" class="my-icon fa fa-chevron-up"></i> {{ "ProductDate" | translate }}
            </div>
            <div class="p-1 w-5">
                <i (click)="onSortClick($event, 'Quantity')" class="my-icon fa fa-chevron-up"></i> {{ "Quantity" | translate }}
            </div>
            <div class="p-1 w-5">
                <i (click)="onSortClick($event, 'Booked')" class="my-icon fa fa-chevron-up"></i> {{ "Booked" | translate }}
            </div>
            <div class="p-1 w-5">
                <i (click)="onSortClick($event, 'Balance')" class="my-icon fa fa-chevron-up"></i> {{ "Balance" | translate }}
            </div>
            <div class="p-1 w-5">
                <i (click)="onSortClick($event, 'ProductSuppliers')" class="my-icon fa fa-chevron-up"></i> {{ "ProductSuppliers" | translate }}
            </div>
            <div class="p-1 w-10">
                <i (click)="onSortClick($event, 'ProductCategory')" class="my-icon fa fa-chevron-up"></i> {{ "ProductCategory" | translate }}
            </div>
            <div class="p-1 w-5">
                <i (click)="onSortClick($event, 'ProductBrand')" class="my-icon fa fa-chevron-up"></i> {{ "ProductBrand" | translate }}
            </div>
            <div class="p-1 w-5">
                <i (click)="onSortClick($event, 'ProductType')" class="my-icon fa fa-chevron-up"></i> {{ "ProductType" | translate }}
            </div>

            <div class="p-1 w-5">
                <i (click)="onSortClick($event, 'LocationTackingNumber')" class="my-icon fa fa-chevron-up"></i> {{ "LocationTackingNumber2" | translate }}
            </div>

            <div class="p-1 w-5">
                <i (click)="onSortClick($event, 'InventoryShelfNumber')" class="my-icon fa fa-chevron-up"></i> {{ "InventoryShelfNumber" | translate }}
            </div>

            <div class="p-1 w-5">
                <i (click)="onSortClick($event, 'PurchasePrice')" class="my-icon fa fa-chevron-up"></i> {{ "PurchasePrice" | translate }}
            </div>
            <div class="p-1 w-5">
                <i (click)="onSortClick($event, 'SellPrice')" class="my-icon fa fa-chevron-up"></i> {{ "SellPrice" | translate }}
            </div>
            <th scope="col">{{ "IsRetail" | translate }}
                <i (click)="onSortClick($event, 'IsRetail')" class="my-icon fa fa-chevron-up"></i>
            </th>
            <div class="p-1 w-5">
                {{ "PublishStatus" | translate }}
            </div>
            <!-- <div class="p-1 w-10">{{ "ProductPrice" | translate }}</div> -->
            <!-- <div class="p-1 w-10">{{ "PatchPriceAfterDiscount" | translate }}</div> -->
            <!-- <div class="p-1 w-5"></div> -->
        </div>
    </div>

    <!--productList-->
    <div class="h-100 overflow-auto">
        <div *ngFor="let p of productLst; let index = i">
            <!--Product list-->
            <div (click)="searchFirstPatchProducts(p)" style="cursor: pointer" class="
          d-flex
          flex-row
          row
          border-bottom border-info
          bg-white
          overflow-hidden
          p-1
          mb-1
          position-relative
        ">
                <div class="px-2 py-1 w-5">
                    <img width="50" height="50" src="{{
              getImageFullPath(p.ImageExist, p.Image, p.LastModifiedTime)
            }}" />
                </div>

                <div class="px-2 py-1 w-5">
                    <a routerLink="/adminhome/product-details/{{ p.ID }}" class="text-primary" target="_blank">
                        {{ p.ID }}
                    </a>
                </div>

                <div class="px-2 py-1 w-10">
                    <span>{{ p?.NameAR }}</span>
                </div>
                <div class="px-2 py-1 w-5">
                    <span>{{ p?.NameEN }}</span>
                </div>
                <div class="px-2 py-1 w-10">
                    <p class="mb-0 mx-2">
                        {{ p?.CreatedTime | date: "dd-MM-yyyy [h:mm a]" }}
                    </p>
                </div>
                <div class="px-2 py-1 w-5">
                    <p class="mb-0 mx-2">
                        {{ p?.QuantitySum }}
                    </p>
                </div>
                <div class="px-2 py-1 w-5">
                    <p class="mb-0 mx-2">
                        {{ p?.BookedSum }}
                    </p>
                </div>
                <div class="px-2 py-1 w-5">
                    <p class="mb-0 mx-2">
                        {{ p?.BalanceSum }}
                    </p>
                </div>

                <div class="px-2 py-1 w-5">
                    <p class="mb-0 mx-2" *ngIf="IsArabic">{{ p?.Supplier?.NameAR }} </p>
                    <p class="mb-0 mx-2" *ngIf="!IsArabic"> {{ p?.Supplier?.NameEN }}</p>
                </div>

                <div class="px-2 py-1 w-10">
                    <p class="mb-0 mx-2" *ngIf="IsArabic">
                        {{ p?.Category?.NameAR }}
                    </p>
                    <p class="mb-0 mx-2" *ngIf="!IsArabic">
                        {{ p?.Category?.NameEN }}
                    </p>
                </div>

                <div class="px-2 py-1 w-5">
                    <p class="mb-0 mx-2" *ngIf="IsArabic">
                        {{ p?.Brand?.NameAR }}
                    </p>
                    <p class="mb-0 mx-2" *ngIf="!IsArabic">
                        {{ p?.Brand?.NameEN }}
                    </p>
                </div>

                <div class="px-2 py-1 w-5">
                    <p class="mb-0 mx-2" *ngIf="IsArabic">{{ p?.Type?.NameAR }}</p>
                    <p class="mb-0 mx-2" *ngIf="!IsArabic">{{ p?.Type?.NameEN }}</p>
                </div>
                <div class="px-2 py-1 w-5">{{ p?.LocationTackingNumber }}</div>
                <div class="px-2 py-1 w-5"> {{ p?.InventoryShelfNumber }}</div>
                <div class="px-2 py-1 w-5">{{ p?.PurchasingPrice }} </div>
                <div class="px-2 py-1 w-5"> {{ p?.PatchPrice }}</div>
                <td>
                    <span *ngIf="p?.IsRetail ==1 ">{{ "Shine" | translate}}</span>
                    <span *ngIf="p?.IsRetail ==2 ">{{ "2assar" | translate}}</span>
                    <span *ngIf="p?.IsRetail ==3 ">{{ "BothAppType" | translate}}</span>
                </td>
                <div class="px-2 py-1 w-5">
                    <p class="mb-0 mx-2 text-danger" *ngIf="!p.IsAvailable"> {{ "NotAvailable" | translate }}</p>
                    <p class="mb-0 mx-2 text-success" *ngIf="p.IsAvailable">{{ "Available" | translate }}</p>
                </div>
                <!-- <div class="px-4 py-1 w-10">
          <p class="mb-0 mx-2">
            {{ p.Price }}
          </p>

        </div> -->

                <div class="p-2 bd-highlight">
                    <span [class]="
              (p.Expanded ? '   fa fa-chevron-up' : '   fa fa-chevron-down ') +
              ' text-primary  '
            ">
                    </span>
                </div>
            </div>
            <div class="row p-1 justify-content-center border-bottom border-info" *ngIf="p.Expanded">
                <!--Patchs item-->
                <div class="w-100 p-2">
                    <div class="w-25 p-2">
                        <input name="ExpireDatepatchsss" type="text" class="rounded" #ExpireDatepatchsss="ngModel" autocomplete="off" [(ngModel)]="p.PatchExpireDateSearch" bsDatepicker [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MM-YYYY' }" />
                        <button class="btn btn-primary bg-red border-0 mx-2" (click)="searchPatchProducts(p)">
                            {{ "Search" | translate }}
                        </button>
                    </div>

                    <!-- header -->
                    <div class="d-flex flex-row bg-secondary text-white">
                        <!-- <div class="p-1 w-20">{{ "PatchNumber" | translate }}</div> -->
                        <div class="p-1 w-20">{{ "ExpireDate" | translate }}</div>
                        <div class="p-1 w-20">{{ "Quantity" | translate }}</div>
                        <div class="p-1 w-20">{{ "Booked" | translate }}</div>
                        <div class="p-1 w-10">{{ "Balance" | translate }}</div>
                        <div class="p-1 w-20" *ngIf="p?.IsRetail ==2 || p?.IsRetail ==3">{{ "PatchPrice2assar" | translate }}</div>
                        <div class="p-1 w-20" *ngIf="p?.IsRetail ==2 || p?.IsRetail ==3">{{ "Discount2assar" | translate }}
                        </div>
                        <div class="p-1 w-20" *ngIf="p?.IsRetail ==1 || p?.IsRetail ==3">{{ "PatchPriceShine" | translate }}</div>
                        <div class="p-1 w-20" *ngIf="p?.IsRetail ==1 || p?.IsRetail ==3">{{ "DiscountShine" | translate }}
                        </div>
                        <!-- <div class="p-1 w-5"></div> -->
                        <div class="p-1 w-5"></div>
                    </div>
                </div>

                <div *ngFor="let pat of p.PatchLst" class="w-100">
                    <!--Data-->
                    <app-product-patch-add [pat]="pat" [product]="p"></app-product-patch-add>
                </div>
                <div *ngIf="p.PatchLst?.length == 0" class="w-100">
                    <!--Data-->
                    {{ "" | translate }}
                </div>
                <div class="w-100 p-2">
                    <pagination *ngIf="p.PatchCount > itemsPerPage" (pageChanged)="pageChanged($event, p)" [maxSize]="3" [ngModel]="p.PatchPageIndex" [itemsPerPage]="itemsPerPage" nextText="{{ 'Next' | translate }}" previousText="{{ 'Previous' | translate }}" [totalItems]="p.PatchCount"></pagination>
                </div>
                <div>
                    <button class="btn btn-primary bg-orange border-0" (click)="AddNewPatch(p)">
                        {{ "addnewpatch" | translate }}
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="productLst?.length == 0" class="
        d-flex
        flex-column
        z-index-3
        w-100
        h-100
        justify-content-center
        align-items-center
        bg-white
      ">
            <label class="la">{{ "NoDataFound" | translate }}</label>
        </div>
    </div>

    <div class="row mt-2">
        <div class="col-xs-12 col-12">
            <app-pagination-component [ItemsCount]="Total" (changePage)="onPagingChange($event)">
            </app-pagination-component>
        </div>
    </div>
</div>