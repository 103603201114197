import { UserAddressDto } from 'src/app/models/UserAddressDto.Model';
import { SalonTypeEnum } from './enum/SalonTypeEnum';
export class MobileUserDto {
  Id: string;
  UserCategoryId: number | null;
  UserName: string;
  FirstName: string;
  LastName: string;
  Name: string;
  PhoneNumber: string;
  PromoCode: string;
  PromoCodeAmount: string;
  ConfirmPassword: string;
  Password: string;
  Email: string;
  PromoCodeType: number;
  UserAddress: UserAddressDto;
  SalonName: string;
  SalonType: SalonTypeEnum;
  VoucherID: number;
  IsActive: boolean;
  IsRetail?: boolean;
}
