import { filter } from 'rxjs/operators';
import { SortingDirectionEnum } from './../../../../models/enum/SortingDirectionEnum';
import { CommandService } from 'src/app/services/command.service';
import { DiscountDTO } from '../../../../models/Discount/DiscountDto';
import { CommunicationService } from 'src/app/services/communication.service';
import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router, Params } from "@angular/router";
import { LoginAdminService } from "src/app/services/login-admin.service";
import { TranslateService } from "@ngx-translate/core";
import { QueryService } from "src/app/services/query.services";
import { Title } from "@angular/platform-browser";
import { NgForm, FormGroup, FormBuilder } from '@angular/forms';
import { PageChangedEvent } from "ngx-bootstrap/pagination";
import { DiscountRequestPoral } from 'src/app/models/Discount/DiscountRequestPoral';
import { DiscountResponse } from 'src/app/models/Discount/DiscountResponse';
import { GeneralDiscount } from 'src/app/models/Discount/GeneralDiscount';

@Component({
  selector: 'app-discount-list',
  templateUrl: './discount-list.component.html',
  styleUrls: ['./discount-list.component.scss']
})
export class DiscountListComponent implements OnInit {
  discountRequest: DiscountDTO = new DiscountDTO();
  request: DiscountRequestPoral;
  discountList: DiscountResponse[] = [];
  showLoading: boolean = false;
  IsArabic: boolean = true;
  errors: string[] = [];
  promoCodeTypeText = "PromoCodePercentage";
  title: string = "";
  Total: number;
  errMsg: string = "";
  error: string = "";
  sortDir:SortingDirectionEnum = SortingDirectionEnum.Ascending;

  constructor(
    private fb: FormBuilder,
    private _route: ActivatedRoute,
    private _adminLogin: LoginAdminService,
    private _translate: TranslateService,
    private _queryService: QueryService,
    private router: Router,
    private _commandService: CommandService,
    private _communicationService: CommunicationService,
    private titleService: Title
  ) { }

  ngOnInit() {
    this.loadAfterLang(this._translate.currentLang);
    this._translate.onLangChange.subscribe(res => this.loadAfterLang(res.lang));
    this.setDefaultSearchValue();
    this.getDiscountList(this.request);
  }


  loadAfterLang(lang: string) {
    this.IsArabic = lang === "ar";
    this._route.params.subscribe((params: Params) => {
      this.title = this._translate.instant("ProductSystem");
      this.titleService.setTitle(this.title);
    });
  }

  setDefaultSearchValue = () => this.request = new DiscountRequestPoral();
  clearSearch(){
    this.setDefaultSearchValue();
    this.getDiscountList(this.request);
  }

  onPagingChange(event: PageChangedEvent) {
    this.request.PageIndex = event.page;
    this.request.PageSize = event.itemsPerPage;
    this.getDiscountList(this.request);
  }

  getDiscountList(request: DiscountRequestPoral) {
    try {
      this.showLoading = true;
      this.errMsg = "";
      this._queryService.GetAllProductDiscount(request)
      .subscribe(res => {
        if (res.HasError) {
          this.errMsg = res.ErrorMessage;
          this.showLoading = false;
          return;
        }

        this.discountList = res.Result.ItemList;
        this.Total = res.Result.Total;

        this.showLoading = false;

      }, (err) => {
        this.errMsg = err;
        this.showLoading = false;
      });
    } catch (error) {
      this.errMsg = error;
      this.showLoading = false;
    }
  }

  onSortClick(event: any, colName: string) {
    let classList = event.currentTarget.classList;
    this.changeStyleOnSortCLicked(classList);
    let request = this.changeRequestOnSortClicked(this.request, colName);
    this.getDiscountList(request);

  }

  changeStyleOnSortCLicked(classList: any) {
    /* sort DESC */
    if (classList.contains('fa-chevron-up')) {
      classList.remove('fa-chevron-up');
      classList.add('fa-chevron-down');
      this.sortDir = SortingDirectionEnum.Descending;

      /* sort ASC */
    } else {
      classList.add('fa-chevron-up');
      classList.remove('fa-chevron-down');
      this.sortDir = SortingDirectionEnum.Ascending;
    }
  }
  
  changeRequestOnSortClicked(request: DiscountRequestPoral, columnName: string) {
    let changedRequest: DiscountRequestPoral = request;
    changedRequest.Lang = this._translate.currentLang;
    changedRequest.SortColumn = columnName;
    changedRequest.SortDirection = this.sortDir;
    return changedRequest;
  }

  
  deleteDiscount(id:number){
    try {
      let request = new GeneralDiscount();
      request.ID = id;
      this.errMsg = '';
      this.showLoading =true;
      this._commandService.DeleteDiscount(request).subscribe(res=> {
        if(res.HasError){
          this.errMsg = res.ErrorMessage;
          this.showLoading = false;
        }
        this.showLoading = false;
        this.ngOnInit();
      });
    } catch (error) {
      
    }


  }
}
