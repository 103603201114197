import { environment } from './../../../../environments/environment.prod';
import { CommandService } from './../../../services/command.service';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ProductSupplierDto } from 'src/app/models/ProductSupplierDto';
import { ProductSupplierRequest } from 'src/app/models/ProductSupplierRequest';
import { QueryService } from 'src/app/services/query.services';
import { configration } from 'src/app/shared/config';
import { FileService } from 'src/app/services/file.service';

@Component({
  selector: 'app-add-edit-product-suppliers',
  templateUrl: './add-edit-product-suppliers.component.html',
  styleUrls: ['./add-edit-product-suppliers.component.scss']
})
export class AddEditProductSuppliersComponent implements OnInit {
  errMsg: string;
  title: string;
  IsArabic = true;
  sortDir = 0;
  suppliersRequest = new ProductSupplierRequest();
  supplier: ProductSupplierDto = new ProductSupplierDto();
  showLoading = false;
  Total = 0;
  imageSource = 'assets/images/default.png';
  _spplierID: number;

  constructor(
    private route: ActivatedRoute,
    private _queryService: QueryService,
    private _commandService: CommandService,
    private fileService: FileService,
    private translateService: TranslateService,
    private _router: Router,
    private titleService: Title,

  ) { }

  ngOnInit() {
    this.suppliersRequest.PageIndex = 1;
    this.suppliersRequest.PageSize = 1;
    this.loadAfterLang(this.translateService.currentLang);
    this.translateService.onLangChange.subscribe((res) => this.loadAfterLang(res.lang));

    if (this._spplierID != 0) {
      this.getSupplier();
    }
  }

  loadAfterLang(lang: string) {
    this.IsArabic = lang === "ar";
    this.route.params.subscribe((params: Params) => {
      this._spplierID = params['ID'];
      if (this._spplierID == 0) {
        this.title = this.translateService.instant("AddProductSupplier");
      }
      else {
        this.title = this.translateService.instant("EditProductSupplier");
        this.suppliersRequest.ID = +this._spplierID;
      }
      this.titleService.setTitle(this.title);
    });
  }

  saveSupplier() {
    let request = new ProductSupplierRequest();
    request.ID = this._spplierID;
    request.NameAR = this.supplier.NameAR;
    request.NameEN = this.supplier.NameEN;
    request.Email = this.supplier.Email;
    request.PhoneNumber = this.supplier.PhoneNumber;
    request.Image = this.supplier.Image;
    request.IsRetail = this.supplier.IsRetail;

    this._commandService.addEditSuppliers(request).subscribe(res => {
      if (res) {
        this._router.navigateByUrl("/adminhome/product-suppliers");
      }
    });
  }

  getSupplier() {
    this._queryService.searchSuppliers(this.suppliersRequest).subscribe(res => {
      this.supplier = res.Result.ItemList[0];
      this.imageSource = this.getImageFullPath();
    });
  }

  forceSaveChanges(isFormDirty: boolean) {
    if (isFormDirty) {
      var check = confirm(this.translateService.instant("DoYouWantToSaveChanges?"));
      if (check) {
        this.saveSupplier();
      } else {
        this._router.navigateByUrl("/adminhome/product-suppliers");
      }
    } else {
      this._router.navigateByUrl("/adminhome/product-suppliers");
    }

  }

  fileChangeEvent(fileInput: any) {
    this.errMsg = "";
    var validImageExtention = this.fileService.checkFileExtentionForImage(fileInput.target.files);
    if (!validImageExtention) {
      this.errMsg = this.translateService.instant("ImageErrorMessage");
      return;
    }
    var validImagesize = this.fileService.checkFileSize(fileInput.target.files);
    if (!validImagesize) {
      this.errMsg = this.translateService.instant("ImageSizeErrorMessage");
      return;
    }
    this.fileService.uploadFiles(fileInput.target.files).subscribe(res => {
      this.showLoading = false;
      if (res.HasError) {
        this.errMsg = res.ErrorMessage;
        return;
      }

      this.supplier.Image = res.Result[0].RelativePath;
      this.imageSource = `${environment.webApiEndPoint}Temp/${this.supplier.Image}`
      document.getElementById("imgup").style.display = 'inline';
    });
  }

  getImageFullPath = (): string => this.supplier.ImageExist ? `${environment.webApiEndPoint}ProductSupplier/${this.supplier.Image}` : 'assets/images/default.png';

}
