import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpUserEvent,
  HttpEvent,
  HttpErrorResponse,
  HttpResponse
} from "@angular/common/http";

import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { catchError, map, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { CommunicationService } from "./communication.service";
import { UserCategoryEnum } from "../models/enum/usercategory.enum";
import { LoginAdminService } from './login-admin.service';

@Injectable({
  providedIn: "root"
})
export class HttpinterceporService implements HttpInterceptor {
  constructor(
    private router: Router,
    private _communcationService: CommunicationService,
    private _loginAdmin: LoginAdminService
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let handle: Observable<HttpEvent<any>> = null;

    if (localStorage.getItem("userToken") != null) {
      const authReq = req.clone({ headers: req.headers.set("Authorization", "Bearer " + localStorage.getItem("userToken")) });

      handle = next.handle(authReq);

    } else {
      handle = next.handle(req.clone());
    }

    return handle.pipe(
      catchError(err => {
        if (err.status === 401) {
          //localStorage.removeItem("userToken");
          this._loginAdmin.removeLocalStorage();
          if (parseInt(this._loginAdmin.getUserCategoryId()) == UserCategoryEnum.Admin || parseInt(this._loginAdmin.getUserCategoryId()) == UserCategoryEnum.DataEntry)
            this.router.navigateByUrl("/loginadmin");
          else {
            this.router.navigateByUrl("/loginregister");
          }
        } else if (err.status != 400) {
          this._communcationService.setGeneralError(err.message);
        }
        throw err;
      })
    );
  }
}
