import { MobileSearchKeywordRequest } from './../../../models/MobileSearchKeywordRequest';
import { Component, OnInit } from '@angular/core';
import { MobileSearchKeywordDto } from 'src/app/models/MobileSearchKeywordDto';
import { QueryService } from 'src/app/services/query.services';
import { CommandService } from 'src/app/services/command.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { configration } from 'src/app/shared/config';

@Component({
  selector: 'app-mobile-search-keyword',
  templateUrl: './mobile-search-keyword.component.html',
  styleUrls: ['./mobile-search-keyword.component.scss']
})
export class MobileSearchKeywordComponent implements OnInit {

  title: string;
  IsArabic = true;
  total: number;
  request = new MobileSearchKeywordRequest();
  sortDir = 0;

  searchKeywordList: MobileSearchKeywordDto[] = [];
  constructor(
    private _queryService: QueryService,
    private _commandService: CommandService,
    private translateService: TranslateService,
    private _router: Router,
    private titleService: Title,

  ) { }

  ngOnInit() {

    this.request.PageIndex = 1;
    this.request.PageSize = configration.pageSize;

    this.loadAfterLang(this.translateService.currentLang);
    this.translateService.onLangChange.subscribe((res) => {
      this.loadAfterLang(res.lang);
    });

    this.getSearchKeywordList();
  }

  loadAfterLang(lang: string) {
    this.IsArabic = lang === "ar";
    this.title = this.translateService.instant("MobileSearchKeyWord");
    this.titleService.setTitle(this.title);
  }

  getSearchKeywordList() {
    try {
      this._queryService.getMobileSearchKeyword(this.request).subscribe(res => {
        this.searchKeywordList = res.Result.ItemList;
        this.total = res.Result.Total;
      })
    } catch (error) {

    }
  }

  onPagingChange(event: PageChangedEvent) {
    this.request.PageIndex = event.page;
    this.request.PageSize = event.itemsPerPage;

    this.getSearchKeywordList();
  }

  onSortClick(event: any, colName: string) {

    let target = event.currentTarget,
      classList = target.classList;

    /* sort DESC */
    if (classList.contains('fa-chevron-up')) {
      classList.remove('fa-chevron-up');
      classList.add('fa-chevron-down');
      this.sortDir = 0;

      /* sort ASC */
    } else {
      classList.add('fa-chevron-up');
      classList.remove('fa-chevron-down');
      this.sortDir = 1;
    }

    this.request.SortColumn = colName;
    this.request.SortDirection = this.sortDir;

    this.getSearchKeywordList();
  }

}
