<div class="h-100 overflow-auto">
    <div class="bd-highlight p-2">
        <h4 class="p-2 mb-0">{{ title }} [{{ "Code" | translate }}: {{ clientDetials.UserCode }}]</h4>
        <h4 class="p-2 mb-0">
            <button class="btn btn-danger" *ngIf="!showLoading" routerLink="/adminhome/editmobileuser/{{ clientDetials.Id }}">{{ "Edit" | translate }} </button>
        </h4>
    </div>

    <div class="container" *ngIf="!showLoading">

        <!-- ======= About Section ======= -->
        <section id="about" class="about">
            <div class="container">

                <!-- <div class="section-title">
          <h2>About</h2>
          <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
        </div> -->

                <div class="row">
                    <div class="col-lg-4" data-aos="fade-right">
                        <img src="assets/images/user-default.png" class="img-fluid" alt="">
                    </div>
                    <div class="col-lg-8 pt-4 pt-lg-0 content" data-aos="fade-left">
                        <h3>{{"Name" | translate}}: {{clientDetials.Name}}</h3>

                        <div class="row">
                            <div class="col-lg-6">
                                <ul>
                                    <li *ngIf="!clientDetials.IsRetail">
                                        <i class="icofont-rounded-right"></i> <strong>{{ "ClientPhoneNumber2" |
                                            translate }}:
                                        </strong>{{ clientDetials.PhoneNumber }}
                                    </li>

                                    <li *ngIf="!clientDetials.IsRetail">
                                        <i class="icofont-rounded-right"></i>
                                        <strong>{{ "SalonName" | translate }}:
                                        </strong>{{ clientDetials.SalonName }}
                                    </li>
                                    <li *ngIf="!clientDetials.IsRetail">
                                        <i class="icofont-rounded-right"></i>
                                        <strong>
                                            {{ "SalonType" | translate }}:
                                        </strong>
                                        <span *ngIf="clientDetials.SalonType === salonTypeEnum.Men">
                                            {{ "Men" | translate }}
                                        </span>
                                        <span *ngIf="clientDetials.SalonType === salonTypeEnum.Female">
                                            {{ "Female" | translate }}
                                        </span>
                                    </li>
                                    <li><i class="icofont-rounded-right"></i> <strong>{{ "CreatedTime" | translate }}:
                                        </strong>{{ clientDetials.RegisterDate | date: "[h:mm a] dd-MM-yyyy" }}</li>
                                </ul>
                            </div>
                            <div class="col-lg-6">
                                <ul>
                                    <li><i class="icofont-rounded-right"></i> <strong>{{ "OrdersDeliveredCount" |
                                            translate }}:
                                        </strong>{{ clientDetials.OrdersCount }}</li>
                                    <li><i class="icofont-rounded-right"></i> <strong>{{ "OrdersTotal" | translate }}:
                                        </strong>{{ clientDetials.OrdersTotal }} {{ "EgyptianPound" | translate }}</li>
                                    <li><i class="icofont-rounded-right"></i> <strong>{{ "LastOrderDate" | translate }}:
                                        </strong>{{ clientDetials.LastOrderDate | date: "[h:mm a] dd-MM-yyyy" }}</li>

                                    <li *ngIf="!clientDetials.IsRetail">
                                        <i class="icofont-rounded-right"></i>
                                        <strong>{{ "ClientStatus" | translate }}: </strong>
                                        <span *ngIf="clientDetials.IsActive" style="color: green">
                                            {{ "ClientActive" | translate }}
                                        </span>
                                        <span *ngIf="!clientDetials.IsActive" style="color: red">
                                            {{ "ClientNotActive" | translate }}
                                        </span>
                                    </li>

                                </ul>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-6">
                                <ul>
                                    <li *ngIf="!clientDetials.IsRetail">
                                        <i class="icofont-rounded-right"></i> <strong>{{ "VoucherID" | translate }}:
                                        </strong>{{ clientDetials.VoucherId }}
                                    </li>
                                    <li *ngIf="!clientDetials.IsRetail">
                                        <i class="icofont-rounded-right"></i> <strong>{{ "SalesName2" | translate }}:
                                        </strong>{{ clientDetials.SalesName }}</li>
                                    <li><i class="icofont-rounded-right"></i> <strong>
                                            {{ "ClientPhoneNumber2" | translate }}: </strong>{{ clientDetials.SalesPhoneNumber}}
                                    </li>
                                </ul>
                            </div>

                        </div>

                    </div>
                </div>

            </div>
        </section>
        <!-- End About Section -->

        <div class="row mt-3 border">
            <div class="col-10">
                <h3>{{ "ClinetAddressList" | translate }}</h3>
                <table class="table table-sm table-striped">
                    <thead>
                        <th>{{ "ReciverName" | translate }}</th>
                        <th>{{ "ReciverMobile" | translate }}</th>
                        <th>{{ "Street" | translate }}</th>
                        <th>{{ "City" | translate }}</th>
                        <th>{{ "Zone" | translate }}</th>
                        <th>{{ "BuildNumber" | translate }}</th>
                        <th>{{ "Description" | translate }}</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let address of clientDetials.UserAddressList">
                            <td>{{ address.ReceiverName }}</td>
                            <td>{{ address.ReciverMobile }}</td>
                            <td>{{ address.Street }}</td>
                            <td>
                                <span *ngIf="IsArabic">{{ address.City.NameAR }} </span>
                                <span *ngIf="!IsArabic"> {{ address.City.NameEN }}</span>
                            </td>
                            <td>
                                <span *ngIf="IsArabic"> {{ address.Zone.NameAR }}</span>
                                <span *ngIf="!IsArabic"> {{ address.Zone.NameEN }}</span>
                            </td>
                            <td>{{ address.BuildNumber }}</td>
                            <td>{{ address.Description }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="row my-3 border">
            <div class="col-6 my-2">
                <h3>{{ "UserNotes" | translate }}</h3>
            </div>
            <div class="col-6 my-2"> <button class="btn btn-info" *ngIf="!showLoading" (click)="showPromoCodePopup()">{{"AddNotes" | translate }}</button> </div>

            <div class="col-12 m-2" *ngIf="clientDetials.UserNotes?.length >0">
                <table class="table table-sm table-striped">
                    <thead>
                        <th scope="col">{{ "OrderID" | translate }}</th>
                        <th>{{ "Note" | translate }}</th>
                        <th>{{ "CreatedBy" | translate }}</th>
                        <th>{{ "CreationTime" | translate }}</th>
                        <th>{{ "ModifiedBy" | translate }}</th>
                        <th>{{ "ModifiedTime" | translate }}</th>
                        <th scope="col">{{ "Actions" | translate }}
                            <i class="fa fa-bolt" aria-hidden="true"></i>
                        </th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of clientDetials.UserNotes">
                            <td>{{ item.ID }}</td>
                            <td>{{ item.Notes }}</td>
                            <td>{{ item.CreatedBy}}</td>
                            <td>{{ item.CreatedTime | date: "dd-MM-yyyy h:mm a" }}</td>
                            <td>{{ item.LastModifiedBy }}</td>
                            <td>{{ item.LastModifiedTime | date: "dd-MM-yyyy h:mm a" }}</td>
                            <div class="btn-group my-1">
                                <a class="btn btn-primary" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fa fa-bars" aria-hidden="true" style="color: #f9f9f9 !important"></i></a>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a class="dropdown-item" (click)="getUserNote(item.ID)">{{ "Edit" | translate }}</a>
                                    <a class=" dropdown-item" (click)="deleteUserNote(item.ID)">{{ "Cancel" | translate}}</a>
                                </div>
                            </div>

                        </tr>
                    </tbody>
                </table>
            </div>

        </div>




        <div class="row mt-3 border">
            <div class="col-10">
                <h3>{{ "ClientOrdersSummary" | translate }}</h3>
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">{{ "orderstatus" | translate }}</th>
                            <th scope="col">{{ "clientOrdersCount" | translate }}</th>
                            <th scope="col">
                                {{ "clientOrderTotalPrice" | translate }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let summary of clientDetials.OrdersSummaryDto">
                            <td>{{ "Status_" + summary.OrderStatus | translate }}</td>
                            <td>{{ summary.OrdersCount }}</td>
                            <td>{{ summary.OrdersTotalPrice }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="row mt-3 border">
            <div class="col-12 p-0">
                <div class="table-responsive-sm">
                    <table class="table table-sm table-striped" id="header-fixed">
                        <thead>
                            <tr>
                                <th scope="col">{{ "OrderID" | translate }}</th>
                                <th scope="col">{{ "OrderDate" | translate }}</th>
                                <th scope="col">{{ "orderstatus" | translate }}</th>
                                <th scope="col">{{ "ClientName2" | translate }}</th>
                                <th scope="col">{{ "ClientPhoneNumber2" | translate }} </th>
                                <th scope="col">{{ "SalesName2" | translate }}</th>
                                <th scope="col">{{ "OrderQuantity" | translate }}</th>
                                <th scope="col"> {{ "DeliveryFees" | translate }}</th>
                                <th scope="col"> {{ "OrderTotal" | translate }} </th>
                                <th scope="col">{{ "orderDeliverydate" | translate }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let order of clientDetials.OrdersList">
                                <td>
                                    <a routerLink="/adminhome/order-details/{{ order.ID }}" target="_blank" style="color: blue">{{ order.ID }}</a>
                                </td>
                                <td> {{ order.OrderDate | date: "dd-MM-yyyy h:mm a" }}</td>
                                <td>
                                    <span *ngIf="order.OrderStatus != OrderStatusEnum.Cancelled" class="fa fa-circle text-success">
                                        {{ "Status_" + order.OrderStatus | translate }}</span>
                                    <span *ngIf="order.OrderStatus === OrderStatusEnum.Cancelled" class="fa fa-ban text-danger">
                                        {{ "Status_" + order.OrderStatus | translate }}</span>
                                </td>
                                <td>
                                    <a style="color: blue" href="/adminhome/client-details/{{order.AspNetUser.UserId}}" target="_blank">{{ order.AspNetUser.FullName }}</a>
                                </td>
                                <td>{{ order.AspNetUser.PhoneNumber }}</td>
                                <td>{{ order.AspNetUser.SalesName }}</td>
                                <td>{{ sumOrderItemQuantity(order.OrderItems) }}</td>
                                <td>{{ order.DeliveryFees }} {{ "le" | translate }}</td>
                                <td>{{ order.TotalAfterDiscount }} {{ "le" | translate }}</td>
                                <td>{{ order.DeliveryDate | date: "dd-MM-yyyy" }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <!-- start this to make loading center in page on any screen size -->
        <div *ngIf="showLoading" class=" d-flex flex-column z-index-3 w-100 h-100 justify-content-center align-items-center bg-white" style="opacity: 0.5">
            <div class="p-2 align-self-center">
                <span class="load-more-List text-center vw-100 vh-100">
                    <span class="spinner-border text-primary" role="status"> </span>
                </span>
            </div>
        </div>
        <!-- end this to make loading center in page on any screen size -->
        <div *ngIf="clientDetials.OrdersList?.length == 0" class=" d-flex flex-column z-index-3 w-100 h-100 justify-content-center align-items-center bg-white">
            <label class="la">{{ "NoDataFound" | translate }}</label>
        </div>
    </div>

    <div *ngIf="showLoading" class=" d-flex flex-column z-index-3 position-fixed w-100 h-100 justify-content-center align-items-center bg-white" style="opacity: 0.5">
        <div class="p-2 align-self-center">
            <span class="load-more-List text-center vw-100 vh-100">
                <span class="spinner-border text-primary" role="status"> </span>
            </span>
        </div>
    </div>

</div>

<div bsModal #promoCodeModal="bs-modal" class="modal fade PromoCode" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-lg h-100 w-100 m-0 p-0">
        <div class="modal-content h-100 w-100">
            <div class="modal-body h-100 w-100" *ngIf="isShowPoupPromoCode">
                <div class="d-flex flex-column h-100 w-100">
                    <div class="d-flex w-100 pb-2">
                        <h6 class="w-100"> {{ "UserNotes" | translate }} </h6>
                        <div>
                            <button type="button" class="close text-danger" aria-label="Close" (click)="hideModal()">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <div class="h-100 d-flex align-items-center">
                        <form #ApplyVoucher="ngForm" (ngSubmit)="ApplyVoucher.form.valid && AddEditNotes()" autocomplete="off" class="w-100">
                            <input placeholder="{{ 'AddUserNotesTxt' | translate }}" type="text" name="PromoCodes" class="form-control border-right-0 border-left-0 border-top-0" [(ngModel)]="NotesText" #UserNames="ngModel" required />
                            <div class="row">
                                <div class="col-6">
                                    <button class="btn btn-primary btn-block border-0 m-0" type="submit">{{ "Save" |
                                        translate }}</button>
                                </div>
                                <div class="col-6">
                                    <button class="btn btn-secondary btn-block border-0 m-0" (click)="hideModal()">{{
                                        "Cancel" | translate }}</button>
                                </div>
                            </div>
                            <div class="row mt-3" *ngIf="voucherError">
                                <div class="col-12">
                                    <h6 class="text-danger text-center">{{errMsg}}</h6>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>