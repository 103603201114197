import { LookupsModel } from './Lookups.model';

export class UserAddressDto {
    ID: number;
    UserID:string;
    Description: string;
    ReceiverName: string;
    ReciverMobile: string;
    Street: string;
    BuildNumber: string;
    isSaveAddressInList : boolean = false;
    CityID: number;
    ZoneID: number;

    City:LookupsModel = new LookupsModel();
    Zone:LookupsModel = new LookupsModel();
}
