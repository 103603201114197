import { configration } from "src/app/shared/config";
import { SortingDirectionEnum } from "../enum/SortingDirectionEnum";


export class QuantityDiscountRequestPortal {
    iD: number | null;
    patchID: number | null;
    productID: number | null;
    amount: number | null;
    productNameEn: string;
    productNameAR: string;
    isArabic: boolean;
    pageIndex: number = 1;
    pageSize: number = configration.pageSize;
    sortColumn: string;
    sortingDirection: SortingDirectionEnum = SortingDirectionEnum.Ascending;
    lang: string;
}