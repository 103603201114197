import { configration } from "../shared/config";

export class ProductTypeRequest {
  NameAR: string;
  NameEN: string;
  ID: number;
  PageIndex: number = 1;
  PageSize: number = configration.pageSize;
  Lang: string;

  SortColumn?: string;
  SortDirection?: number;
  IsRetail?: number;
}
