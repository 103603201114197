export class ProductColorDTO
{
    ID: number;
    NameAR : string;
    NameEN : string;
    ColorCode: string = null;
    IsDeleted : boolean = false;
    IsRetail?: number;
}

