import { OrderItemRequest } from 'src/app/models/OrderItemRequest.Model';
import { OrderItemModel } from 'src/app/models/Order.Model';
import { environment } from './../../../../environments/environment.prod';
import { CartService } from './../../../services/cart.service';
import { ProductService } from './../../../services/product.service';
import {
  Component,
  OnInit,
  Query,
  ViewEncapsulation,
  AfterViewInit,
  Input,
  ViewChild,
} from "@angular/core";
import { QueryService } from "src/app/services/query.services";
import { ProductRequest } from "src/app/models/ProductRequest.Model";
import { ProductModel } from "src/app/models/Product.Model";
import { configration } from "src/app/shared/config";
import { CommunicationService } from "src/app/services/communication.service";
import { AddItemToCartRequest } from "src/app/models/AddItemToCartRequest.Model";
import { CommandService } from "src/app/services/command.service";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { BsModalRef, BsModalService, ModalDirective } from "ngx-bootstrap/modal";

import { ComponentEnum } from "src/app/shared/component.enum";
import { UserCategoryEnum } from "src/app/models/enum/usercategory.enum";
import { TranslateService } from "@ngx-translate/core";
import { AddItemToFavouritRequest } from "src/app/models/AddItemToFavouritRequest";
import { ProductPatchModel } from "src/app/models/ProductPatch.model";
import { OrderRequest } from "src/app/models/OrderRequest.Model";
import { LoginAdminService } from "src/app/services/login-admin.service";
import { DeleteProductPatchFromCartRequest } from "src/app/models/DeleteProductPatchFromCartRequest";
import { filter, map, tap } from 'rxjs/operators';
import { combineLatest, forkJoin } from 'rxjs';

@Component({
  selector: "app-product",
  templateUrl: "./product.component.html",
  styleUrls: ["./product.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ProductComponent implements OnInit {
  @Input() UserId: string = "";
  @Input() patchId: number = 0;
  @Input() orderId: number = 0;
  //#region Properties
  //@Input()
  product: ProductPatchModel = new ProductPatchModel();
  @ViewChild("productDetailsModal", { static: false })
  productDetailsModal: ModalDirective;
  errMsg: string = "";
  showLoading: boolean;
  pathOfAPI: string;
  modalRef: BsModalRef;
  showPopup: boolean = false;
  isArabic: boolean = true;
  isBusy: boolean;
  imageSource: string = "";

  //#endregion Properties
  constructor(
    private _queryService: QueryService,
    private _trans: TranslateService,
    private _login: LoginAdminService,
    private router: Router,
    private _commandService: CommandService,
    private _communcationServices: CommunicationService,
    private _productService: ProductService,
    private _cartService: CartService,
    private activatedRoute: ActivatedRoute,
    
  ) {
    _trans.onLangChange.subscribe(() => {
      this.isArabic = this._trans.currentLang == "ar";
    });
  }

  ngOnInit() {
    this.isArabic = this._trans.currentLang == "ar";
    this._productService.currentProductList.subscribe(result => this.product = result.find(x => x.PatchID === this.patchId));
    this.getProductQyt();
    this.imageSource = this.getImageFullPath();
  }

  getProductQyt() {
    var request = new OrderRequest();
    request.ID = +this.orderId;
    this.showLoading = true;
    this._queryService.getSearchOrder(request).pipe(
      tap(() => this.showLoading = true),
      map(product => ({ ...this.product, qyt: product.Result.OrderItems.find(w => w.PatchId == this.patchId).Quantity }) as ProductPatchModel)
    ).subscribe((res: any) => {
      this.product.Quantity = res.qyt;
      
      this.showLoading = false
    },
      () => this.showLoading = false,
      () => this.showLoading = false);
  }


  // addItemToCart() {
  //   try {
  //     this.isBusy = true;
  //     let request = new OrderRequest();
  //     request.OrderId = +this.orderId;
  //     // request.UserID = this.UserId;
  //     this.errMsg = "";
  //     this._queryService.findOpenCart(request).subscribe(res => {
  //       if (res.HasError) {
  //         this.errMsg = res.ErrorMessage;
  //         return;
  //       }
  //       // if (res.Result.OrderItems.findIndex((a) => a.PatchId == this.product.PatchID) < 0) 
  //       this.addItemToCartMethod();

  //     },
  //       (err) => {
  //         this.errMsg = err;
  //       }
  //     );
  //   } catch (err) {
  //     this.errMsg = err;
  //   }
  // }

  addItemToCart() {
    try {
      if (this.product.Balance == 0) return;
      var request = new AddItemToCartRequest();

      request.ProductID = this.product.ProductID;
      request.PatchId = this.product.PatchID;
      request.UserID = this.UserId;
      request.OrderID = this.orderId;
      request.Qty = 1;

      this.errMsg = "";
      this.showLoading = true;


      this._commandService.AddItemToCart(request).subscribe(
        (res) => {
          this.showLoading = false;
          if (res.HasError) {
            this.errMsg = res.ErrorMessage;
            return;
          }
          this.product = res.Result.OrderItems.filter(a => a.PatchId == this.product.PatchID)[0].Product;

          this.product.AddedToCart = true;
          this._communcationServices.showProductOnList();
          this.isBusy = false;

          if (this.product.Quantity == 0)
            this.product.Quantity++

          this.updateProductState(this.product);
          this._communcationServices.updateCartNumber(res.Result.OrderItems.length)
        },
        (err) => {
          this.errMsg = err;
          this.showLoading = false;
        }
      );
    } catch (err) {
      this.errMsg = err;
      this.showLoading = false;
    }
  }
  addItemToFav() {
    if (
      this._login.getUserToken() == null ||
      (this._login.getUserToken() != null &&
        parseInt(this._login.getUserCategoryId()) != UserCategoryEnum.Client)
    ) {
      this.router.navigateByUrl("loginregister");
    } else {
      try {
        var request = new AddItemToFavouritRequest();

        request.ProductID = this.product.ProductID;

        this.errMsg = "";
        this.showLoading = true;
        this._commandService.AddItemToFav(request).subscribe(
          (res) => {
            this.showLoading = false;
            if (res.HasError) {
              this.errMsg = res.ErrorMessage;
              return;
            }
            this.product.AddedToFav = !this.product.AddedToFav;
          },
          (err) => {
            this.errMsg = err;
            this.showLoading = false;
          }
        );
      } catch (err) {
        this.errMsg = err;
        this.showLoading = false;
      }
    }
  }
  showProductDetails() {
    this.showPopup = true;
    this.productDetailsModal.show();
  }

  hideModal() {
    this.productDetailsModal.hide();
  }

  get components() {
    return ComponentEnum;
  }

  deleteItemFromCart() {

    try {
      let request = new DeleteProductPatchFromCartRequest();
      request.patchId = this.product.PatchID;
      request.userId = this.UserId;
      this.errMsg = "";
      this._commandService.DeleteProductFromCart(request).subscribe(
        (res) => {

          if (res.HasError) {
            this.errMsg = res.ErrorMessage;
            return;
          }

          if (res.Result) {
            this.product.AddedToCart = false;
            this.updateProductState(this.product);
            this._cartService.deleteProductFromOrder(this.product, this.UserId);
          }

        },
        (err) => {
          this.errMsg = err;
        }
      );
    } catch (err) {
      this.errMsg = err;
    }
  }

  updateProductState(product: ProductPatchModel) {
    if (product.Quantity == 0)
      product.AddedToCart = false;

    this._productService.changeProductState(product);
  }

  getImageFullPath=(): string =>this.product.ImageExist ? `${environment.webApiEndPoint}Product/${this.product.Image}?date=${this.product.LastModifiedTime}` :
   'assets/images/default.jpg';
  



  incQty(e) {
    e.stopPropagation();
    e.preventDefault();

    if (this.product.Balance <= this.product.Quantity) {
      return;
    } else {
      this.product.Quantity++;
      //this.product.Balance--;

      let orderItem = new OrderItemModel();
      orderItem.ID = this.product.OrderItemId;
      orderItem.OrderID = this.orderId;
      orderItem.PatchId = this.product.PatchID;
      orderItem.Quantity = this.product.Quantity;
      orderItem.Product.Balance = this.product.Balance;
      orderItem.Price = this.product.Price;
      orderItem.isDeleted = false;
      orderItem.AspNetUser.UserId = this.UserId;
      this.updateOrderToCard(orderItem);

    }
  }

  decQty(e) {
    e.stopPropagation();
    e.preventDefault();

    if (this.product.Quantity <= 0) {
      this.product.Quantity = 0;
      return;
    } else {
      this.product.Quantity--;
      //this.product.Balance++;

      let orderItem = new OrderItemModel();
      orderItem.ID = this.product.OrderItemId;
      orderItem.OrderID = this.orderId;
      orderItem.PatchId = this.patchId;
      orderItem.Quantity = this.product.Quantity;
      orderItem.Product.Balance = this.product.Balance;
      orderItem.Price = this.product.Price;
      orderItem.isDeleted = false;
      orderItem.AspNetUser.UserId = this.UserId;
      this.updateOrderToCard(orderItem);

    }
  }

  updateOrderToCard(oi: OrderItemModel) {
    try {
      var request = new OrderItemRequest();
      request.order = oi;
      request.UserID = this.UserId;

      this.errMsg = "";
      this.showLoading = true;
      this._commandService.UpdateProductPatchInOrder(request).subscribe(
        (res) => {
          this.showLoading = false;
          if (res.HasError) {
            this.errMsg = res.ErrorMessage;
            return;
          }

          this.updateProductState(this.product);
          this._communcationServices.updateCartNumber(res.Result.OrderItems.length);
          this._cartService.orderSubjectChange(res.Result)
        },
        (err) => {
          this.errMsg = err;

          this.showLoading = false;
        }
      );
    } catch (err) {
      this.errMsg = err;
      this.showLoading = false;
    }
  }

  closeDetailsModalHandeler($event) {
    if ($event) {
      if (this.showPopup) {
        this.hideModal();
      }
    }
  }

  changeProductState(product: ProductPatchModel) {
    this.product.AddedToFav = product.AddedToFav;
    this.product.AddedToCart = product.AddedToCart;
  }

  stopProp(e) {
    e.stopPropagation();
    e.preventDefault();
  }
}
