<!-- Title -->
<div class="row p-2">
    <div class="col-md-4">
        <div class="bd-highlight">
            <h4>{{ title }}</h4>
        </div>
    </div>
</div>

<!-- Loading Spinner -->
<div *ngIf="showLoading" class="d-flex flex-column z-index-3 position-fixed w-100 h-100 justify-content-center align-items-center bg-white" style="opacity: 0.5">
    <div class="p-2 align-self-center">
        <span class="load-more-List text-center vw-100 vh-100">
            <span class="spinner-border text-primary" role="status"> </span>
        </span>
    </div>
</div>

<!-- Upload File / Table / Category / Brand / Type -->
<div class="row p-2">
    <!-- Upload File -->
    <div class="col-12">
        <label for="upload-file">
            {{ "UploadFile" | translate }}
        </label>
        <div class="tools">
            <div class="file-input-wrapper">


                <label for="upload-file" class="file-input-button">{{
                    "UploadFile" | translate
                    }}</label>
                <input id="upload-file" type="file" name="excel" (change)="changeExcelFile($event)" /> {{fileName}}
                <i class="fa fa-times text-danger" *ngIf="fileName" aria-hidden="true" (click)="resetWindow($event)"></i>
            </div>
        </div>
    </div>


    <!-- Table -->
    <div class="col-12">
        <div class="table-wrapper">
            <div class="row py-2 px-1 bg-secondary text-white custom-sticky">
                <div class="widthSmall">
                    <input type="checkbox" name="checkall" id="checkAll" [checked]="selectedItems.length != 0" (change)="CheckAllOptions()" [(ngModel)]="AllChecked">

                </div>
                <div class="widthSmall">
                    #
                </div>
                <div class="col">
                    {{"ProductImage" | translate}}
                </div>
                <div class="col">
                    {{ "NameAR" | translate }}
                </div>
                <div class="col">
                    {{ "NameEN" | translate }}
                </div>
                <div class="col">
                    {{ "DescriptionAR" | translate }}
                </div>
                <div class="col">
                    {{ "DescriptionEN" | translate }}
                </div>
                <div class="col">
                    {{ "ProductCategory" | translate }}

                </div>
                <div class="col">
                    {{ "ProductBrand" | translate }}

                </div>
                <div class="col">
                    {{ "ProductType" | translate }}

                </div>
                <div class="col">
                    {{ "ProductColor" | translate }}

                </div>
                <div class="col">
                    {{ "ProductPrice" | translate }}

                </div>
                <div class="col">
                    {{ "LocationTackingNumber" | translate }}

                </div>
                <div class="widthSmall invisible">
                    <p class="mb-0 invisible">
                        Trash

                    </p>
                </div>
            </div>
            <div class="row py-2 px-1 border-bottom" *ngFor="let item of data; let i=index">
                <div class="widthSmall">
                    <input type="checkbox" name="item(i)" id="item(i)" (change)="changeSelected(i)" [(ngModel)]="item.checked" autocomplete="off">
                </div>
                <div class="widthSmall"> {{i+1}}</div>
                <div class="col">
                    <ngx-dropzone id="productImage-{{i}}" class="dropZone" (change)="onSelect($event, i)" [accept]="'image/png, .jpeg, .jpg, .bmp'">

                        <ngx-dropzone-label>Drop your image, or click to choose!
                        </ngx-dropzone-label>

                        <ngx-dropzone-image-preview class="dropZone" ngProjectAs="ngx-dropzone-preview" *ngFor="let f of item.ImageFile" [removable]="true" [removable]="true" (removed)="onRemove(i)" [file]="f">

                            <ngx-dropzone-label class="text-white" style="z-index: 0;background-color: rgb(0 0 0 / 67%);">
                                {{ item.NameEN }}

                            </ngx-dropzone-label>

                        </ngx-dropzone-image-preview>

                    </ngx-dropzone>
                </div>
                <div class="col">{{item.NameAR | TrimString}}</div>
                <div class="col">{{item.NameEN | TrimString}}</div>
                <div class="col">{{item.DescriptionAR | TrimString}}</div>
                <div class="col">{{item.DescriptionEN | TrimString}}</div>
                <div class="col">{{item.Category.ID}}</div>
                <div class="col">{{item.Brand.ID}}</div>
                <div class="col">{{item.Type.ID}}</div>
                <div class="col">{{item.Color.ID}}</div>
                <div class="col">{{ item.PurchasingPrice }}</div>
                <div class="col">{{ item.LocationTackingNumber }}</div>
                <div class="widthSmall">
                    <i class="fa fa-minus-circle text-danger" aria-hidden="true" (click)="removeItem(i)"></i>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- Buttons -->
<div class="row p-2">
    <div class="col-md-6 d-flex justify-content-start">
        <button class="btn d-flex btn-danger mx-1 bg-red bg-blue border-0" (click)="deleteSelected()" [class.disabled]="selectedItems.length == 0" [disabled]="selectedItems.length == 0">
            {{ "DeleteSelected" | translate }}
        </button>
    </div>
    <div class="col-md-6 d-flex justify-content-end">
        <button class="btn d-flex btn-success mx-1 border-0" (click)="SaveBlukProducts()" [class.disabled]="data.length == 0" [disabled]="data.length == 0">
            {{"Save" | translate}}
        </button>
        <button class="btn d-flex btn-primary bg-red bg-blue border-0" routerLink="/adminhome">
            {{"Cancel" | translate}}
        </button>
    </div>
</div>


<!-- Modal -->
<!-- Success -->
<div *ngIf="isModalShown" [config]="{ show: true }" bsModal #autoShownModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-body m-auto">
                <div class="correct_icon">
                    <svg width="98" height="98" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 98 98" style="enable-background:new 0 0 98 98;" xml:space="preserve">

                        <line class="line1" x1="32.6" y1="54.5" x2="45.8" y2="71.6" />
                        <line class="line2" x1="72.4" y1="29.5" x2="46" y2="71.6" />

                        <circle class="circle" cx="49.1" cy="49.1" r="47.1" />

                    </svg>
                    <h4>
                        {{ "SavedSuccessfully" | translate}}
                    </h4>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Error -->
<div *ngIf="isErrorModal" [config]="{ show: true }" bsModal #autoShownModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-body m-auto">
                <div class="incorrect_icon">
                    <svg width="98" height="98" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 98 98" style="enable-background:new 0 0 98 98;" xml:space="preserve">

                        <line class="line1" x1="70" x2="30" y1="20" y2="70"></line>
                        <line class="line2" x1="30" x2="70" y1="20" y2="70"></line>
                        <circle class="circle" cx="49.1" cy="49.1" r="47.1" />

                    </svg>
                    <h4>
                        {{ "SavedSuccessfully" | translate}}
                    </h4>
                </div>
            </div>
        </div>
    </div>
</div>