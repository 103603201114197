export class LookupsModel
{
    ID : number;
    NameAR : string;
    NameEN : string;
    checked : boolean = false;
}

export class ColorLookupsModel
{
    ID : string;
    Name : string;

}
