import { DiscountCategoryEnum } from "../enum/DiscountCategoryEnum";
import { DiscountCodeTypeEnum, DiscountClientEligibility } from "../enum/usercategory.enum";
import { DiscountCustomerRequest } from "./GeneralDiscount";



export class VoucherDiscountRequest {
    ID: number;
    Name: string;
    PercentageType: DiscountCodeTypeEnum;
    Amount: number;
    CustomersEligibility: DiscountClientEligibility;
    DiscountCategory: DiscountCategoryEnum;
    VoucherCode: string;
    ValidDateFrom: Date;
    ValidDateTo: Date;
    MinmumOrderAmount: number;
    DiscountCustomers: DiscountCustomerRequest[];
    IsRetail:number;
}