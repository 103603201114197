export class ProductSupplierRequest {
    ID: number;
    NameEN: string;
    NameAR: string;
    Image: string;
    IsDeleted: boolean;
    ImageExist: boolean;
    PhoneNumber: string;
    Email: string;
    PageIndex: number;
    PageSize: number;
    IsArcabic: boolean;
    SortDirection: number;
    SortColumn: string;
    IsExcel: boolean;
    UserId: string;
    IsRetail?: number;
}
