import { LookupsModel } from "./Lookups.model";

export class ProductExcelModel{
  Product_ID?: number;
  Product_NameEN?: string;
  Product_NameAR?: string;
  Category_NameEN?: string;
  Category_NameAR?: string;
  Brand_NameEN?: string;
  Brand_NameAR?: string;
  Type_NameEN?: string;
  Type_NameAR?: string;
  Color_NameEN?: string;
  Color_NameAR?: string;
  Supplier_NameAR?: string;
  Supplier_NameEN?: string;

  Quantity?: number;
  Booked?: number;
  Balance?: number;

  PurchasingPrice?: number;
  Product_DescriptionEN?: string;
  Product_DescriptionAR?: string;

  IsAvailable?: boolean;

  LocationTackingNumber?: string;
  InventoryShelfNumber?: string;
  ProductWeights?: number;
  ProductLength?: number;
  ProductWidth?: number;
  ProductOriginCountry?: string;

  ActualStock?:number;
  ProductType?:string;
}


export class ProductDTOExcel {
  ID: number;
  NameEN: string;
  NameAR: string;
  DescriptionEN: string;
  DescriptionAR: string;
  IsAvailable: boolean;
  Category: LookupsModel = new LookupsModel();
  Category1: LookupsModel  = new LookupsModel();
  Category2: LookupsModel  = new LookupsModel();
  Category3: LookupsModel  = new LookupsModel();
  Brand: LookupsModel  = new LookupsModel();
  Type: LookupsModel  = new LookupsModel();
  Color: LookupsModel  = new LookupsModel();
  Supplier: LookupsModel  = new LookupsModel();
  QuantitySum: number;
  BookedSum: number;
  BalanceSum: number;
  PatchCount: number | null;
  PurchasingPrice: number;
  LocationTackingNumber: string;
  InventoryShelfNumber: string;
  ProductCategory1: number | null;
  ProductCategory2: number | null;
  ProductCategory3: number | null;
  ProductWeights: number;
  ProductLength: number;
  ProductWidth: number;
  ProductOriginCountry: string;
  PatchPrice: number | null;
  ActualStock: number | null;
  ProductOrder: number | null;
  IsRetail: number | null;
}