import { environment } from './../../../../environments/environment.prod';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { QueryService } from 'src/app/services/query.services';
import { CommandService } from 'src/app/services/command.service';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { ProductBrandDTO } from 'src/app/models/ProductBrandDTO.model';
import { AddEditProductBrandRequest } from 'src/app/models/AddEditProductBrandRequest.model';
import { ProductBrandRequest } from 'src/app/models/ProductBrandRequest.model';
import { FileService } from 'src/app/services/file.service';
import { configration } from 'src/app/shared/config';

@Component({
  selector: 'app-product-brand-add-edit',
  templateUrl: './product-brand-add-edit.component.html',
  styleUrls: ['./product-brand-add-edit.component.scss']
})
export class ProductBrandAddEditComponent implements OnInit {

  IsArabic = true;
  title: string;
  productBrand: ProductBrandDTO = new ProductBrandDTO();

  _AddEditProductBrandRequst: AddEditProductBrandRequest = new AddEditProductBrandRequest();
  errMsg: string;
  showLoading: boolean;
  imageSource = 'assets/images/default.png';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private queryService: QueryService,
    private commandService: CommandService,
    private translateService: TranslateService,
    private titleService: Title,
    private fileService: FileService
  ) { }

  ngOnInit() {
    this.loadAfterLang(this.translateService.currentLang);
    this.translateService.onLangChange.subscribe(res => this.loadAfterLang(res.lang));
    this.getProductBrandById();
  }

  loadAfterLang(lang: string) {
    this.IsArabic = (lang === 'ar');

    this.route.params.subscribe((params: Params) => {
      let id = params['ID'];
      if (id == 0) {
        this.title = this.translateService.instant("AddProductBrand");
      }
      else {
        this.title = this.translateService.instant("EditProductBrand");
      }

      this.titleService.setTitle(this.title);
    });


  }

  getProductBrandById() {
    try {
      this.route.params.subscribe((params: Params) => {
        this.productBrand.ID = params['ID'];

        if (!this.productBrand.ID || this.productBrand.ID <= 0) {
          return;
        }

        this.errMsg = "";
        this.showLoading = true;
        let productRequest = new ProductBrandRequest();
        productRequest.ID = this.productBrand.ID;

        this.queryService.searchProductBrand(productRequest).subscribe(res => {
          this.showLoading = false;
          if (res.HasError) {
            this.errMsg = res.ErrorMessage;
            return;
          }
          this.productBrand = res.Result.ItemList[0];
          this.imageSource = this.getImageFullPath();
        }, err => {
          this.errMsg = err;
          this.showLoading = false;
        });

      });

    } catch (err) {
      this.errMsg = err;
      this.showLoading = false;

    }
  }

  saveProductBrand() {
    try {
      this.errMsg = "";
      this.showLoading = true;
      this._AddEditProductBrandRequst.ProductBrandDTO = this.productBrand;
      this.commandService.AddEditProductBrand(this._AddEditProductBrandRequst).subscribe(res => {
        this.showLoading = false;
        if (res.HasError) {
          if (res.ErrorMessage == "NotUniquo") {
            var check = confirm(this.translateService.instant("Namealreadyexists"))
            return;
          }
          this.errMsg = res.ErrorMessage;
          return;
        }

        this.router.navigateByUrl('adminhome/productbrandadminlist')

      }, err => {
        this.errMsg = err;
        this.showLoading = false;
      });
    } catch (err) {
      this.errMsg = err;
      this.showLoading = false;

    }

  }

  fileChangeEvent(fileInput: any) {
    this.errMsg = "";
    var validImageExtention = this.fileService.checkFileExtentionForImage(fileInput.target.files);
    if (!validImageExtention) {

      this.errMsg = this.translateService.instant("ImageErrorMessage");
      return;
    }
    var validImagesize = this.fileService.checkFileSize(fileInput.target.files);
    if (!validImagesize) {

      this.errMsg = this.translateService.instant("ImageSizeErrorMessage");
      return;
    }
    this.fileService.uploadFiles(fileInput.target.files).subscribe(res => {
      this.showLoading = false;
      if (res.HasError) {
        this.errMsg = res.ErrorMessage;
        return;
      }

      this.productBrand.Image = res.Result[0].RelativePath;
      this.imageSource = `${environment.webApiEndPoint}Temp/${this.productBrand.Image}`
      document.getElementById("imgup").style.display = 'inline';
    });
  }

  getImageFullPath = (): string => this.productBrand.ImageExist ?
    `${environment.webApiEndPoint}ProductBrand/${this.productBrand.Image}?date=${this.productBrand.LastModifiedTime}` : 'assets/images/default.png';


}
