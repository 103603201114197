import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[onlynumber]'
})
export class OnlyNumberDirective {

    constructor(private el: ElementRef) { }


    // @Input('patternmask') pattern: RegExp;

    @HostListener('keypress', ['$event']) onkeypress(event: KeyboardEvent) {
        var charCode = (event.which) ? event.which : event.keyCode;
         
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
        // let text = event.clipboardData.getData('text/plain');
        // var input = text.replace(/^-?[0-9]+(\.[0-9]*){0,1}$/g, '');
        // if (input !== text) {
        //     return false;
        // }
        // return true;
    }
    @HostListener('paste', ['$event']) onpaste(event: ClipboardEvent) {
        let text = event.clipboardData.getData('text/plain');
        var input = text.replace(/^-?[0-9]+(\.[0-9]*){0,1}$/g, '');
        if (input !== text) {
            return true;
        }
        return false;
    }
}