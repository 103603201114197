export class OrderRequest {
    ID: number;
    UserID: string;
    UserCategoryId: number;
    StatusIDs: number[];
    PageIndex: number;
    PageSize: number;
    From: Date | null;
    To: Date | null;
    DeliveryDateFrom: Date | null;
    DeliveryDateTo: Date | null;
    IsExcel: boolean = false;
    OrderId: number;
    OrderStatusId: number;
    VoucherId: number;
    SalesPersonId: string;
    SortColumn: string;
    SortDirection: number;
    IsArabic: boolean;
    ClientName: string;
    ClientCode: number;
    ClientNumber: string;
    ProductID: number;
    FromCurrentStatus?: number
    OrderPreviousStatus?: number ;
    IsRetail?:boolean;
}

export class OrderRequestNew {
    ID: number;
    UserID: string;
    StatusIDs: number[];
    PageIndex: number;
    PageSize: number;
    From: Date | null;
    To: Date | null;
    IsExcel: boolean = false;
    OrderId: number;
    OrderStatusId: number;
    VoucherId: number;
    SalesPersonId: string;

}


