<div class="d-flex flex-column h-100 w-100 p-2">
    <div class="bd-highlight">
        <div class="row">
            <div class="col-6">
                <h4>{{ title }}</h4>
            </div>
        </div>


        <div class="row">
            <div class="col-4">
                <button class="btn btn-primary" routerLink="/adminhome/orders-config">
          {{ "OrdersConfig" | translate }}
        </button>
            </div>

            <div class="col-4">
                <button class="btn btn-primary" routerLink="/adminhome/shineImage">
          {{ "ShineImages" | translate }}
        </button>
            </div>

            <div class="col-4">
                <button class="btn btn-success" routerLink="/adminhome/mobile-search-keyword">
          {{ "MobileSearchKeyWord" | translate }}
        </button>
            </div>

        </div>
    </div>
</div>