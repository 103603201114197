import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { Location } from '@angular/common';
import { QueryService } from 'src/app/services/query.services';
import { UserAddressRequest } from 'src/app/models/UserAddressRequest.Model';
import { UserAddressDto } from 'src/app/models/UserAddressDto.Model';
import { CommandService } from 'src/app/services/command.service';
import { DeleteUserAdressRequest } from 'src/app/models/DeleteUserAdressRequest.Model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommunicationService } from 'src/app/services/communication.service';
import { Action, ActionType } from 'src/app/models/Action';
import { ComponentEnum } from 'src/app/shared/component.enum';
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'app-user-address-list',
  templateUrl: './user-address-list.component.html',
  styleUrls: ['./user-address-list.component.scss']
})
export class UserAddressListComponent implements OnInit {

  errMsg: string;
  showLoading: boolean;
  request: UserAddressRequest = null;
  userAdressesLst: UserAddressDto[] = [];
  addressId: number = 0;
  showChooseButton = false;
  showMenu: boolean = false;
  isArabic: boolean = true;
  currentView: ComponentEnum = ComponentEnum.userAddressList;
  @Input() isSelectionMode: boolean = false;
  @Input() UserId: string ;

  constructor(private _queryService: QueryService,
    private _CommandService: CommandService,
    private _trans: TranslateService,
    private _communcationServices: CommunicationService,
    private _location: Location,
    private _route: ActivatedRoute, private _router: Router) {
    _trans.onLangChange.subscribe(() => {
      this.isArabic = this._trans.currentLang == 'ar'

    });


  }

  ngOnInit() {
    this.isArabic = this._trans.currentLang == 'ar';
    this.getAllUserAdresses();
    this._communcationServices.getAction().subscribe(res => {
      if (res.actionType == ActionType.ShowHideSideMenu) {
        this.showMenu = res.Result;
      } else if (res.actionType == ActionType.UserEditAddressClosed) {
        this.currentView = ComponentEnum.userAddressList;
      } else if (res.actionType == ActionType.UserEditAddressSaved) {
        this.currentView = ComponentEnum.userAddressList;
        this.getAllUserAdresses();
      }
    });
  }

  showSideMenu() {
    this.showMenu = !this.showMenu;
    this._communcationServices.showSideMenuAction(this.showMenu);

  }
  getAllUserAdresses() {
    try {
      this.errMsg = "";
      this.showLoading = true;
      this.request = new UserAddressRequest();
      this.request.UserId=this.UserId;
      this._queryService.getAllUserAddress(this.request).subscribe(res => {
        this.showLoading = false;
        if (res.HasError) {
          this.errMsg = res.ErrorMessage;
          return;
        }
        this.userAdressesLst = res.Result;
      }, err => {
        this.errMsg = err;
        this.showLoading = false;
      });
    } catch (err) {
      this.errMsg = err;
      this.showLoading = false;

    }
  }
  deleteAddress(ua: UserAddressDto) {
    try {

      var check = confirm(this._trans.instant("AreYouSureDelete"))
        if(check == false)
            return;

      this.errMsg = "";
      this.showLoading = true;
      let request = new DeleteUserAdressRequest();
      request.UserId=this.UserId;
      request.AddressId = ua.ID
      this._CommandService.DeleteUserAddress(request).subscribe(res => {
        this.showLoading = false;
        if (res.HasError) {
          this.errMsg = res.ErrorMessage;
          return;
        }
        if (res.Result) {
          //TODO Business.
          this.ngOnInit();
        }
      }, err => {
        this.errMsg = err;
        this.showLoading = false;
      });
    } catch (err) {
      this.errMsg = err;
      this.showLoading = false;

    }
  }

  chooseAddress(ua: UserAddressDto) {
    this._communcationServices.UserAddressSelected(ua);
  }

  EditAddress(id) {
    this.addressId = id;
    this.currentView = ComponentEnum.addEditUserAddress;
  }

  GoToAddAddress() {
    this.addressId = 0;
    this.currentView = ComponentEnum.addEditUserAddress;
  }


  BackBtn() {
    if(this.isSelectionMode)
      this._communcationServices.UserAddressListClosed();
    else
      this._router.navigate(['home']);
  }


  get components() {
    return ComponentEnum;
  }
  get isPopup() {
    return this._route.routeConfig.outlet && this._route.routeConfig.outlet == "popupoutlet";
  }
}
