<div class="h-100 overflow-auto">
    <div class="bd-highlight p-2">
        <h4 class="p-2 mb-0">{{ title }}</h4>
    </div>


    <div class="text text-danger px-3"><span *ngFor="let error of errors"> {{ error }}</span></div>

    <!-- <div *ngIf="showLoading || showLoadingCategory || showLoadingPerson" class=" d-flex flex-column z-index-3 justify-content-center align-items-center bg-white" style="opacity: 0.5">
        <div class="p-2 align-self-center">
            <span class="load-more-List text-center vw-100 vh-100">
            <span class="spinner-border text-primary" role="status"> </span>
            </span>
        </div>
    </div> -->

    <!-- start this to make loading center in page on any screen size -->
    <div *ngIf="showLoading || showLoadingCategory || showLoadingPerson" class="d-flex flex-column z-index-3 position-fixed w-100 h-100 justify-content-center align-items-center bg-white" style="opacity: 0.5">
        <div class="p-2 align-self-center">
            <span class="load-more-List text-center vw-100 vh-100">
        <span class="spinner-border text-primary" role="status"> </span>
            </span>
        </div>
    </div>
    <!-- Edit Mode Off  -->
    <form #AddEditFrom="ngForm" (ngSubmit)="AddEditFrom.form.valid && SavesalesPerson()" autocomplete="off">
        <!-- [mustMatch]="editMode && (!Password.touched || !Password.dirty) ? ' ' : ['Password', 'ConfirmPassword']"> -->
        <div class=" row mt-1 mb-1">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <label> {{ "FullName" | translate }} </label>

                <input name="Name" #Names="ngModel" type="text" class="form-control mb-2 rounded" [(ngModel)]="salesPerson.Name" placeholder="{{ 'FullName' | translate }}" required />
                <div *ngIf="AddEditFrom.submitted && Names.errors?.required" class="text text-danger pl-2 pr-2">
                    {{ "Nameisrequired" | translate }}
                </div>
            </div>
            <!-- <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12  ">
        <label> {{ "username" | translate }} </label>

        <input
          name="UserName"
          type="text"
          class="form-control mb-2 rounded"
          #UserNames="ngModel"
          required
          [(ngModel)]="salesPerson.UserName"
          placeholder="{{ 'username' | translate }}"
        />
        <div
          *ngIf="AddEditFrom.submitted && UserNames.errors?.required"
          class="text text-danger offset-3"
        >
          {{ "usernameisrequired" | translate }}
        </div>
      </div> -->
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <label> {{ "phonenumber" | translate }} </label>

                <input name="PhoneNumber" maxlength="11" onlynumber type="text" class="form-control mb-2 rounded" [(ngModel)]="salesPerson.PhoneNumber" placeholder="{{ 'phonenumber' | translate }}" #PhoneNumber="ngModel" required />
                <div *ngIf="AddEditFrom.submitted && PhoneNumber.errors?.required" class="text text-danger px-3">
                    {{ "phonenumberisrequired" | translate }}
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <label> {{ "Email" | translate }} </label>

                <input name="Email" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" type="email" class="form-control mb-2 rounded" [(ngModel)]="salesPerson.Email  " placeholder="{{ 'Email' | translate }}" #Emails="ngModel" required />
                <div *ngIf="AddEditFrom.submitted && Emails.errors?.required" class="text text-danger px-3">
                    {{ "EmailIsRequired" | translate }}
                </div>
                <div *ngIf="AddEditFrom.submitted && Emails.errors?.pattern" class="text text-danger px-3">
                    {{ "EmailFormat" | translate }}
                </div>
            </div>

            <div *ngIf="!editMode" class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <label> {{ "password" | translate }} </label>

                <input name="Password" type="password" class="form-control mb-2 rounded" [(ngModel)]="salesPerson.Password" placeholder="{{ 'password' | translate }}" #Password="ngModel" required />
                <div *ngIf="AddEditFrom.submitted && Password.errors?.required" class="text text-danger px-3">
                    {{ "passwordisrequired" | translate }}
                </div>
            </div>
            <div *ngIf="!editMode" class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <label> {{ "confrimpassword" | translate }} </label>

                <input name="ConfirmPassword" type="password" class="form-control mb-2 rounded" [(ngModel)]="salesPerson.ConfirmPassword" placeholder="{{ 'confrimpassword' | translate }}" #ConfirmPasswords="ngModel" [required]=" salesPerson.Password != '' " />
                <div *ngIf="AddEditFrom.submitted && ConfirmPasswords.errors?.required" class="text text-danger px-3">
                    {{ "ConfirmPasswordisrequired" | translate }}
                </div>
                <div *ngIf="AddEditFrom.submitted && ConfirmPasswords.errors?.mustMatch" class="text text-danger px-3">
                    {{"PasswordNotmatched" | translate}}
                </div>

            </div>

            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <label> {{ "UserCategory" | translate }} </label>

                <select class="form-control" name="UserCategoryId" #UserCategoryIds="ngModel" [(ngModel)]="salesPerson.UserCategoryId" required>
          <option [ngValue]="null">
            {{ "SelectUserCategory" | translate }}
          </option>
          <option *ngFor="let item of UserCategoryList" [ngValue]="item.ID">
            <span *ngIf="IsArabic"> {{ item.NameAR }}</span>
            <span *ngIf="!IsArabic"> {{ item.NameEN }}</span>
          </option>
        </select>
                <div *ngIf="AddEditFrom.submitted && UserCategoryIds.errors?.required" class="text text-danger px-3">
                    {{ "UserCategoryisrequired" | translate }}
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <label> {{ "Note" | translate }} </label>
                <textarea class="form-control w-100 rounded" placeholder="{{ 'Note' | translate }}" name="Notes" [(ngModel)]="salesPerson.Notes"></textarea>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <label class="d-block w-100 hold-checkbox position-relative align-self-end">
          <input class="form-control rounded" type="checkbox" name="IsAvailable" [(ngModel)]="salesPerson.IsActive" />
          <span class="checkmark"></span>
          <span class="d-inline-block">{{ "Active" | translate }}</span>
        </label>
            </div>
        </div>

        <div class="row mb-1">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <button class="btn btn-primary bg-red border-danger" type="submit">
          {{ "Save" | translate }}
        </button>
                <button class="btn btn-primary bg-blue border-0 m-1" routerLink="/adminhome/adminuserlist">
          {{ "Cancel" | translate }}
        </button>
            </div>
        </div>
    </form>




</div>