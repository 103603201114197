import { Component, OnInit } from "@angular/core";
import { CommunicationService } from "src/app/services/communication.service";
import { ActionType } from "src/app/models/Action";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-admin-home",
  templateUrl: "./admin-home.component.html",
  styleUrls: ["./admin-home.component.scss"],
})
export class AdminHomeComponent implements OnInit {
  isCollapsed: boolean = true;
  showSideMenu: boolean = true;
  showAlert: boolean = false;
  msg: string;

  constructor(
    private _communicationService: CommunicationService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this._communicationService.getAction().subscribe((res) => {
      if (res.actionType == ActionType.AlertMsg) {
        this.showAlert = true;
        this.msg = this.translate.instant("ErrorConnection");
      } else if (res.actionType == ActionType.ColseAlert) {
        this.showAlert = false;
      }
    });
  }
  collapseSideMenu() {
    this.isCollapsed = !this.isCollapsed;
    this._communicationService.setCloseAdminSideMenu(this.isCollapsed);
  }
  closealertMessage() {
    this.showAlert = false;
  }

  closeSideMenu() {
    this._communicationService.showSideMenuAction(false);
  }
}
