export enum OrderStatusEnum {
  Open = 1,
  Saved = 2,
  OnTheWay = 3,
  Delivered = 4,
  Cancelled = 5,
  End=6,
  Edit=7
}


export enum ProductSortEnum {
  ProductListAscending = 1,
  ProductListDescending = 2,
  PriceProductListLowest = 3,
  PriceProductListhighest = 4,
  ProductListLastAdded = 5
}

export class OrderStatus{
  ID:number;
  NameAr:string;
  NameEn:string;
}
