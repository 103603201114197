import { VoucherDiscountAddEditComponent } from './component/portal/discount/voucher-discount-add-edit/voucher-discount-add-edit.component';
import { QuantityDiscountListComponent } from './component/portal/discount/quantity-discount-list/quantity-discount-list.component';
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
} from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { MyHammerConfig } from "./myHammer.config";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ModalModule } from "ngx-bootstrap/modal";
import { AngularFontAwesomeModule } from "angular-font-awesome";
import { HttpinterceporService } from "./services/httpintercepor.service";
import { TabsModule } from "ngx-bootstrap/tabs";

import { OnlyNumberDirective } from "./shared/Directive/onlynumber";
import { ProductAdminListComponent } from "./component/portal/product-admin-list/product-admin-list.component";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { PaginationsComponent } from "./shared/component/pagination/pagination.component";
import { ProductAddEditComponent } from "./component/portal/product-add-edit/product-add-edit.component";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ProductAdminComponent } from "./component/portal/product-admin/product-admin.component";
import { TrimString } from "./shared/pipes/trimString";
import { NgxDropzoneModule } from "ngx-dropzone";
import { adminOrderListComponent } from "./component/portal/admin-order-list/admin-order-list.component";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { GenerateVoucherComponent } from "./component/portal/generate-voucher/generate-voucher.component";
import { QueryVoucherComponent } from "./component/portal/query-voucher/query-voucher.component";
import { SideMenuPortalComponent } from "./component/portal/side-menu-portal/side-menu-portal.component";
import { AdminloginComponent } from "./component/portal/adminlogin/adminlogin.component";
import { AddeditAdminUserComponent } from "./component/portal/addedit-Admin-User/addedit-Admin-User.component";
import { AdminUserListComponent } from "./component/portal/Admin-User-list/Admin-User-list.component";
import { CategoryAdminListComponent } from "./component/portal/category-admin-list/category-admin-list.component";
import { CategoryAddEditComponent } from "./component/portal/category-add-edit/category-add-edit.component";
import { ProductTypeAdminListComponent } from "./component/portal/product-type-admin-list/product-type-admin-list.component";
import { ProductTypeAddEditComponent } from "./component/portal/product-type-add-edit/product-type-add-edit.component";
import { ProductBrandAdminListComponent } from "./component/portal/product-brand-admin-list/product-brand-admin-list.component";
import { ProductBrandAddEditComponent } from "./component/portal/product-brand-add-edit/product-brand-add-edit.component";
import { ProductColorAdminListComponent } from "./component/portal/product-color-admin-list/product-color-admin-list.component";
import { ProductColorAddEditComponent } from "./component/portal/product-color-add-edit/product-color-add-edit.component";
import { InventoryComponent } from "./component/portal/inventory/inventory.component";
import { ProductPatchAddComponent } from "./component/portal/product-patch-add/product-patch-add.component";
import { AdminHomeComponent } from "./component/portal/admin-home/admin-home.component";
import { AlertMessageComponent } from "./component/portal/alert-message/alert-message.component";
import { AdminMobileUserListComponent } from "./component/portal/admin-mobile-user-list/admin-mobile-user-list.component";
import { PublishPushNotificationComponent } from "./component/portal/publish-push-notification/publish-push-notification.component";
import { EditMobileUserComponent } from "./component/portal/edit-mobile-user/edit-mobile-user.component";
import { AddNewOrderComponent } from "./component/portal/add-new-order/add-new-order.component";
import { ProductCategoryComponent } from "./component/portal/product-category/product-category.component";
import { ProductListComponent } from "./component/portal/product-list/product-list.component";
import { ProductComponent } from "./component/portal/product/product.component";
import { CartComponent } from "./component/portal/cart/cart.component";
import { CityZoneLookupComponent } from "./component/portal/city-zone-lookup/city-zone-lookup.component";
import { UserAddressListComponent } from "./component/portal/user-address-list/user-address-list.component";
import { UserAddressADDEditComponent } from "./component/portal/user-address-addedit/user-address-addedit.component";
import { ProductFilterComponent } from "./component/portal/product-filter/product-filter.component";
import { MustMatchDirective } from "./shared/Directive/must-match";
import { ProductAddBulkComponent } from "./component/portal/product-add-bulk/product-add-bulk.component";
import { NgxBootstrapConfirmModule } from "ngx-bootstrap-confirm";
import { DatePipe } from "@angular/common";
import { UserCategoryPagesComponent } from './component/user-category-pages/user-category-pages.component';
import { AccessDeniedComponent } from "./component/portal/access-denied/access-denied.component";
import { HomeComponent } from './component/portal/home/home.component';
import { ChangePasswordComponent } from './component/portal/change-password/change-password.component';
// import { ConfirmationDialogComponent } from './component/portal/confirmation-dialog/confirmation-dialog.component';
// import { MaterialModule } from '@angular/material/material'
// import { MaterialModule } from './../material/material.module';
import {MatTableModule} from '@angular/material/table';
import { OrderDetailsComponent } from './component/portal/order-details/order-details.component';
import { GenerateOrderDetailsComponent } from './component/portal/generate-order-details/generate-order-details.component';
import { NotActiveUsersComponent } from './component/portal/not-active-users/not-active-users.component';
import { OrdersConfigurationComponent } from './component/portal/orders-configuration/orders-configuration.component';
import { AdminConfigComponent } from './component/portal/admin-config/admin-config.component';
import { ClientDetialsComponent } from './component/portal/client-detials/client-detials.component';
import { ProductDetialsComponent } from './component/portal/product-detials/product-detials.component';
import { MobileSearchKeywordComponent } from './component/portal/mobile-search-keyword/mobile-search-keyword.component';
import { ProductSuppliersComponent } from './component/portal/product-suppliers/product-suppliers.component';
import { AddEditProductSuppliersComponent } from './component/portal/add-edit-product-suppliers/add-edit-product-suppliers.component';
// import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgSelectModule } from '@ng-select/ng-select';
import { DiscountListComponent } from './component/portal/discount/discount-list/discount-list.component';
import { NavbarDiscountComponent } from './component/portal/discount/navbar-discount/navbar-discount.component';
import { DiscountAddEditComponent } from './component/portal/discount/discount-add-edit/discount-add-edit.component';
import { QuantityDiscountAddEditComponent } from './component/portal/discount/quantity-discount-add-edit/quantity-discount-add-edit.component';
import { VoucherDiscountListComponent } from './component/portal/discount/voucher-discount-list/voucher-discount-list.component';
import { CustomerDiscountListComponent } from './component/portal/discount/customer-discount-list/customer-discount-list.component';
import { CustomerDiscountAddEditComponent } from './component/portal/discount/customer-discount-add-edit/customer-discount-add-edit.component';
import { ShineImagesComponent } from './component/portal/Shine/shineImages/shineImages.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    MustMatchDirective,
    OnlyNumberDirective,
    ProductAddEditComponent,
    ProductAdminListComponent,
    PaginationsComponent,
    ProductAdminComponent,
    ProductAdminComponent,
    AdminUserListComponent,
    AddeditAdminUserComponent,
    AdminHomeComponent,
    adminOrderListComponent,
    ShineImagesComponent,
    QueryVoucherComponent,
    GenerateVoucherComponent,
    SideMenuPortalComponent,
    AdminloginComponent,
    CategoryAdminListComponent,
    CategoryAddEditComponent,
    ProductTypeAdminListComponent,
    ProductTypeAddEditComponent,
    ProductBrandAdminListComponent,
    ProductBrandAddEditComponent,
    ProductColorAdminListComponent,
    ProductColorAddEditComponent,
    InventoryComponent,
    ProductPatchAddComponent,
    AlertMessageComponent,
    AdminMobileUserListComponent,
    PublishPushNotificationComponent,
    EditMobileUserComponent,
    AddNewOrderComponent,
    ProductCategoryComponent,
    ProductListComponent,
    ProductComponent,
    ProductFilterComponent,
    CartComponent,
    CityZoneLookupComponent,
    UserAddressListComponent,
    UserAddressADDEditComponent,
    TrimString,
    ProductAddBulkComponent,
    UserCategoryPagesComponent,
    AccessDeniedComponent,
    HomeComponent,
    ChangePasswordComponent,
    OrderDetailsComponent,
    GenerateOrderDetailsComponent,
    NotActiveUsersComponent,
    OrdersConfigurationComponent,
    AdminConfigComponent,
    ClientDetialsComponent,
    ProductDetialsComponent,
    MobileSearchKeywordComponent,
    ProductSuppliersComponent,
    AddEditProductSuppliersComponent,
    DiscountListComponent,
    DiscountAddEditComponent,
    QuantityDiscountListComponent,
    NavbarDiscountComponent,
    QuantityDiscountAddEditComponent,
    VoucherDiscountAddEditComponent,
    VoucherDiscountListComponent,
    CustomerDiscountListComponent,
    CustomerDiscountAddEditComponent
    // ConfirmationDialogComponent,

  ],
  imports: [
    ModalModule.forRoot(),
    NgSelectModule,
    // NgOptionHighlightModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BsDatepickerModule.forRoot(),
    TabsModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    AngularFontAwesomeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    PaginationModule.forRoot(),
    DropDownsModule,
    BrowserAnimationsModule,
    NgxDropzoneModule,
    MatTableModule
    //NgxBootstrapConfirmModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpinterceporService,
      multi: true,
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
