import { environment } from './../../../../environments/environment.prod';
import { OrderItemModel } from './../../../models/Order.Model';
import { OrderModel } from 'src/app/models/Order.Model';
import { Component, ElementRef, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OrderRequest } from 'src/app/models/OrderRequest.Model';
import { QueryService } from 'src/app/services/query.services';
import { OrderStatusEnum } from 'src/app/models/enum/OrderStatusEnum';
import html2canvas from 'html2canvas';
import { HTMLOptions, jsPDF } from 'jspdf';
import autoTable, { RowInput, UserOptions } from 'jspdf-autotable';



@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit, AfterViewInit {

  orderId: number;
  errMsg: string = "";
  showLoading: boolean;
  pathOfAPI: string;
  IsArabic = true;
  title: string;
  orderDetails: OrderModel = new OrderModel();
  orderStatusEnum = OrderStatusEnum;
  exportOnly: string;
  @ViewChild('ExportedToPDF', { static: false }) pdfTable: ElementRef;

  constructor(
    private _route: ActivatedRoute,
    private _queryService: QueryService,
    private translateService: TranslateService,
    private titleService: Title,
  ) { }

  ngAfterViewInit(): void {

  }

  ngOnInit() {
    this.loadAfterLang(this.translateService.currentLang);
    this.translateService.onLangChange.subscribe((res) => this.loadAfterLang(res.lang));

    this._route.params.subscribe((params: Params) => {
      this.orderId = params["OrderId"];
      this.exportOnly = params["ExportToPDF"];

      this.getOrderDetails(this.orderId)
    });
  }

  loadAfterLang(lang: string) {
    this.IsArabic = lang === "ar";
    this.title = this.translateService.instant("OrderDetails");
    this.titleService.setTitle(this.title);
  }

  getOrderDetails(orderId: number) {

    let request = new OrderRequest();
    request.ID = orderId;

    try {
      this.errMsg = "";
      this.showLoading = true;

      this._queryService.getSearchOrder(request).subscribe(res => {
        if (res.HasError) {
          this.errMsg = res.ErrorMessage;
          this.showLoading = false;
          return;
        }

        this.orderDetails = res.Result;
        this.showLoading = false;
      },
        (err) => {
          this.errMsg = err;
          this.showLoading = false;
        }
      );
    } catch (err) {
      this.errMsg = err;
      this.showLoading = false;
    }
  }


  getImageFullPath = (imageExist: boolean, imageName: string, lastModifiedTime: Date): string => imageExist ?
    `${environment.webApiEndPoint}Product/${imageName}?date=${lastModifiedTime}` : 'assets/images/default.png';


  // sumOrderItemQuantity() {
  //   let quantity = 0;
  //   this.orderDetails.OrderItems.filter(x => x.isDeleted == false).forEach(x => quantity = quantity + x.Quantity);
  //   return quantity;
  // }


  sumOrderItemQuantity = () => this.orderDetails.OrderItems.filter(x => x.isDeleted == false)
    .reduce((partialTotal, x) => partialTotal + x.Quantity, 0)

  exportToPDF() {

    html2canvas(this.pdfTable.nativeElement).then((canvas) => {
      const FILEURI = canvas.toDataURL('image/png');
      let PDF = new jsPDF('p', 'mm', 'a4');
      let pageHeight = 295;
      let fileWidth = 208;
      let fileHeight = canvas.height * fileWidth / canvas.width;
      let heigtLeft = fileHeight;
      let position = 10; //some top padding to first page

      // PDF.addFont("DubaiW23-Medium.ttf", "dubai", "normal");
      // PDF.setFont("dubai")
      // autoTable( PDF, {html: '#mytable'})




      // PDF.addImage(FILEURI, 'PNG',0, position, fileWidth, fileHeight + 30)
      while (heigtLeft > 0) {
        PDF.addPage();
        position += heigtLeft - fileHeight; // top padding for other pages
        PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);
        heigtLeft -= pageHeight;
      }
      PDF.deletePage(1);
      PDF.save(`Order_Invoice_${this.orderDetails.ID}.pdf`);
    });


  }


  sumOrderItemsTotal = (orderItems: OrderItemModel[]) => orderItems.filter(x => x.isDeleted == false)
    .reduce((partialSum, a) => partialSum + (a.Price * a.Quantity), 0);



}
