import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class DateService {
  constructor() { }

  getYesterday() {
    let today = new Date();
    let yesterday = new Date();
    return yesterday.setDate(today.getDate() - 1);
  }

  getFirstDayOfCurrentWeek() {
    let currentDate = new Date();
    let firstday = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay() - 1));
    return firstday;
  }

  getLastDayOfCurrentWeek() {
    var currentDate = new Date();
    var lastday = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 5));

    return lastday;
  }

  getFirstDayOfPreviousWeek() {
    let currentDate = new Date();
    let firstday = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay() - 7 - 1));
    return firstday;
  }

  getLastDayOfPreviousWeek() {
    var currentDate = new Date();
    var lastday = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 6 - 7 - 1));

    return lastday;
  }

  getFirstDayOfCurrentMonth() {
    let date = new Date();
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    return firstDay;
  }

  getLastDayOfCurrentMonth() {
    let date = new Date();
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return lastDay;
  }

  getFirstDayOfPreviousMonth() {
    let date = new Date();
    let firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    return firstDay;
  }

  getLastDayOfPreviousMonth() {
    let date = new Date();
    let lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
    return lastDay;
  }
}
