import { ProductRequest } from '../models/ProductRequest.Model';
import { ProductPatchModel } from '../models/ProductPatch.model';

export class AppState {
    productListState: ProductListState = new ProductListState();
}

export class ProductListState {

    private requeryRequired: boolean = true;
    private productList: ProductPatchModel[] = [];

    filter: ProductRequest;
    scrollPosition: number = 0;

    constructor() {
        this.filter = new ProductRequest();
    }

    clearFilterAndResult() {
        this.filter.clear();
        this.clearResult();
    }

    clearResult() {
        this.productList = [];
        this.requeryRequired = true;
        this.scrollPosition = 0;
        this.filter.PageIndex = 1;
    }

    isRequeryRequired():boolean {
        return this.requeryRequired;
    }

    setSearchResult(productList: ProductPatchModel[]) {
        this.productList = productList;
        this.requeryRequired = false;
        this.scrollPosition = 0;
        this.filter.PageIndex = 1;
    }

    concatSearchResult(productList: ProductPatchModel[]) {
        this.productList = this.productList.concat(productList);
    }

    getProductList():ProductPatchModel[] {
        return this.productList;
    }

    changeProductState(updatedProduct: ProductPatchModel) {
        this.productList.map((product) => {
          if (product.PatchID === updatedProduct.PatchID) {
            product.AddedToCart = updatedProduct.AddedToCart;
            product.AddedToFav = updatedProduct.AddedToFav;
    
          } else {
            return product;
          }
        });
      }
    
      getProduct(product: ProductPatchModel) {
        let productIndex = this.productList.findIndex((obj) => obj === product);
        return this.productList[productIndex];
      }
}
