import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "TrimString" })
export class TrimString implements PipeTransform {
  transform(stringValue: string, maxlength = 5) {
    let newString = stringValue;
    if (newString && stringValue.length > maxlength) {
      newString = stringValue.slice(0, maxlength) + "...";
    }
    return newString;
  }
}
