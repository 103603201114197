<!--Data-->

<div class="d-flex flex-row bg-white position-relative">
    <!-- loading-->
    <div *ngIf="showLoading" class="d-flex flex-column z-index-3 p-0 m-0 position-absolute bg-white w-100 p-20 h-100 justify-content-center align-items-center" style="opacity: 0.8;">
        <div class="align-self-center">
            <span class="load-more-List text-center">
        <span class="spinner-border text-primary" role="status"> </span>
            </span>
        </div>
    </div>
    <!-- <div class="p-1 w-20 ">
      {{ pat.PatchId }}
    </div> -->
    <div class="p-1 w-20 ">
        <div class="calendar position-relative">
            <input name="ExpireDatepatch" type="text" autocomplete="off" class="form-control rounded " #ExpireDatepatchs="ngModel" required [(ngModel)]="pat.ExpireDate" [placeholder]="minDate" bsDatepicker [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MM-YYYY' }"
                [minDate]="minDate" readonly />
            <!-- <div class="calendar_button d-flex d-none">
        <i class="fa fa-calendar m-auto" aria-hidden="true"></i>

      </div> -->

        </div>
        <div *ngIf="pat.submitted && ExpireDatepatchs.errors?.required" class="text text-danger px-3"> {{ "ExpireDatepatchisrequired" | translate }}</div>
    </div>
    <div class="p-1 w-20">
        <input name="Qtypatch" autocomplete="off" type="number" class="form-control rounded" #Qtypatchs="ngModel" required [(ngModel)]="pat.Quantity" placeholder="{{ 'Quantity' | translate }}" />
        <div *ngIf="pat.submitted && Qtypatchs.errors?.required" class="text text-danger px-3"> {{ "PatchQtyisrequired" | translate }} </div>
        <div *ngIf="pat.Quantity < pat.Booked" Qty class="text text-danger px-3">{{ "PatchQtylessthanbooked" | translate }}
        </div>
    </div>
    <div class="p-1 w-20">
        <p class="mb-0 mx-3"> {{ pat.Booked }}</p>
    </div>
    <div class="p-1 w-10">
        <p class="mb-0 mx-3">{{ pat.Balance }}</p>
    </div>


    <!-- show input incase product is 2assar or both  -->
    <div class="p-1 w-20" *ngIf="product?.IsRetail ==2 || product?.IsRetail ==3">
        <input name="Pricepatch" autocomplete="off" type="text" pattern="^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$" class="form-control rounded" #Pricepatchs="ngModel" required [(ngModel)]="pat.OriginalPrice" placeholder="{{ 'PatchPrice' | translate }}" />
        <div *ngIf="pat.submitted && Pricepatchs.errors?.required" class="text text-danger px-3">{{ "PatchPriceisrequired" | translate }}</div>
        <div *ngIf="Pricepatchs.errors?.pattern" class="text text-danger px-3"> {{ "Patchnotnigative" | translate }} </div>
    </div>

    <!-- show input incase product is 2assar or both  -->
    <div class="p-1 w-20" *ngIf="product?.IsRetail ==2 || product?.IsRetail ==3">
        <input name="Discount" autocomplete="off" type="text" pattern="^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$" class="form-control rounded" #PricepatchAfterDiscount="ngModel" required [(ngModel)]="pat.Discount" placeholder="{{ 'Discount' | translate }}" />
        <div *ngIf="pat.submitted && PricepatchAfterDiscount.errors?.required" class="text text-danger px-3">{{ "PatchDiscountisrequired" | translate }}</div>
        <div *ngIf="PricepatchAfterDiscount.errors?.pattern" class="text text-danger px-3"> {{ "PatchDiscountnotnigative" | translate }} </div>
    </div>

    <!-- show input incase product is shine or both  -->
    <div class="p-1 w-20" *ngIf="product?.IsRetail ==1 || product?.IsRetail ==3">
        <input name="Pricepatch" autocomplete="off" type="text" pattern="^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$" class="form-control rounded" #PricepatchsShine="ngModel" required [(ngModel)]="pat.OriginalPriceShine" placeholder="{{ 'PatchPriceShine' | translate }}"
        />
        <div *ngIf="pat.submitted && PricepatchsShine.errors?.required" class="text text-danger px-3">{{ "PatchPriceisrequired" | translate }}</div>
        <div *ngIf="PricepatchsShine.errors?.pattern" class="text text-danger px-3"> {{ "Patchnotnigative" | translate }} </div>
    </div>

    <!-- show input incase product is shine or both  -->
    <div class="p-1 w-20" *ngIf="product?.IsRetail ==1 || product?.IsRetail ==3">
        <input name="Discount" autocomplete="off" type="text" pattern="^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$" class="form-control rounded" #PricepatchAfterDiscountShine="ngModel" required [(ngModel)]="pat.DiscountShine" placeholder="{{ 'DiscountShine' | translate }}"
        />
        <div *ngIf="pat.submitted && PricepatchAfterDiscountShine.errors?.required" class="text text-danger px-3">{{ "PatchDiscountisrequired" | translate }}</div>
        <div *ngIf="PricepatchAfterDiscountShine.errors?.pattern" class="text text-danger px-3"> {{ "PatchDiscountnotnigative" | translate }} </div>
    </div>

    <div class="p-1 w-5">
        <button class="btn btn-secondary" (click)="SavePatch(pat)">{{"Save" | translate }} </button>
    </div>
    <div class="p-2 w-5">
        <a (click)="DeletePatch(pat)">
            <i class="fa fa-trash-o fa-lg" aria-hidden="true"></i>
        </a>
    </div>
</div>
<div *ngIf="errMsg" class="text-danger d-flex justify-content-end p-2 bd-highlight">{{ errMsg }}</div>