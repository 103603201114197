import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { QueryService } from 'src/app/services/query.services';
import { CityZoneDto } from 'src/app/models/CityZoneDto.Model';
import { CommunicationService } from 'src/app/services/communication.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-city-zone-lookup',
  templateUrl: './city-zone-lookup.component.html',
  styleUrls: ['./city-zone-lookup.component.scss']
})
export class CityZoneLookupComponent implements OnInit {

  errMsg: string;
  showLoading: boolean;
  isArabic: boolean = true;
  cityzoneList:CityZoneDto[]=[];
  @Input() target: string;

  constructor(private _queryService:QueryService,
    private _communcationServices : CommunicationService,
    private _trans: TranslateService,) { }

  ngOnInit() {
    this.getAllCityZones();
    this.isArabic = this._trans.currentLang == 'ar'
  }

  getAllCityZones()
  {
    try {

      this.errMsg = "";
      this.showLoading = true;
      this._queryService.getAllCityZones().subscribe(res => {
        this.showLoading = false;
        if (res.HasError) {
          this.errMsg = res.ErrorMessage;
          return;
        }

        this.cityzoneList=res.Result;

      }, err => {
        this.errMsg = err;
        this.showLoading = false;
      });
    } catch (err) {
      this.errMsg = err;
      this.showLoading = false;

    }

  }

  Expand(c : CityZoneDto){
    c.Expanded = !c.Expanded;
  }
  SelectedZone(cID:Number,zID:Number)
  {
    let selectedCity= this.cityzoneList.find(c=>c.City.ID==cID);
    let SelectedZone=selectedCity.ZoneList.find(z=>z.ID==zID);
    this._communcationServices.CityZoneSelected(selectedCity.City, SelectedZone);
  }

  hideModal() {
    this._communcationServices.CityZoneClosed();

  }

}
