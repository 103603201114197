export class PatchDto {
  Id: number;
  ProductId: number;
  PatchId: string;
  Quantity: number;
  Booked: number = 0;
  Balance: number = 0;
  Price: number = 0;
  OriginalPrice: number = 0;
  Discount: number = 0;
  ExpireDate: Date = new Date();
  submitted: boolean = false;
  IsDeleted: boolean = false;
  IsRetail?: number;

  PriceShine?: number;
  DiscountShine?: number;
  OriginalPriceShine?: number;

}
