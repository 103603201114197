import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar-discount',
  templateUrl: './navbar-discount.component.html',
  styleUrls: ['./navbar-discount.component.scss']
})
export class NavbarDiscountComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
