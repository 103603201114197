<div class="h-100 d-flex flex-column overflow-hidden">

    <div class="d-flex flex-column h-100 overflow-auto" (scroll)="onProductsScroll($event)">
        <div *ngIf="showLoading" class="d-flex flex-column z-index-3 position-fixed w-100 h-100 justify-content-center align-items-center bg-white" style="opacity: 0.5;">
            <div class="p-2 align-self-center">
                <span class="load-more-List text-center vw-100 vh-100">
          <span class="spinner-border text-primary" role="status"> </span>
                </span>
            </div>
        </div>
        <div *ngIf="ProductCategoryList.length == 0" class="p-0 w-100 h-100 bd-highlight bg-light-gray d-flex">
        </div>

        <div class="row p-1">
            <div class="col-12 p-0">
                <div class="row">
                    <div *ngFor="let prodCat of ProductCategoryList" class="col-sm-6 col-xs-6 col-6 col-md-3 col-lg-3 p-1 mb-2 productCategoryListContainer">
                        <div class="row position-relative h-100 productCategoryList" (click)="categoryClick(prodCat)">
                            <div class="col-4 d-flex align-self-center justify-content-center p-0">
                                <img class="productImg" src="{{getImageFullPath(prodCat)}}" />
                            </div>

                            <div class="col-8 d-flex align-items-center px-1">
                                <span class="d-block productName" *ngIf="isArabic">{{ prodCat.NameAR }}</span>
                                <span class="gray-1 d-block productName" *ngIf="!isArabic">{{ prodCat.NameEN }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>