<app-navbar-discount>
</app-navbar-discount>

<div class="container-fluid">
    <div class="bd-highlight p-2">
        <h4 class="p-2 mb-0">{{ title }}</h4>
    </div>

    <div *ngIf=" showLoading" class=" d-flex flex-column z-index-3 position-fixed w-100 h-100 justify-content-center align-items-center bg-white " style="opacity: 0.5">
        <div class="p-2 align-self-center">
            <span class="load-more-List text-center vw-100 vh-100">
        <span class="spinner-border text-primary" role="status"> </span>
            </span>
        </div>
    </div>
    <div class="text text-danger px-3" *ngIf="errors.length >0"> <span *ngFor="let o of errors"> {{ o }}</span></div>

    <div class="row" *ngIf="!showLoading">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
            <form novalidate (ngSubmit)="Save()" [formGroup]="quantityDiscountForm">
                <div class="">
                    <div class="form-group">
                        <label>{{ "SelectSpecificProduct" | translate }}</label>
                        <ng-select [typeahead]="productTextSearch" #searchProductText [items]="productList" (clear)="clearSelectedProducts()" [loading]="showLoadingProduct" [virtualScroll]="true" [clearOnBackspace]="true" (scrollToEnd)="onScrollToEnd()" bindLabel="{{ 'NameEN' }}"
                            bindValue="ID" labelForId="productId" clearAllText="Clear" formControlName="productId">
                            <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                                <div>
                                    <span class="font font-weight-bold">{{ item.NameEN }}</span>
                                </div>
                                <small>{{ item.ID }} | {{ item.NameAR }} </small>
                            </ng-template>
                        </ng-select>
                        <span class="text-danger" *ngIf="
              quantityDiscountForm?.errors?.productListError &&
              !showLoadingProduct && (quantityDiscountForm.get('selectedProduct')?.touched || quantityDiscountForm.get('selectedProduct')?.dirty) ">{{ "discountProductsEmpty" | translate }}</span>
                    </div>
                    <div formArrayName="quantityDiscounts">
                        <div class="formArrayName" *ngFor="let quantity of quantityDiscountsFormArray?.controls; let i=index">
                            <div [formGroupName]="i">
                                <div class="row">
                                    <div class="col-4">
                                        <div class="form-control mt-2 mb-2">
                                            <label>{{ "discountCodeType" | translate }}</label>
                                            <label class="radio-inline ml-3 mr-3">
                        <input type="radio" name="{{PercentageType}}" value="0" formControlName="PercentageType"
                          (change)="changeDiscountLabel('PromoCodePercentage')" />
                        {{ "Percentage" |  translate }}
                      </label>

                                            <label class="radio-inline ml-3 mr-3">
                        <input type="radio" name="{{PercentageType}}" value="1" formControlName="PercentageType" checked
                          (change)="changeDiscountLabel('PromoCodeAmount')" /> {{ "Amount" | translate }}
                      </label>
                                        </div>
                                    </div>

                                    <div class="col-3 mt-2 mb-2">
                                        <input class="form-control text-info" name="QuantityFrom" formControlName="QuantityFrom" type="text" placeholder="{{ 'QuantityFrom' | translate }}" [ngClass]="{
                          'is-invalid':
                            (quantityDiscountForm.get('QuantityFrom')?.touched || quantityDiscountForm.get('QuantityFrom')?.dirty) &&
                            quantityDiscountForm.get('QuantityFrom')?.invalid }" />

                                        <span class="invalid-feedback">
                      <span
                        *ngIf="
                            quantityDiscountForm.get('QuantityFrom')?.errors?.required ||
                            quantityDiscountForm.get('QuantityFrom')?.touched ||
                            quantityDiscountForm.get('QuantityFrom')?.dirty ">{{ "QuantityFromRequired" | translate }}</span>
                                        </span>
                                        <span class="invalid-feedback" *ngIf="quantityDiscountForm.get('QuantityFrom')?.errors?.pattern">{{"acceptNumberOnly"| translate }}</span>
                                    </div>

                                    <div class="col-3 mt-2 mb-2">
                                        <input class="form-control text-info" name="Amount" formControlName="Amount" type="text" placeholder="{{ 'discountValue' | translate }}" [ngClass]="{
                        'is-invalid':
                          (quantityDiscountForm.get('Amount')?.touched || quantityDiscountForm.get('Amount')?.dirty) &&
                          quantityDiscountForm.get('Amount')?.invalid }" />
                                        <span class="invalid-feedback">
                      <span *ngIf="
                          quantityDiscountForm.get('Amount')?.errors?.required ||
                          quantityDiscountForm.get('Amount')?.touched ||
                          quantityDiscountForm.get('Amount')?.dirty ">{{ "discountValueRequired" | translate }}</span>
                                        </span>
                                        <span class="invalid-feedback" *ngIf="quantityDiscountForm.get('Amount')?.errors?.pattern">{{"acceptNumberOnly"| translate }}</span>
                                    </div>

                                    <div class="col-2 mt-2 mb-2">
                                        <button class="btn btn-outline-secondary btn-sm" (click)="deleteQuantityDiscount(i)">{{"Delete" | translate}}</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>



                <div class="text-center mt-3">
                    <span class="text-danger">{{ errorMsg | translate }}</span>
                </div>


                <div class="text-center mt-3">
                    <span class="text-success" *ngIf="isSuccessOperation">{{msg | translate}}</span>
                    <span class="text-danger" *ngIf="!isSuccessOperation">{{msg | translate}}</span>
                </div>

                <div class="row mb-1">
                    <div class=" m-1">
                        <button class="btn btn-primary bg-navy border-danger" type="submit" [disabled]="quantityDiscountForm.invalid || isLoading">{{ "Save" | translate }}</button>
                        <button class="btn btn-primary bg-blue border-0 m-1" routerLink="/adminhome/qytdiscount">{{"Cancel"|translate }}</button>
                    </div>
                </div>
            </form>



        </div>
        <div class="row mt-2 mb-2">
            <div class="col-md-2 ">
                <button class="btn btn-outline-secondary btn-sm" (click)="addQuantityDiscount()">{{"Add"| translate}}</button>
            </div>
        </div>

    </div>

</div>