<div class="d-flex flex-column h-100 w-100 p-2">
    <div class="bd-highlight">
        <div class="row">
            <div class="col-6">
                <h4>{{ title }}</h4>
            </div>
            <div class="col-6 d-flex justify-content-end">
                <a class="btn btn-success" routerLink="/adminhome/add-edit-suppliers/0" target="_blank">
                    <i class="fa fa-plus" style="color: white !important;"></i> {{ "Add" | translate }}
                </a>
            </div>
        </div>

        <div class="row m-2">
            <div class="col-2  ">
                <label for="isRetail">{{ "IsRetail"| translate}}</label>
            </div>
            <div class="col-2 ">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" #IsRetailInput (ngModelChange)="onChanges($event)" name="isRetail" id="Retail" value="1" [(ngModel)]="suppliersRequest.IsRetail">
                    <label class="form-check-label" for="retail">{{"Retail" | translate}}</label>
                </div>
            </div>
            <div class="col-2 ">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" #IsRetailInput (ngModelChange)="onChanges($event)" name="isRetail" id="Salon" value="2" [(ngModel)]="suppliersRequest.IsRetail">
                    <label class="form-check-label" for="salon">{{"2assar" | translate}}</label>
                </div>
            </div>
            <div class="col-2 ">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" #IsRetailInput (ngModelChange)="onChanges($event)" name="isRetail" id="Retail" value="3" [(ngModel)]="suppliersRequest.IsRetail" checked>
                    <label class="form-check-label" for="retail">{{"BothAppType" | translate}}</label>
                </div>
            </div>
        </div>

    </div>

    <div class="d-flex h-100 flex-column overflow-hidden mt-1">
        <div *ngIf="showLoading" class=" d-flex flex-column z-index-3 position-fixed w-100 h-100 justify-content-center align-items-center bg-white" style="opacity: 0.5">
            <div class="p-2 align-self-center">
                <span class="load-more-List text-center vw-100 vh-100">
                    <span class="spinner-border text-primary" role="status"> </span></span>
            </div>
        </div>
        <table class="table table-striped tableFixHead" id="header-fixed">
            <thead>
                <tr>
                    <th scope="col">{{ "Code" | translate }}<i (click)="onSortClick($event, 'ID')" class="my-icon fa fa-chevron-up"></i></th>
                    <th scope="col">{{ "Name" | translate }}<i (click)="onSortClick($event, 'NameAR')" class="my-icon fa fa-chevron-up"></i> </th>
                    <th scope="col"> {{ "Name" | translate }} <i (click)="onSortClick($event, 'NameEN')" class="my-icon fa fa-chevron-up"></i></th>
                    <th scope="col">{{ "PhoneNumber" | translate }}<i (click)="onSortClick($event, 'PhoneNumber')" class="my-icon fa fa-chevron-up"></i></th>
                    <th scope="col">{{ "Logo" | translate }}</th>
                    <th scope="col">{{ "Status" | translate }}</th>
                    <th scope="col">{{ "IsRetail" | translate }}</th>
                    <th scope="col">{{ "Actions" | translate }} <i class="fa fa-bolt" aria-hidden="true"></i></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let supplier of suppliersList">
                    <td>{{ supplier.ID }}</td>
                    <td>{{ supplier.NameAR }}</td>
                    <td>{{ supplier.NameEN }}</td>
                    <td>{{ supplier.PhoneNumber }}</td>
                    <td><img src="{{ getImageFullPath(supplier) }}" width="50" height="50" />
                    </td>
                    <td>
                        <span *ngIf="!supplier.IsDeleted" class="text-success">{{ "Available" | translate }}</span>
                        <span *ngIf="supplier.IsDeleted" class="text-danger">{{ "NotAvailable" | translate }}
                        </span>
                    </td>
                    <td>
                        <span *ngIf="supplier?.IsRetail ==1 ">{{ "Shine" | translate}}</span>
                        <span *ngIf="supplier?.IsRetail ==2 ">{{ "2assar" | translate}}</span>
                        <span *ngIf="supplier?.IsRetail ==3 ">{{ "BothAppType" | translate}}</span>
                    </td>
                    <td>
                        <div class="dropdown">
                            <a class="btn btn-primary" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fa fa-bars" aria-hidden="true" style="color: white !important"></i>
                            </a>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item" routerLink="/adminhome/add-edit-suppliers/{{ supplier.ID }}" target="_blank">
                                    <i class="fa fa-lg fa-pencil-square-o" aria-hidden="true" style="color: slategray !important"></i> {{ "Edit" | translate }}
                                </a>

                                <a class="dropdown-item" (click)="deleteSupplier(supplier.ID)" style="cursor: pointer">
                                    <i class="fa fa-lg fa-trash-o" aria-hidden="true" style="color: red !important"></i> {{ "Delete" | translate }}
                                </a>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

        <div *ngIf="suppliersList?.length == 0" class="
        d-flex
        flex-column
        z-index-3
        w-100
        h-100
        justify-content-center
        align-items-center
        bg-white
      ">
            <label class="la">{{ "NoDataFound" | translate }}</label>
        </div>
    </div>

    <!-- #region Pagination -->
    <div class="w-100 mt-2">
        <div class="col-xs-12 col-12 p-1">
            <app-pagination-component [ItemsCount]="Total" (changePage)="onPagingChange($event)">
            </app-pagination-component>
        </div>
    </div>
    <!--#endregion Pagination -->
</div>