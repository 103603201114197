import { IDDto } from './../../../../models/IDDto';
import { Params } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { CommunicationService } from 'src/app/services/communication.service';
import { CommandService } from 'src/app/services/command.service';
import { Router } from '@angular/router';
import { QueryService } from 'src/app/services/query.services';
import { TranslateService } from '@ngx-translate/core';
import { LoginAdminService } from 'src/app/services/login-admin.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PageChangedEvent } from "ngx-bootstrap/pagination";
import { QuantityDiscountRequestPortal } from 'src/app/models/Discount/QuantityDiscountRequestPortal';
import { QuantityDiscountResponse } from 'src/app/models/Discount/QuantityDiscountResponse';
import { SortingDirectionEnum } from 'src/app/models/enum/SortingDirectionEnum';

@Component({
  selector: 'app-quantity-discount-list',
  templateUrl: './quantity-discount-list.component.html',
  styleUrls: ['./quantity-discount-list.component.scss']
})
export class QuantityDiscountListComponent implements OnInit {
  title: string = "";
  Total: number;
  errMsg: string = "";
  errors: string[] = [];
  error: string = "";
  request: QuantityDiscountRequestPortal;
  discountList: QuantityDiscountResponse[] = [];
  IsArabic: boolean = true;
  showLoading: boolean = false;
  sortDir:SortingDirectionEnum = SortingDirectionEnum.Ascending;


  constructor(
    private fb: FormBuilder,
    private _route: ActivatedRoute,
    private _adminLogin: LoginAdminService,
    private _translate: TranslateService,
    private _queryService: QueryService,
    private router: Router,
    private _commandService: CommandService,
    private _communicationService: CommunicationService,
    private titleService: Title
  ) { }

  ngOnInit() {
    this.loadAfterLang(this._translate.currentLang);
    this._translate.onLangChange.subscribe(res => this.loadAfterLang(res.lang));
    this.setDefaultSearchValue();
    this.getDiscountList(this.request)
  }

  loadAfterLang(lang: string) {
    this.IsArabic = lang === "ar";
    this._route.params.subscribe((params: Params) => {
      this.title = this._translate.instant("QuantityDiscount");
      this.titleService.setTitle(this.title);
    });
  }

  setDefaultSearchValue = () => this.request = new QuantityDiscountRequestPortal();
  clearSearch() {
    this.setDefaultSearchValue();
    this.getDiscountList(this.request);
  }

  getDiscountList(request: QuantityDiscountRequestPortal) {
    try {
      this.showLoading = true;
      this.errMsg = "";
      this._queryService.GetAllQuantityPatchPrice(request).subscribe(res => {
        if (res.HasError) {
          this.errMsg = res.ErrorMessage;
          this.showLoading = false;
          return;
        }
        this.discountList = res.Result.ItemList;
        this.Total = res.Result.Total;
        this.showLoading = false;
      }, (err) => {
        this.errMsg = err;
        this.showLoading = false;
      });
    } catch (error) {
      this.errMsg = error;
      this.showLoading = false;
    }
  }  

  onPagingChange(event: PageChangedEvent) {
    this.request.pageIndex = event.page;
    this.request.pageSize = event.itemsPerPage;
    this.getDiscountList(this.request);
  }

  onSortClick(event: any, colName: string) {
    let classList = event.currentTarget.classList;
    this.changeStyleOnSortCLicked(classList);
    let request = this.changeRequestOnSortClicked(this.request, colName);
    this.getDiscountList(request);
  }

  changeStyleOnSortCLicked(classList: any) {
    /* sort DESC */
    if (classList.contains('fa-chevron-up')) {
      classList.remove('fa-chevron-up');
      classList.add('fa-chevron-down');
      this.sortDir = SortingDirectionEnum.Descending;

      /* sort ASC */
    } else {
      classList.add('fa-chevron-up');
      classList.remove('fa-chevron-down');
      this.sortDir = SortingDirectionEnum.Ascending;
    }
  }

  changeRequestOnSortClicked(request: QuantityDiscountRequestPortal, columnName: string) {
    let changedRequest: QuantityDiscountRequestPortal = request;
    changedRequest.lang = this._translate.currentLang;
    changedRequest.sortColumn = columnName;
    changedRequest.sortingDirection = this.sortDir;
    return changedRequest;
  }

  deleteDiscount(id: number) {
    try {
      let request: IDDto = new IDDto();
      request.iD = id;
      this.errMsg = '';
      this.showLoading = true;
      this._commandService.DeleteQuantityPatchPrice(request).subscribe(res => {
        if (res.HasError) {
          this.errMsg = res.ErrorMessage;
          this.showLoading = false;
        }
        this.showLoading = false;
        this.ngOnInit();
      });
    } catch (error) {
      this.errMsg = error;
      this.showLoading = false;
    }
  }
}
