import { environment } from './../../environments/environment.prod';
import { Injectable } from "@angular/core";
import { HttpClient, HttpEvent } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { GlobalResponse } from "../models/GlobalResponse.Model";
import { configration } from "../shared/config";
import { catchError } from "rxjs/operators";
import { AttachmentDTO } from "../models/attachment.model";

@Injectable({
  providedIn: "root",
})
export class FileService {
  constructor(private _http: HttpClient) { }

  uploadFiles(files: File[]): Observable<GlobalResponse<AttachmentDTO[]>> {
    const url = `${environment.webApiEndPoint + configration.urls.File.apiUploadFilesUrl}`;
    let formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("uploadFile" + i, files[i], files[i]["name"]);
    }

    return this._http
      .post<GlobalResponse<AttachmentDTO[]>>(url, formData)
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }

  checkFileSize(files: File[]): boolean {
    let size = files[0].size / 1024 / 1024;
    if (size < 5) {
      return true;
    } else {
      return false;
    }
  }
  checkFileExtentionForImage(files: File[]): boolean {
   
    var filename = files[0]["name"];
    var fileExtension = filename.substring(
      filename.lastIndexOf(".") + 1,
      filename.length
    );

    if (
      fileExtension.toLowerCase() == "png" ||
      fileExtension.toLowerCase() == "jpeg" ||
      fileExtension.toLowerCase() == "jpg" ||
      fileExtension.toLowerCase() == "bmp"
    ) {
      return true;
    } else {
      return false;
    }
  }


  async getBase64ImageFromUrl(imageUrl) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();

    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener("load", function () {
        resolve(reader.result);
      }, false);

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
  }

  test() {
    let cc = this.getBase64ImageFromUrl('your url')
      .then(result => {
      })
      .catch(err => console.error(err));
  }

  errorResponse(err) {
    var response = new GlobalResponse<any>();
    response.Result = err;
    response.ErrorMessage =
      err.status === 0 || err.status === 504 ? "" : err.message;
    response.HasError = true;
    return response;
  }
}
