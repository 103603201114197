<div class="d-flex w-100 flex-column bd-highlight h-100 p-2">
    <div class="row">
        <div class="col-md-4">
            <div class="bd-highlight">
                <h4>{{ title }}</h4>
            </div>
        </div>


        <!-- <div class="col-md-3 d-flex justify-content-end offset-md-5">
      <button
        class="btn d-flex btn-primary mx-2 bg-red bg-blue border-0"
        routerLink="/adminhome/productaddedit/0"
      >
        {{ "AddProduct" | translate }}
      </button>
      <button
        class="btn d-flex btn-primary bg-red bg-blue border-0"
        routerLink="/adminhome/productaddbulk"
      >
        {{ "AddBulkProduct" | translate }}
      </button>
    </div> -->

        <!-- <div

  class="d-flex flex-column z-index-3 p-0 m-0 position-absolute bg-white w-100 p-20 h-100 justify-content-center align-items-center"
  style="opacity: 0.8;"
> -->

    </div>




    <div class="row m-2">
        <div class="col-2 mt-4">
            <a class="btn btn-primary mr-3 mb-1 btn-sm" data-toggle="collapse" href="#advancedFilter" role="button" aria-expanded="false" aria-controls="advancedFilter">{{ "Filter" | translate }}</a>

            <button class="btn btn-danger btn-sm" (click)="onSearchSubmit()">
                {{ "Search" | translate }}
            </button>
        </div>

        <div class="col-2">
            <label> {{ "NameAR" | translate }} </label>
            <input class="form-control rounded" [(ngModel)]="ProductRequest.NameAR" />
        </div>
        <div class="col-2">
            <label> {{ "NameEN" | translate }} </label>
            <input class="form-control rounded" [(ngModel)]="ProductRequest.NameEN" />
        </div>
        <div class="col-2">
            <label> {{ "ProductID" | translate }} </label>
            <input class="form-control rounded" #searchProductInput [(ngModel)]="ProductRequest.ID" />
        </div>
        <div class="col-6 align-self-end m-2">
            <button class="btn btn-success mr-3 mb-1 btn-sm" routerLink="/adminhome/productaddedit/0">
                <i class="fa fa-plus" aria-hidden="true"></i>
                {{ "AddProduct" | translate }}
            </button>

            <button class="btn btn-warning mr-3 mb-1 btn-sm" routerLink="/adminhome/productaddbulk">
                <i class="fa fa-plus-circle" aria-hidden="true"></i>
                {{ "AddBulkProduct" | translate }}
            </button>

            <button class="btn btn-danger btn-sm" (click)="exportToExcel()">
                <i class="fa fa-file-excel-o" aria-hidden="true"></i>
                {{ "Export" | translate }}
            </button>
        </div>
    </div>


    <div class="row mb-2 mt-1">
        <div class="col">
            <div class="bd-highlight collapse multi-collapse" id="advancedFilter">
                <!-- #region Search  -->
                <!-- #region Search Criteria -->
                <div class="card card-body">
                    <div class="row mt-2">
                        <div class="col-lg-4 col-sm-6 form-group mb-0">
                            <div class="row">
                                <label class="col-3 p-0 d-flex align-self-center">
                                    {{ "ProductType" | translate }}
                                </label>
                                <kendo-multiselect kendoMultiSelectSummaryTag class="form-control rounded col-9" [data]="productTypes" [valueField]="'ID'" [textField]="IsArabic ? 'NameAR' : 'NameEN'" [(ngModel)]="ProductRequest.productTypeIds" [loading]="typeLoading" [valuePrimitive]="true"
                                    [filterable]="true" (filterChange)="getProductType($event)" [autoClose]="false">
                                    <ng-template kendoMultiSelectNoDataTemplate>
                                        <h4>
                                            <span class="k-icon k-i-warning"></span><br /><br /> {{ "NotAvailable" | translate }}
                                        </h4>
                                    </ng-template>
                                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                                        <span class="k-icon k-i-arrow-s"></span> {{ dataItems.length }} {{ "itemsSelected" | translate }}
                                    </ng-template>
                                </kendo-multiselect>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 form-group mb-0">
                            <div class="row">
                                <label class="col-3 p-0 d-flex align-self-center">
                                    {{ "ProductColor" | translate }}
                                </label>
                                <kendo-multiselect kendoMultiSelectSummaryTag maxSelectedItems="3" class="form-control rounded col-9" [data]="ColorsList" [valueField]="'ID'" [textField]="IsArabic ? 'NameAR' : 'NameEN'" [(ngModel)]="ProductRequest.productColorIds" [loading]="colorLoading"
                                    [valuePrimitive]="true" [filterable]="true" (filterChange)="getColors($event)" [autoClose]="false">
                                    <ng-template kendoMultiSelectNoDataTemplate>
                                        <h4>
                                            <span class="k-icon k-i-warning"></span><br /><br /> {{ "NotAvailable" | translate }}
                                        </h4>
                                    </ng-template>
                                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                                        <span class="k-icon k-i-arrow-s"></span> {{ dataItems.length }} {{ "itemsSelected" | translate }}
                                    </ng-template>
                                </kendo-multiselect>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6 form-group mb-0 kendo">
                            <div class="row">
                                <label class="col-3 p-0 d-flex align-self-center">
                                    {{ "ProductCategory" | translate }}
                                </label>
                                <kendo-multiselect kendoMultiSelectSummaryTag class="form-control rounded col-9" [data]="CategoriesList" [valueField]="'ID'" [textField]="IsArabic ? 'NameAR' : 'NameEN'" [(ngModel)]="ProductRequest.productCatIds" [loading]="categoryLoading" [valuePrimitive]="true"
                                    [filterable]="true" (filterChange)="getCategories($event)" [autoClose]="false">
                                    <ng-template kendoMultiSelectNoDataTemplate>
                                        <h4>
                                            <span class="k-icon k-i-warning"></span><br /><br /> {{ "NotAvailable" | translate }}
                                        </h4>
                                    </ng-template>
                                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                                        <span class="k-icon k-i-arrow-s"></span> {{ dataItems.length }} {{ "itemsSelected" | translate }}
                                    </ng-template>
                                </kendo-multiselect>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class="col-lg-4 col-sm-6 form-group mb-0">
                            <div class="row">
                                <label class="col-3 p-0 d-flex align-self-center">
                                    {{ "ProductBrand" | translate }}
                                </label>
                                <kendo-multiselect kendoMultiSelectSummaryTag class="form-control rounded col-9" [data]="BrandsList" [valueField]="'ID'" [textField]="IsArabic ? 'NameAR' : 'NameEN'" [(ngModel)]="ProductRequest.productBrandIds" [loading]="brandLoading" [valuePrimitive]="true"
                                    [filterable]="true" (filterChange)="getBrands($event)" [autoClose]="false">
                                    <ng-template kendoMultiSelectNoDataTemplate>
                                        <h4>
                                            <span class="k-icon k-i-warning"></span><br /><br /> {{ "NotAvailable" | translate }}
                                        </h4>
                                    </ng-template>
                                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                                        <span class="k-icon k-i-arrow-s"></span> {{ dataItems.length }} {{ "itemsSelected" | translate }}
                                    </ng-template>
                                </kendo-multiselect>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 form-group mb-0">
                            <div class="row">
                                <label class="col-3 p-0 d-flex align-self-center">{{ "Status" | translate }}</label>
                                <select class="form-control rounded col-9" [(ngModel)]="ProductRequest.IsAvailable">
                                    <option value="null" selected>{{ "All" | translate }}</option>
                                    <option value="true">{{ "Available" | translate }}</option>
                                    <option value="false"> {{ "NotAvailable" | translate }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 form-group mb-0">
                            <div class="row">
                                <label class="col-3 p-0 d-flex align-self-center"> {{ "ProductSuppliers" | translate }}</label>
                                <kendo-multiselect kendoMultiSelectSummaryTag class="form-control rounded col-9" [data]="SuppliersList" [valueField]="'ID'" [textField]="IsArabic ? 'NameAR' : 'NameEN'" [(ngModel)]="ProductRequest.suppliersIds" [loading]="suppliersLoading" [valuePrimitive]="true"
                                    [filterable]="true" [autoClose]="false">
                                    <ng-template kendoMultiSelectNoDataTemplate>
                                        <h4>
                                            <span class="k-icon k-i-warning"></span><br /><br /> {{ "NotAvailable" | translate }}
                                        </h4>
                                    </ng-template>
                                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                                        <span class="k-icon k-i-arrow-s"></span> {{ dataItems.length }} {{ "itemsSelected" | translate }}
                                    </ng-template>
                                </kendo-multiselect>
                            </div>
                        </div>



                    </div>
                    <div class="row mt-2">
                        <div class="col-2">
                            <label for="isRetail">{{ "IsRetail"| translate}}</label>
                        </div>
                        <div class="col-2">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" #IsRetailInput (ngModelChange)="onChanges($event)" name="isRetail" id="Salon" value="1" [(ngModel)]="ProductRequest.IsRetail" checked>
                                <label class="form-check-label" for="salon">{{"2assar" | translate}}</label>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" #IsRetailInput (ngModelChange)="onChanges($event)" name="isRetail" id="Retail" value="2" [(ngModel)]="ProductRequest.IsRetail">
                                <label class="form-check-label" for="retail">{{"Shine" | translate}}</label>
                            </div>
                        </div>

                        <div class="col-2">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" #IsRetailInput (ngModelChange)="onChanges($event)" name="isRetail" id="Retail" value="3" [(ngModel)]="ProductRequest.IsRetail">
                                <label class="form-check-label" for="retail">{{"BothAppType" | translate}}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <!--#endregion Search Criteria -->

                <!-- button search -->
                <!-- <div class="row mt-2">
      <div class="col-4"></div>
      <div class="col-4"></div>

      <div class="col-4 d-flex justify-content-end">
        <button class="btn btn-primary bg-red border-0" (click)="onSearchSubmit()">
          {{ "Search" | translate }}
        </button>
      </div>
    </div> -->
                <!--#endregion  Search -->
            </div>
        </div>
    </div>

    <div class="d-flex h-100 flex-column overflow-hidden mt-1">
        <!-- start this to make loading center in page on any screen size  -->
        <div *ngIf="showListLoading" class="  d-flex  flex-column  z-index-3  position-fixed  w-100  h-100  justify-content-center  align-items-center  bg-white" style="opacity: 0.5">
            <div class="p-2 align-self-center">
                <span class="load-more-List text-center vw-100 vh-100">
                    <span class="spinner-border text-primary" role="status"> </span>
                </span>
            </div>
        </div>
        <div class="w-100 text-center">
            <!-- header -->
            <!-- <div class="row">
        <div class="col-12">
          <div class="row bg-secondary align-items-center py-2">
            <div class="col-1">{{ "#ID" | translate }}</div>
            <div class="col-2">{{ "ProductNameAR" | translate }}</div>
            <div class="col-2">{{ "ProductNameEN" | translate }}</div>
            <div class="col-2">{{ "ProductCategory" | translate }}</div>
            <div class="col-1">{{ "ProductBrand" | translate }}</div>
            <div class="col-1">{{ "ProductType" | translate }}</div>
            <div class="col-1">{{ "ProductImage" | translate }}</div>
            <div class="col">{{ "ProductPrice" | translate }}</div>
            <div class="col">{{ "Edit" | translate }}</div>
            <div class="col">{{ "PublishStatus" | translate }}</div>
          </div>
        </div>
      </div> -->
        </div>

        <div class="h-100 products__table">

            <table class="table table-striped table-sm" id="header-fixed">
                <thead>
                    <tr>
                        <th scope="col">{{ "#ID" | translate }}
                            <i (click)="onSortClick($event, 'ID')" class="my-icon fa fa-chevron-down"></i>
                        </th>
                        <th scope="col">{{ "ProductNameAR" | translate }}
                            <i (click)="onSortClick($event, 'NameAR')" class="my-icon fa fa-chevron-up"></i>
                        </th>
                        <th scope="col">{{ "ProductNameEN" | translate }}
                            <i (click)="onSortClick($event, 'NameEN')" class="my-icon fa fa-chevron-up"></i>
                        </th>
                        <th scope="col">{{ "ProductSuppliers" | translate }}
                            <i (click)="onSortClick($event, 'ProductSuppliers')" class="my-icon fa fa-chevron-up"></i>
                        </th>
                        <th scope="col">{{ "ProductCategory" | translate }}
                            <i (click)="onSortClick($event, 'ProductCategory')" class="my-icon fa fa-chevron-up"></i>
                        </th>
                        <th scope="col">{{ "ProductBrand" | translate }}
                            <i (click)="onSortClick($event, 'ProductBrand')" class="my-icon fa fa-chevron-up"></i>
                        </th>
                        <th scope="col"> {{ "ProductType" | translate }}
                            <i (click)="onSortClick($event, 'ProductType')" class="my-icon fa fa-chevron-up"></i>
                        </th>
                        <th scope="col">{{ "ProductImage" | translate }}
                        </th>
                        <th scope="col" class="text-wrap">{{ "PurchasePrice" | translate }}
                            <i (click)="onSortClick($event, 'PurchasingPrice')" class="my-icon fa fa-chevron-up"></i>
                        </th>

                        <th scope="col">{{ "SellPrice" | translate }}
                            <i (click)="onSortClick($event, 'SellPrice')" class="my-icon fa fa-chevron-up"></i>
                        </th>

                        <th scope="col">{{ "PatchCount" | translate }}
                            <i (click)="onSortClick($event, 'PatchCount')" class="my-icon fa fa-chevron-up"></i>
                        </th>

                        <th scope="col">{{ "Quantity" | translate }}
                            <i (click)="onSortClick($event, 'Quantity')" class="my-icon fa fa-chevron-up"></i>
                        </th>

                        <th scope="col">{{ "LocationTackingNumber2" | translate }}
                            <i (click)="onSortClick($event, 'LocationTackingNumber')" class="my-icon fa fa-chevron-up"></i>
                        </th>

                        <th scope="col">{{ "InventoryShelfNumber" | translate }}
                            <i (click)="onSortClick($event, 'InventoryShelfNumber')" class="my-icon fa fa-chevron-up"></i>
                        </th>

                        <th scope="col">{{ "IsRetail" | translate }}
                            <i (click)="onSortClick($event, 'InventoryShelfNumber')" class="my-icon fa fa-chevron-up"></i>
                        </th>

                        <th scope="col">
                            {{ "PublishStatus" | translate }}
                        </th>




                        <th scope="col">
                            {{ "Actions" | translate }}
                            <i class="fa fa-bolt" aria-hidden="true"></i>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let product of products.ItemList">
                        <td>
                            <a style="color: blue" routerLink="/adminhome/product-details/{{ product.ID }}" target="_blank">
                                {{ product.ID }}
                            </a>
                        </td>
                        <td class="text-wrap" style="width: 7rem;">{{ product?.NameAR }}</td>
                        <td class="text-wrap" style="width: 7rem;">{{ product?.NameEN }}</td>
                        <td class="text-wrap" style="width: 7rem;">{{ IsArabic ? product?.Supplier?.NameAR : product?.Supplier?.NameEN }}
                        </td>
                        <td class="text-wrap" style="width: 7rem;">{{ IsArabic ? product?.Category?.NameAR : product?.Category?.NameEN }}
                        </td>
                        <td class="text-wrap" style="width: 7rem;">{{ IsArabic ? product?.Brand?.NameAR : product?.Brand?.NameEN }}</td>
                        <td class="text-wrap" style="width: 7rem;">{{ IsArabic ? product?.Type?.NameAR : product?.Type?.NameEN }}</td>
                        <td>
                            <img src="{{getImageFullPath(product)}}" width="60" height="60">
                        </td>
                        <td>{{ product?.PurchasingPrice }}</td>
                        <td>
                            {{product?.PatchPrice}}
                        </td>
                        <td>
                            {{product?.PatchCount}}
                        </td>

                        <td>{{ product?.QuantitySum }}</td>
                        <td>
                            {{ product?.LocationTackingNumber }}
                        </td>
                        <td>
                            {{ product?.InventoryShelfNumber }}
                        </td>

                        <td>
                            <span *ngIf="product?.IsRetail ==1 ">{{ "Shine" | translate}}</span>
                            <span *ngIf="product?.IsRetail ==2 ">{{ "2assar" | translate}}</span>
                            <span *ngIf="product?.IsRetail ==3 ">{{ "BothAppType" | translate}}</span>
                        </td>

                        <td>
                            <button class="btn btn-success" *ngIf="product.IsAvailable" (click)="SetProductAvailability(product)">
                                {{ "Available" | translate}}
                            </button>
                            <button class="btn btn-danger" *ngIf="!product.IsAvailable" (click)="SetProductAvailability(product)">
                                {{ "NotAvailable" | translate}}
                            </button>
                        </td>

                        <td>
                            <div class="dropdown">
                                <a class="btn btn-primary" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fa fa-bars" aria-hidden="true" style="color:white !important;"></i>
                                </a>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a class="dropdown-item" routerLink="/adminhome/product-details/{{ product.ID }}">
                                        <i class="fa fa-info-circle" aria-hidden="true" style="color: slategray !important"></i> {{ "Details" | translate }}
                                    </a>

                                    <a class="dropdown-item" routerLink="/adminhome/productaddedit/{{ product.ID }}">
                                        <i class="fa fa-lg fa-pencil-square-o" aria-hidden="true" style="color: slategray !important"></i> {{ "Edit" | translate }}
                                    </a>

                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <!-- <app-product-admin
        *ngFor="let item of products.ItemList"
        [ProductModel]="item"
      ></app-product-admin> -->


            <div *ngIf="products.ItemList?.length == 0" class="
          d-flex
          flex-column
          z-index-3
          w-100
          h-100
          justify-content-center
          align-items-center
          bg-white
        ">
                <label class="la">{{ "NoDataFound" | translate }}</label>
            </div>
        </div>

        <!-- #region Pagination -->
        <div class="w-100 mt-2">
            <div class="col-xs-12 col-12 p-1">
                <app-pagination-component [ItemsCount]="products.Total" (changePage)="onPagingChange($event)">
                </app-pagination-component>
            </div>
        </div>
        <!--#endregion Pagination -->
    </div>
</div>

<!-- <div id="myModal" class="modal" *ngIf="showProductImagePopup">
  <span class="close" (click)="showProductImagePopup = false">&times;</span>
  <img class="modal-content" id="img01"
  [src]="productIamgeSrc">
  <div id="caption">xxxxx</div>
</div> -->