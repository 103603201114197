import { environment } from './../../../../environments/environment.prod';
import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { QueryService } from 'src/app/services/query.services';
import { CommandService } from 'src/app/services/command.service';
import { LookupsModel } from 'src/app/models/Lookups.model';
import { LookupsRequest } from 'src/app/models/lookups.Request';
import { TranslateService } from '@ngx-translate/core';
import { AddEditProductRequst } from 'src/app/models/AddEditProductRequst.model';
import { ProductRequest } from 'src/app/models/ProductRequest.Model';
import { ProductModel } from 'src/app/models/Product.Model';
import { FileService } from 'src/app/services/file.service';
import { SearchStream } from 'src/app/models/search-stream-object.model';
import { Title } from '@angular/platform-browser';
import { MutipleImagesUpload } from 'src/app/models/MutipleImagesUpload';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';



@Component({
  selector: 'app-product-add-edit',
  templateUrl: './product-add-edit.component.html',
  styleUrls: ['./product-add-edit.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductAddEditComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('DescriptionAR', { static: true }) productImage: ElementRef;
  IsArabic = true;
  showProductImageReqMesg: boolean = false;
  title: string;
  product: ProductModel = new ProductModel();

  _AddEditProductRequst: AddEditProductRequst = new AddEditProductRequst();
  errMsg: string;
  multiUploaderrMsg: string;
  showLoading: boolean;

  SuppliersList: LookupsModel[];
  CategoriesList: LookupsModel[];
  BrandsList: LookupsModel[];
  TypesList: LookupsModel[];
  ColorsList: LookupsModel[];
  SearchStream: SearchStream = new SearchStream();

  imageSource = 'assets/images/default.png';
  formProductImage = '';

  categoryLoading = false;
  typeLoading = false;
  brandLoading = false;
  colorLoading = false;
  productID: number = 0;
  productCategoryIDs: number[] = [];
  productImagesToUpload: MutipleImagesUpload[] = [];
  progress: number = 0;

  PrductForm: FormGroup;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private queryService: QueryService,
    private commandService: CommandService,
    private translateService: TranslateService,
    private fileService: FileService,
    private titleService: Title,
    private fb: FormBuilder
  ) {
    this.SetProductIdFromUrl();
  }
  ngAfterViewInit(): void {
    //this.productImage.value = this.product.Image;
  }

  ngOnInit() {
    this.loadAfterLang(this.translateService.currentLang);
    this.translateService.onLangChange.subscribe(res => this.loadAfterLang(res.lang));
    this.getProductById(this.setProductRequest(this.productID))
    this.getCategories();
    this.getBrands();
    this.getTypes();
    this.getColors();
    this.getSuppliers();
  }

  SetProductIdFromUrl = () => this.route.params.subscribe((params: Params) => this.productID = params['ID']);

  setProductRequest(id: number): ProductRequest {
    let productRequest = new ProductRequest();
    productRequest.ID = id;
    productRequest.isAdminPage = true;
    return productRequest;
  }

  loadAfterLang(lang: string) {
    this.IsArabic = (lang === 'ar');
    this.title = this.productID == 0 ? this.translateService.instant("AddProduct") : this.translateService.instant("EditProduct");
    this.titleService.setTitle(this.title);

  }

  getSuppliers() {
    let search = new LookupsRequest();
    search.pageIndex = 1;
    search.pageSize = 1000;
    search.Lang = this.translateService.currentLang;
    this.queryService.getAllSuppliers(search).subscribe(res => this.SuppliersList = res.Result);
  }


  getProductById(productRequest: ProductRequest) {
    if (!productRequest.ID || productRequest.ID <= 0) return;

    try {
      this.errMsg = "";
      this.showLoading = true;
      this.queryService.getProductsAdmin(productRequest).subscribe(res => {
        if (res.HasError) {
          this.errMsg = res.ErrorMessage;
          return;
        }
        this.product = res.Result.ItemList[0];
        this.formProductImage = this.product.Image;
        //let dateNow = new Date();
        //this.imageSource = `${environment.webApiEndPoint}Product/${this.product.Image}?date=${this.product.LastModifiedTime}`
        this.imageSource = this.getImageFullPath();
        this.fillProductImagesPreviews(res.Result.ItemList[0].ProductImages)
        this.showLoading = false;


      }, err => {
        this.errMsg = err;
        this.showLoading = false;

      });

    } catch (err) {
      this.errMsg = err;
      this.showLoading = false;

    }
  }

  fillProductImagesPreviews(_productImages: string[]) {

    this.productImagesToUpload = [];
    this.product.ProductImages = [];
    _productImages.forEach((item, index) => {
      var name = item;
      let imageSrc = `${environment.webApiEndPoint}Product/${this.product.ID}/${item}?date=${this.product.LastModifiedTime}`;
      this.product.ProductImages.push(name);
      fetch(imageSrc, { mode: "cors" })
        .then(res => res.blob())
        .then(blob => {
          var extension = name.substr(name.lastIndexOf('.') + 1);
          const file = new File([blob], name, { type: 'image/' + extension });

          let imageUpload = new MutipleImagesUpload();
          imageUpload.Index = index + 1;
          imageUpload.File = file;
          imageUpload.Value = imageSrc;

          this.productImagesToUpload.push(imageUpload);
        });


    })


  }

  setInputFileUploadImageValue = (_value: string) => this.productImage.nativeElement.value = this.product.Image;

  getCategories(searchKeyword: string = "") {
    this.SearchStream.initStream("CategoryDDL_ProductAddEdit", (a) => {
      this.categoryLoading = true;
      let search = new LookupsRequest();
      search.Filter = a;
      search.pageIndex = 1;
      search.pageSize = 1000;
      search.Lang = this.translateService.currentLang;

      this.queryService.getAllProductCategory(search).subscribe(res => {
        if (res.Result)
          this.CategoriesList = res.Result;
        this.categoryLoading = false;
      }
        , _err => this.categoryLoading = false
        , () => this.categoryLoading = false);

    }).next(searchKeyword);

  }

  getBrands(searchKeyword: string = "") {
    this.SearchStream.initStream("BrandDDL_ProductAddEdit", (a) => {
      this.brandLoading = true;
      let search = new LookupsRequest();
      search.Filter = a;
      search.pageIndex = 1;
      search.pageSize = 1000;
      search.Lang = this.translateService.currentLang;

      this.queryService.getAllProductBrand(search).subscribe(res => {
        if (res.Result)
          this.BrandsList = res.Result;
        this.brandLoading = false;
      }
        , _err => this.brandLoading = false
        , () => {
          this.brandLoading = false;
        });

    }).next(searchKeyword);

  }

  getTypes(searchKeyword: string = "") {
    this.SearchStream.initStream("TypeDDL_ProductAddEdit", (a) => {
      this.typeLoading = true;
      let search = new LookupsRequest();
      search.Filter = a;
      search.pageIndex = 1;
      search.pageSize = 1000;
      search.Lang = this.translateService.currentLang;


      this.queryService.getAllProductType(search).subscribe(res => {
        if (res.Result)
          this.TypesList = res.Result;
        this.typeLoading = false;
      }
        , _err => this.typeLoading = false
        , () => this.typeLoading = false);

    }).next(searchKeyword);

  }

  getColors(searchKeyword: string = "") {
    this.SearchStream.initStream("ColorDDL_ProductAddEdit", (a) => {
      this.colorLoading = true;
      let search = new LookupsRequest();
      search.Filter = a;
      search.pageIndex = 1;
      search.pageSize = 1000;
      search.Lang = this.translateService.currentLang;


      this.queryService.getAllProductColor(search).subscribe(res => {
        if (res.Result)
          this.ColorsList = res.Result;
        this.colorLoading = false;
      }
        , _err => this.colorLoading = false
        , () => this.colorLoading = false);

    }).next(searchKeyword);

  }

  saveProductImages() {
    try {

      /** 1- Save Images **/
      this.showLoading = true;

      let uploadedFiles: File[] = [];

      this.productImagesToUpload.forEach(item => uploadedFiles.push(item.File))

      if (uploadedFiles.length > 0) {
        this.fileService.uploadFiles(uploadedFiles).subscribe(res => {
          this.showLoading = false;
          this.showProductImageReqMesg = false;
          if (res.HasError) {
            this.errMsg = res.ErrorMessage;
            return;
          }

          this.product.ProductImages = [];


          res.Result.forEach(image => this.product.ProductImages.push(image.RelativePath));

          /*------------- */

          /** 2- Save Product **/
          this.saveProdcut();
          /*----------- */

        });
      } else {
        this.product.ProductImages = [];
        this.saveProdcut();
      }

    } catch (err) {
      this.errMsg = err;
      this.showLoading = false;

    }

  }

  saveProdcut() {
    if (this.product.Image) {
      this.showProductImageReqMesg = false;
    } else {
      this.showProductImageReqMesg = true;
      this.showLoading = false;
      return
    }

    this.errMsg = "";
    this.showLoading = true;
    this._AddEditProductRequst.ProductDTO = this.product;
    this._AddEditProductRequst.ProductDTO.ProductCategoryIDs = [];
    this._AddEditProductRequst.ProductDTO.ProductCategoryIDs = this.productCategoryIDs;

    console.log(this.product);
    
    this.fillFullProductDescriptionWithProductInfo();
    if (this.productImagesToUpload.length > 0) {
      this._AddEditProductRequst.ProductDTO.ProductImages = this.product.ProductImages;
    }

    this.commandService.AddEditProduct(this._AddEditProductRequst).subscribe(res => {
      this.showLoading = false;
      if (res.HasError) {
        this.errMsg = res.ErrorMessage;
        return;
      }

      this.router.navigateByUrl('adminhome/ProductAdminList')

    }, err => {
      this.errMsg = err;
      this.showLoading = false;
    });
  }

  findLookup(list: LookupsModel[], item: LookupsModel): LookupsModel {
    if (item) {
      let data = list.find(x => x.ID == item.ID);
      return data != null ? data : null;
    }
    return null;
  }

  fillLookupDescription = (item: LookupsModel): string => item != null ? ` ${item.NameAR} ${item.NameEN} ` : "";

  fillFullProductDescriptionWithProductInfo() {
    let cat = this.findLookup(this.CategoriesList, this.product.Category);
    let cat1 = this.findLookup(this.CategoriesList, this.product.Category1);
    let cat2 = this.findLookup(this.CategoriesList, this.product.Category2);
    let cat3 = this.findLookup(this.CategoriesList, this.product.Category3);
    let type = this.findLookup(this.TypesList, this.product.Type);
    let brand = this.findLookup(this.BrandsList, this.product.Brand);
    let color = this.findLookup(this.ColorsList, this.product.Color);

    this._AddEditProductRequst.ProductDTO.FullProductDescription = "";
    this._AddEditProductRequst.ProductDTO.FullProductDescription = this.product.NameAR + " " + this.product.NameEN + " " + this.product.DescriptionAR + " " + this.product.DescriptionEN + " ";
    this._AddEditProductRequst.ProductDTO.FullProductDescription += this.fillLookupDescription(cat);
    this._AddEditProductRequst.ProductDTO.FullProductDescription += this.fillLookupDescription(cat1);
    this._AddEditProductRequst.ProductDTO.FullProductDescription += this.fillLookupDescription(cat2);
    this._AddEditProductRequst.ProductDTO.FullProductDescription += this.fillLookupDescription(cat3);
    this._AddEditProductRequst.ProductDTO.FullProductDescription += this.fillLookupDescription(type);
    this._AddEditProductRequst.ProductDTO.FullProductDescription += this.fillLookupDescription(brand);
    this._AddEditProductRequst.ProductDTO.FullProductDescription += this.fillLookupDescription(color);
  }


  fileChangeEvent(fileInput: any) {
    this.errMsg = "";
    var validImageExtention = this.fileService.checkFileExtentionForImage(fileInput.target.files);
    if (!validImageExtention) {

      this.errMsg = this.translateService.instant("ImageErrorMessage");
      return;
    }
    var validImagesize = this.fileService.checkFileSize(fileInput.target.files);
    if (!validImagesize) {

      this.errMsg = this.translateService.instant("ImageSizeErrorMessage");
      return;
    }
    this.fileService.uploadFiles(fileInput.target.files).subscribe(res => {

      this.showLoading = false;
      this.showProductImageReqMesg = false;
      if (res.HasError) {
        this.errMsg = res.ErrorMessage;
        return;
      }

      this.product.Image = res.Result[0].RelativePath;
      this.imageSource = `${environment.webApiEndPoint}Temp/${this.product.Image}`

      // this.getImageFullPath(tru)
      document.getElementById("imgup").style.display = 'inline';

    });
  }


  ngOnDestroy = (): void => this.SearchStream.DestroyStreams();



  getImageFullPath = (): string => this.product.ImageExist ? `${environment.webApiEndPoint}Product/${this.product.Image}?date=${this.product.LastModifiedTime}` : 'assets/images/default.png';


  validateImageRequired() {
    if (this.product.Image) {
      this.showProductImageReqMesg = false;
    } else {
      this.showProductImageReqMesg = true;
      return
    }
  }

  onSelectFiles($event: any): void {
    let files = $event.target.files;
    this.multiUploaderrMsg = "";
    var validImageExtention = this.fileService.checkFileExtentionForImage(files);
    if (!validImageExtention) {

      this.multiUploaderrMsg = this.translateService.instant("ImageErrorMessage");
      return;
    }
    var validImagesize = this.fileService.checkFileSize(files);
    if (!validImagesize) {

      this.multiUploaderrMsg = this.translateService.instant("ImageSizeErrorMessage");
      return;
    }

    if (files && files[0]) {
      const numberOfFiles = files.length;
      for (let i = 0; i < numberOfFiles; i++) {
        const reader = new FileReader();
        reader.onload = (file: any) => {

          let imageupload = new MutipleImagesUpload();
          imageupload.Index = i + 1;
          imageupload.File = files[i];
          imageupload.Value = file.target.result;

          this.productImagesToUpload.push(imageupload);
        };

        reader.readAsDataURL(files[i]);
      }
    }

  }



  removeImagePreview(image: MutipleImagesUpload) {
    if (this.product.ID != 0) {
      var check = confirm(this.translateService.instant("AreYouSureToDeleteThisImage"));
      if (check) {
        let imageIndex = this.productImagesToUpload.findIndex(x => x.Value == image.Value);
        if (imageIndex > -1) {
          this.productImagesToUpload.splice(imageIndex, 1);
        }

      }
    } else {
      let imageIndex = this.productImagesToUpload.findIndex(x => x.Value == image.Value);
      if (imageIndex > -1) {
        this.productImagesToUpload.splice(imageIndex, 1);
      }
    }

  }


  PrductFormInitialization() {
    this.PrductForm = this.fb.group({
      productNameEn: ['', Validators.required],
      productNameAr: ['', Validators.required],
      suppliers: [null],
      category: [null],
      productCategory2: [null],
      productCategory3: [null],
      productCategory4: [null],
      brand: [null],
      type: [null],
      color: [null],
      productPurchasingPrice: [, [Validators.required, Validators.pattern("/^[0-9]+([\,\.][0-9]+)?$/gm")]],
      productOriginCountry: [''],
      productWeight: ['', [Validators.pattern("/^[0-9]+([\,\.][0-9]+)?$/gm")]],
      productLength: ['', [Validators.pattern("/^[0-9]+([\,\.][0-9]+)?$/gm")]],
      productWidth: ['', [Validators.pattern("/^[0-9]+([\,\.][0-9]+)?$/gm")]],
      productOrderInMobile: [''],
      status: ['', [Validators.required]],
      descriptionAr: ['', [Validators.required]],
      descriptionEn: ['', [Validators.required]],
      LocationTrackingNumber: [''],
      ShelfNumber: [''],
      mainProductImage: [],
      MultiProductImages: [],
      IsRetail:[null,[Validators.required]]
    });
  }

  displayProduct(product: ProductModel) {
    if (product.ID > 1)
      this.PrductForm.patchValue({
        productNameEn: product.NameEN,
        productNameAr: product.NameAR,
        suppliers: product.Supplier.ID,
        category: product.Category.ID,
        productCategory1: product.Category1.ID,
        productCategory2: product.Category2.ID,
        productCategory3: product.Category3.ID,
        brand: product.BrandName,
        type: product.Type.ID,
        color: product.Color.ID,
        productPurchasingPrice: product.PurchasingPrice,
        productOriginCountry: product.ProductOriginCountry,
        productWeight: product.ProductWeights,
        productLength: product.ProductLength,
        productWidth: product.ProductWidth,
        productOrderInMobile: product.ProductOrder,
        status: product.IsAvailable,
        descriptionAr: product.DescriptionAR,
        descriptionEn: product.DescriptionEN,
        LocationTrackingNumber: product.LocationTackingNumber,
        ShelfNumber: product.InventoryShelfNumber,
        mainProductImage: product.ImageFile,
        MultiProductImages: product.ProductImages,
        IsRetail:product.IsRetail
      });
  }



  // uploadProductImages() {

  //   let uploadedFiles: File[] = [];

  //   this.productImagesToUpload.forEach(item => {
  //     uploadedFiles.push(item.File);
  //   });

  //   this.fileService.uploadFiles(uploadedFiles).subscribe(res => {

  //     this.showLoading = false;
  //     this.showProductImageReqMesg = false;
  //     if (res.HasError) {
  //       this.errMsg = res.ErrorMessage;
  //       return;
  //     }

  //     this.product.ProductImages = [];
  //     res.Result.forEach(image => {
  //       this.product.ProductImages.push(image.RelativePath);
  //     });


  //   });
  // }
}
