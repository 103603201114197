<div class="d-flex flex-column w-100 h-100 p-2">
  <div class="bd-highlight py-1">
    <div class="row">
      <div class="col-md-4">
        <div class="bd-highlight">
          <h4>{{ title }}</h4>
        </div>
      </div>
      <div class="col-8 d-flex justify-content-end">
        <button
          class="btn btn-success m-1"
          routerLinkActive="active"
          routerLink="/adminhome/addeditadminuser/0"
        >
          {{ "AddUser" | translate }}
        </button>

        <button
          class="btn btn-primary m-1"
          (click)="onSearchSubmit()"
        >
          {{ "Search" | translate }}
        </button>
      </div>
    </div>
  </div>

  <div class="row">
    <div
      *ngIf="showDeleteInfo"
      class="alert alert-success fixed-top text-center m-1"
    >
      <app-alert-message [msg]="infoMsg | translate"></app-alert-message>
    </div>
  </div>

  <!-- start this to make loading center in page on any screen size -->
  <div
    *ngIf="showLoading"
    class="
      d-flex
      flex-column
      z-index-3
      position-fixed
      w-100
      h-100
      justify-content-center
      align-items-center
      bg-white
    "
    style="opacity: 0.5"
  >
    <div class="p-2 align-self-center">
      <span class="load-more-List text-center vw-100 vh-100">
        <span class="spinner-border text-primary" role="status"> </span>
      </span>
    </div>
  </div>

  <div class="bd-highlight">
    <!-- #region Search  -->
    <!-- #region Search Criteria -->
    <div class="row">
      <div class="col-3">
        <label> {{ "username" | translate }} </label>
        <input
          class="form-control col-9 rounded"
          [(ngModel)]="salesPersonRequest.UserName"
        />
      </div>
      <div class="col-3">
        <label> {{ "FullName" | translate }} </label>
        <input
          class="form-control col-9 rounded"
          [(ngModel)]="salesPersonRequest.FullName"
        />
      </div>
      <div class="col-3">
        <label> {{ "OrderStatus" | translate }} </label>
        <kendo-multiselect
          class="w-100 form-control rounded"
          name="orderStatusIds"
          [data]="OrderStatusList"
          [textField]="IsArabic ? 'NameAR' : 'NameEN'"
          [valueField]="'ID'"
          placeholder="{{ 'SelectOrderStatus' | translate }}"
          [valuePrimitive]="true"
          [(ngModel)]="salesPersonRequest.OrdersStatusIds"
          #orderStatusIds="ngModel"
        >
        </kendo-multiselect>
      </div>
      <div class="col-3">
        <label> {{ "UserCategory" | translate }} </label>

        <kendo-multiselect
          class="w-100 form-control rounded"
          name="orderStatusIds"
          [data]="UserCategoryList"
          [textField]="IsArabic ? 'NameAR' : 'NameEN'"
          [valueField]="'ID'"
          placeholder="{{ 'SelectUserCategory' | translate }}"
          [valuePrimitive]="true"
          [(ngModel)]="salesPersonRequest.UserCategoryIds"
          #orderStatusIds="ngModel"
        >
        </kendo-multiselect>
      </div>
    </div>
  </div>
  <div class="h-100 overflow-auto p-2">
    <table class="table table-striped tableFixHead" id="header-fixed">
      <thead>
        <tr>
          <th scope="col">
            {{ "Code" | translate }}
            <i
              (click)="onSortClick($event, 'Code')"
              class="my-icon fa fa-chevron-up"
            ></i>
          </th>

          <th scope="col">
            {{ "Name" | translate }}
            <i
              (click)="onSortClick($event, 'Name')"
              class="my-icon fa fa-chevron-up"
            ></i>
          </th>
          <th scope="col">
            {{ "PhoneNumber" | translate }}
            <i
              (click)="onSortClick($event, 'PhoneNumber')"
              class="my-icon fa fa-chevron-up"
            ></i>
          </th>
          <th scope="col">
            {{ "UserCategoryName" | translate }}
          </th>
          <th scope="col">
            {{ "CreatedTime" | translate }}
            <i
              (click)="onSortClick($event, 'CreatedTime')"
              class="my-icon fa fa-chevron-up"
            ></i>
          </th>
          <th scope="col">
            {{ "OrdersCount" | translate }}
            <i
              (click)="onSortClick($event, 'OrdersCount')"
              class="my-icon fa fa-chevron-down"
            ></i>
          </th>
          <th scope="col">
            {{ "OrdersTotal" | translate }}
            <i
              (click)="onSortClick($event, 'OrdersTotal')"
              class="my-icon fa fa-chevron-up"
            ></i>
          </th>
          <th scope="col">
            {{ "Status" | translate }}
            <i
              (click)="onSortClick($event, 'Status')"
              class="my-icon fa fa-chevron-up"
            ></i>
          </th>

          <th scope="col">
            {{ "Actions" | translate }}
            <i class="fa fa-bolt" aria-hidden="true"></i>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let sales of salesPersonLst">
          <td>{{ sales.UserCode }}</td>
          <td>
            <!-- <a
              style="color: blue"
              href="/adminhome/client-details/{{ sales.Id }}"
              target="_blank"
            >
              {{ sales.Name }}
            </a> -->
            {{ sales.Name }}
          </td>
          <td>{{ sales.PhoneNumber }}</td>
          <td>{{ sales.UserCategoryName | translate }}</td>
          <td class="text-wrap" style="width: 10rem">
            <span>{{ sales.CreatedTime | date: "dd-MM-yyyy" }}</span>
            <br />
            <span>{{ sales.CreatedTime | date: "h:mm a" }}</span>
          </td>
          <td>
            <a
              class="dropdown-item"
              routerLink="/adminhome/adminorderlist/{{ sales.Id }}/{{
                sales.UserCategoryId
              }}/{{
                salesPersonRequest.OrdersStatusIds.length > 0
                  ? salesPersonRequest.OrdersStatusIds
                  : 0
              }}/0"
              style="color: blue"
              target="_blank"
            >
              {{ sales.OrdersCount }}
            </a>
          </td>
          <td>{{ sales.OrdersTotal | currency: "":"" }}</td>

          <td>
            <span *ngIf="sales.IsActive" style="color: green">
              {{ "ClientActive" | translate }}
            </span>
            <span *ngIf="!sales.IsActive" style="color: red">
              {{ "ClientNotActive" | translate }}
            </span>
          </td>

          <td>
            <div class="dropdown">
              <a
                class="btn btn-primary"
                href="#"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i
                  class="fa fa-bars"
                  aria-hidden="true"
                  style="color: white !important"
                ></i>
              </a>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <!-- <a
                  class="dropdown-item"
                  routerLink="/adminhome/client-details/{{ sales.Id }}"
                >
                  <i
                    class="fa fa-info-circle"
                    aria-hidden="true"
                    style="color: slategray !important"
                  ></i>
                  {{ "Details" | translate }}
                </a> -->

                <a
                  class="dropdown-item"
                  routerLink="/adminhome/addeditadminuser/{{ sales.Id }}"
                >
                  <i
                    class="fa fa-lg fa-pencil-square-o"
                    aria-hidden="true"
                    style="color: slategray !important"
                  ></i>
                  {{ "Edit" | translate }}
                </a>

                <a
                  class="dropdown-item"
                  routerLink="/adminhome/change-password/{{ sales.Id }}"
                >
                  <i
                    class="fa fa-key"
                    aria-hidden="true"
                    style="color: slategray !important"
                  ></i>
                  {{ "ChangePassword" | translate }}
                </a>

                <a
                  class="dropdown-item"
                  (click)="deleteUser(sales.Id)"
                  style="cursor: pointer"
                >
                  <i
                    class="fa fa-lg fa-trash-o"
                    aria-hidden="true"
                    style="color: red !important"
                  ></i>
                  {{ "Delete" | translate }}
                </a>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- <div class="list-product w-100 row">
      <div *ngFor="let sp of salesPersonLst" class="p-2 col-lg-4 col-md-4 col-xs-12 col-sm-12">
        <div *ngIf="sp.IsActive" class="border rounded h-100 d-flex flex-column p-1 each__user__info">
          <div class="p-2 h-25 bd-highlight text-secondary">
            {{ sp.Email == null ? 'No Email' : sp.Email }}
          </div>
          <div class="p-2 h-25 bd-highlight text-secondary">
            {{ sp.Name }}
          </div>
          <div class="p-2 h-50 bd-highlight text-secondary">
            {{ sp.PhoneNumber }}
          </div>
          <div class="p-2 h-50 bd-highlight text-secondary">
            {{ sp.UserCategoryName | translate }}
          </div>
          <div class="p-2 h-25 d-flex align-self-end align-items-end justify-content-end bd-highlight">
            <span class="text-secondary d-inline-block" routerLinkActive="active"
              routerLink="/adminhome/addeditadminuser/{{ sp.Id }}">
              <i class="fa fa-lg fa-pencil-square-o" aria-hidden="true"></i>
            </span>

            <span class="text-secondary d-inline-block ml-4" (click)="deleteUser(sp.Id)">
              <i class="fa fa-lg fa-trash-o" aria-hidden="true"></i>
            </span>
            <span class="text-secondary d-inline-block ml-4"
            routerLink="/adminhome/change-password/{{ sp.Id }}">
              <i class="fa fa-key" aria-hidden="true"></i>
            </span>
          </div>
        </div>
        <div *ngIf="!sp.IsActive" class="border border-danger rounded h-100 d-flex flex-column p-1 each__user__info">
          <div class="p-2 h-25 bd-highlight text-secondary">
            {{ sp.Email == null ? 'No Email' : sp.Email }}
          </div>
          <div class="p-2 h-25 bd-highlight text-secondary">
            {{ sp.Name }}
          </div>
          <div class="p-2 h-50 bd-highlight text-secondary">
            {{ sp.PhoneNumber }}
          </div>
          <div class="p-2 h-50 bd-highlight text-secondary">
            {{ sp.UserCategoryName | translate }}
          </div>

          <div class="p-2 h-50 bd-highlight text-danger">
            {{ "UserNotActive" | translate }}
          </div>

          <div class="p-2 h-25 d-flex align-self-end align-items-end justify-content-end bd-highlight">
            <span class="text-secondary d-inline-block" routerLinkActive="active"
              routerLink="/adminhome/addeditadminuser/{{ sp.Id }}">
              <i class="fa fa-lg fa-pencil-square-o" aria-hidden="true"></i>
            </span>

            <span class="text-secondary d-inline-block ml-4" (click)="deleteUser(sp.Id)">
              <i class="fa fa-lg fa-trash-o" aria-hidden="true"></i>
            </span>
            <span class="text-secondary d-inline-block ml-4"
            routerLink="/adminhome/change-password/{{ sp.Id }}">
              <i class="fa fa-key" aria-hidden="true"></i>
            </span>

          </div>
        </div>
      </div>
    </div> -->
    <div
      *ngIf="salesPersonLst?.length == 0"
      class="
        d-flex
        flex-column
        z-index-3
        w-100
        h-100
        justify-content-center
        align-items-center
        bg-white
      "
    >
      <label class="la">{{ "NoDataFound" | translate }}</label>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-xs-12 col-12 p-0">
      <app-pagination-component
        [ItemsCount]="Total"
        (changePage)="onPagingChange($event)"
      >
      </app-pagination-component>
    </div>
  </div>
</div>
