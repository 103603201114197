<div class="d-flex flex-column h-100 w-100 overflow-hidden">

  <div class="modal-header p-2 border-0">
    <h4 class="modal-title pull-left">{{'choosecitezone'|translate}} </h4>
    <button type="button" class="close text-danger pull-right" aria-label="Close" (click)="hideModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="h-100 overflow-auto">
    <div *ngFor='let c of cityzoneList'  class="w-100 p-2  ">

      <div class="w-100" (click)="Expand(c)">
        <span>{{isArabic ? c.City.NameAR :c.City.NameEN}} <span
            [class]="(c.Expanded ? '   fa fa-chevron-up' : '   fa fa-chevron-down ' ) +   ' text-primary float-right'"></span>
        </span>
      </div>

      <div class="p-2" *ngIf="c.Expanded">
        <div *ngFor='let z of c.ZoneList'>
          <span (click)="SelectedZone(c.City.ID,z.ID)">{{isArabic? z.NameAR:z.NameEN}}</span>
        </div>
      </div>

    </div>
  </div>

</div>
