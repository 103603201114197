<div class="d-flex w-100 flex-column bd-highlight p-2">
    <div class="bd-highlight p-2">
        <h4 class="p-2 mb-0">{{ title }}</h4>
    </div>

    <div *ngIf="errMsg" class="text-danger mr-auto p-2 bd-highlight">
        {{ errMsg }}
    </div>
    <!-- start this to make loading center in page on any screen size -->
    <div *ngIf="showLoading" class="d-flex flex-column z-index-3 position-fixed w-100 h-100 justify-content-center align-items-center bg-white" style="opacity: 0.5;">
        <div class="p-2 align-self-center">
            <span class="load-more-List text-center vw-100 vh-100">
        <span class="spinner-border text-primary" role="status"> </span>
            </span>
        </div>
    </div>

    <!-- #region Form -->
    <form #AddEditFrom="ngForm" (ngSubmit)="AddEditFrom.form.valid && saveCategory()" autocomplete="off">
        <!-- #region form Body -->
        <div class="row mt-2">
            <div class="col-4">
                <label> {{ "CategoryNameEN" | translate }} </label>
                <input class="form-control rounded" name="NameEN" type="text" [(ngModel)]="category.NameEN" #NameEN="ngModel" placeholder="{{ 'CategoryNameEN' | translate }}" required />
                <div *ngIf="AddEditFrom.submitted && NameEN.errors?.required" class="text text-danger px-3">
                    {{ "NameENisrequired" | translate }}
                </div>
            </div>
            <div class="col-4">
                <label> {{ "CategoryNameAR" | translate }} </label>
                <input class="form-control rounded" name="NameAR" type="text" [(ngModel)]="category.NameAR" #NameAR="ngModel" placeholder="{{ 'CategoryNameAR' | translate }}" required />
                <div *ngIf="AddEditFrom.submitted && NameAR.errors?.required" class="text text-danger px-3">
                    {{ "NameARisrequired" | translate }}
                </div>
            </div>
            <div class="col-4">
                <label> {{ "CategoryOrder" | translate }} </label>
                <input class="form-control rounded" name="CategoryOrder" type="number" [(ngModel)]="category.CategoryOrder" #CategoryOrder="ngModel" placeholder="{{ 'CategoryOrder' | translate }}" required min="0" />
                <div *ngIf="AddEditFrom.submitted && CategoryOrder.errors?.required" class="text text-danger px-3">
                    {{ "CategoryOrderIsRequired" | translate }}
                </div>
            </div>

            <div class="col-8 d-inline-flex bd-highlight align-items-center mt-3">
                <div class="col-3">
                    <label for="isRetail">{{ "IsRetail"| translate}}</label>
                </div>
                <div class="col-2">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="IsRetail" id="Salon" required #IsRetailInput="ngModel" [value]="1" [(ngModel)]="category.IsRetail">
                        <label class="form-check-label" for="Salon">{{"Shine" | translate}}</label>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="IsRetail" id="Retail" required #IsRetailInput="ngModel" [value]="2" [(ngModel)]="category.IsRetail">
                        <label class="form-check-label" for="Retail">{{"2assar" | translate}}</label>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="IsRetail" id="Retail" required #IsRetailInput="ngModel" [value]="3" [(ngModel)]="category.IsRetail">
                        <label class="form-check-label" for="Retail">{{"BothAppType" | translate}}</label>
                    </div>
                </div>
                <div *ngIf="AddEditFrom.submitted && IsRetailInput.errors?.required" class="text text-danger px-3">
                    {{ "Retailisrequired" | translate }}
                </div>
            </div>

        </div>

        <div class="row mt-2">
            <div class="col-6">
                <label class="d-block"> {{ "ProductImage" | translate }} </label>
                <!-- <input
          class=" "
          id="fileUpload"
          type="file"
          accept="image/png, .jpeg, .jpg, .bmp"
          (change)="fileChangeEvent($event)"
          placeholder="{{ 'Upload a file...' | translate }}"
        /> -->
                <div class="file-input-wrapper">
                    <label for="upload-file" class="file-input-button">{{
            "UploadFile" | translate
            }}</label>
                    <input id="upload-file" type="file" name="image" accept="image/png, .jpeg, .jpg, .bmp" (change)="fileChangeEvent($event)" />
                </div>
                <img id="imgup" class="img-thumbnail align-self-center border-0 img-fluid mx-3" [src]="imageSource" />
            </div>
        </div>
        <!--#endregion Form Body -->

        <!-- buttons -->
        <div class="row mt-2">
            <div class="col-6">
                <button class="btn btn-primary bg-blue border-0 m-1" type="submit">
          {{ "Save" | translate }}
        </button>
                <button class="btn btn-primary bg-blue border-0 m-1" routerLink="/adminhome/categoryadminlist">
          {{ "Cancel" | translate }}
        </button>
            </div>
        </div>
    </form>
    <!--#endregion Form -->
</div>