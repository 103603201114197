<div class="d-flex w-100 flex-column bd-highlight p-2">
    <div class="bd-highlight p-2">
        <h4 class="p-2 mb-0">{{ title }}</h4>
    </div>

    <!-- start this to make loading center in page on any screen size -->
    <div *ngIf="showLoading" class="d-flex flex-column z-index-3 position-fixed w-100 h-100 justify-content-center align-items-center bg-white" style="opacity: 0.5;">
        <div class="p-2 align-self-center">
            <span class="load-more-List text-center vw-100 vh-100">
        <span class="spinner-border text-primary" role="status"> </span>
            </span>
        </div>
    </div>

    <!-- #region Form -->
    <form #AddEditFrom="ngForm" (ngSubmit)="AddEditFrom.form.valid && saveProductType()" autocomplete="off">
        <!-- #region form Body -->
        <div class="row mt-2">
            <div class="col-4">
                <label> {{ "NameEN" | translate }} </label>
                <input class="form-control rounded" name="NameEN" type="text" [(ngModel)]="productType.NameEN" #NameEN="ngModel" placeholder="{{ 'NameEN' | translate }}" required />
                <div *ngIf="AddEditFrom.submitted && NameEN.errors?.required" class="text text-danger px-3">
                    {{ "NameENisrequired" | translate }}
                </div>
            </div>

            <div class="col-4">
                <label> {{ "NameAR" | translate }} </label>
                <input class="form-control rounded" name="NameAR" type="text" [(ngModel)]="productType.NameAR" #NameAR="ngModel" placeholder="{{ 'NameAR' | translate }}" required />
                <div *ngIf="AddEditFrom.submitted && NameAR.errors?.required" class="text text-danger px-3">
                    {{ "NameARisrequired" | translate }}
                </div>
            </div>

            <div class="col-8 d-inline-flex bd-highlight align-items-center">
                <div class="col-3">
                    <label for="isRetail">{{ "IsRetail"| translate}}</label>
                </div>
                <div class="col-2">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="IsRetail" id="Salon" required #IsRetailInput="ngModel" [value]="1" [(ngModel)]="productType.IsRetail">
                        <label class="form-check-label" for="Salon">{{"Shine" | translate}}</label>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="IsRetail" id="Retail" required #IsRetailInput="ngModel" [value]="2" [(ngModel)]="productType.IsRetail">
                        <label class="form-check-label" for="Retail">{{"2assar" | translate}}</label>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="IsRetail" id="Retail" required #IsRetailInput="ngModel" [value]="3" [(ngModel)]="productType.IsRetail">
                        <label class="form-check-label" for="Retail">{{"BothAppType" | translate}}</label>
                    </div>
                </div>
                <div *ngIf="AddEditFrom.submitted && IsRetailInput.errors?.required" class="text text-danger px-3">
                    {{ "Retailisrequired" | translate }}
                </div>
            </div>

        </div>

        <!--#endregion Form Body -->

        <!-- buttons -->
        <div class="row mt-2">
            <div class="col-6">
                <button class="btn btn-primary bg-blue border-0 m-1" type="submit">
          {{ "Save" | translate }}
        </button>
                <button class="btn btn-primary bg-blue border-0 m-1" routerLink="/adminhome/producttypeadminlist">
          {{ "Cancel" | translate }}
        </button>
            </div>
        </div>
    </form>
    <!--#endregion Form -->
</div>