import { configration } from "src/app/shared/config";
import { SortingDirectionEnum } from "../enum/SortingDirectionEnum";

export class DiscountRequestPoral {
    ID: number;
    Name: string;
    IsArabic: boolean;
    PageIndex: number= 1;
    PageSize: number  = configration.pageSize;;
    Lang: string;
    DiscountValidFrom: Date;
    DiscountValidTo: Date;
    SortColumn: string;
    SortDirection: SortingDirectionEnum = SortingDirectionEnum.Ascending;
    PercentageType: string;
    CustomerEligibility: number;
    ProductEligibility: number;
    UserPhoneNumber: string;
    UserName: string;
    UserID: string;
    ProductID: number;
    ProductName: string;
    Amount: number;
}