import { environment } from './../../../../environments/environment.prod';
import { ProductService } from './../../../services/product.service';
import {
  Component,
  OnInit,
  Query,
  ViewEncapsulation,
  AfterViewInit,
  ViewChild,
  Input,
} from "@angular/core";
import { QueryService } from "src/app/services/query.services";
import { ProductRequest } from "src/app/models/ProductRequest.Model";
import { ProductModel } from "src/app/models/Product.Model";
import { configration } from "src/app/shared/config";
import { CommunicationService } from "src/app/services/communication.service";
import { AddItemToCartRequest } from "src/app/models/AddItemToCartRequest.Model";
import { CommandService } from "src/app/services/command.service";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService, ModalDirective } from "ngx-bootstrap/modal";

import { ActionType } from "src/app/models/Action";
import { ComponentEnum } from "src/app/shared/component.enum";

import { TranslateService } from "@ngx-translate/core";
import { UserCategoryEnum } from "src/app/models/enum/usercategory.enum";
import { ProductSortEnum } from "src/app/models/enum/OrderStatusEnum";
import { ProductPatchModel } from "src/app/models/ProductPatch.model";
import { ProductListState } from "src/app/services/app.state";

@Component({
  selector: "app-new-product-list",
  templateUrl: "./product-list.component.html",
  styleUrls: ["./product-list.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ProductListComponent implements OnInit {
  @Input() UserId: string = "";
  @Input() orderId: number = 0;
  sProductSortEnum = ProductSortEnum;
  isThereMoreProduct: boolean;

  errMsg: string = "";
  showLoading: boolean;
  pathOfAPI: string;
  isArabic: boolean = true;
  showList: boolean = false;
  productList: ProductPatchModel[];
  moreProducts: boolean = true;

  //#endregion Properties
  constructor(
    private _queryService: QueryService,
    private route: ActivatedRoute,
    private _trans: TranslateService,
    private _route: Router,
    private modalService: BsModalService,
    private _commandService: CommandService,
    private _communcationServices: CommunicationService,
    private _productService: ProductService
  ) {
    _trans.onLangChange.subscribe(() => {
      this.isArabic = this._trans.currentLang == "ar";
    });
  }

  getProductListState = (): ProductListState => this._communcationServices.state.productListState;


  ngOnInit() {
    this.isArabic = this._trans.currentLang == "ar";

    if (this.getProductListState().isRequeryRequired()) {
      let request = this.getProductListState().filter;
      this.queryProducts(request);
    } else {
      setTimeout(() => {
        if (document.getElementById("productsDiv") != null)
          document.getElementById("productsDiv").scrollTop = this.getProductListState().scrollPosition;
      }, 500);
    }
    this.querySearchTextFromSearchBar();


    this._productService.productListSubject.subscribe(result => {
      this.productList = result;
    });
  }

  querySearchTextFromSearchBar() {
    this._communcationServices.getAction().subscribe(a => {
      if (a.actionType == ActionType.QueryProducts) {
        this.showList = true;
        let request: ProductRequest = this.getProductListState().filter;
        this.queryProducts(request);

      }
    });
  }
  get components() {
    return ComponentEnum;
  }

  //#region Method

  queryProducts(request: ProductRequest) {
    try {
      this.errMsg = "";
      this.showLoading = true;

      this.getProductListState().clearResult();
      this.getProductListState().filter.UserId = this.UserId;
      this._queryService.getProductsUser(this.getProductListState().filter).subscribe(res => {
        if (res.HasError) {
          this.errMsg = res.ErrorMessage;
          this.showLoading = false;
          return;
        }

        this.getProductListState().setSearchResult(res.Result.ItemList);
        this._productService.setSearchResult(res.Result.ItemList);
        this._productService.totalProductCount = res.Result.Total;
        if (this.noMoreProducts())
          this.moreProducts = false;

        this.isThereMoreProducts(res.Result.Total, this._productService.getProductList().length);
        this.showLoading = false;

      },
        (err) => {
          this.errMsg = err;
          this.showLoading = false;
        }
      );
    } catch (err) {
      this.errMsg = err;
      this.showLoading = false;
    }
  }

  addItemToCart(product: ProductModel) {
    try {
      if (product.Balance == 0) return;

      var request = new AddItemToCartRequest();

      request.ProductID = product.ID;
      request.PatchId = product.PatchObj.Id;
      request.Qty = 1;

      this.errMsg = "";
      this.showLoading = true;
      this._commandService.AddItemToCart(request).subscribe(
        (res) => {
          this.showLoading = false;
          if (res.HasError) {
            this.errMsg = res.ErrorMessage;
            this.showLoading = false;
            return;
          }
          product.AddedToCart = true;

          this._productService.productListSubjectChange(this.productList);
        },
        (err) => {
          this.errMsg = err;
          this.showLoading = false;
        }
      );
    } catch (err) {
      this.errMsg = err;
      this.showLoading = false;
    }
  }

  MoreProducts() {
    if (this.showLoading) return;
    try {
      this.showLoading = true;
      this.getProductListState().filter.PageIndex += 1;
      this.getProductListState().filter.PageSize = configration.pageSize;
      this._queryService
        .getProductsUser(this.getProductListState().filter)
        .subscribe(
          (res) => {
            if (res.HasError) {
              this.errMsg = res.ErrorMessage;
              this.showLoading = false;
              return;
            }
            let ProductList = res.Result.ItemList;
            this.getProductListState().concatSearchResult(ProductList);

            this._productService.concatSearchResult(ProductList);
            //this._productService.productListSubjectChange(res.Result.ItemList);

            this.showLoading = false;
          },
          (err) => {
            this.showLoading = false;
            this.errMsg = err;
          }
        );
    } catch (err) {
      this.errMsg = err;
      this.showLoading = false;
    }
  }

  onProductsScroll(a) {
    let pos = a.srcElement.scrollTop + a.srcElement.clientHeight;
    let h = a.srcElement.scrollHeight;
    let prcnt = Math.round((pos / h) * 100);

    this.getProductListState().scrollPosition = a.srcElement.scrollTop;

    if (prcnt > 95) this.MoreProducts();
  }
  GoToHome() {
    this._route.navigateByUrl("home");
    this._communcationServices.showSideMenuAction(false);
  }

  noMoreProducts() {
    this.showLoading = true;
    let currentProductsLoaded = this.productList.length;
    if (this._productService.totalProductCount === currentProductsLoaded) {
      this.showLoading = false;
      return true;
    } else {
      this.showLoading = false;
      return false;
    }
  }

  isThereMoreProducts = (totalProducts: number, loadedProducts: number) => totalProducts === loadedProducts ? this.isThereMoreProduct = false : this.isThereMoreProduct = true;

  changeProductStatus = (product: ProductPatchModel) => this._communcationServices.state.productListState.changeProductState(product);
  //#endregion Method
}
