import { SalonTypeEnum } from 'src/app/models/enum/SalonTypeEnum';
import { DateService } from 'src/app/services/date.service';
import { CustomDatesEnum } from 'src/app/models/enum/CustomDatesEnum';
import { Input, ElementRef, ViewChild } from '@angular/core';
import { ZoneDto } from './../../../models/ZoneDto';
import { LookupsModel } from './../../../models/Lookups.model';
import { UserAddressDto } from './../../../models/UserAddressDto.Model';
import { CommunicationService } from './../../../services/communication.service';
import { DeleteUserModel } from './../../../models/DeleteUserModel';
import { LoginAdminService } from './../../../services/login-admin.service';
import { Component, OnInit } from '@angular/core';
import { SalesPersonDto } from 'src/app/models/SalesPersonDto.Model';
import { SalesPersonRequest } from 'src/app/models/SalesPersonRequest.Model';
import { QueryService } from 'src/app/services/query.services';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { configration } from 'src/app/shared/config';
import { UserCategoryEnum } from 'src/app/models/enum/usercategory.enum';
import { PageChangedEvent } from 'ngx-bootstrap/pagination/public_api';
import { ActionType } from 'src/app/models/Action';
import { SearchStream } from 'src/app/models/search-stream-object.model';
import { UserAddressRequest } from 'src/app/models/UserAddressRequest.Model';
import * as XLSX from "xlsx";
import { ClinetsSalesViewModelExcel } from 'src/app/models/ClinetsSalesViewModelExcel';
import { CityZoneDto } from 'src/app/models/CityZoneDto.Model';
import { OrderStatusEnum } from 'src/app/models/enum/OrderStatusEnum';
import * as FileSaver from 'file-saver';
// import {NgbdSortableHeader, SortEvent} from './sortable.directive';

@Component({
  selector: 'app-admin-mobile-user-list',
  templateUrl: './admin-mobile-user-list.component.html',
  styleUrls: ['./admin-mobile-user-list.component.scss']
})
export class AdminMobileUserListComponent implements OnInit {
  @ViewChild("dateFrom", { static: false }) dateFrom: ElementRef;
  @ViewChild("dateTo", { static: false }) dateTo: ElementRef;
  displayedColumns: string[] = ['Name', 'PhoneNumber', 'SalesName', 'SalesNumber', 'CreatedTime', 'Active', 'Actions'];
  OrderStatusList: LookupsModel[];
  dataSource: SalesPersonDto[] = [];
  MobileUserList: SalesPersonDto[] = [];
  MobileUserListExcel: SalesPersonDto[] = [];
  MobileUserRequest: SalesPersonRequest = new SalesPersonRequest();
  //_clinetsSalesViewModel: ClinetsSalesViewModel[] = [];
  _clinetsSalesViewModelExcel: ClinetsSalesViewModelExcel[] = [];
  //_ClientsSalesViewRequest: ClientsSalesViewRequest = new ClientsSalesViewRequest();
  Total: number;
  errMsg: string;
  showLoading: boolean;
  title: string;
  IsArabic = true;
  infoMsg: string;
  showDeleteInfo = false;
  showAlert = false;
  salesPersonLst: SalesPersonDto[] = [];
  cityList: LookupsModel[] = [];
  zoneList: ZoneDto[] = [];
  AllZoneList: ZoneDto[] = [];
  customDatesEnum = CustomDatesEnum;
  from: Date = new Date("01/01/2021");
  to: Date = new Date();
  salonTypeEnum = SalonTypeEnum;

  SalesPersonDDLLoading: boolean = false;
  cityDDLLoading: boolean = false;
  zoneDDLLoading: boolean = false;
  SearchStream: SearchStream = new SearchStream();
  sortDir = 1;//1= 'ASE' -1= DSC
  userAddressDto: UserAddressDto = new UserAddressDto();

  constructor(private _queryService: QueryService,
    private translateService: TranslateService,
    private _loginAdminService: LoginAdminService,
    private _communcationServices: CommunicationService,
    private _dateTimeService: DateService,
    private titleService: Title) { }

  ngOnInit() {
    this.MobileUserRequest.OrdersStatusIds = [];
    this.MobileUserRequest.OrdersStatusIds.push(OrderStatusEnum.Saved);
    this.MobileUserRequest.OrdersStatusIds.push(OrderStatusEnum.OnTheWay);
    this.MobileUserRequest.OrdersStatusIds.push(OrderStatusEnum.Delivered);
    this.getOrdersStauts();
    this.getUser();
    this.getAllCitiesDDL();
    this.getAllZonesDDL();
    this._communcationServices.getAction().subscribe((res) => {
      if (res.actionType == ActionType.ShowAlert) {
        this.showAlert = true;
        this.errMsg = res.Result;
      } else if (res.actionType == ActionType.ColseAlert) {
        this.showAlert = false;
      } else if (res.actionType == ActionType.ShowAlertInfo) {
        this.showDeleteInfo = true;
        this.infoMsg = res.Result;
      } else if (res.actionType == ActionType.CloseAlertInof) {
        this.showDeleteInfo = false;
      }

    });


    this.loadAfterLang(this.translateService.currentLang);
    this.translateService.onLangChange.subscribe(res => {
      this.loadAfterLang(res.lang);
    });
    this.MobileUserRequest.PageIndex = 1;
    this.MobileUserRequest.PageSize = configration.pageSize;
    this.MobileUserRequest.UserCategories.push(UserCategoryEnum.Client);

    if (+this._loginAdminService.getUserCategoryId() === UserCategoryEnum.Sales) {
      this.MobileUserRequest.UserCategoryId = UserCategoryEnum.Sales;
    }
    else {
      this.MobileUserRequest.UserCategoryId = 0;
    }
    this.searchUsers();
  }
  loadAfterLang(lang: string) {
    this.IsArabic = lang === "ar";
    this.title = this.translateService.instant("mobileusers");
    this.titleService.setTitle(this.title);

  }

  searchUsers() {
    try {
      this.showLoading = true;
      this.MobileUserRequest.Lang = this.translateService.currentLang;
      this._queryService
        .getMobileUserList(this.MobileUserRequest)
        .subscribe(
          res => {
            if (res.HasError) {
              this.errMsg = res.ErrorMessage;
              this.showLoading = false;
              return;
            }
            //populate PersonList

            this.MobileUserList = res.Result.ItemList;
            this.Total = res.Result.Total;
            this.showLoading = false;
          },
          err => {
            this.showLoading = false;
            this.errMsg = err;
          }
        );
    } catch (err) {
      this.errMsg = err;
      this.showLoading = false;
    }

  }


  onSearchSubmit() {
    this.MobileUserRequest.PageIndex = 1;
    this.searchUsers();
  }

  onPagingChange(event: PageChangedEvent) {
    this.MobileUserRequest.PageIndex = event.page;
    this.MobileUserRequest.PageSize = event.itemsPerPage;
    this.searchUsers();
  }
  //end file

  deleteClient(userId: string) {
    var confirmDelete = confirm(this.translateService.instant("AreYouSureDeleteClient"))
    if (confirmDelete) {
      try {
        this.showLoading = true;

        let deleteUserModel = new DeleteUserModel();
        deleteUserModel.UserId = userId;
        deleteUserModel.IsActive = false;

        this._loginAdminService
          .deleteUser(deleteUserModel)
          .subscribe(
            res => {
              if (res.HasError) {
                this.errMsg = res.ErrorMessage;
                this.showLoading = false;
                return;
              }

              if (res.Result) {
                let userIndex = this.MobileUserList.findIndex(x => x.Id == userId);
                if (userIndex > -1) {
                  this.MobileUserList[userIndex].IsActive = false;

                  this._communcationServices.showAlertInof(
                    this.translateService.instant("ClientDeletedSuccessfully")
                  );
                }
              }
            },
            err => {
              this.showLoading = false;
              this.errMsg = err;
            }

          );
      } catch (err) {
        this.errMsg = err;
        this.showLoading = false;
      }

    } else {
      return;
    }
  }

  getUser(searchKeyword: string = "") {

    this.SearchStream.initStream("UserDDL", (a) => {
      let search = new SalesPersonRequest();
      search.Filter = a;
      search.PageIndex = 1;
      search.PageSize = 1000;
      search.UserCategories.push(UserCategoryEnum.Sales);

      this.SalesPersonDDLLoading = true;
      this._queryService.getAllSalesPersonList(search).subscribe(
        (res) => {
          if (res.HasError) {
            this.errMsg = res.ErrorMessage;
            return;
          }
          //populate PersonList
          this.salesPersonLst = res.Result.ItemList;
        },
        (err) => {
          this.errMsg = err;
        },
        () => {
          this.SalesPersonDDLLoading = false;
        }
      );
    }).next(searchKeyword);
  }

  getAllCitiesDDL() {

    try {
      this.cityDDLLoading = true;
      this._queryService.getAllCities().subscribe(res => {
        if (res.HasError) {
          this.errMsg = res.ErrorMessage;
          return;
        }

        this.cityList = res.Result;
        this.cityDDLLoading = false;
      })

    } catch (err) {
      this.errMsg = err;
      this.showLoading = false;
    }
    this.cityDDLLoading = false;
  }

  getAllZonesDDL() {

    try {
      this.zoneDDLLoading = true;
      this._queryService.getAllZones().subscribe(res => {
        if (res.HasError) {
          this.errMsg = res.ErrorMessage;
          return;
        }

        this.AllZoneList = res.Result;
        this.zoneDDLLoading = false;

      })

    } catch (error) {

    }
    this.zoneDDLLoading = false;
  }

  onSortClick(event: any, colName: string) {

    let target = event.currentTarget,
      classList = target.classList;

    /* sort DESC */
    if (classList.contains('fa-chevron-up')) {
      classList.remove('fa-chevron-up');
      classList.add('fa-chevron-down');
      this.sortDir = 0;

      /* sort ASC */
    } else {
      classList.add('fa-chevron-up');
      classList.remove('fa-chevron-down');
      this.sortDir = 1;
    }

    this.MobileUserRequest.SortColumn = colName;
    this.MobileUserRequest.SortDirection = this.sortDir;
    this.MobileUserRequest.UserCategories.push(UserCategoryEnum.Client);

    this.searchUsers();
  }

  clearSearchFilter() {
    this.MobileUserRequest = new SalesPersonRequest();
    this.MobileUserRequest.PageSize = 25;
  }
  getUserAddress(clientId: string) {
    let userAddressRequest = new UserAddressRequest()
    userAddressRequest.UserId = clientId;
    try {
      this.showLoading = true;
      this._queryService.getUserAddressByUserId(userAddressRequest)
        .subscribe(
          res => {
            if (res.HasError) {
              this.errMsg = res.ErrorMessage;
              this.showLoading = false;
              return;
            }
            this.userAddressDto = res.Result;
            this.showLoading = false;
          },
          err => {
            this.showLoading = false;
            this.errMsg = err;
          }

        );
    } catch (err) {
      this.errMsg = err;
      this.showLoading = false;
    }

  }

  private constructSalesPersonDto(item: SalesPersonDto): SalesPersonDto {
    let mobileUserListExcel = new SalesPersonDto();
    mobileUserListExcel.Name = item.Name;
    mobileUserListExcel.PhoneNumber = item.PhoneNumber;
    mobileUserListExcel.SalesName = item.SalesName;
    mobileUserListExcel.SalesNumber = item.SalesNumber;
    mobileUserListExcel.OrdersCount = item.OrdersCount;
    mobileUserListExcel.OrdersTotal = item.OrdersTotal;
    mobileUserListExcel.LastOrderDate = item.LastOrderDate;
    mobileUserListExcel.CreatedTime = item.CreatedTime;
    mobileUserListExcel.IsActive = item.IsActive;
    mobileUserListExcel.City = item.UserAddressDto.City.NameAR;
    mobileUserListExcel.Area = item.UserAddressDto.Zone.NameAR;
    mobileUserListExcel.AddressDescription = item.UserAddressDto.Description;
    mobileUserListExcel.UserCategoryName = item.UserCategoryName + (item.IsRetail ? "-Retailer" : "-2assar");
    mobileUserListExcel.SalonTypeName = item.SalonType == SalonTypeEnum.Men ? "Men" : "Female";
    return mobileUserListExcel;
  }

  // downloadProductTaxExcel() {
  //   this.taxesService.exportExcel().subscribe(res => {

  //     saveAs(blobObj, 'productTaxes' + '.xlsx');
  //     this.toastr.success('Download Started!');
  //   }, err => {
  //     this.toastr.error('Failed to export !');
  //   });
  // }

  // exportCustomersToExcel() {
  //   this.showLoading = true;
  //   this.errMsg = "";
  //   try {
  //     this.MobileUserRequest.IsExcel = true;
  //     this._queryService.getExcelMobileUser(this.MobileUserRequest).subscribe((res:any) => {
  //       console.log(res.headers.get('content-disposition'));
  //                 const blobObj = new Blob([res as Blob], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
  //         console.log(blobObj);
          
  //         let excelTitle = `Clients${this.getDateFormated()}.xlsx`;
  //         FileSaver.saveAs(blobObj,excelTitle );
  //         this.showLoading=false;
  //       },
  //         (err) => {
  //           this.errMsg = err;
  //           this.showLoading = false;
  //         }
  //       );
  //   } catch (err) {
  //     this.errMsg = err;
  //     this.showLoading = false;
  //   }
  // }


  exportCustomersToExcel() {
    this.showLoading = true;
    this.errMsg = "";
    try {
      this.MobileUserRequest.IsExcel = true;
      this._queryService
        .getMobileUserList(this.MobileUserRequest)
        .subscribe(
          (res) => {

            if (res.HasError) {
              this.errMsg = res.ErrorMessage;
              this.showLoading = false;
              return;
            }
            this.MobileUserListExcel = res.Result.ItemList;
            let excelDate: SalesPersonDto[] = [];
            this.MobileUserListExcel.forEach(item => excelDate.push(this.constructSalesPersonDto(item)))
            this.exportExcelSheet(excelDate, false)

          },
          (err) => {
            this.errMsg = err;
            this.showLoading = false;
          }
        );
    } catch (err) {
      this.errMsg = err;
      this.showLoading = false;
    }
  }

  getDateFormated(): string {
    let dd = String(new Date().getDate()).padStart(2, '0');
    let mm = String(new Date().getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = new Date().getFullYear();
    let result = dd + '/' + mm + '/' + yyyy;
    return result;
  }

  exportExcelSheet(result: any, isExcelLoading: boolean) {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(result);
    const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    /* save to file */
    this.showLoading = isExcelLoading;
    this.MobileUserRequest.IsExcel = isExcelLoading;
    let excelTitle = "Customers [" + this.getDateFormated() + "].xlsx";
    XLSX.writeFile(workbook, excelTitle);

  }

  SelectedCity(city: LookupsModel) {
    this.zoneList = [];
    this.MobileUserRequest.ZoneIdS = [];
    this.AllZoneList.forEach(x => {
      if (x.CityId === city.ID) {
        this.zoneList.push(x)
      }
    })
  }

  getOrdersStauts() {
    this._queryService.getAllOrderStatus().subscribe(
      (res) => {
        if (res.HasError) {
          this.errMsg = res.ErrorMessage;
          return;
        }
        this.OrderStatusList = res.Result;
      },
      (err) => {
        this.errMsg = err;
      }
    );
  }


  searchOrderByCustomeDate(dateType: CustomDatesEnum) {
    let dayFrom = new Date();
    let dayTo = new Date();

    switch (dateType) {
      case this.customDatesEnum.Yesterday:
        dayFrom = new Date(this._dateTimeService.getYesterday());
        dayTo = new Date(this._dateTimeService.getYesterday());
        break;

      case this.customDatesEnum.CurrentWeek:
        dayFrom = new Date(this._dateTimeService.getFirstDayOfCurrentWeek());
        dayTo = new Date(this._dateTimeService.getLastDayOfCurrentWeek());
        break;

      case this.customDatesEnum.LastWeek:
        dayFrom = new Date(this._dateTimeService.getFirstDayOfPreviousWeek());
        dayTo = new Date(this._dateTimeService.getLastDayOfPreviousWeek());
        break;

      case this.customDatesEnum.CurrentMonth:
        dayFrom = new Date(this._dateTimeService.getFirstDayOfCurrentMonth());
        dayTo = new Date(this._dateTimeService.getLastDayOfCurrentMonth());
        break;

      case this.customDatesEnum.LastMonth:
        dayFrom = new Date(this._dateTimeService.getFirstDayOfPreviousMonth());
        dayTo = new Date(this._dateTimeService.getLastDayOfPreviousMonth());
        break;

      default:
        break;
    }

    this.from = new Date(dayFrom);
    this.to = new Date(dayTo);


    this.InquiryUserList();
  }

  incrementOrderDayFrom() {
    this.from.setDate(this.from.getDate() + 1)
    this.dateFrom.nativeElement.value = this.getDateFormatedI(this.from);
  }
  decrementOrderDayFrom() {
    this.from.setDate(this.from.getDate() - 1)
    this.dateFrom.nativeElement.value = this.getDateFormatedI(this.from);
  }
  incrementOrderDayTo() {
    this.to.setDate(this.to.getDate() + 1)
    this.dateTo.nativeElement.value = this.getDateFormatedI(this.to);
  }
  decrementOrderDayTo() {
    this.to.setDate(this.to.getDate() - 1)
    this.dateTo.nativeElement.value = this.getDateFormatedI(this.to);
  }

  getDateFormatedI(date: Date): string {
    let dd = String(date.getDate()).padStart(2, '0');
    let mm = String(date.getMonth() + 1).padStart(2, '0');
    var yyyy = date.getFullYear();
    let result = dd + '-' + mm + '-' + yyyy;
    return result;
  }

  InquiryUserList() {
    this.MobileUserRequest.CreatedTimeFrom = this.from;
    this.MobileUserRequest.CreatedTimeTo = this.to;
    this.searchUsers();
  }


}

