import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { QueryService } from 'src/app/services/query.services';
import { SalesPersonRequest } from 'src/app/models/SalesPersonRequest.Model';
import { SalesPersonDto } from 'src/app/models/SalesPersonDto.Model';
import { anyChanged } from '@progress/kendo-angular-common';
import { Router } from '@angular/router';
import { SearchStream } from 'src/app/models/search-stream-object.model';
import { UserCategoryEnum } from 'src/app/models/enum/usercategory.enum';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { GenerateVoucherRequest } from 'src/app/models/Discount/GenerateVoucherRequest.Model';

@Component({
  selector: 'app-generate-voucher',
  templateUrl: './generate-voucher.component.html',
  styleUrls: ['./generate-voucher.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class GenerateVoucherComponent implements OnInit {
  showLoading: boolean;
  SalesPersonDDLLoading= false;

  errMsg: string;
  salesPersonLst: SalesPersonDto[]=[];
  SelectedsalesPerson: SalesPersonDto;
  NoVoucher:number;
  Notes:string;
  Request:GenerateVoucherRequest=new GenerateVoucherRequest();
  SearchStream: SearchStream = new SearchStream();
  title: string;

  constructor(
    private _queryService:QueryService,
    private router : Router,
    private translateService: TranslateService,
    private titleService: Title
    ) {

  }

  ngOnInit() {
    this.loadAfterLang(this.translateService.currentLang);
    this.translateService.onLangChange.subscribe(res => {
      this.loadAfterLang(res.lang);
    });

    this.getUser();
  }

 loadAfterLang(lang: string) {
    this.title = this.translateService.instant('generatevoucher')
    this.titleService.setTitle(this.title);
  }

  getUser(searchKeyword: string = "") {
    this.SearchStream.initStream("UserDDL", (a) => {

      let search = new SalesPersonRequest();
      search.Filter = a;
      search.PageIndex = 1;
      search.PageSize = 100;
      search.UserCategories.push(UserCategoryEnum.Sales);

      this.SalesPersonDDLLoading = true;
      this._queryService.getAllSalesPersonList(search).subscribe(res => {
        if (res.HasError) {
          this.errMsg = res.ErrorMessage;
          return;
        }
        //populate PersonList
        this.salesPersonLst = res.Result.ItemList;
      }, err => {
        this.errMsg = err;
      }, ()=> {
        this.SalesPersonDDLLoading = false;
      });

    }).next(searchKeyword);








  }
  save(){
    try {

      this.showLoading = true;
      this.Request.NumberofVoucher=this.NoVoucher;
      this.Request.UserId=this.SelectedsalesPerson.Id;
      this.Request.UserName=this.SelectedsalesPerson.UserName;
      this.Request.Note=this.Notes;
      if(this.NoVoucher==0){
          this.errMsg="Enter number of voucher"
          return;
        }
      this._queryService.GenerateUserVouchers(this.Request).subscribe(res => {
      if (res.HasError) {
        this.errMsg = res.ErrorMessage;
        this.showLoading = false;
        return;
      }
      //populate PersonList
      if(res.Result)
      {
        this.router.navigateByUrl('adminhome/adminqueryvoucher');
      }
      this.showLoading = false;
    }, err => {
      this.showLoading = false;
      this.errMsg = err;});
  }
    catch (err) {
      this.errMsg = err;
      this.showLoading = false;
  }

  }

}
