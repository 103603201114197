import { UserAddressDto } from 'src/app/models/UserAddressDto.Model';
import { SalonTypeEnum } from './enum/SalonTypeEnum';
export class RegisterBindingModel {
  IsActive: boolean = true;
  UserId: string;
  UserName: string;
  Password: string;
  ConfirmPassword: string;
  PhoneNumber: string;
  Name: string;
  UserCategoryId: number;
  VoucherID: number | null;
  Notes: string;
  Image: string;
  isAdmin: boolean = false;
  PromoCode: string;
  PromoCodeAmount: string;
  Email: string;
  PromoCodeType: number;
  UserAddress: UserAddressDto;
  SalonName: string;
  SalonType: SalonTypeEnum;

}
