<div
  [class]="
    translate.currentLang == 'ar' ? ' rtl whole__page' : ' ltr whole__page'
  "
>
  <router-outlet></router-outlet>
</div>

<div
  bsModal
  #childModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-lg h-100 w-100 m-auto p-0">
    <div class="modal-content h-100 w-100">
      <div class="modal-body h-100 w-100">
        <router-outlet name="popupoutlet"></router-outlet>
      </div>
    </div>
  </div>
</div>
