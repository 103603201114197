import { map } from 'rxjs/operators';
import { filter } from 'rxjs/operators';
import { GetUserNoteById } from './../../../models/UserNotes/GetUserNoteById';
import { IDDto } from './../../../models/IDDto';
import { UserNotesRequest } from './../../../models/UserNotes/UserNotesRequest';
import { CommandService } from 'src/app/services/command.service';
import { OrderItemModel } from './../../../models/Order.Model';
import { ClientDetailsRequest } from './../../../models/ClientDetailsRequest';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { QueryService } from 'src/app/services/query.services';
import { CommunicationService } from 'src/app/services/communication.service';
import { Title } from '@angular/platform-browser';
import { ClientDetailsDto } from 'src/app/models/ClientDetailsDto';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { OrderStatusEnum } from 'src/app/models/enum/OrderStatusEnum';
import { UserAddressDto } from 'src/app/models/UserAddressDto.Model';
import { SalonTypeEnum } from 'src/app/models/enum/SalonTypeEnum';
import { BsModalRef, BsModalService, ModalDirective } from "ngx-bootstrap/modal";
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-client-detials',
  templateUrl: './client-detials.component.html',
  styleUrls: ['./client-detials.component.scss']
})
export class ClientDetialsComponent implements OnInit {

  showLoading = false;
  IsArabic: boolean = true;
  title: string;
  _userId: string;
  errMsg: string;
  clientDetials = new ClientDetailsDto();
  userNotesRequest: UserNotesRequest = new UserNotesRequest();
  OrderStatusEnum = OrderStatusEnum;
  showOrdersGridView = true;
  salonTypeEnum = SalonTypeEnum;
  @ViewChild("promoCodeModal", { static: false })
  promoCodeModal: ModalDirective;
  isShowPoupPromoCode: boolean = false;
  NotesText: string;
  voucherError: boolean = false;
  noteID: number;

  constructor(private _route: ActivatedRoute,
    private _translate: TranslateService,
    private _queryService: QueryService,
    private router: Router,
    private command: CommandService,
    private _communicationService: CommunicationService,
    private titleService: Title
  ) { }

  ngOnInit() {
    this.loadAfterLang(this._translate.currentLang);
    this._translate.onLangChange.subscribe(res => this.loadAfterLang(res.lang));
    this._route.params.subscribe((params: Params) => this._userId = params["ID"]);

    this.getClientDetails();
  }

  loadAfterLang(lang: string) {
    this.IsArabic = lang === "ar";

    this._route.params.subscribe((params: Params) => {
      let id = params["ID"];

      this.title = this._translate.instant("ClientDetails");
      this.titleService.setTitle(this.title);
    });
  }

  getClientDetails() {
    try {
      this.showLoading = true;
      let request = new ClientDetailsRequest();
      request.ClientId = this._userId;

      this._queryService.getClientDetails(request)
        .pipe(tap(() => this.showLoading = false))
        .subscribe((res) => {
          console.log(res);
          
          this.clientDetials = res.Result;
        });

    } catch (error) {
      this.showLoading = false;
    }
  }

  sumOrderItemQuantity = (OrderItemModel: OrderItemModel[]) => OrderItemModel.filter(x => x.isDeleted == false).reduce((partial, x) => partial + x.Quantity, 0)

  getOrderCityZoneNames(address: UserAddressDto) {
    let currentLang = this._translate.currentLang;
    let cityName = currentLang == 'en' ? address.City.NameEN : address.City.NameAR;
    let zoneName = currentLang == 'en' ? address.Zone.NameEN : address.Zone.NameAR;
    if (cityName)
      return cityName + '/' + zoneName;
  }

  showPromoCodePopup() {
    this.isShowPoupPromoCode = true;
    this.promoCodeModal.show();
  }

  hideModal() {
    this.isShowPoupPromoCode = false;
    this.NotesText = "";
    this.noteID = 0;
    this.promoCodeModal.hide();
  }

  AddEditNotes() {
    try {
      this.showLoading = true;
      this.userNotesRequest.Notes = this.NotesText;
      this.userNotesRequest.UserID = this.clientDetials.Id;
      this.userNotesRequest.ID = this.noteID == 0 ? 0 : this.noteID;
      this.command.AddEditUserNotes(this.userNotesRequest)
        .pipe(
          tap(() => {
            this.showLoading = false;
            this.hideModal();
          }))
        .subscribe(res => {
          if (res.HasError) {
            this.errMsg = this._translate.instant("inValidOperation");
            this.voucherError = true
            this.noteID = 0;
          }
          this.getClientDetails();
        });
    } catch (error) {
      this.showLoading = false;
      this.hideModal();
    }
  }

  deleteUserNote(id: number) {
    try {
      let request: IDDto = new IDDto();
      request.iD = id;
      this.errMsg = '';
      this.showLoading = true;
      this.command.DeleteUserNotes(request)
        .pipe(tap(() => this.showLoading = false))
        .subscribe(res => {
          if (res.HasError) {
            this.errMsg = res.ErrorMessage;
          }
          this.ngOnInit();
        });
    } catch (error) {
      this.errMsg = error;
      this.showLoading = false;
    }
  }

  getUserNote(id: number) {
    try {
      let request = new GetUserNoteById();
      request.Id = id;
      this.noteID = id;
      this.showLoading = true;
      this._queryService.GetUserNotesBySearch(request)
        .pipe(
          tap(() => {
          this.showPromoCodePopup();
          this.showLoading = false;
        }))
        .subscribe(res =>
           this.NotesText = res.Result.ItemList[0].Notes
           )
    } catch (error) {
      this.showPromoCodePopup();

    }
  }


}
