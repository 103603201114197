import { GetUserNoteById } from './../models/UserNotes/GetUserNoteById';
import { UserNotesResponse } from './../models/UserNotes/UserNotesResponse';
import { GetAllUserNotes } from './../models/UserNotes/GetAllUserNotes';
import { environment } from './../../environments/environment.prod';
import { ProductSupplierDto } from 'src/app/models/ProductSupplierDto';
import { ProductDetailsRequest } from './../models/ProductDetailsRequest';
import { ClientDetailsRequest } from './../models/ClientDetailsRequest';
import { OrdersConfigurationsRequest } from './../models/OrdersConfigurationsRequest';
import { ZoneDto } from './../models/ZoneDto';
import { ProductCategoryModel } from "src/app/models/product-category.model";
import * as  http from "@angular/common/http";
import { Injectable } from "@angular/core";
import { configration } from "../shared/config";
import { Observable, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { GlobalResponse } from "../models/GlobalResponse.Model";
import { ProductCategoryRequest } from "../models/ProductCategoryRequest.Model";
import {
  ProductRequest,
  ProductPatchRequest,
  ProductDiscountRequest,
} from "../models/ProductRequest.Model";
import { SearchResult } from "../models/SearchResult.Model";
import { ProductModel } from "../models/Product.Model";
import { UserAddressRequest } from "../models/UserAddressRequest.Model";
import { UserAddressDto } from "../models/UserAddressDto.Model";
import { LookupsModel, ColorLookupsModel } from "../models/Lookups.model";
import { AddEditUserAddressRequst } from "../models/AddEditUserAddressRequst.Model";
import { LookupsRequest } from "../models/lookups.Request";
import { OrderModel } from "../models/Order.Model";
import { OrderRequest, OrderRequestNew } from "../models/OrderRequest.Model";
import { CityZoneDto } from "../models/CityZoneDto.Model";
import { SalesPersonDto } from "../models/SalesPersonDto.Model";
import { SalesPersonRequest } from "../models/SalesPersonRequest.Model";
import { VoucherDto } from "../models/VoucherDto.Model";
import { VoucherRequest } from "../models/VoucherRequest.Model";
import { VoucherLogDto } from "../models/VoucherLogDto.Model";
import { ProductBrandRequest } from "../models/ProductBrandRequest.model";
import { ProductBrandDTO } from "../models/ProductBrandDTO.model";
import { ProductTypeRequest } from "../models/ProductTypeRequest.model";
import { ProductColorRequest } from "../models/ProductColorRequest.model";
import { ProductTypeDTO } from "../models/ProductTypeDTO.model";
import { ProductColorDTO } from "../models/ProductColorDTO.model";
import { UserDiscountRequestSearch, UserRequest, UserRequestSearch } from "../models/UserRequest.Model";
import { UserInfoDto } from "../models/UserInfoDto.Model";
import { UserFavProductRequest } from "../models/UserFavProductRequest";
import { PatchDto } from "../models/PatchDto.Model";
import { ProductPatchModel } from "../models/ProductPatch.model";
import { MobileUserDto } from "../models/MobileUserDto";
import { UserCategoryPagesRequest } from "../models/UserCategoryPagesRequest";
import { UserCategoryPagesDto } from "../models/UserCategoryPages";
import { UserDto } from "../models/UserDto";
import { ClinetsSalesViewModel } from "../models/ClinetsSalesViewModel";
import { ClientsSalesViewRequest } from "../models/ClientsSalesViewRequest";
import { OrdersConfigurationsDto } from '../models/OrdersConfigurationsDto';
import { ClientDetailsDto as prod } from '../models/ClientDetailsDto';
import { ProductDetailsDto } from '../models/ProductDetailsDto';
import { MobileSearchKeywordDto } from '../models/MobileSearchKeywordDto';
import { MobileSearchKeywordRequest } from '../models/MobileSearchKeywordRequest';
import { ProductSupplierRequest } from '../models/ProductSupplierRequest';
import { GenerateVoucherRequest } from '../models/Discount/GenerateVoucherRequest.Model';
import { DiscountRequestPoral } from '../models/Discount/DiscountRequestPoral';
import { DiscountResponse } from '../models/Discount/DiscountResponse';
import { GeneralDiscount } from '../models/Discount/GeneralDiscount';
import { QuantityDiscountRequestPortal } from '../models/Discount/QuantityDiscountRequestPortal';
import { QuantityDiscountResponse } from '../models/Discount/QuantityDiscountResponse';
import { ProductDTOExcel, ProductExcelModel } from '../models/ProductExcelModel';

@Injectable({
  providedIn: "root",
})
export class QueryService {
  constructor(private _http: http.HttpClient) { }

  imageExist(imagePath: string): Observable<string> {
    return this._http.get(imagePath)
      .pipe(
        map(response => {
          return imagePath;
        }),
        catchError(error => {
          return of('assets/images/default.png');
        })
      );
  }


  getClientDetails(
    filter: ClientDetailsRequest
  ): Observable<GlobalResponse<prod>> {
    return this._http
      .post<GlobalResponse<prod>>(
        environment.webApiEndPoint +
        configration.urls.apiGetClientDetails,
        filter
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }

  getProductDetails(
    filter: ProductDetailsRequest
  ): Observable<GlobalResponse<ProductDetailsDto>> {
    return this._http
      .post<GlobalResponse<ProductDetailsDto>>(
        environment.webApiEndPoint +
        configration.urls.apiGetProductDetails,
        filter
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }

  getProductCategory(
    filter: ProductCategoryRequest
  ): Observable<GlobalResponse<SearchResult<ProductCategoryModel>>> {
    return this._http
      .post<GlobalResponse<SearchResult<ProductCategoryModel>>>(environment.webApiEndPoint + configration.urls.apiSearchProductCategoryURL, filter)
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }

  getMobileSearchKeyword(
    filter: MobileSearchKeywordRequest
  ): Observable<GlobalResponse<SearchResult<MobileSearchKeywordDto>>> {
    return this._http
      .post<GlobalResponse<SearchResult<MobileSearchKeywordDto>>>(
        environment.webApiEndPoint +
        configration.urls.apiGetMobileSearchKeyword,
        filter
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }

  searchOrdersConfigurations(
    filter: OrdersConfigurationsRequest
  ): Observable<GlobalResponse<SearchResult<OrdersConfigurationsDto>>> {
    return this._http
      .post<GlobalResponse<SearchResult<OrdersConfigurationsDto>>>(
        environment.webApiEndPoint +
        configration.urls.apiSearchOrdersConfigurations,
        filter
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }

  getProductsAdmin(
    filter: ProductRequest
  ): Observable<GlobalResponse<SearchResult<ProductModel>>> {
    return this._http
      .post<GlobalResponse<SearchResult<ProductModel>>>(environment.webApiEndPoint + configration.urls.apiSearchProductsURL, filter)
      .pipe(catchError((err) => of(this.errorResponse(err))));
  } apiSearchProductsExcelURL

  getProductsExcelAdmin(
    filter: ProductRequest
  ): Observable<GlobalResponse<SearchResult<ProductDTOExcel>>> {
    return this._http
      .post<GlobalResponse<SearchResult<ProductDTOExcel>>>(environment.webApiEndPoint + configration.urls.apiSearchProductsExcelURL, filter)
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }

  getProductsUser(
    filter: ProductRequest
  ): Observable<GlobalResponse<SearchResult<ProductPatchModel>>> {
    return this._http
      .post<GlobalResponse<SearchResult<ProductPatchModel>>>(
        environment.webApiEndPoint +
        configration.urls.apiSearchProductPatchesURL,
        filter
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }

  getProductPatchs(
    filter: ProductPatchRequest
  ): Observable<GlobalResponse<SearchResult<PatchDto>>> {
    return this._http
      .post<GlobalResponse<SearchResult<PatchDto>>>(
        environment.webApiEndPoint + configration.urls.apiSearchPatchesURL,
        filter
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }
  getMyFav(
    filter: UserFavProductRequest
  ): Observable<GlobalResponse<SearchResult<ProductModel>>> {
    return this._http
      .post<GlobalResponse<SearchResult<ProductModel>>>(
        environment.webApiEndPoint +
        configration.urls.SearchUserFavProductsURL,
        filter
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }

  searchProductBrand(
    filter: ProductBrandRequest
  ): Observable<GlobalResponse<SearchResult<ProductBrandDTO>>> {
    return this._http
      .post<GlobalResponse<SearchResult<ProductBrandDTO>>>(
        environment.webApiEndPoint +
        configration.urls.apiSearchProductBrandURL,
        filter
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }
  SearchProductType(
    filter: ProductTypeRequest
  ): Observable<GlobalResponse<SearchResult<ProductTypeDTO>>> {
    return this._http
      .post<GlobalResponse<SearchResult<ProductTypeDTO>>>(
        environment.webApiEndPoint + configration.urls.apiSearchProductTypeURL,
        filter
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }
  SearchProductColor(
    filter: ProductColorRequest
  ): Observable<GlobalResponse<SearchResult<ProductColorDTO>>> {
    return this._http
      .post<GlobalResponse<SearchResult<ProductColorDTO>>>(
        environment.webApiEndPoint +
        configration.urls.apiSearchProductColorURL,
        filter
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }

  getAllUserAddress(
    request: UserAddressRequest
  ): Observable<GlobalResponse<UserAddressDto[]>> {
    return this._http
      .post<GlobalResponse<UserAddressDto[]>>(
        environment.webApiEndPoint + configration.urls.apiAllUserAddrsessURL,
        request
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }

  getSearchOrderList(filter: OrderRequest): Observable<GlobalResponse<SearchResult<OrderModel>>> {
    return this._http.post<GlobalResponse<SearchResult<OrderModel>>>(environment.webApiEndPoint + configration.urls.apiSearchOrderListURL, filter)
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }

  getSearchOrder(
    filter: OrderRequest
  ): Observable<GlobalResponse<OrderModel>> {
    return this._http
      .post<GlobalResponse<OrderModel>>(environment.webApiEndPoint + configration.urls.apiSearchOrderURL, filter)
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }

  getUpdateOrderStatus(filter: OrderRequest): Observable<GlobalResponse<OrderModel>> {
    return this._http.post<GlobalResponse<OrderModel>>(environment.webApiEndPoint + configration.urls.apiUpdateOrderStatusURL, filter)
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }

  getAllProductCategory(
    filter: LookupsRequest
  ): Observable<GlobalResponse<LookupsModel[]>> {
    return this._http
      .post<GlobalResponse<LookupsModel[]>>(
        environment.webApiEndPoint +
        configration.urls.Lookups.apiGetAllProductCategoryURL,
        filter
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }

  getAllSuppliers(
    filter: LookupsRequest
  ): Observable<GlobalResponse<LookupsModel[]>> {
    return this._http
      .post<GlobalResponse<LookupsModel[]>>(
        environment.webApiEndPoint +
        configration.urls.Lookups.apiGetAllSuppliersURL,
        filter
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }

  getAllUserCategoriesList(): Observable<GlobalResponse<LookupsModel[]>> {
    return this._http.get<GlobalResponse<LookupsModel[]>>
      (environment.webApiEndPoint + configration.urls.Lookups.apiGetAllGetAllUserCategoriesURL)
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }

  getAllUserCategoryPagesList(filter: UserCategoryPagesRequest): Observable<GlobalResponse<UserCategoryPagesDto[]>> {
    return this._http.post<GlobalResponse<UserCategoryPagesDto[]>>
      (environment.webApiEndPoint + configration.urls.Lookups.apiGetAllGetAllUserCategoryPagesURL, filter)
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }

  getAllColorList(): Observable<GlobalResponse<ColorLookupsModel[]>> {
    return this._http
      .get<GlobalResponse<ColorLookupsModel[]>>(
        environment.webApiEndPoint +
        configration.urls.Lookups.apiGetAllColorListURL
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }
  getAllOrderStatus(): Observable<GlobalResponse<LookupsModel[]>> {
    return this._http
      .get<GlobalResponse<LookupsModel[]>>(
        environment.webApiEndPoint +
        configration.urls.Lookups.apiGetAllOrderStatusURL
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }

  getAllProductBrand(
    filter: LookupsRequest
  ): Observable<GlobalResponse<LookupsModel[]>> {
    return this._http
      .post<GlobalResponse<LookupsModel[]>>(
        environment.webApiEndPoint +
        configration.urls.Lookups.apiGetAllProductBrandURL,
        filter
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }

  getAllProductType(
    filter: LookupsRequest
  ): Observable<GlobalResponse<LookupsModel[]>> {
    return this._http
      .post<GlobalResponse<LookupsModel[]>>(
        environment.webApiEndPoint +
        configration.urls.Lookups.apiGetAllProductTypeURL,
        filter
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }

  getAllSalesPersonList(
    request: SalesPersonRequest
  ): Observable<GlobalResponse<SearchResult<SalesPersonDto>>> {
    return this._http
      .post<GlobalResponse<SearchResult<SalesPersonDto>>>(
        environment.webApiEndPoint +
        configration.urls.apiGetAllSalesPersonListURL,
        request
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }

  getMobileUserList( request: SalesPersonRequest ): Observable<GlobalResponse<SearchResult<SalesPersonDto>>> {
    return this._http.post<GlobalResponse<SearchResult<SalesPersonDto>>>(  environment.webApiEndPoint + configration.urls.apiGetMobileUsersURL,  request)
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }

  getExcelMobileUser(request: SalesPersonRequest) {

    return this._http.post(environment.webApiEndPoint + configration.urls.apiExcelMobileUsersURL, request, {
      responseType: "arraybuffer" as "json",
      observe: "response" as "body"
    })
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }


  getMobileUserListNotActive( request: SalesPersonRequest): Observable<GlobalResponse<SearchResult<SalesPersonDto>>> {
    return this._http
      .post<GlobalResponse<SearchResult<SalesPersonDto>>>(
        environment.webApiEndPoint + configration.urls.apiGetMobileUsersNotActiveURL,
        request
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }

  getSalesPersonById(
    request: SalesPersonRequest
  ): Observable<GlobalResponse<SalesPersonDto>> {
    return this._http
      .post<GlobalResponse<SalesPersonDto>>(
        environment.webApiEndPoint +
        configration.urls.apiGetSalesPersonByIdURL,
        request
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }
  getMogileUserById(
    request: SalesPersonRequest
  ): Observable<GlobalResponse<MobileUserDto>> {
    return this._http
      .post<GlobalResponse<MobileUserDto>>(
        environment.webApiEndPoint + configration.urls.apiGetMobileUserByIdURL,
        request
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }

  getUserById(
    request: UserRequest
  ): Observable<GlobalResponse<UserDto>> {
    return this._http
      .post<GlobalResponse<UserDto>>(
        environment.webApiEndPoint + configration.urls.apiGetUserByIdURL,
        request
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }

  getUserInfoById(
    request: UserRequest
  ): Observable<GlobalResponse<UserInfoDto>> {
    return this._http
      .post<GlobalResponse<UserInfoDto>>(
        environment.webApiEndPoint + configration.urls.apiGetUserInfoByIdURL,
        request
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }
  getAllProductColor(
    filter: LookupsRequest
  ): Observable<GlobalResponse<LookupsModel[]>> {
    return this._http
      .post<GlobalResponse<LookupsModel[]>>(
        environment.webApiEndPoint +
        configration.urls.Lookups.apiGetAllProductColorURL,
        filter
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }

  getAllZones(): Observable<GlobalResponse<ZoneDto[]>> {
    return this._http
      .get<GlobalResponse<ZoneDto[]>>(
        environment.webApiEndPoint +
        configration.urls.Lookups.apiGetAllZonesURL
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }
  getAllCities(): Observable<GlobalResponse<LookupsModel[]>> {
    return this._http
      .get<GlobalResponse<LookupsModel[]>>(
        environment.webApiEndPoint +
        configration.urls.Lookups.apiGetAllCitiesURL
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }

  getAllCityZones(): Observable<GlobalResponse<CityZoneDto[]>> {
    return this._http
      .get<GlobalResponse<CityZoneDto[]>>(
        environment.webApiEndPoint +
        configration.urls.Lookups.apiGetAllCityZoneURL
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }
  getUserVoutcherByLogId(
    request: VoucherRequest
  ): Observable<GlobalResponse<VoucherDto[]>> {
    return this._http
      .post<GlobalResponse<VoucherDto[]>>(
        environment.webApiEndPoint +
        configration.urls.apiGetUserVoutcherByLogIdURL,
        request
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }
  QueryVoutcher(
    request: VoucherRequest
  ): Observable<GlobalResponse<SearchResult<VoucherLogDto>>> {
    return this._http
      .post<GlobalResponse<SearchResult<VoucherLogDto>>>(
        environment.webApiEndPoint + configration.urls.apiQueryVoutcherURL,
        request
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }
  GenerateUserVouchers(request: GenerateVoucherRequest): Observable<GlobalResponse<boolean>> {
    return this._http
      .post<GlobalResponse<boolean>>(
        environment.webApiEndPoint +
        configration.urls.apiGenerateUserVouchersURL,
        request
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }
  getUserAddressById(
    request: UserAddressRequest
  ): Observable<GlobalResponse<UserAddressDto>> {
    return this._http
      .post<GlobalResponse<UserAddressDto>>(
        environment.webApiEndPoint + configration.urls.apiUserAddrsessByIdURL,
        request
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }

  getUserAddressByUserId(
    request: UserAddressRequest
  ): Observable<GlobalResponse<UserAddressDto>> {
    return this._http
      .post<GlobalResponse<UserAddressDto>>(
        environment.webApiEndPoint + configration.urls.apiUserAddrsessByUserIdURL,
        request
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }

  findOpenCart(request: OrderRequest): Observable<GlobalResponse<OrderModel>> {
    return this._http
      .post<GlobalResponse<OrderModel>>(
        environment.webApiEndPoint + configration.urls.apiFindOpenCart,
        request
      )
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }
  getAllProductsPatches(filter: ProductRequest): Observable<GlobalResponse<SearchResult<ProductPatchModel>>> {
    return this._http.post<GlobalResponse<SearchResult<ProductPatchModel>>>(
      environment.webApiEndPoint + configration.urls.apiSearchAllProductPatchesURL, filter)
      .pipe(catchError((err) => of(this.errorResponse(err))));
  }

  // findOpenCartNew(request: OrderRequestNew): Observable<GlobalResponse<OrderModelNew>> {
  //   return this._http
  //     .post<GlobalResponse<OrderModelNew>>(
  //       environment.webApiEndPoint + configration.urls.apiFindOpenCart,
  //       request
  //     )
  //     .pipe(catchError((err) => of(this.errorResponse(err))));
  // }

  searchSuppliers = (request: ProductSupplierRequest): Observable<GlobalResponse<SearchResult<ProductSupplierDto>>> => this._http
    .post<GlobalResponse<SearchResult<ProductSupplierDto>>>(environment.webApiEndPoint + configration.urls.apiGetProductSuppliers, request)
    .pipe(catchError((err) => of(this.errorResponse(err))));


  GetProducts = (request: ProductRequest): Observable<GlobalResponse<SearchResult<ProductModel>>> => this._http
    .post<GlobalResponse<SearchResult<ProductModel>>>(environment.webApiEndPoint + configration.urls.apiGetProducts, request)
    .pipe(catchError((err) => of(this.errorResponse(err))));

  GetProductsDiscountByIds = (request: ProductDiscountRequest): Observable<GlobalResponse<SearchResult<ProductModel>>> => this._http
    .post<GlobalResponse<SearchResult<ProductModel>>>(environment.webApiEndPoint + configration.urls.apiGetProducts, request)
    .pipe(catchError((err) => of(this.errorResponse(err))));

  GetUsers = (request: UserRequestSearch): Observable<GlobalResponse<SearchResult<UserInfoDto>>> => this._http
    .post<GlobalResponse<SearchResult<UserInfoDto>>>(environment.webApiEndPoint + configration.urls.apiGetUsers, request)
    .pipe(catchError((err) => of(this.errorResponse(err))));

  GetUsersDiscountByIds = (request: UserDiscountRequestSearch): Observable<GlobalResponse<SearchResult<UserInfoDto>>> => this._http
    .post<GlobalResponse<SearchResult<UserInfoDto>>>(environment.webApiEndPoint + configration.urls.apiGetUsers, request)
    .pipe(catchError((err) => of(this.errorResponse(err))));

  GetAllDiscount = (request: DiscountRequestPoral): Observable<GlobalResponse<SearchResult<DiscountResponse>>> => this._http
    .post<GlobalResponse<SearchResult<DiscountResponse>>>(environment.webApiEndPoint + configration.urls.apiGetAllDiscount, request)
    .pipe(catchError((err) => of(this.errorResponse(err))));

  GetAllProductDiscount = (request: DiscountRequestPoral): Observable<GlobalResponse<SearchResult<DiscountResponse>>> => this._http
    .post<GlobalResponse<SearchResult<DiscountResponse>>>(environment.webApiEndPoint + configration.urls.apiGetAllProductDiscount, request)
    .pipe(catchError((err) => of(this.errorResponse(err))));

  GetAllCustomerDiscount = (request: DiscountRequestPoral): Observable<GlobalResponse<SearchResult<DiscountResponse>>> => this._http
    .post<GlobalResponse<SearchResult<DiscountResponse>>>(environment.webApiEndPoint + configration.urls.apiGetAllCustomerDiscount, request)
    .pipe(catchError((err) => of(this.errorResponse(err))));

  GetDiscountBySearch = (request: GeneralDiscount): Observable<GlobalResponse<SearchResult<DiscountResponse>>> => this._http
    .post<GlobalResponse<SearchResult<DiscountResponse>>>(environment.webApiEndPoint + configration.urls.apiGetDiscountBySearch, request)
    .pipe(catchError((err) => of(this.errorResponse(err))));

  GetDiscountByID = (request: GeneralDiscount): Observable<GlobalResponse<DiscountResponse>> => this._http
    .post<GlobalResponse<DiscountResponse>>(environment.webApiEndPoint + configration.urls.apiGetDiscountByID, request)
    .pipe(catchError((err) => of(this.errorResponse(err))));

  GetDiscountVoucherByID = (request: GeneralDiscount): Observable<GlobalResponse<DiscountResponse>> => this._http
    .post<GlobalResponse<DiscountResponse>>(environment.webApiEndPoint + configration.urls.apiGetDiscountVoucherByID, request)
    .pipe(catchError((err) => of(this.errorResponse(err))));

  GetAllDiscountVoucher = (request: DiscountRequestPoral): Observable<GlobalResponse<SearchResult<DiscountResponse>>> => this._http
    .post<GlobalResponse<SearchResult<DiscountResponse>>>(environment.webApiEndPoint + configration.urls.apiGetAllDiscountVoucher, request)
    .pipe(catchError((err) => of(this.errorResponse(err))));


  GetAllQuantityPatchPrice = (request: QuantityDiscountRequestPortal): Observable<GlobalResponse<SearchResult<QuantityDiscountResponse>>> => this._http
    .post<GlobalResponse<SearchResult<QuantityDiscountResponse>>>(environment.webApiEndPoint + configration.urls.apiGetAllQuantityPatchPrice, request)
    .pipe(catchError((err) => of(this.errorResponse(err))));

  GetQuantityDiscountBySearch = (request: QuantityDiscountRequestPortal): Observable<GlobalResponse<SearchResult<QuantityDiscountResponse>>> => this._http
    .post<GlobalResponse<SearchResult<QuantityDiscountResponse>>>(environment.webApiEndPoint + configration.urls.apiGetQuantityDiscountBySearch, request)
    .pipe(catchError((err) => of(this.errorResponse(err))));

  GetAllUserNotes = (request: GetAllUserNotes): Observable<GlobalResponse<SearchResult<UserNotesResponse>>> => this._http
    .post<GlobalResponse<SearchResult<UserNotesResponse>>>(environment.webApiEndPoint + configration.urls.apiGetAllUserNotes, request)
    .pipe(catchError((err) => of(this.errorResponse(err))));

  GetUserNotesBySearch = (request: GetUserNoteById): Observable<GlobalResponse<SearchResult<UserNotesResponse>>> => this._http
    .post<GlobalResponse<SearchResult<UserNotesResponse>>>(environment.webApiEndPoint + configration.urls.apiGetUserNotesBySearch, request)
    .pipe(catchError((err) => of(this.errorResponse(err))));

  errorResponse(err) {
    var response = new GlobalResponse<any>();
    response.Result = err;
    response.ErrorMessage = err.status === 0 || err.status === 504 ? "" : err.message;
    response.HasError = true;
    return response;
  }
}
