import { environment } from './../../../../environments/environment.prod';
import { Component, OnInit } from "@angular/core";
import { QueryService } from "src/app/services/query.services";
import { TranslateService } from "@ngx-translate/core";
import { Title } from "@angular/platform-browser";
import { SearchResult } from "src/app/models/SearchResult.Model";
import { ProductCategoryRequest } from "src/app/models/ProductCategoryRequest.Model";
import { ProductCategoryModel } from "src/app/models/product-category.model";
import { configration } from "src/app/shared/config";
import { PageChangedEvent } from "ngx-bootstrap/pagination";
import { AddEditProductCategoryRequest } from "src/app/models/AddEditProductCategoryRequest.model";
import { CommandService } from "src/app/services/command.service";

@Component({
  selector: "app-category-admin-list",
  templateUrl: "./category-admin-list.component.html",
  styleUrls: ["./category-admin-list.component.scss"],
})
export class CategoryAdminListComponent implements OnInit {
  _AddEditProductCategoryRequst: AddEditProductCategoryRequest = new AddEditProductCategoryRequest();

  productCategories: SearchResult<ProductCategoryModel> = new SearchResult<
    ProductCategoryModel
  >();
  ProductCategoryRequest: ProductCategoryRequest;
  IsArabic = true;
  searchName = "";
  showListLoading = false;
  errMsg = "";
  pathOfAPI: string;
  title: string;

  constructor(
    private commandService: CommandService,
    private queryService: QueryService,
    private translateService: TranslateService,
    private titleService: Title
  ) { }

  ngOnInit() {
    this.pathOfAPI = environment.webApiEndPoint;
    this.setDefaultSearchValues();

    this.loadAfterLang(this.translateService.currentLang);
    this.translateService.onLangChange.subscribe((res) => this.loadAfterLang(res.lang));
  }

  loadAfterLang(lang: string) {
    this.IsArabic = lang === "ar";
    this.title = this.translateService.instant("ProductCategory");
    this.titleService.setTitle(this.title);
    this.searchCaller();
  }

  Delete(item: ProductCategoryModel) {
    var check = confirm(this.translateService.instant("AreYouSureDeleteCategory"));
    if (check) {
      try {
        this.errMsg = "";
        this.showListLoading = true;
        this._AddEditProductCategoryRequst.ProductCategoryDTO = item;
        this._AddEditProductCategoryRequst.ProductCategoryDTO.IsDeleted = true;
        this.commandService
          .AddEditProductCategory(this._AddEditProductCategoryRequst)
          .subscribe(
            (res) => {
              this.showListLoading = false;
              if (res.HasError) {
                if (res.ErrorMessage == "NotDeleted") {
                  var check = confirm( this.translateService.instant("DeleteCategory"));
                  return;
                }
                this.errMsg = res.ErrorMessage;
                return;
              }

              this.searchCaller();
            },
            (err) => {
              this.errMsg = err;
              this.showListLoading = false;
            }
          );
      } catch (err) {
        this.errMsg = err;
        this.showListLoading = false;
      }
    }
  }

  //#region "table Pagination and Search"
  setDefaultSearchValues =()=> this.ProductCategoryRequest = new ProductCategoryRequest();
  

  onSearchSubmit() {
    this.ProductCategoryRequest.PageIndex = 1;
    this.searchCaller();
  }

  onPagingChange(event: PageChangedEvent) {
    this.ProductCategoryRequest.PageIndex = event.page;
    this.ProductCategoryRequest.PageSize = event.itemsPerPage;
    this.searchCaller();
  }

  searchCaller() {
    try {
      this.errMsg = "";
      this.showListLoading = true;

      // if (this.IsArabic) {
      //   this.ProductCategoryRequest.NameAR = this.searchName;
      //   this.ProductCategoryRequest.NameEN = '';
      // } else {
      //   this.ProductCategoryRequest.NameEN = this.searchName;
      //   this.ProductCategoryRequest.NameAR = '';
      // }
      this.ProductCategoryRequest.Lang = this.translateService.currentLang;
      this.queryService
        .getProductCategory(this.ProductCategoryRequest)
        .subscribe(
          (res) => {
            if (res.HasError == false && res.Result != null) {
              this.productCategories = res.Result;
            } else {
              this.productCategories.ItemList = [];
              this.productCategories.Total = 0;
              this.errMsg = res.ErrorMessage;
            }
            this.showListLoading = false;
          },
          (err) => {
            this.errMsg = err;
            this.showListLoading = false;
          }
        );
    } catch (err) {
      this.errMsg = err;
      this.showListLoading = false;
    }
  }

  clearSearch() {
    this.setDefaultSearchValues();
    this.searchCaller();
  }

  //#endregion "table Pagination and Search"

  getImageFullPath=(imageExist: boolean, imageName: string, lastModifiedTime: Date): string =>imageExist?
  `${environment.webApiEndPoint}ProductCategory/${imageName}?date=${lastModifiedTime}`: 'assets/images/default.png'
  

  onChanges = (data: any) => {
    this.ProductCategoryRequest.IsRetail = data
    this.searchCaller();

  }
}
