import { configration } from "../shared/config";

export class ProductBrandRequest {
  NameAR: string;
  NameEN: string;
  ID: number;
  PageIndex: number = 1;
  PageSize: number = configration.pageSize;
  Lang: string;
  SearchText: string;
  BrandsIDs: number[];
  SortColumn?: string;
  SortDirection?: number;
  ProductCategoryId: number;
  IsRetail?: number;

}
