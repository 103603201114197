<div class="d-flex w-100 flex-column bd-highlight h-100 p-2">
    <div class="bd-highlight py-2">
        <div class="row">
            <div class="col-md-4">
                <div class="bd-highlight">
                    <h4>{{ title }}</h4>
                </div>
            </div>
            <div class="col-md-2 offset-md-6">
                <button class="btn d-flex btn-primary ml-auto bg-red bg-blue border-0" routerLink="/adminhome/categoryaddedit/0">
          {{ "AddProductCategory" | translate }}
        </button>
            </div>
        </div>
    </div>

    <div class="bd-highlight">
        <!-- #region Search  -->
        <!-- #region Search Criteria -->
        <div class="row mt-2 w-100">
            <div class="col-lg-8 col-sm-6">
                <div class="row">
                    <label class="col-3 p-0 d-flex align-self-center">
            {{ "ProductCategory" | translate }}
          </label>
                    <input class="form-control mr-2 col-4 rounded" placeholder="{{ 'SearchByArabic' | translate }}" [(ngModel)]="ProductCategoryRequest.NameAR" />
                    <input class="form-control ml-2 col-4 rounded" placeholder="{{ 'SearchByEnglish' | translate }}" [(ngModel)]="ProductCategoryRequest.NameEN" />
                </div>
                <div class="row m-2">
                    <div class="col-2  ">
                        <label for="isRetail">{{ "IsRetail"| translate}}</label>
                    </div>
                    <div class="col-2 ">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" #IsRetailInput (ngModelChange)="onChanges($event)" name="isRetail" id="Salon" value="1" [(ngModel)]="ProductCategoryRequest.IsRetail" checked>
                            <label class="form-check-label" for="salon">{{"Shine" | translate}}</label>
                        </div>
                    </div>
                    <div class="col-2 ">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" #IsRetailInput (ngModelChange)="onChanges($event)" name="isRetail" id="Retail" value="2" [(ngModel)]="ProductCategoryRequest.IsRetail">
                            <label class="form-check-label" for="retail">{{"2assar" | translate}}</label>
                        </div>
                    </div>
                    <div class="col-2 ">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" #IsRetailInput (ngModelChange)="onChanges($event)" name="isRetail" id="Retail" value="3" [(ngModel)]="ProductCategoryRequest.IsRetail">
                            <label class="form-check-label" for="retail">{{"BothAppType" | translate}}</label>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-lg-4 col-sm-6 d-flex justify-content-end">
                <div class="bd-highlight align-self-center">
                    <button class="btn btn-primary mr-1 ml-1 bg-red border-0" (click)="onSearchSubmit()">
            {{ "Search" | translate }}
          </button>
                </div>
            </div>
        </div>
        <!--#endregion Search Criteria -->

        <!--#endregion  Search -->
    </div>
    <div class="h-100 overflow-auto bd-highlight">
        <!-- #region table -->
        <div class="row mt-2">
            <!-- start this to make loading center in page on any screen size -->
            <div *ngIf="showListLoading" class="d-flex flex-column z-index-3 position-fixed w-100 h-100 justify-content-center align-items-center bg-white" style="opacity: 0.5;">

                <div class="p-2 align-self-center">
                    <span class="load-more-List text-center vw-100 vh-100">
            <span class="spinner-border text-primary" role="status"> </span>
                    </span>
                </div>
            </div>
            <div class="row w-100">
                <!-- header -->
                <!-- <div class="d-flex flex-row bg-secondary w-100 text-white mb-1">
          <div class="p-2  col-6"> {{'ProductCategory' | translate}} </div>
          <div class="p-2 col-6"> {{'ProductCategoryImage' | translate}} </div>
        </div> -->
                <div class="row w-100">
                    <div *ngFor="let item of productCategories.ItemList" class="col-3 bg-white overflow-hidden p-1 mb-1 position-relative">
                        <div class="w-100 border m-1 h-100 border-info">
                            <div class="row p-2 w-100">
                                <div class="col-9">
                                    <a class="text-dark">{{ item.NameAR }}</a>
                                    <br />
                                    <a class="text-dark"> {{ item.NameEN }}</a>
                                    <p class="w-100 text-dark">{{"ID" | translate}}: {{item.ID}}</p>
                                    <p class="w-100 text-blue" *ngIf="item.IsRetail == 1">{{"IsRetail" | translate}}: {{"Shine" | translate}}</p>
                                    <p class="w-100 text-info" *ngIf="item.IsRetail == 2">{{"IsRetail" | translate}}: {{"2assar" | translate}}</p>
                                    <p class="w-100 text-secondary" *ngIf="item.IsRetail == 3">{{"IsRetail" | translate}}: {{"BothAppType" | translate}}</p>
                                </div>
                                <div class="col-3">
                                    <a class="mr-2 ml-2" routerLink="/adminhome/categoryaddedit/{{ item.ID }}">
                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                    </a>

                                    <a class="mr-2 ml-2" (click)="Delete(item)">
                                        <i class="fa fa-trash-o" aria-hidden="true"></i>
                                    </a>
                                </div>
                            </div>

                            <div class="p-2 w-100">
                                <img class="img-thumbnail align-self-center border-0 img-fluid" src="{{getImageFullPath(item.ImageExist, item.Image, item.LastModifiedTime)}}" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--#endregion table -->
        <!-- #region Pagination -->
        <div *ngIf="productCategories.ItemList?.length==0" class="d-flex flex-column z-index-3 position-relative w-100 h-100 justify-content-center align-items-center bg-white">
            <label class="la">{{"NoDataFound"|translate}}</label>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-xs-12 col-12 p-0">
            <app-pagination-component [ItemsCount]="productCategories.Total" (changePage)="onPagingChange($event)">
            </app-pagination-component>
        </div>
    </div>
    <!--#endregion Pagination -->
</div>