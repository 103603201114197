import { AppTypeEnum } from './../../../models/enum/AppTypeEnum';
import { tap } from 'rxjs/operators';
import { environment } from './../../../../environments/environment.prod';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { ProductModel } from "src/app/models/Product.Model";
import {
  ProductRequest,
  ProductPatchRequest,
} from "src/app/models/ProductRequest.Model";
import { QueryService } from "src/app/services/query.services";
import { configration } from "src/app/shared/config";
import { PageChangedEvent } from "ngx-bootstrap/pagination/public_api";
import { PatchDto } from "src/app/models/PatchDto.Model";
import { CommunicationService } from "src/app/services/communication.service";
import { ActionType } from "src/app/models/Action";
import { ProductDTOExcel, ProductExcelModel } from "src/app/models/ProductExcelModel";
import * as XLSX from "xlsx";
import { LookupsModel } from "src/app/models/Lookups.model";
import { SearchStream } from "src/app/models/search-stream-object.model";
import { LookupsRequest } from "src/app/models/lookups.Request";
import { fromEvent } from 'rxjs';

@Component({
  selector: "app-inventory",
  templateUrl: "./inventory.component.html",
  styleUrls: ["./inventory.component.scss"],
})
export class InventoryComponent implements OnInit, AfterViewInit {
  IsArabic = true;
  title: string;
  pathOfAPI: string;
  productLst: ProductModel[] = [];
  productRequest: ProductRequest = new ProductRequest();
  SearchName = "";
  errMsg: string = "";
  showLoading: boolean = false;
  Total: number;
  minDate: Date = new Date();
  itemsPerPage: number = 5;
  currentPage: number = 1;
  filterPatch: ProductPatchRequest = new ProductPatchRequest();
  expandSearch: boolean = false;
  sortDir: number;
  pageIndexBrand: number = 0;

  CategoriesList: LookupsModel[];
  productTypes: LookupsModel[];
  BrandsList: LookupsModel[];
  ColorsList: LookupsModel[];
  SuppliersList: LookupsModel[];
  SearchStream: SearchStream = new SearchStream();
  @ViewChild('brandListScroll', { static: false }) brandScroll: ElementRef;

  categoryLoading = false;
  typeLoading = false;
  brandLoading = false;
  colorLoading = false;
  constructor(
    private _communcationService: CommunicationService,
    private translateService: TranslateService,
    private titleService: Title,
    private _queryService: QueryService
  ) { }


  ngOnInit() {
    this.pathOfAPI = environment.webApiEndPoint;
    this.productRequest.PageIndex = 1;
    this.productRequest.PageSize = configration.pageSize;

    this._communcationService.getAction().subscribe((res) => {
      if (res.actionType == ActionType.UpdateProductPatch) {
        this.getProductsEdit(this.productRequest, res.Result.ProductId);
      }
    });

    this.loadAfterLang(this.translateService.currentLang);
    //when language change
    this.translateService.onLangChange.subscribe(res => this.loadAfterLang(res.lang));
  }
  loadAfterLang(lang: string) {
    this.IsArabic = lang === "ar";
    this.title = this.translateService.instant("Inventory");
    this.titleService.setTitle(this.title);
    this.searchProduct();
    this.getCategories();
    this.getProductType();
    this.getBrands();
    this.getColors();
    this.getSuppliers();
  }

  searchProduct() {
    this.productRequest.Lang = this.translateService.currentLang;
    this.getProducts(this.productRequest);
  }

  //check on scroll event happen, to fetch more brand and concat w/ original
  ngAfterViewInit() {
    fromEvent(this.brandScroll.nativeElement, 'scroll')
      .pipe(tap(x => console.log(x)))
      .subscribe(() => this.fetchMoreBrand());
  }

  getCategories(searchKeyword: string = "") {
    this.SearchStream.initStream("CategoryDDL_ProductList", (a) => {
      this.categoryLoading = true;
      let search = new LookupsRequest();
      search.Filter = a;
      search.pageIndex = 1;
      search.pageSize = 1000;
      search.Lang = this.translateService.currentLang;

      this._queryService.getAllProductCategory(search).subscribe(
        (res) => {
          if (res.Result) this.CategoriesList = res.Result;
        },
        (err) => { },
        () => {
          this.categoryLoading = false;
        }
      );
    }).next(searchKeyword);
  }

  getProductType(searchKeyword: string = "") {
    this.SearchStream.initStream("TypeDDL_ProductList", (a) => {
      this.typeLoading = true;
      let search = new LookupsRequest();
      search.Filter = a;
      search.pageIndex = 1;
      search.pageSize = 1000;
      search.Lang = this.translateService.currentLang;

      this._queryService.getAllProductType(search).subscribe(
        (res) => {
          if (res.Result) this.productTypes = res.Result;
        },
        (err) => { },
        () => this.typeLoading = false
      );
    }).next(searchKeyword);
  }

  fetchMoreBrand() {
    let search = new LookupsRequest();
    this.pageIndexBrand++;
    search.pageIndex = this.pageIndexBrand;
    search.Lang = this.translateService.currentLang;
    let BrandsList = this.BrandsList;
    this.BrandsList = BrandsList.concat(this.getBrandList(search));
  }

  getBrandList(request: LookupsRequest) {
    this.brandLoading = true;
    this._queryService.getAllProductBrand(request).subscribe(res => {
      if (res.Result)
        this.BrandsList = res.Result;
    }, (err) => this.brandLoading = false,
      () => this.brandLoading = false)
    return this.BrandsList;

  }


  getBrands(searchKeyword: string = "") {
    this.SearchStream.initStream("BrandDDL_ProductList", (a) => {
      this.brandLoading = true;
      let search = new LookupsRequest();
      search.Filter = a;
      search.pageIndex = 1;
      search.pageSize = 1000;
      search.Lang = this.translateService.currentLang;

      this._queryService.getAllProductBrand(search).subscribe(
        (res) => {
          if (res.Result) this.BrandsList = res.Result;
        },
        (err) => { },
        () => {
          this.brandLoading = false;
        }
      );
    }).next(searchKeyword);
  }

  getColors(searchKeyword: string = "") {
    this.SearchStream.initStream("ColorDDL_ProductList", (a) => {
      this.colorLoading = true;
      let search = new LookupsRequest();
      search.Filter = a;
      search.pageIndex = 1;
      search.pageSize = 1000;
      search.Lang = this.translateService.currentLang;

      this._queryService.getAllProductColor(search).subscribe(
        (res) => {
          if (res.Result) this.ColorsList = res.Result;
        },
        (err) => { },
        () => {
          this.colorLoading = false;
        }
      );
    }).next(searchKeyword);
  }

  getSuppliers() {
    let search = new LookupsRequest();
    search.pageIndex = 1;
    search.pageSize = 1000;
    search.Lang = this.translateService.currentLang;
    this._queryService.getAllSuppliers(search).subscribe(res => {
      this.SuppliersList = res.Result;
    });
  }

  pageChanged(event, p: ProductModel) {
    p.PatchPageIndex = event.page;
    this.filterPatch.ProductId = p.ID;
    this.filterPatch.PageIndex = event.page;
    this.filterPatch.PatchExpireDateSearch = p.PatchExpireDateSearch;
    this.getPatchProducts();
  }
  searchPatchProducts(p: ProductModel) {
    this.filterPatch.PageIndex = 1;
    this.filterPatch.ProductId = p.ID;
    p.PatchPageIndex = 1;
    this.filterPatch.PatchExpireDateSearch = p.PatchExpireDateSearch;
    this.getPatchProducts();
  }
  searchFirstPatchProducts(p: ProductModel) {
    p.Expanded = !p.Expanded;
    this.filterPatch.PageIndex = 1;
    this.filterPatch.ProductId = p.ID;
    p.PatchPageIndex = 1;
    this.filterPatch.PatchExpireDateSearch = p.PatchExpireDateSearch;
    this.getPatchProducts();
  }

  getPatchProducts() {
    try {
      this.errMsg = "";
      this.showLoading = true;
      this._queryService.getProductPatchs(this.filterPatch).subscribe(
        (res) => {
          this.showLoading = false;
          if (res.HasError) {
            this.errMsg = res.ErrorMessage;
            this.showLoading = false;
            return;
          }
          res.Result.ItemList.forEach(res => res.ExpireDate = new Date(res.ExpireDate));
          this.productLst.find(a => a.ID == this.filterPatch.ProductId).PatchLst = res.Result.ItemList;
          this.productLst.find(a => a.ID == this.filterPatch.ProductId).PatchCount = res.Result.Total;
        },
        (err) => {
          this.errMsg = err;
          this.showLoading = false;
        }
      );
    } catch (err) {
      this.errMsg = err;
      this.showLoading = false;
    }
  }

  getProducts(filter: ProductRequest) {
    try {
      this.errMsg = "";
      this.showLoading = true;
      this._queryService.getProductsAdmin(filter).subscribe(
        (res) => {
          this.showLoading = false;
          if (res.HasError) {
            this.errMsg = res.ErrorMessage;
            this.showLoading = false;
            return;
          }

          this.productLst = res.Result.ItemList;
          this.Total = res.Result.Total;
        },
        (err) => {
          this.errMsg = err;
          this.showLoading = false;
        }
      );
    } catch (err) {
      this.errMsg = err;
      this.showLoading = false;
    }
  }

  getProductsEdit(filter: ProductRequest, productId) {
    try {
      this.errMsg = "";
      this.showLoading = true;
      this._queryService.getProductsAdmin(filter).subscribe(
        (res) => {
          this.showLoading = false;
          if (res.HasError) {
            this.errMsg = res.ErrorMessage;
            this.showLoading = false;
            return;
          }

          this.productLst = res.Result.ItemList;
          this.searchFirstPatchProducts(this.productLst.find(a => a.ID == productId));

          this.Total = res.Result.Total;
        },
        (err) => {
          this.errMsg = err;
          this.showLoading = false;
        }
      );
    } catch (err) {
      this.errMsg = err;
      this.showLoading = false;
    }
  }

  keyDownFunction(event) {
    if (event.keyCode == 13) {
      this.searchProduct();
    }
  }

  AddNewPatch(product: ProductModel) {

    let productPatch: PatchDto = new PatchDto();
    productPatch.ProductId = product.ID;
    productPatch.IsRetail = product.IsRetail;
    product.PatchLst.push(productPatch);
  }

  onPagingChange(event: PageChangedEvent) {
    this.productRequest.PageIndex = event.page;
    this.productRequest.PageSize = event.itemsPerPage;
    this.searchProduct();
  }

  getImageFullPath(imageExist: boolean, imageName: string, lastModifiedTime: Date): string {
    if (imageExist) {
      return `${environment.webApiEndPoint}Product/${imageName}?date=${lastModifiedTime}`
    }
    return 'assets/images/default.jpg';
  }

  onSortClick(event: any, colName: string) {

    let target = event.currentTarget,
      classList = target.classList;

    /* sort DESC */
    if (classList.contains('fa-chevron-up')) {
      classList.remove('fa-chevron-up');
      classList.add('fa-chevron-down');
      this.sortDir = 0;

      /* sort ASC */
    } else {
      classList.add('fa-chevron-up');
      classList.remove('fa-chevron-down');
      this.sortDir = 1;
    }

    this.productRequest.Lang = this.translateService.currentLang;
    this.productRequest.SortColumn = colName;
    this.productRequest.SortDirection = this.sortDir;

    this.getProducts(this.productRequest);


  }


  exportToExcel() {
    try {
      this.errMsg = "";
      this.showLoading = true;
      this.productRequest.IsExcel = true;
      this._queryService
        .getProductsExcelAdmin(this.productRequest)
        .subscribe(
          (res) => {
            if (res.HasError) {
              this.errMsg = res.ErrorMessage;
              this.showLoading = false;
              return;
            }
            let excelDate: ProductExcelModel[] = [];
            res.Result.ItemList.map(item => excelDate.push(this.constructProductExcel(item)));
            
            // res.Result.ItemList.forEach(item => excelDate.push(this.constructProductExcel(item)))
            
            this.exportExcelSheet(excelDate)
          },
          (err) => {
            this.errMsg = err;
            this.showLoading = false;
          }
        );
    } catch (err) {
      this.errMsg = err;
      this.showLoading = false;
    }
  }

  private constructProductExcel(item: ProductDTOExcel): ProductExcelModel {
    let productExcel = new ProductExcelModel();
    productExcel.Product_ID = item.ID;
    productExcel.Product_NameEN = item.NameEN;
    productExcel.Product_NameEN = item.NameAR;
    productExcel.Quantity = item.QuantitySum;
    productExcel.Booked = item.BookedSum;
    productExcel.Balance = item.BalanceSum;
    productExcel.PurchasingPrice = item.PurchasingPrice;
    productExcel.IsAvailable = item.IsAvailable;
    productExcel.ProductType = item.IsRetail == AppTypeEnum.Assar ? "2assar" : item.IsRetail == AppTypeEnum.Shine ? "Shine" : "Both";

    productExcel.Category_NameEN = item.Category.NameEN;
    productExcel.Category_NameAR = item.Category.NameAR;

    productExcel.Brand_NameEN = item.Brand.NameEN;
    productExcel.Brand_NameEN = item.Brand.NameAR;

    productExcel.Type_NameEN = item.Type.NameEN;
    productExcel.Type_NameEN = item.Type.NameAR;

    productExcel.Color_NameEN = item.Color.NameEN;
    productExcel.Color_NameEN = item.Color.NameAR;

    productExcel.Supplier_NameAR = item.Supplier.NameAR;
    productExcel.Supplier_NameEN = item.Supplier.NameEN;

    productExcel.LocationTackingNumber = item.LocationTackingNumber;
    productExcel.InventoryShelfNumber = item.InventoryShelfNumber;
    productExcel.ProductWeights = item.ProductWeights;
    productExcel.ProductLength = item.ProductLength;
    productExcel.ProductWidth = item.ProductWidth;
    productExcel.ProductOriginCountry = item.ProductOriginCountry;

    productExcel.Product_DescriptionEN = item.DescriptionEN;
    productExcel.Product_DescriptionAR = item.DescriptionAR;
    return productExcel;
  }


  exportExcelSheet(result: any) {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(result);
    const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
    // const excelBuffer: any = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    this.showLoading = false;
    this.productRequest.IsExcel = false;
    /* save to file */
    XLSX.writeFile(workbook, "Inventory [" + this.getDateFormated() + "].xlsx");
  }


  getDateFormated(): string {

    let dd = String(new Date().getDate()).padStart(2, '0');
    let mm = String(new Date().getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = new Date().getFullYear();
    let result = dd + '/' + mm + '/' + yyyy;
    return result;
  }

  clearSearchFilter() {
    this.productRequest = new ProductRequest();
  }

 

}
