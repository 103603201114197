<!-- start this to make loading center in page on any screen size -->

<ng-container *ngIf="currentView == components.cart">
    <!-- start this to make loading center in page on any screen size -->
    <div *ngIf="showLoading " class="  d-flex  flex-column  z-index-3  justify-content-center  align-items-center  bg-white" style="opacity: 0.5">
        <div class="p-2 align-self-center">
            <span class="load-more-List text-center vw-100 vh-100">
                <span class="spinner-border text-primary" role="status"> </span>
            </span>
        </div>
    </div>
    <!-- end this to make loading center in page on any screen size -->
    <!-- ********************************************************* -->

    <div class="d-flex flex-column h-100 w-100" *ngIf="!showLoading">
        <!-- start header -->

        <div class="d-flex flex-row hig-48px">

            <!-- buttons - start -->
            <div class="w-100 h-100 p-4px d-flex align-items-center">
                <div (click)="toggelTabs()" [ngClass]="
            (showTab ? '' : ' active') +
            ' d-flex justify-content-center align-items-center rounded p-4px headerBtn'
          ">

                    <div [ngClass]="(showTab ? '' : ' active') + ' fa fa-shopping-cart'"></div>
                    <div [ngClass]="showTab ? '' : 'active'">{{ "Cart" | translate }}</div>
                </div>

                <div class="p-4px"></div>

                <div (click)="toggelTabs()" [ngClass]="
            (showTab ? ' active' : '') + ' d-flex justify-content-center align-items-center rounded p-4px headerBtn'">
                    <div [ngClass]="(showTab ? ' active' : '') + ' fa fa-truck'"></div>
                    <div [ngClass]="showTab ? 'active' : ''">
                        {{ "ChooseAddress" | translate }}
                    </div>
                </div>
            </div>
            <!-- buttons - end -->
        </div>
        <!-- end header -->
        <!-- *********************************************************** -->
        <!-- start order items details -->
        <div class="h-50 overflow-auto" [hidden]="showTab" [class.h-50]="order?.OrderItems?.length != 0">
            <!-- showLoading - start -->
            <div *ngIf="showLoading " class="d-flex flex-column z-index-3 position-fixed w-100 h-100 justify-content-center align-items-center bg-white" style="opacity: 0.5">
                <div class="p-2 align-self-center">
                    <span class="load-more-List text-center vw-100 vh-100">
                        <span class="spinner-border text-primary" role="status"> </span>
                    </span>
                </div>
            </div>
            <!-- showLoading - start -->

            <!-- start cart item -->
            <div [hidden]="showTab" class="row p-1">
                <div *ngFor="let oi of order.OrderItems" class="col-sm-12 col-xs-12 col-md-6 col-lg-6 p-1">
                    <div class="row overflow-hidden mb-2 position-relative product">
                        <!-- product img - start -->
                        <div class="col-4 p-0">
                            <div class="productImgContainer d-flex justify-content-center align-items-center h-100">
                                <!-- this for sale -->
                                <!-- <div class="labels">
                  <div class="bestSaleLabel">
                    best sale
                  </div>
                  <div class="saleLabel">
                    خصم
                    <span>20%</span>
                  </div>
                </div> -->
                                <img class="align-self-center border-0 img-fluid productImg h-100" src="{{ pathOfAPI }}Product/{{ oi.Product.Image }}" onerror="this.src='assets/images/default.jpg'" />
                            </div>
                        </div>
                        <!-- product img - end -->
                        <!-- product details - start -->
                        <div class="col-8 px-1">
                            <div class="row no-gutters">
                                <div class="col-12">
                                    <div class="productDetails">
                                        <!-- Product Name -->
                                        <h6 *ngIf="isArabic" class="productName">
                                            {{ oi.Product?.NameAR }}
                                        </h6>
                                        <h6 *ngIf="isArabic == false" class="productName">
                                            {{ oi.Product?.NameEN }}
                                        </h6>
                                        <!-- as shawky request -->
                                        <!-- <span> Total : {{oi.Total}} </span> -->
                                        <div class="row no-gutters">
                                            <div class="col-4">
                                                <div class="row no-gutters">
                                                    <div class="col-12">
                                                        <!--  Price    -->
                                                        <p class="productPrice">
                                                            {{ oi.Price }} {{ "le" | translate }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-2">
                                                <div class="row no-gutters">
                                                    <div class="col-12 quantity">
                                                        <span class="icon fa fa-plus" *ngIf="isEditable" (click)="incQty(oi)"></span>
                                                        <span class="quantityVal"> {{ oi.Quantity }}</span>
                                                        <span class="icon fa fa-minus" *ngIf="isEditable" (click)="decQty(oi)"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="row">
                                                    <div class="col-12 px-1 d-flex justify-content-end align-items-start">
                                                        <p class="saller">
                                                            {{ "Seller" | translate }}:
                                                            <span *ngIf="isArabic">
                                                                {{ oi.Product.Brand?.NameAR | TrimString }}
                                                            </span>
                                                            <span *ngIf="!isArabic">
                                                                {{ oi.Product.Brand?.NameEN | TrimString }}
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div class="col-12 px-1 d-flex justify-content-end AddedToCartContainer">
                                                        <span (click)="DeleteOrderItem(oi)" class="Dicon">
                                                            <i class="fa fa-trash fa-lg" aria-hidden="true"></i>
                                                        </span>
                                                        <!--

                            <span (click)="addItemToFav(oi.Product)" class="icon">
                              <i *ngIf="oi.Product.AddedToFav" class="fa fa-heart active fa-lg"></i>
                              <i *ngIf="!oi.Product.AddedToFav" class="fa fa-heart-o fa-lg"></i>
                            </span>

-->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <!-- product details - end -->
                    </div>
                </div>
            </div>
            <!-- end cart item -->

            <!--  start no data to show -->
            <div *ngIf="order?.OrderItems?.length == 0" class="p-0 w-100 bd-highlight d-flex flex-column">
                <!-- <span class="text-info">{{'NoProducts' | translate}}</span> -->
                <!-- <img class=" h-100 mx-auto" src="assets/images/emptycart.png"> -->
                <img class="img-fluid mx-auto" src="assets/images/emptycart.jpg" *ngIf="isArabic" />
                <img class="img-fluid mx-auto" src="assets/images/emptycartEn.jpg" *ngIf="!isArabic" />
                <div class="text-center">
                    <!-- <button class="btn btn-primary border-0 w-75 forRout" type="button" (click)="GoToHome()">
      {{ "goToShoppingBtn" | translate }}
    </button> -->
                </div>
            </div>
            <!--  end no data to show -->
            <!-- ***************************************************************** -->
        </div>
        <!-- end order items details -->
        <!-- ***************************************************************** -->
        <!-- start choose adresses -->
        <div class="h-100" [hidden]="!showTab">
            <div *ngIf="showLoading" class="d-flex flex-column z-index-3 position-fixed w-100 h-100 justify-content-center align-items-center bg-white" style="opacity: 0.5">
                <div class="p-2 align-self-center">
                    <span class="load-more-List text-center vw-100 vh-100">
                        <span class="spinner-border text-primary" role="status"> </span>
                    </span>
                </div>
            </div>

            <form #AddEditFrom="ngForm" (ngSubmit)="(AddEditFrom.form.valid)" autocomplete="off">
                <div class="row">
                    <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12">
                        <!-- #region Order Address form -->
                        <input name="ReceiverName" type="text" class="position-relative form-control border-right-0 border-left-0 border-top-0" #ReceiverNames="ngModel" [(ngModel)]="_address.ReceiverName" placeholder="{{ 'ReciverName' | translate }}" required />
                        <div *ngIf="isSubmitted && ReceiverNames.errors?.required" class="text text-danger offset-3">
                            {{ "ReceiverNameisrequired" | translate }}
                        </div>
                        <a (click)="EditCityZone()">
                            <input name="City" readonly required type="text" placeholder="{{ 'CityZone' | translate }}" class="input_style position-relative form-control border-right-0 border-left-0 border-top-0" [(ngModel)]="cityZone" />
                        </a>
                        <div *ngIf="isSubmitted && cityZone == null" class="text text-danger offset-3">
                            {{ "CityZoneisrequired" | translate }}
                        </div>
                        <input name="BuildNumber" onlynumber type="text" class="position-relative form-control border-right-0 border-left-0 border-top-0" [(ngModel)]="_address.BuildNumber" placeholder="{{ 'BuildNumber' | translate }}" />
                        <!--#endregion Order Address form -->
                    </div>
                    <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12">
                        <input name="ReceiverNumber" type="text" class="position-relative form-control border-right-0 border-left-0 border-top-0" [(ngModel)]="_address.ReciverMobile || _address.ReciverMobile" placeholder="{{ 'ReciverMobile' | translate }}" onlynumber #ReciverMobile="ngModel"
                            required />
                        <div *ngIf="isSubmitted && ReciverMobile.errors?.required" class="text text-danger offset-3">
                            {{ "ReciverMobileisrequired" | translate }}
                        </div>

                        <input name="Description" type="text" class="position-relative form-control border-right-0 border-left-0 border-top-0" #AddressDescription="ngModel" [(ngModel)]="_address.Description || _address.Description" placeholder="{{ 'AddressDescription' | translate }}"
                            required />
                        <div *ngIf="isSubmitted && AddressDescription.errors?.required" class="text text-danger offset-3">
                            {{ "AddressDescriptionisrequired" | translate }}
                        </div>
                        <input name="StreetName" onlynumber type="text" class="position-relative form-control border-right-0 border-left-0 border-top-0" [(ngModel)]="_address.Street" placeholder="{{ 'Street' | translate }}" />
                    </div>
                </div>
            </form>
            <!-- <div class="row">
        <div class="col-12">
          <label class="d-block w-100 hold-checkbox position-relative">
            <input id="chooseAddressID" name="chooseAddress" type="checkbox"
              [(ngModel)]="order.Address.isSaveAddressInList" />
            <span class="checkmark"></span>
            <span class="d-inline-block mx-4 p-2">
              {{ "addToMyAddress" | translate }}
            </span>
          </label>
        </div>
      </div> -->
            <!-- <div class="row">
        <div class="col-12">
          <a class="mb-2 text-blue" (click)="ImportAddress()">{{
            "ChoosefrommyAddress" | translate
          }}</a>
          <span class="text-blue d-inline-block mr-1 ml-1"><i class="fa fa-lg fa-thumb-tack" aria-hidden="true"></i>
          </span>
        </div>
      </div> -->
        </div>
        <!-- end choose adresses -->
        <!-- ***************************************************************** -->
        <!--started promo code-->
        <div class="w-100 align-items-center" *ngIf="isPromoCodeApplied == false && order.OrderItems.length > 0 " [hidden]="showTab">
            <div class="row">
                <div class="col-12 voucher px-2">
                    <div class="row no-gutters">
                        <div class="col-7">
                            <div class="label">
                                <label class="mb-0">{{ "promoCodeTxt" | translate }}</label>
                            </div>
                        </div>
                        <div class="col-5">
                            <div class="button">
                                <button (click)="showPromoCodePopup()">{{ "enterpromocode" | translate }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="w-100  align-items-center" *ngIf="isPromoCodeApplied == true && order.OrderItems.length > 0" [hidden]="showTab">
            <div class="row">
                <div class="col-12 voucher px-2">
                    <div class="row no-gutters">
                        <div class="col-8">
                            <div class="label">
                                <label class="mb-0">{{ "promoCodeadded" | translate }}</label>
                                <span> {{ this.order?.PromoCodeAmount }} {{"le" | translate }}</span>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="button">
                                <button (click)="removePromoCode()"> {{ "Delete" | translate }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- errMsg -->
        <div *ngIf="errMsg" class="text-danger mx-auto">{{ errMsg }}</div>

        <!-- start total order cost -->
        <div class="totalOrder" [hidden]="showTab">
            <!-- orderDetails - start -->
            <div class="orderDetails" *ngIf="order.OrderItems.length > 0">
                <!-- <span class="d-block gray-1 font-weight-bold"> {{'Time' | translate}} : {{order.OrderDate}}</span> -->
                <!-- <div class="orderDetailsItem">
          <span class="orderDetailsItemtitle">{{'Total' | translate}}</span>
          <span class="orderDetailsItemCost"><b>{{order.Total}}</b> {{'le' | translate}}</span>
        </div>-->
                <div class="orderDetailsItem">
                    <span class="orderDetailsItemtitle">{{'Total' | translate}}</span>
                    <span class="orderDetailsItemCost"><b>{{order?.GrossAmount}}</b> {{'le' | translate}}</span>
                </div>
                <div class="orderDetailsItem">
                    <span class="orderDetailsItemtitle">{{'DeliveryFees' | translate}}</span>
                    <span class="orderDetailsItemCost"><b>{{order?.DeliveryFees}}</b> {{'le' | translate}}</span>
                </div>
                <div class="orderDetailsItem">
                    <span class="orderDetailsItemtitle">{{'Discount' | translate}}</span>
                    <span class="orderDetailsItemCost"><b>{{order?.DiscountAmount}}</b> {{'le' | translate}}</span>
                </div>
                <div class="orderDetailsItem">
                    <span class="orderDetailsItemtitle">{{ "AfterDiscount" | translate }}</span>
                    <span class="orderDetailsItemCost"><b>{{ order?.NetAmount }}</b> {{ "le" | translate }}</span>
                </div>
            </div>
            <!-- orderBtnContainer - start -->
            <div class="orderBtnContainer d-flex justify-content-center w-100" *ngIf="order.OrderItems.length > 0">
                <button type="button" (click)="confirmOrder()" class="btn btn-primary border-0" *ngIf="isEditable">
                    {{ "ConfirmOrder" | translate }}
                </button>

                <button class="btn btn-secondary border-0" *ngIf="isCancellable" (click)="cancelOrder()">
                    {{ "CancelOrder" | translate }}
                </button>
            </div>
            <!-- orderBtnContainer - end -->
        </div>
        <!-- end total order cost -->
        <!-- ***************************************************************** -->

        <!-- start Save Address Btn -->
        <div class="row mt-1" [hidden]="!showTab">
            <div class="col-12 p-0">
                <div class="card border-0">
                    <div class="card-body p-0">
                        <div class="d-inline-flex flex-row justify-content-center w-100">
                            <button type="button" (click)="SaveAddressOrder()" class="btn btn-primary w-75 mx-auto" *ngIf="isEditable">
                                {{ "SaveAddressOrder" | translate }}
                                <!-- <i class="fa fa-check fa-lg" aria-hidden="true"></i> -->
                            </button>

                            <!-- <div class="p-2 bd-highlight  ">

               <button class="btn btn-secondary" *ngIf="isCancellable"
                (click)="cancelOrder()">{{'CancelOrder' | translate}}</button>
              </div>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end Save Address Btn -->
    </div>

    <!-- ************************************************************************* -->
    <div bsModal #cityModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
        <div class="modal-dialog modal-lg h-100 w-100 m-0 p-0">
            <div class="modal-content h-100 w-100">
                <div class="modal-body h-100 w-100" *ngIf="isShowPoupCityZone">
                    <app-city-zone-lookup [target]="'CartComponent'"></app-city-zone-lookup>
                </div>
            </div>
        </div>
    </div>

    <div bsModal #promoCodeModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">

        <div class="modal-dialog modal-lg h-100 w-100 m-0 p-0">
            <div class="modal-content h-100 w-100">

                <div class="modal-body h-100 w-100" *ngIf="isShowPoupPromoCode">

                    <div class="d-flex flex-column h-100 w-100">
                        <div class="d-flex w-100 pb-2">
                            <h6 class="w-100">{{ "PromoCode" | translate }} </h6>
                            <div></div>
                        </div>
                        <div class="h-100 d-flex align-items-center">
                            <form #ApplyVoucher="ngForm" (ngSubmit)="ApplyVoucher.form.valid && ApplyVoucherbtn()" autocomplete="off" class="w-100">
                                <input placeholder="{{ 'EnterPromoCodeTxt' | translate }}" type="text" name="PromoCodes" class="form-control border-right-0 border-left-0 border-top-0" [(ngModel)]="PromoCodeText" #UserNames="ngModel" required />
                                <div class="row">
                                    <div class="col-6">
                                        <button class="btn btn-primary btn-block border-0 m-0" type="submit">{{ "Apply"|translate }} </button>
                                    </div>
                                    <div class="col-6">
                                        <button class="btn btn-secondary btn-block border-0 m-0" (click)="hideModal()">{{ "Cancel" | translate }} </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="currentView == components.userAddressList">
    <app-user-address-list [UserId]='UserId' [isSelectionMode]="true"></app-user-address-list>
</ng-container>

<div bsModal #ProductAvailabilityModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name" style="background: white; height: 80%;">

    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{ "ProductsNotAvailable" | translate }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeProductAvailabilityModal()">
      <span aria-hidden="true" style="color: red;">&times;</span>
    </button>
    </div>
    <div class="modal-body">
        <div class="container">
            <div class="row" *ngFor="let prod of _productAvilabilityList">
                <div *ngIf="isArabic">
                    -{{"ProductPatch" | translate}} {{ prod.ProductPatch.NameAR }} :
                    <span *ngIf="prod.ProductPatch.Balance>0">
            {{"AvailableOnly" | translate}} ({{prod.ProductPatch.Balance}})</span>
                    <span *ngIf="prod.ProductPatch.Balance <= 0">{{"NotAvailable" | translate}}</span>


                </div>
                <div *ngIf="!isArabic">
                    -{{"ProductPatch" | translate}} {{ prod.ProductPatch.NameEN }} : <span *ngIf="prod.ProductPatch.Balance>0">
            {{"AvailableOnly" | translate}} ({{prod.ProductPatch.Balance}})
          </span>
                    <span *ngIf="prod.ProductPatch.Balance <= 0">
            {{"NotAvailable" | translate}}
          </span>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark">{{"Save"| translate}}</button>
      </div> -->
</div>