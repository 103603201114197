export enum UserCategoryPages {
  AccessDenied = 0,
  Products = 1,
  Orders = 2,
  Vouchers = 3,
  Users = 4,
  ClientManagement = 5,
  Category = 6,
  Brand = 7,
  ProductType = 8,
  Inventory = 10,
  PushNotification = 11,
  UserCategoryPages = 12,
  ProductColor = 13,
  AdminConfig = 14,
  ProductSuppliers = 15,
  Discount=16
}
