import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { distinct, distinctUntilChanged, debounceTime, tap } from 'rxjs/operators';
import { DiscountDTO } from 'src/app/models/Discount/DiscountDto';
import { DiscountResponse } from 'src/app/models/Discount/DiscountResponse';
import { GeneralDiscount, DiscountProductRequest, DiscountCustomerRequest } from 'src/app/models/Discount/GeneralDiscount';
import { DiscountCategoryEnum } from 'src/app/models/enum/DiscountCategoryEnum';
import { DiscountProductEligibilityEnum, DiscountClientEligibility } from 'src/app/models/enum/usercategory.enum';
import { ProductModel } from 'src/app/models/Product.Model';
import { ProductRequest, ProductDiscountRequest } from 'src/app/models/ProductRequest.Model';
import { UserInfoDto } from 'src/app/models/UserInfoDto.Model';
import { UserRequestSearch, UserDiscountRequestSearch } from 'src/app/models/UserRequest.Model';
import { CommandService } from 'src/app/services/command.service';
import { CommunicationService } from 'src/app/services/communication.service';
import { LoginAdminService } from 'src/app/services/login-admin.service';
import { QueryService } from 'src/app/services/query.services';
import { configration } from 'src/app/shared/config';

@Component({
  selector: 'app-customer-discount-add-edit',
  templateUrl: './customer-discount-add-edit.component.html',
  styleUrls: ['./customer-discount-add-edit.component.scss']
})
export class CustomerDiscountAddEditComponent implements OnInit, AfterViewInit {

  @ViewChild('searchProductText', { static: false }) searchProductSelect: ElementRef;
  productTextSearch = new EventEmitter<string>();
  userTextSearch = new EventEmitter<string>();
  // productRequest: ProductRequest = new ProductRequest();
  userRequest: UserRequestSearch = new UserRequestSearch();
  discountRequest: DiscountDTO = new DiscountDTO();
  generalDiscountRequest: GeneralDiscount = new GeneralDiscount();

  discountResponse: DiscountResponse;
  isDiscountUsed: number = 1;
  showLoading: boolean = false;
  IsArabic: boolean = true;
  errors: string[] = [];
  promoCodeTypeText = "PromoCodePercentage";

  userList: UserInfoDto[] = [];
  selectedUserList: UserInfoDto[] = [];

  // productList: ProductModel[] = [];
  productEligibilityEnum = DiscountProductEligibilityEnum;
  clientEligibilityEnum = DiscountClientEligibility;
  isSuccessOperation: boolean = false;
  // showLoadingProduct = false;
  isLoading: boolean = false;
  isArabic: boolean = false;
  showLoadingUsers = false;
  // totalProduct: number;
  totalUser: number;
  pageIndexProduct: number = 0;
  pageIndexUser: number = 0;
  discountTypeText: string = 'PromoCodePercentage';
  validDateFrom: string;
  validDateTo: string;
  errorMsg: string;
  title: string;
  msg: string;
  errMsg: string = "";
  error: string = "";
  discountForm: FormGroup;

  constructor(
    private queryService: QueryService,
    private route: ActivatedRoute,
    private _translate: TranslateService,
    private router: Router,
    private commandService: CommandService,
    private titleService: Title,
    private _communicationService: CommunicationService,
    private _adminLogin: LoginAdminService,
    private fb: FormBuilder,
    private _queryService: QueryService,
    private _commandService: CommandService,
  ) {
    _translate.onLangChange.subscribe(() => this.isArabic = this._translate.currentLang == 'ar');
  }

  ngAfterViewInit(): void {
    this.searchUsers();
  }

  ngOnInit() {
    this.searchUserList(this.userRequest);
    this.loadAfterLang(this._translate.currentLang);
    this._translate.onLangChange.subscribe(res => this.loadAfterLang(res.lang));
    this.getDiscountID();
    this.IntialiazeFormsDiscount(this.generalDiscountRequest)
  }

  changePromoCodeLabel = (value: string) => this.promoCodeTypeText = value;
  setDefaultDiscountResponse = () => this.discountResponse = new DiscountResponse();


  searchUserList(request: UserRequestSearch) {
    this.showLoadingUsers = true;
    try {
      this.queryService.GetUsers(request)
        .pipe(tap(() => {
          this.showLoadingUsers = false;
        }))
        .subscribe(res => {
          if (res.HasError) this.errorMsg = res.ErrorMessage;
          this.userList = res.Result.ItemList

        },
          (err) => {
            this.errorMsg = err;
            this.showLoadingUsers = false;
          });

    } catch (error) {
      this.errorMsg = error;
      this.showLoadingUsers = false;
    }
    return this.userList;
  }


  getUserByIds(request: UserDiscountRequestSearch) {
    this.showLoadingUsers = true;
    try {
      this.queryService.GetUsersDiscountByIds(request).subscribe(res => {
        if (res.HasError) this.errorMsg = res.ErrorMessage;
        this.userList = res.Result.ItemList
        this.showLoadingUsers = false;
      },
        (err) => {
          this.errorMsg = err;
          this.showLoadingUsers = false;
        });

    } catch (error) {
      this.errorMsg = error;
      this.showLoadingUsers = false;
    }

  }


  loadAfterLang(lang: string) {
    this.isArabic = lang === "ar";
    this.route.params.subscribe(() => {
      this.title = this._translate.instant("CustomerSystem");
      this.titleService.setTitle(this.title);
    });
  }


  discountFormEdit(discountResponse: DiscountResponse) {
    this.discountForm = this.fb.group({
      discountName: [discountResponse.Name, [Validators.required]],
      discountType: [discountResponse.PercentageType.toFixed(), Validators.required],
      discountValue: [discountResponse.Amount, [Validators.required, Validators.pattern('^[0-9]+(.[0-9]{0,3})?$')]],
      validDateTo: [discountResponse.ValidDateTo, Validators.required],
      validDateFrom: [discountResponse.ValidDateFrom, Validators.required],
      minmumOrderAmount: [discountResponse.MinmumOrderAmount, Validators.required],
      appType: [discountResponse.IsRetail ? "true" : "false", Validators.required],
      appliesToCustomer: [discountResponse.CustomersEligibility.toFixed(), Validators.required],
      selectedUsers: [this.mapCustomerEligibility(discountResponse).map(x => x.UserId)],
    })
  }


  discountFormInitialization() {
    this.discountForm = this.fb.group({
      discountName: ["", [Validators.required]],
      discountType: ["", Validators.required],
      discountValue: ["", [Validators.required, Validators.pattern('^[0-9]+(.[0-9]{0,3})?$')]],
      validDateTo: ["", Validators.required],
      validDateFrom: ["", Validators.required],
      appType: ["", Validators.required],
      appliesToCustomer: ["", Validators.required],
      minmumOrderAmount: ["", [Validators.required, Validators.pattern('^[0-9]+(.[0-9]{0,3})?$')]],
      selectedUsers: [],
    }, {
      validator: [
        this.dateLessThan('validDateFrom', 'validDateTo'),
        this.checkSelectedCustomer('appliesToCustomer', 'selectedUsers'),
      ]
    })
  }

  Save() {
    let request = this.createGeneralRequest();
    this.isLoading = true;
    this.commandService.AddDiscount(request).subscribe(res => {
      this.isLoading = false;
      if (!res.Result) {
        this.errorMsg = res.ErrorMessage;
        this.isSuccessOperation = false;
        this.isLoading = false;
        this.msg = this._translate.instant("inValidOperation");
      }
      else {
        this.isLoading = false;
        this.isSuccessOperation = true;
        this.msg = this._translate.instant("validOperation");
        this.router.navigateByUrl("/adminhome/customerdiscount")
      }
    }, (err) => {
      this.errorMsg = err;
      this.isLoading = false;
    })
  }

  get selectedUsers() { return this.discountForm.get('selectedUsers') }
  changeDiscountLabel = (value: string) => this.discountTypeText = value;

  onScrollToEndUser = () => this.fetchMoreUser();
  onScrollUser(a) {
    if (this.showLoadingUsers || this.userList.length >= this.totalUser)
      return;
    if (a.start + this.userList.length >= configration.pageSize) {
      this.fetchMoreUser();
    }
  }

  fetchMoreUser() {
    let request = new UserRequestSearch();
    this.pageIndexUser++;
    request.PageIndex = this.pageIndexUser;
    let userList = this.userList;
    this.userList = userList.concat(this.searchUserList(request));
  }


  initializeUserRequestSearch(data: any): UserRequestSearch {
    let request = new UserRequestSearch();
    if (this.checkTextAsNumber(data))
      request.PhoneNumber = data;
    else
      request.UserName = data;
    return request;
  }


  private searchUsers() {
    this.userTextSearch.pipe(
      distinctUntilChanged(),
      debounceTime(3000)).subscribe(res => {
        this.userList = null;
        this.userList = this.searchUserList(this.initializeUserRequestSearch(res))
      })
  }

  private checkTextAsNumber = (text: string) => new RegExp('^[0-9]+$').test(text);

  private dateLessThan(from: string, to: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let f = group.controls[from];
      let t = group.controls[to];
      if (f.value > t.value)
        return { dates: "dateToMustBiggerThanFrom" };
      return {};
    }
  }

  private checkSelectedProduct(controlNameRadio: string, controlNameSelectedList: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let selectedValue = group.controls[controlNameRadio].value;
      let selectedList = group.controls[controlNameSelectedList].value;
      if (selectedValue == this.productEligibilityEnum.SpecificProducts && selectedList == null)
        return { productListError: "productListEMpty" }
      return {}
    }
  }

  private checkSelectedCustomer(controlNameRadio: string, controlNameSelectedList: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let selectedValue = group.controls[controlNameRadio].value;
      let selectedList = group.controls[controlNameSelectedList].value;
      if (selectedValue == this.clientEligibilityEnum.SpecificClients && selectedList == null)
        return { clientListError: "clientListEMpty" }
      return {}
    }
  }

  private checkDiscountISProductOrCustomers(SelectedProductcontrolName: string, SelectedUsercontrolName: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let selectedPRoduct = group.controls[SelectedProductcontrolName].value;
      let selectedUser = group.controls[SelectedUsercontrolName].value;
      if (selectedPRoduct == 3 && selectedUser == 3)
        return { discountProductOrUser: "discountProductOrUser" }
      return {};
    }
  }

  checkSelectedUserLis() {
    if (this.discountForm.get('appliesToCustomer').value != this.clientEligibilityEnum.SpecificClients)
      this.selectedUsers.setValue(null);
  }


  clearSelectedUsers = () => this.selectedUsers.setValue(null);

  createGeneralRequest(): GeneralDiscount {
    let request = new GeneralDiscount();
    request.Name = this.discountForm.value.discountName;
    request.PercentageType = this.discountForm.value.discountType;
    request.Amount = this.discountForm.value.discountValue;
    request.ValidDateFrom = this.discountForm.value.validDateFrom;
    request.ValidDateTo = this.discountForm.value.validDateTo;
    request.CustomersEligibility = this.discountForm.value.appliesToCustomer;
    request.MinmumOrderAmount = this.discountForm.value.minmumOrderAmount;
    request.DiscountCategory = DiscountCategoryEnum.Customer;
    request.DiscountCustomers = this.createSelectedCustomers(this.discountForm.value.selectedUsers);
    request.ID = this.generalDiscountRequest.ID != 0 ? this.generalDiscountRequest.ID : 0;
    request.IsRetail = this.discountForm.value.appType;
    // if (request.CustomersEligibility != DiscountClientEligibility.All) {
    //   let customerList: DiscountCustomerRequest[] = [];
    //   let userIDS: string[] = this.selectedUsers.value;
    //   if (userIDS != null && userIDS.length > 0) {
    //     userIDS.forEach(item => {
    //       let user = new DiscountCustomerRequest();
    //       user.CustomerID = item;
    //       user.DiscountCategoryEnum = DiscountCategoryEnum.Customer
    //       customerList.push(user);
    //     });
    //   } else if (this.discountResponse != null && this.generalDiscountRequest.ID != 0) {
    //     let userIds = this.discountResponse.DiscountCustomers.map(x => x.CustomerID);
    //     userIds.forEach(item => {
    //       let user = new DiscountCustomerRequest();
    //       user.DiscountCategoryEnum = DiscountCategoryEnum.Customer
    //       user.CustomerID = item;
    //       customerList.push(user);
    //     });

    //   }

    //   request.DiscountCustomers = customerList;

    // } else request.DiscountCustomers = null;
    // if (this.generalDiscountRequest.ID != 0)
    //   request.ID = this.generalDiscountRequest.ID;

    return request;
  }

  getDiscountID = () => this.route.params.subscribe(params => this.generalDiscountRequest.ID = +params['ID'])


  IntialiazeFormsDiscount(request: GeneralDiscount) {
    if (request.ID != 0) {
      this.isDiscountUsed = 1;
      this.getDiscountByID(request)
    }
    else
      this.discountFormInitialization()
  }

  getDiscountByID(request: GeneralDiscount) {
    try {
      this.showLoading = true;
      this.showLoadingUsers = true;
      this.errMsg = '';
      this.queryService.GetDiscountByID(request)
        .pipe(tap(() => {
          this.showLoading = false;
          this.showLoadingUsers = false;
        }
        ))
        .subscribe(res => {
          if (res.HasError)
            this.errMsg = res.ErrorMessage;

          this.discountResponse = res.Result;
          this.discountFormEdit(res.Result)
          this.selectedUserList = this.mapCustomerEligibility(res.Result);

          this.userList = this.mapCustomerEligibility(res.Result);

        },
          Err => {
            this.errMsg = Err;
            this.showLoading = false;
            this.showLoadingUsers = false;
          })
    } catch (error) {
      this.errMsg = error;
      this.showLoading = false;
      this.showLoadingUsers = false;
    }
  }

  mapCustomerEligibility(request: DiscountResponse): UserInfoDto[] {
    let result: UserInfoDto[] = [];
    request.DiscountCustomers.forEach(item => {
      let user = new UserInfoDto();
      user.Name = item.CustomerName;
      user.UserId = item.CustomerID;
      user.PhoneNumber = item.CustomerPhone;
      result.push(user);
    });
    return result;
  }

  createSelectedCustomers(userIds: string[]): DiscountCustomerRequest[] {
    if (userIds == null) return null;
    let customerList: DiscountCustomerRequest[] = [];
    userIds.forEach(item => {
      let user = new DiscountCustomerRequest();
      user.CustomerID = item;
      user.DiscountCategoryEnum = DiscountCategoryEnum.Voucher;
      customerList.push(user);
    });
    return customerList;
  }


}