<div class="h-100 overflow-auto">
  <div class="bd-highlight p-2">
    <h4 class="p-2 mb-0">{{ title }}  [{{ "ID" | translate }}: {{ productDetails.ID }}] </h4>
    <h4 class="p-2 mb-0">
      <button
        class="btn btn-danger"
        *ngIf="!showLoading"
        routerLink="/adminhome/productaddedit/{{ productDetails.ID }}"
      >
        {{ "Edit" | translate }}
      </button>
    </h4>
  </div>

  <div class="container" *ngIf="!showLoading">
        <!-- ======= About Section ======= -->
        <section id="about" class="about">
          <div class="container">

            <div class="section-title">
              <h2>{{"ProductInfo" | translate}}</h2>
            </div>

            <div class="row">
              <div class="col-lg-4" data-aos="fade-right">
                <img src="{{getImageFullPath(productDetails)}}" class="img-fluid" alt="">
              </div>
              <div class="col-lg-8 pt-4 pt-lg-0 content" data-aos="fade-left">
                <h3>{{ "NameAR" | translate }}: {{ productDetails.NameAR }}</h3>
                <h3>{{ "NameEN" | translate }}: {{ productDetails.NameEN }}</h3>

                <div class="row">
                  <div class="col-lg-6">
                    <ul>
                      <li><i class="icofont-rounded-right"></i> <strong>{{ "PublishStatus" | translate }}:
                        </strong>
                        <span *ngIf="productDetails.IsAvailable" style="color: green">
                          {{ "Available" | translate }}
                        </span>
                        <span *ngIf="!productDetails.IsAvailable" style="color: red">
                          {{ "NotAvailable" | translate }}
                        </span>
                      </li>
                      <li><i class="icofont-rounded-right"></i> <strong>{{ "CountOfOrders" | translate }}: </strong>

                      <a target="_blank"
                         routerLink="/adminhome/adminorderlist//0/0/0/{{productDetails.ID}}"
                         class="tex-primary"
                         style="color: blue;"
                      >
                      {{ productDetails?.CountOfOrders }}
                      </a>

                      </li>
                      <li><i class="icofont-rounded-right"></i> <strong>{{ "TotalOrders" | translate }}:  </strong>{{ productDetails?.TotalOrders | currency:'':''}} {{ "EgyptianPound" | translate }}</li>
                      <li><i class="icofont-rounded-right"></i> <strong>{{ "CountOfOrdersPerItem" | translate }}:  </strong>{{ productDetails?.CountOfOrdersPerItem }}</li>
                    </ul>
                  </div>
                  <div class="col-lg-6">
                    <ul>
                      <li><i class="icofont-rounded-right"></i> <strong>{{ "Quantity" | translate }}:  </strong>{{ productDetails?.QuantitySum }}</li>
                      <li><i class="icofont-rounded-right"></i> <strong>{{ "Booked" | translate }}:  </strong>{{ productDetails?.BookedSum }}</li>
                      <li><i class="icofont-rounded-right"></i> <strong>{{ "Balance" | translate }}:  </strong>{{ productDetails?.BalanceSum }} </li>
                      <li><i class="icofont-rounded-right"></i> <strong>{{ "ProductSuppliers" | translate }}:  </strong>
                        {{
                          IsArabic ? productDetails?.Supplier?.NameAR : productDetails?.Supplier?.NameEN
                         }} </li>
                    </ul>
                  </div>
                </div>

                <div class="row">

                  <div class="col-lg-6">
                    <ul>
                      <li>
                        <i class="icofont-rounded-right"></i> <strong>
                        {{ "ProductBrand" | translate }}: </strong>{{ productDetails?.Brand?.NameAR }}
                      </li>
                      <li>
                        <i class="icofont-rounded-right"></i> <strong>
                          {{ "ProductType" | translate }}:  </strong>{{ productDetails?.Type?.NameAR }}
                      </li>
                      <li>
                        <i class="icofont-rounded-right"></i> <strong>
                          {{ "ProductColor" | translate }}:  </strong>{{ productDetails?.Color?.NameAR }}
                      </li>
                    </ul>
                  </div>

                  <div class="col-lg-6">
                    <ul>
                      <li><i class="icofont-rounded-right"></i> <strong>{{ "ProductCategory" | translate }}:  </strong>{{ productDetails?.Category0?.NameAR }}</li>

                      <li
                      ><i class="icofont-rounded-right"></i> <strong>{{ "ProductCategory2" | translate }}:  </strong>{{ productDetails?.Category1?.NameAR }}</li>

                      <li><i
                        class="icofont-rounded-right"></i> <strong>{{ "ProductCategory3" | translate }}:  </strong>{{ productDetails?.Category2?.NameAR }}</li>

                        <li><i
                        class="icofont-rounded-right"></i> <strong>{{ "ProductCategory4" | translate }}:  </strong>{{ productDetails?.Category3?.NameAR }}</li>
                    </ul>
                  </div>

                </div>

                <div class="row">

                  <div class="col-lg-6">
                    <ul>
                      <li>
                        <i class="icofont-rounded-right"></i> <strong>
                          {{ "ProductPurchasingPrice" | translate }}:  </strong>{{ productDetails.PurchasingPrice }}
                      </li>
                      <li>
                        <i class="icofont-rounded-right"></i> <strong>
                          {{ "ProductOriginCountry" | translate }}:  </strong>{{ productDetails.ProductOriginCountry }}
                      </li>
                      <li>
                        <i class="icofont-rounded-right"></i> <strong>
                          {{ "LocationTackingNumber" | translate }}:  </strong>{{ productDetails.LocationTackingNumber }}
                      </li>
                      <li>
                        <i class="icofont-rounded-right"></i> <strong>
                          {{ "InventoryShelfNumber" | translate }}:  </strong>{{ productDetails.InventoryShelfNumber }}
                      </li>
                    </ul>
                  </div>
                  <div class="col-lg-6">
                    <ul>
                      <li>
                        <i class="icofont-rounded-right"></i> <strong>
                          {{ "ProductWeigths" | translate }}:  </strong>{{ productDetails.ProductWeights }}
                      </li>
                      <li>
                        <i class="icofont-rounded-right"></i> <strong>
                          {{ "ProductLength" | translate }}:  </strong>{{ productDetails.ProductLength }}
                      </li>
                      <li>
                        <i class="icofont-rounded-right"></i> <strong>
                          {{ "ProductWidth" | translate }}:  </strong>{{ productDetails.ProductWidth }}
                      </li>
                    </ul>
                  </div>
                </div>

                <label>{{ "DescriptionEN" | translate }}</label>
                <p>
                  {{ productDetails?.DescriptionEN }}
                </p>
                <label>{{ "DescriptionAR" | translate }}</label>
                <p>
                  {{ productDetails?.DescriptionAR }}
                </p>
              </div>
            </div>

          </div>
        </section>
        <!-- End About Section -->

        <div class="row mt-3 border">
          <div class="col-10">
            <h3>{{ "ClientOrdersSummary" | translate }}</h3>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">{{ "orderstatus" | translate }}</th>
                  <th scope="col">{{ "clientOrdersCount" | translate }}</th>
                  <th scope="col">
                    {{ "clientOrderTotalPrice" | translate }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let summary of productDetails.OrdersSummaryDto">
                  <td>{{ "Status_" + summary.OrderStatus | translate }}</td>
                  <td>{{ summary.OrdersCount }}</td>
                  <td>{{ summary.OrdersTotalPrice }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="row mt-3 border">
          <div class="col-12 p-0">
            <div class="table-responsive-sm">
              <table class="table table-sm table-striped" id="header-fixed">
                <thead>
                  <tr>
                    <th scope="col">
                      {{ "OrderID" | translate }}
                    </th>
                    <th scope="col">
                      {{ "OrderDate" | translate }}
                    </th>
                    <th scope="col">
                      {{ "orderstatus" | translate }}
                    </th>
                    <th scope="col">
                      {{ "ClientName2" | translate }}
                    </th>
                    <th scope="col">
                      {{ "ClientPhoneNumber2" | translate }}
                    </th>
                    <th scope="col">
                      {{ "SalesName2" | translate }}
                    </th>
                    <th scope="col">
                      {{ "OrderQuantity" | translate }}
                    </th>
                    <th scope="col">
                      {{ "DeliveryFees" | translate }}
                    </th>
                    <th scope="col">
                      {{ "OrderTotal" | translate }}
                    </th>
                    <th scope="col">
                      {{ "orderDeliverydate" | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let order of productDetails.OrdersList">
                    <td>
                      <a
                        routerLink="/adminhome/order-details/{{ order.ID }}"
                        target="_blank"
                        style="color: blue"
                        >{{ order.ID }}</a
                      >
                    </td>
                    <td>
                      {{ order.OrderDate | date: "dd-MM-yyyy h:mm a" }}
                    </td>
                    <td>
                      <span
                        *ngIf="order.OrderStatus != OrderStatusEnum.Cancelled"
                        class="fa fa-circle text-success"
                      >
                        {{ "Status_" + order.OrderStatus | translate }}</span
                      >
                      <span
                        *ngIf="order.OrderStatus === OrderStatusEnum.Cancelled"
                        class="fa fa-ban text-danger"
                      >
                        {{ "Status_" + order.OrderStatus | translate }}</span
                      >
                    </td>
                    <td>
                      <a
                        style="color: blue"
                        href="/adminhome/client-details/{{
                          order.AspNetUser.UserId
                        }}"
                        target="_blank"
                      >
                        {{ order.AspNetUser.FullName }}
                      </a>
                    </td>
                    <td>{{ order.AspNetUser.PhoneNumber }}</td>
                    <td>{{ order.AspNetUser.SalesName }}</td>

                    <td>
                      {{ sumOrderItemQuantity(order.OrderItems) }}
                    </td>

                    <td>{{ order.DeliveryFees }} {{ "le" | translate }}</td>

                    <td>{{ order.TotalAfterDiscount }} {{ "le" | translate }}</td>

                    <td>{{ order.DeliveryDate | date: "dd-MM-yyyy" }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

            <!-- ======= Portfolio Section ======= -->
    <section id="portfolio" class="portfolio section-bg">
      <div class="container">

        <div class="section-title">
          <h2>{{ "MultipleProductImage" | translate }}</h2>
        </div>

        <div class="row portfolio-container" data-aos="fade-up" data-aos-delay="100">

          <div class="col-lg-4 col-md-6 portfolio-item filter-app" *ngFor="let image of productDetails.ProductImages">
            <div class="portfolio-wrap">
              <img src="{{getProductImagesPath(image, productDetails.ID)}}" class="img-fluid" alt="">
              <!-- <div class="portfolio-links">
                <a href="assets/img/portfolio/portfolio-1.jpg" data-gall="portfolioGallery" class="venobox" title="App 1"><i class="bx bx-plus"></i></a>
                <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
              </div> -->
            </div>
          </div>

        </div>

      </div>
    </section><!-- End Portfolio Section -->

  </div>

  <!-- <div class="container" *ngIf="!showLoading">
    <div class="row">
      <div class="col-3">
        <img  src="{{getImageFullPath(productDetails)}}" width="200" height="200">
      </div>

      <div class="col-3">
        <label>{{"ProductInfo" | translate}}</label>
        <ul class="list-group">

          <li class="list-group-item">
            {{ "NameEN" | translate }}: {{ productDetails.NameEN }}
          </li>
          <li class="list-group-item">
            {{ "NameAR" | translate }}: {{ productDetails.NameAR }}
          </li>
          <li class="list-group-item">
            {{ "PublishStatus" | translate }}:
            <span *ngIf="productDetails.ProductWidth" style="color: green">
              {{ "Available" | translate }}
            </span>
            <span *ngIf="!productDetails.ProductWidth" style="color: red">
              {{ "NotAvailable" | translate }}
            </span>
          </li>
        </ul>
      </div>

      <div class="col-3">
        <label>{{"OrdersStatistics" | translate}}</label>
        <ul class="list-group">
          <li class="list-group-item">
            {{ "CountOfOrders" | translate }}:
            {{ productDetails?.CountOfOrders }}
          </li>
          <li class="list-group-item">
            {{ "CountOfOrdersPerItem" | translate }}:
            {{ productDetails?.CountOfOrdersPerItem }}
          </li>
          <li class="list-group-item">
            {{ "TotalOrders" | translate }}:
            {{ productDetails?.TotalOrders }}
          </li>
        </ul>
      </div>

      <div class="col-3">
        <label>{{"Inventory" | translate}}</label>
        <ul class="list-group">
          <li class="list-group-item">
            {{ "Quantity" | translate }}: {{ productDetails?.QuantitySum }}
          </li>
          <li class="list-group-item">
            {{ "Booked" | translate }}:  {{ productDetails?.BookedSum }}
          </li>
          <li class="list-group-item">
            {{ "Balance" | translate }}:  {{ productDetails?.BalanceSum }}
          </li>
        </ul>
      </div>

    </div>
    <div class="row mt-3 p-2">
      <div class="col-4">
        <ul class="list-group">
          <li class="list-group-item">
            {{ "ProductCategory" | translate }}: {{ productDetails?.Category0?.NameAR }}
          </li>
          <li class="list-group-item">
            {{ "ProductCategory2" | translate }}:
            {{ productDetails?.Category1?.NameAR }}
          </li>
          <li class="list-group-item">
            {{ "ProductCategory3" | translate }}:
            {{ productDetails?.Category2?.NameAR }}
          </li>
          <li class="list-group-item">
            {{ "ProductCategory4" | translate }}:
            {{ productDetails?.Category3?.NameAR }}
          </li>
        </ul>
      </div>

      <div class="col-4">
        <ul class="list-group">
          <li class="list-group-item">
            {{ "ProductBrand" | translate }}:
            {{ productDetails?.Brand?.NameAR }}
          </li>
          <li class="list-group-item">
            {{ "ProductType" | translate }}: {{ productDetails?.Type?.NameAR }}
          </li>
          <li class="list-group-item">
            {{ "ProductColor" | translate }}:  {{ productDetails?.Color?.NameAR }}
          </li>
        </ul>
      </div>
      <div class="col-4">
        <ul class="list-group">
          <li class="list-group-item">
            {{ "ProductPurchasingPrice" | translate }}: {{ productDetails.PurchasingPrice }}
          </li>
          <li class="list-group-item">
            {{ "ProductOriginCountry" | translate }}: {{ productDetails.ProductOriginCountry }}
          </li>
          <li class="list-group-item">
            {{ "LocationTackingNumber" | translate }}: {{ productDetails.LocationTackingNumber }}
          </li>
        </ul>
      </div>

    </div>

    <div class="row mt-3">

      <div class="col-4">
        <ul class="list-group">
          <li class="list-group-item">
            {{ "ProductWeigths" | translate }}: {{ productDetails.ProductWeights }}
          </li>
          <li class="list-group-item">
            {{ "ProductLength" | translate }}: {{ productDetails.ProductLength }}
          </li>
          <li class="list-group-item">
            {{ "ProductWidth" | translate }}: {{ productDetails.ProductWidth }}
          </li>
        </ul>
      </div>

      <div class="col-4">
          <label>{{ "DescriptionEN" | translate }}</label>
          <p class="border">
            {{ productDetails?.DescriptionEN }}
          </p>
      </div>

      <div class="col-4">
        <label>{{ "DescriptionAR" | translate }}</label>
        <p class="border">
          {{ productDetails?.DescriptionAR }}
        </p>
      </div>

    </div>

    <div class="row mt-3">
      <label> {{ "MultipleProductImage" | translate }} </label>
      <div class="col-10">
        <img *ngFor="let image of productDetails.ProductImages"
        class="img-thumbnail p-1"
        src="{{getProductImagesPath(image, productDetails.ID)}}"
        width="200" height="200">
      </div>
    </div>
  </div>

  <div
    *ngIf="showLoading"
    class="
      d-flex
      flex-column
      z-index-3
      position-fixed
      w-100
      h-100
      justify-content-center
      align-items-center
      bg-white
    "
    style="opacity: 0.5"
  >
    <div class="p-2 align-self-center">
      <span class="load-more-List text-center vw-100 vh-100">
        <span class="spinner-border text-primary" role="status"> </span>
      </span>
    </div>
  </div> -->
</div>
