export class DiscountResponse {
    ID: number;
    Name: string;
    PercentageType: number;
    Amount: number;
    ValidDateFrom: Date;
    ValidDateTo: Date;
    CustomersEligibility: number;
    ProductsEligibility: number;
    MaxProdcutPrice:number;
    MinProdcutPrice:number;
    DiscountCustomers: DiscountCustomerResponse[] = [];
    DiscountProducts: DiscountProductResponse[] = [];

     DiscountCategory : number;
     MinmumOrderAmount : number;
    VoucherCode : string;
    IsRetail:boolean;
}

export class DiscountCustomerResponse {
    ID: number;
    CustomerID: string;
    CustomerPhone: string;
    CustomerName: string;
    DiscountID: number;
    DiscountName: number;
    DiscountCategory:number;
}

export class DiscountProductResponse {
    ID: number;
    ProductID: number;
    ProductNameEN: string;
    ProductNameAR: string;
    DiscountID: number;
    DiscountName: string;
}