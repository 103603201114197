import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CommunicationService } from 'src/app/services/communication.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ActionType } from 'src/app/models/Action';
import { LoginAdminService } from 'src/app/services/login-admin.service';
import { UserCategoryPages } from 'src/app/models/enum/UserPagesEnum';

@Component({
  selector: 'side-menu-portal',
  templateUrl: './side-menu-portal.component.html',
  styleUrls: ['./side-menu-portal.component.scss'], encapsulation: ViewEncapsulation.None
})
export class SideMenuPortalComponent implements OnInit {
  isEnglish: boolean = false;
  isCollapsed: boolean = true;
  userName = "";
  activeHome = false;
  activrVocher = false;
  activeCoellctios = false;
  activeCat = false;
  activeAdminSetting = false;
  activeSuppliers = false;
  activrUser = false;
  activeBrand = false;
  activeType = false;
  activeColor = false;
  activeInventory = false;
  activeUserCatPages = false;
  get UserCategoryPages() {
    return UserCategoryPages;
  }
  constructor(public translate: TranslateService, private _adminLogin: LoginAdminService,
    private _route: Router, private _communcationService: CommunicationService) {
    _route.events.subscribe(res => {
      this.ActiveRoute();

    })
  }

  ngOnInit() {
    this.ActiveRoute();
    this.isEnglish = this._adminLogin.getCurrentCulture() == 'ar';
    this.userName = this._adminLogin.getUserName();
    this._communcationService.getAction().subscribe(res => {
      if (res.actionType == ActionType.AdminCloseSideMenu) {
        this.isCollapsed = res.Result
      }
    })
  }
  collapseSideMenu() {
    this.isCollapsed = !this.isCollapsed;
    this._communcationService.setCloseAdminSideMenu( this.isCollapsed);

  }
  ActiveRoute() {
    this.activeHome = this._route.url.indexOf("ProductAdminList") >= 0;
    this.activrVocher = this._route.url.indexOf("adminqueryvoucher") >= 0;
    this.activeCoellctios = this._route.url.indexOf("adminorderlist") >= 0;

    this.activeCat = this._route.url.indexOf("categoryadminlist") >= 0;
    this.activeAdminSetting = this._route.url.indexOf("admin-config") >= 0;

    this.activeSuppliers = this._route.url.indexOf("product-suppliers") >= 0;
    this.activrUser = this._route.url.indexOf("adminuserlist") >= 0;
    this.activeBrand = this._route.url.indexOf("productbrandadminlist") >= 0;
    this.activeType = this._route.url.indexOf("producttypeadminlist") >= 0;
    this.activeColor = this._route.url.indexOf("productcoloradminlist") >= 0;
    this.activeInventory = this._route.url.indexOf("inventory") >= 0;
    this.activeUserCatPages = this._route.url.indexOf("usercategorypages") >= 0;

  }


  changelang(key: string) {
    this._adminLogin.setCurrentCulture(key);
    this.isEnglish = !this.isEnglish;
    //this._communcationService.setCloseAdminSideMenu(false);
  }

  GoToSignOut() {
    this._adminLogin.removeLocalStorage();
    this._route.navigateByUrl('loginadmin');
  }

  isUserAllowed(userPageid: UserCategoryPages) {

    let allowedUserCategoryPages = this._adminLogin.getUserCategoryPages();
    if (allowedUserCategoryPages != null) {
      let check = this._adminLogin.userCategoryPages.find(x => x.PageId === +userPageid);
      if(check){
        return check.Allow;
      }else{
      return false;
      }
    } else {
      return false;
    }
  }

}
