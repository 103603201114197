<app-navbar-discount>
</app-navbar-discount>

<div class="container-fluid">
    <div class="bd-highlight p-2">
        <h4 class="p-2 mb-0">{{ title }}</h4>
    </div>

    <div *ngIf="showLoading" class=" d-flex flex-column z-index-3 position-fixed w-100 h-100 justify-content-center align-items-center bg-white " style="opacity: 0.5">
        <div class="p-2 align-self-center">
            <span class="load-more-List text-center vw-100 vh-100">
                <span class="spinner-border text-primary" role="status"> </span>
            </span>
        </div>
    </div>

    <div class="row m-3">
        <div class="col-6 align-self-end">
            <button class="btn btn-success mr-3 mb-1 btn-sm" routerLink="/adminhome/qytdiscountaddedit/0">
                <i class="fa fa-plus" aria-hidden="true"></i>
                {{ "AddQuantityDiscount" | translate }}
            </button>
        </div>
    </div>

    <div class="row m-3" *ngIf="!showLoading">
        <div class="table-responsive">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th scope="col">
                            {{ "ID" | translate }}
                            <i (click)="onSortClick($event, 'ID')" class="my-icon fa fa-chevron-down"></i>
                        </th>
                        <th scope="col">
                            {{ "Name" | translate }}
                            <i (click)="onSortClick($event, 'Name')" class="my-icon fa fa-chevron-down"></i>
                        </th>
                        <th scope="col">
                            {{ "QuantityFrom" | translate }}
                            <i (click)="onSortClick($event, 'QuantityFrom')" class="my-icon fa fa-chevron-down"></i>
                        </th>
                        <th scope="col">
                            {{ "Amount" | translate }}
                            <i (click)="onSortClick($event, 'Amount')" class="my-icon fa fa-chevron-down"></i>
                        </th>

                        <th scope="col">
                            {{ "Actions" | translate }}
                            <i class="fa fa-bolt" aria-hidden="true"></i>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of discountList">
                        <th scope="row"> {{item.ID}}</th>
                        <td>
                            <span *ngIf="!IsArabic">{{item.ProductNameEn}}</span>
                            <span *ngIf="IsArabic">{{item.ProductNameAR}}</span>
                        </td>
                        <td>{{item.QuantityFrom }}</td>
                        <td>{{item.Amount}}
                            <span *ngIf="item.PercentageType == 0"> ٪ </span>
                            <span *ngIf="item.PercentageType == 1"> {{ "le" | translate }}</span>
                        </td>

                        <td>
                            <div class="btn-group">
                                <a class="btn btn-primary" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fa fa-bars" aria-hidden="true" style="color: #f9f9f9 !important"></i></a>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a class="dropdown-item" routerLink="/adminhome/qytdiscountaddedit/{{ item.ProductID }}">{{
                                        "Edit" | translate }}</a>
                                    <a class=" dropdown-item" (click)="deleteDiscount(item.ID)">{{ "Cancel" | translate
                                        }}</a>
                                </div>
                            </div>
                    </tr>

                </tbody>
            </table>
        </div>

        <div class=" d-flex flex-column z-index-3  w-100 h-100 justify-content-center align-items-center bg-white" *ngIf="discountList?.length == 0">
            <label class="la">{{ "NoDataFound" | translate }}</label>
        </div>
    </div>


    <div class="row mt-2">
        <div class="col-xs-12 col-12 p-1">
            <app-pagination-component [ItemsCount]="Total" (changePage)="onPagingChange($event)">
            </app-pagination-component>
        </div>
    </div>

</div>