
export class Action<T>
{
  actionType: ActionType;
  Result: T;

}


export enum ActionType {
  ShowHideSideMenu = 8,
  AlertMsg = 11,
  ColseAlert = 12,
  AdminCloseSideMenu = 13,
  UpdateProductPatch = 14,
  QueryProducts = 15,
  CloseProductFilter = 16,
  CityZoneSelected = 17,
  CityZoneClosed = 18,
  UserAddressSelected = 19,
  UserAddressListClosed = 20,
  ShowAlert = 21,
  SendFromListToCart = 22,
  UserEditAddressSaved = 23,
  UserEditAddressClosed = 24,
  ShowAlertInfo = 25,
  CloseAlertInof = 26
}
