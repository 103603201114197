import { QueryService } from './query.services';
import { ProductPatchModel } from './../models/ProductPatch.model';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { OrderModel } from '../models/Order.Model';
import { OrderRequest } from '../models/OrderRequest.Model';
import { UserAddressDto } from '../models/UserAddressDto.Model';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  address: UserAddressDto = new UserAddressDto();
  order: OrderModel = new OrderModel();
  orderSubject: BehaviorSubject<OrderModel> = new BehaviorSubject<OrderModel>(this.order);
  currentOrderList = this.orderSubject.asObservable();

  constructor(private _queryService: QueryService) {
  }

  orderSubjectChange(order: OrderModel) {
    this.order = order;
    this.orderSubject.next(this.order);
  }

  deleteProductFromOrder(product: ProductPatchModel, userId: string) {
    let newOrder = this.order;
    let productIndex = newOrder.OrderItems.findIndex(x => x.Product.PatchID === product.PatchID);

    var request = new OrderRequest();
    request.UserID = userId;
    this._queryService.findOpenCart(request).subscribe(result => {
      this.orderSubjectChange(result.Result);
    });
  }

}
