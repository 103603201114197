import { configration } from "../shared/config";

export class UserRequest {
  UserId: string;
  mobilePhone: string;
  PageIndex: number = 1;
  PageSize: number = configration.pageSize;
  UserName: string;
}


export class UserRequestSearch{
   UserId:string 
   UserName:string  
   PhoneNumber:string 
   PageIndex:number; 
   PageSize:number = configration.pageSize;
}

export class UserDiscountRequestSearch{
  UserId:string[]; 
  UserName:string  
  PhoneNumber:string 
  PageIndex:number; 
  PageSize:number = configration.pageSize;
}
