import { configration } from "../shared/config";
import { ProductSortEnum } from "./enum/OrderStatusEnum";
import { ProductModel } from "./Product.Model";

export class ProductRequest {
  ID: number;
  UserId: string;
  SearchText: string;
  NameAR: string;
  isAdminPage: boolean = false;
  NameEN: string;
  productCatIds: number[];
  productColorIds: number[];
  productBrandIds: number[];
  productTypeIds: number[];
  suppliersIds: number[];
  PriceFrom: number;
  PriceTo: number;
  PageIndex: number = 1;
  sortEnum: ProductSortEnum;
  isFavourite: boolean = false;
  PageSize: number = configration.pageSize;
  IsAvailable: boolean = null;
  IsArabic: boolean = true;
  Lang: string;
  ProductPatchPriceFrom: number;
  ProductPatchPriceTo: number;
  ProductPatchQuantatityFrom: number;
  ProductPatchQuantatityTo: number;
  ProductPatchBookedFrom: number;
  ProductPatchBookedTo: number;
  ProductPatchBalanceFrom: number;
  ProductPatchBalanceTo: number;
  ProductPatchExpireFrom: Date;
  ProductPatchExpireTo: Date;
  SortColumn: string;
  SortDirection: number;
  IsExcel: boolean;

  ProductCreatedTimeFrom: Date;
  ProductCreatedTimeTo: Date;
  ProductPurchasePriceFrom: number;
  ProductPurchasePriceTo: number;

  IsRetail?: number;

  clear() {
    this.NameAR = "";

    this.NameEN = "";

    this.productCatIds = [];

    this.productColorIds = [];

    this.productBrandIds = [];

    this.productTypeIds = [];

    this.sortEnum = null;

    this.PageIndex = 1;
  }
  get isFiltered(): boolean {
    if (this.NameAR) return true;

    if (this.NameEN) return true;

    if (this.productCatIds && this.productCatIds.length > 0) return true;

    if (this.productColorIds && this.productColorIds.length > 0) return true;

    if (this.productBrandIds && this.productBrandIds.length > 0) return true;

    if (this.productTypeIds && this.productTypeIds.length > 0) return true;

    if (this.sortEnum) return true;

    return false;
  }
}

export class ProductListRequest {
  ProductList: ProductModel[] = [];
  UserId: string;
  PageSize: number = configration.pageSize;
  PageIndex: number = 1;
}

export class ProductPatchRequest {
  PageIndex: number = 1;
  PatchExpireDateSearch: string;
  ProductId: number;
  PageSize: number = 5;
  Lang: string;
}

export class ProductDiscountRequest {
  ID: number[];
  UserId: string;
  SearchText: string;
  NameAR: string;
  isAdminPage: boolean = false;
  NameEN: string;
  productCatIds: number[];
  productColorIds: number[];
  productBrandIds: number[];
  productTypeIds: number[];
  suppliersIds: number[];
  PriceFrom: number;
  PriceTo: number;
  PageIndex: number = 1;
  sortEnum: ProductSortEnum;
  isFavourite: boolean = false;
  PageSize: number = configration.pageSize;
  IsAvailable: boolean = null;
  IsArabic: boolean = true;
  Lang: string;
  ProductPatchPriceFrom: number;
  ProductPatchPriceTo: number;
  ProductPatchQuantatityFrom: number;
  ProductPatchQuantatityTo: number;
  ProductPatchBookedFrom: number;
  ProductPatchBookedTo: number;
  ProductPatchBalanceFrom: number;
  ProductPatchBalanceTo: number;
  ProductPatchExpireFrom: Date;
  ProductPatchExpireTo: Date;
  SortColumn: string;
  SortDirection: number;
  IsExcel: boolean;

  ProductCreatedTimeFrom: Date;
  ProductCreatedTimeTo: Date;
  ProductPurchasePriceFrom: number;
  ProductPurchasePriceTo: number;

  clear() {
    this.NameAR = "";

    this.NameEN = "";

    this.productCatIds = [];

    this.productColorIds = [];

    this.productBrandIds = [];

    this.productTypeIds = [];

    this.sortEnum = null;

    this.PageIndex = 1;
  }
  get isFiltered(): boolean {
    if (this.NameAR) return true;

    if (this.NameEN) return true;

    if (this.productCatIds && this.productCatIds.length > 0) return true;

    if (this.productColorIds && this.productColorIds.length > 0) return true;

    if (this.productBrandIds && this.productBrandIds.length > 0) return true;

    if (this.productTypeIds && this.productTypeIds.length > 0) return true;

    if (this.sortEnum) return true;

    return false;
  }
}