<div class="row">
    <div class="col-md-6 mt-2">

        <div class="row">

            <div (click)="showList=!showList" class="px-2 py-1" *ngIf="showList">
                <img class="w-100" [src]="isArabic? 'assets/images/arrow-back-white.png': 'assets/images/arrow-back.png'" />
            </div>
            <div class=" w-80 ">
                <div class="form-group  m-0 has-search">
                    <input placeholder="{{'Search' | translate}}" #searchProductInput type="text" [(ngModel)]="searchName" class="form-control w-100  border">
                </div>
            </div>
            <div class="p-2 w-10 text-danger" (click)="searchProduct(searchName)">
                <i class="fa fa-search" aria-hidden="true"></i>
            </div>
        </div>

        <app-product-category *ngIf="!showList"></app-product-category>
        <app-new-product-list *ngIf="showList" [orderId]="orderId" [UserId]="userId"></app-new-product-list>
    </div>
    <div class="col-md-6">
        <app-cart [orderId]="orderId"></app-cart>
    </div>
</div>