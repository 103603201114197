<div class="d-flex flex-column h-100 w-100 p-2">
  <div class="bd-highlight">
    <div class="row">
      <div class="col-6">
        <h4>{{ title }}</h4>
      </div>
    </div>

    <table class="table table-striped" id="header-fixed">
      <thead>
        <tr>
          <th scope="col">
            {{ "Keyword" | translate }}
            <i
              (click)="onSortClick($event, 'Keyword')"
              class="my-icon fa fa-chevron-up"
            ></i>
          </th>

          <th scope="col">
            {{ "KeywordCount" | translate }}
            <i
              (click)="onSortClick($event, 'KeywordCount')"
              class="my-icon fa fa-chevron-down"
            ></i>
          </th>

        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let searchKeyword of searchKeywordList">
          <td>
            {{ searchKeyword.Keyword }}
          </td>
          <td>
            {{ searchKeyword.KeywordCount }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="row mt-2">
    <div class="col-xs-12 col-12 p-0">
      <app-pagination-component
        [ItemsCount]="total"
        (changePage)="onPagingChange($event)"
      >
      </app-pagination-component>
    </div>
  </div>
</div>
