import { LookupsModel } from 'src/app/models/Lookups.model';
import { Component, OnInit } from "@angular/core";
import { QueryService } from "src/app/services/query.services";
import { TranslateService } from "@ngx-translate/core";
import { Title } from "@angular/platform-browser";
import { PushNotificationRequest } from "src/app/models/PushNotificationRequest";
import { CommandService } from "src/app/services/command.service";
import { NotificationTypeEnum } from 'src/app/models/enum/NotificationTypeEnum';
import { PushNotificationResponse } from 'src/app/models/PushNotificationResponse';
import { Router } from '@angular/router';

@Component({
  selector: "app-publish-push-notification",
  templateUrl: "./publish-push-notification.component.html",
  styleUrls: ["./publish-push-notification.component.scss"],
})
export class PublishPushNotificationComponent implements OnInit {
  request: PushNotificationRequest = new PushNotificationRequest();
  NotificationTypeList: LookupsModel[] = [];
  errMsg: string;
  showLoading: boolean;
  title: string;
  IsArabic = true;
  IsOtherNotificationType: boolean = true;
  error: string = "";
  isSuccessPush = false;
  pushNotificationResponse: PushNotificationResponse = new PushNotificationResponse();
  constructor(
    private _translate: TranslateService,
    private commandService: CommandService,
    private router: Router,
    private translateService: TranslateService,
    private titleService: Title
  ) {
    this.loadAfterLang(this._translate.currentLang);
    this._translate.onLangChange.subscribe(res => this.loadAfterLang(res.lang));

    this.NotificationTypeList = this.loadNotificationTypeList();

  }

  ngOnInit() {
    this.loadAfterLang(this.translateService.currentLang);
    this.translateService.onLangChange.subscribe((res) => {
      this.loadAfterLang(res.lang);
    });
    this.request.TypeId = 1;
  }

  loadAfterLang(lang: string) {
    this.IsArabic = lang === "ar";
    this.title = this.translateService.instant("PushNotification");
    this.titleService.setTitle(this.title);
  }


  onSubmit() {
    try {
      this.showLoading = true;
      this.commandService.PublishPushNotification(this.request).subscribe(res => {
        this.showLoading = false;

        if (res.HasError) this.errMsg = res.ErrorMessage;
        if (res.Result) {
          this.isSuccessPush = true;
          this.SaveNotification();
          this.error = this._translate.instant("validPushNotification")
          this.router.navigateByUrl("/adminhome")
        }
        if (!res.Result) {
          this.isSuccessPush = false;
          this.error = this._translate.instant("invalidPushNotification")
        }

        this.showLoading = false;
      },
        (err) => {
          this.errMsg = err;
          this.showLoading = false;
        }
      );
    } catch (err) {
      this.errMsg = err;
      this.error = this._translate.instant("invalidPushNotification");
      this.showLoading = false;
    }
  }
  SaveNotification() {
    try {
      this.showLoading = true;

      this.commandService.SaveNotification(this.request).subscribe(
        (res) => {
          this.showLoading = false;
          if (res.HasError) {
            this.errMsg = res.ErrorMessage;
            this.showLoading = false;
          }

        },
        (err) => {
          this.errMsg = err;
          this.showLoading = false;
        }
      );
    } catch (err) {
      this.errMsg = err;
      this.showLoading = false;
    }
  }

  loadNotificationTypeList(): LookupsModel[] {
    let result: LookupsModel[] = [];
    [
      { ID: +NotificationTypeEnum.Category, NameAR: "الفئة", NameEN: "Category", checked: false },
      { ID: +NotificationTypeEnum.Product, NameAR: "المنتج", NameEN: "Product", checked: false },
      { ID: +NotificationTypeEnum.Brand, NameAR: "العلامة التجارية", NameEN: "Brand", checked: false },
      { ID: +NotificationTypeEnum.Other, NameAR: "اخرى", NameEN: "Other", checked: false },
    ].forEach(item => result.push(item));
    return result;

  }

  isArabic = () => this._translate.currentLang === "ar" ? true : false;



  onChangeNotificationType(data: any) {
    this.request.TypeId = data;
    if (data == 4) {
      this.IsOtherNotificationType = false;
      this.request.ItemId = null;
    } else
      this.IsOtherNotificationType = true;
  }
}
