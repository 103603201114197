import { tap } from 'rxjs/operators';
import { configration } from 'src/app/shared/config';
import { DiscountClientEligibility, DiscountProductEligibilityEnum } from '../../../../models/enum/usercategory.enum';
import { ProductModel } from './../../../../models/Product.Model';
import { UserInfoDto } from './../../../../models/UserInfoDto.Model';
import { UserDiscountRequestSearch, UserRequestSearch } from './../../../../models/UserRequest.Model';
import { ProductDiscountRequest, ProductRequest } from './../../../../models/ProductRequest.Model';
import { Component, OnInit, ElementRef, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommandService } from 'src/app/services/command.service';
import { QueryService } from 'src/app/services/query.services';
import { debounceTime, distinct, distinctUntilChanged } from 'rxjs/operators';
import { DiscountResponse } from 'src/app/models/Discount/DiscountResponse';
import { VoucherDiscountRequest } from 'src/app/models/Discount/VoucherDiscountRequest';
import { DiscountCategoryEnum } from 'src/app/models/enum/DiscountCategoryEnum';
import { DiscountCustomerRequest, GeneralDiscount } from 'src/app/models/Discount/GeneralDiscount';

@Component({
  selector: 'app-voucher-discount-add-edit',
  templateUrl: './voucher-discount-add-edit.component.html',
  styleUrls: ['./voucher-discount-add-edit.component.scss']
})
export class VoucherDiscountAddEditComponent implements OnInit, AfterViewInit {

  @ViewChild('searchProductText', { static: false }) searchProductSelect: ElementRef;
  productTextSearch = new EventEmitter<string>();
  userTextSearch = new EventEmitter<string>();
  productRequest: ProductRequest = new ProductRequest();
  userRequest: UserRequestSearch = new UserRequestSearch();
  isDiscountUsed: number = 1;

  showLoading: boolean = false;
  IsArabic: boolean = true;
  errors: string[] = [];
  promoCodeTypeText = "PromoCodePercentage";

  userList: UserInfoDto[] = [];
  selectedUserList: UserInfoDto[] = [];
  discountResponse: DiscountResponse;

  voucherDiscountRequest: VoucherDiscountRequest = new VoucherDiscountRequest();

  clientEligibilityEnum = DiscountClientEligibility;
  isSuccessOperation: boolean = false;
  showLoadingProduct = false;
  isLoading: boolean = false;
  isArabic: boolean = false;
  showLoadingUsers = false;
  totalUser: number;
  pageIndexProduct: number = 0;
  pageIndexUser: number = 0;
  discountTypeText: string = 'PromoCodePercentage';
  validDateFrom: string;
  validDateTo: string;
  errorMsg: string;
  title: string;
  msg: string;
  errMsg: string = "";
  error: string = "";
  discountForm: FormGroup;
  constructor(
    private queryService: QueryService,
    private route: ActivatedRoute,
    private _translate: TranslateService,
    private router: Router,
    private titleService: Title,
    private fb: FormBuilder,
    private _commandService: CommandService,
  ) {
    _translate.onLangChange.subscribe(() => this.isArabic = this._translate.currentLang == 'ar');
  }

  ngAfterViewInit(): void {
    this.searchUsers();
  }

  ngOnInit() {
    this.searchUserList(this.userRequest);
    this.loadAfterLang(this._translate.currentLang);
    this._translate.onLangChange.subscribe(res => this.loadAfterLang(res.lang));
    this.getDiscountID();
    this.IntialiazeFormsDiscount(this.voucherDiscountRequest)
  }

  getDiscountID = () => this.route.params.subscribe(params => this.voucherDiscountRequest.ID = +params['ID'])
  changePromoCodeLabel = (value: string) => this.promoCodeTypeText = value;
  setDefaultDiscountResponse = () => this.discountResponse = new DiscountResponse();

  searchUserList(request: UserRequestSearch) {
    this.showLoadingUsers = true;
    try {
      this.queryService.GetUsers(request).subscribe(res => {
        if (res.HasError) this.errorMsg = res.ErrorMessage;
        this.userList = res.Result.ItemList
        this.showLoadingUsers = false;
      },
        (err) => {
          this.errorMsg = err;
          this.showLoadingUsers = false;
        });

    } catch (error) {
      this.errorMsg = error;
      this.showLoadingUsers = false;
    }
    return this.userList;
  }

  getUserByIds(request: UserDiscountRequestSearch) {
    this.showLoadingUsers = true;
    try {
      this.queryService.GetUsersDiscountByIds(request).subscribe(res => {
        if (res.HasError) this.errorMsg = res.ErrorMessage;
        this.userList = res.Result.ItemList
        this.showLoadingUsers = false;
      },
        (err) => {
          this.errorMsg = err;
          this.showLoadingUsers = false;
        });

    } catch (error) {
      this.errorMsg = error;
      this.showLoadingUsers = false;
    }

  }

  loadAfterLang(lang: string) {
    this.isArabic = lang === "ar";
    this.route.params.subscribe(() => {
      this.title = this._translate.instant("VoucherSystem");
      this.titleService.setTitle(this.title);
    });
  }

  discountFormEdit(discountResponse: DiscountResponse) {
    this.discountForm = this.fb.group({
      minmumOrderAmount: [discountResponse.MinmumOrderAmount, Validators.required],
      voucherCode: [discountResponse.VoucherCode, [Validators.required]],
      discountName: [discountResponse.Name, [Validators.required]],
      discountType: [discountResponse.PercentageType.toFixed(), Validators.required],
      discountValue: [discountResponse.Amount, [Validators.required, Validators.pattern('^[0-9]+(.[0-9]{0,3})?$')]],
      appType: [discountResponse.IsRetail? "true":"false", Validators.required],
      validDateTo: [discountResponse.ValidDateTo, Validators.required],
      validDateFrom: [discountResponse.ValidDateFrom, Validators.required],
      appliesToCustomer: [discountResponse.CustomersEligibility.toFixed(), Validators.required],
      selectedUsers: [this.mapCustomerEligibility(discountResponse).map(x => x.UserId)],
    })
  }

  discountFormInitialization() {
    this.discountForm = this.fb.group({
      discountName: ["", [Validators.required]],
      voucherCode: ["", [Validators.required]],
      discountType: ["", Validators.required],
      discountValue: ["", [Validators.required, Validators.pattern('^[0-9]+(.[0-9]{0,3})?$')]],
      validDateFrom: ["", Validators.required],
      validDateTo: ["", Validators.required],
      appType: ["", Validators.required],
      appliesToCustomer: [1, Validators.required],
      minmumOrderAmount: ["", [Validators.required, Validators.pattern('^[0-9]+(.[0-9]{0,3})?$')]],
      selectedUsers: [],
    }, {
      validator: [
        this.dateLessThan('validDateFrom', 'validDateTo'),
        this.checkSelectedCustomer('appliesToCustomer', 'selectedUsers'),
        // this.checkDiscountISProductOrCustomers('appliesToProduct', 'appliesToCustomer')
      ]
    })
  }

  get selectedUsers() { return this.discountForm.get('selectedUsers') }
  changeDiscountLabel = (value: string) => this.discountTypeText = value;

  onScrollToEndUser = () => this.fetchMoreUser();
  onScrollUser(a) {
    if (this.showLoadingUsers || this.userList.length >= this.totalUser)
      return;
    if (a.start + this.userList.length >= configration.pageSize) {
      this.fetchMoreUser();
    }
  }

  fetchMoreUser() {
    let request = new UserRequestSearch();
    this.pageIndexUser++;
    request.PageIndex = this.pageIndexUser;
    let userList = this.userList;
    this.userList = userList.concat(this.searchUserList(request));
  }

  initializeUserRequestSearch(data: any): UserRequestSearch {
    let request = new UserRequestSearch();
    if (this.checkTextAsNumber(data))
      request.PhoneNumber = data;
    else
      request.UserName = data;
    return request;
  }


  private searchUsers() {
    this.userTextSearch.pipe(
      distinctUntilChanged(),
      debounceTime(3000)).subscribe(res => {
        this.userList = null;
        this.userList = this.searchUserList(this.initializeUserRequestSearch(res))
      })
  }

  private checkTextAsNumber = (text: string) => new RegExp('^[0-9]+$').test(text);

  private dateLessThan(from: string, to: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let f = group.controls[from];
      let t = group.controls[to];
      if (f.value > t.value)
        return { dates: "dateToMustBiggerThanFrom" };
      return {};
    }
  }


  private checkSelectedCustomer(controlNameRadio: string, controlNameSelectedList: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let selectedValue = group.controls[controlNameRadio].value;
      let selectedList = group.controls[controlNameSelectedList].value;
      if (selectedValue != this.clientEligibilityEnum.All && selectedList == null)
        return { clientListError: "clientListEMpty" }
      return {}
    }
  }

  private checkDiscountISProductOrCustomers(SelectedProductcontrolName: string, SelectedUsercontrolName: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let selectedPRoduct = group.controls[SelectedProductcontrolName].value;
      let selectedUser = group.controls[SelectedUsercontrolName].value;
      if (selectedPRoduct == 3 && selectedUser == 3)
        return { discountProductOrUser: "discountProductOrUser" }
      return {};
    }
  }

  checkSelectedUserLis() {
    if (this.discountForm.get('appliesToCustomer').value == this.clientEligibilityEnum.All)
      this.selectedUsers.setValue(null);
  }

  clearSelectedUsers = () => this.selectedUsers.setValue(null);

  Save() {
    let discountrequest = this.mapFormValueToDiscountRequest()
    try {
      this.showLoading = true;
      this.isLoading = true;
      this._commandService.AddEditDiscountVoucher(discountrequest)
        .pipe(tap(() => {
          this.isLoading = false;
          this.showLoading = false;
        }))
        .subscribe(res => {
          if (!res.Result) {
            this.errMsg = res.ErrorMessage;
            this.isSuccessOperation = false;
            this.error = this._translate.instant("invalidDiscountCode");
          }
          else {
            this.isSuccessOperation = true;
            this.error = this._translate.instant("validDiscountCode");
            this.router.navigateByUrl("/adminhome/voucherdiscount")
          }

        },
          (err) => {
            this.error = this._translate.instant("invalidDiscountCode");
            this.showLoading = false;
          },
          () => this.showLoading = false);
    } catch (error) {
      this.showLoading = false;
    }
  }

  mapFormValueToDiscountRequest(): VoucherDiscountRequest {
    let response = new VoucherDiscountRequest();
    response.Name = this.discountForm.value.discountName;
    response.VoucherCode = this.discountForm.value.voucherCode;
    response.PercentageType = this.discountForm.value.discountType;
    response.MinmumOrderAmount = this.discountForm.value.minmumOrderAmount;
    response.Amount = this.discountForm.value.discountValue;
    response.ValidDateFrom = this.discountForm.value.validDateFrom;
    response.ValidDateTo = this.discountForm.value.validDateTo;
    response.CustomersEligibility = this.discountForm.value.appliesToCustomer;
    response.DiscountCustomers = this.createSelectedCustomers(this.discountForm.value.selectedUsers);
    response.DiscountCategory = DiscountCategoryEnum.Voucher;
    response.ID = this.voucherDiscountRequest.ID != 0 ? this.voucherDiscountRequest.ID : 0;
    response.IsRetail = this.discountForm.value.appType;
    return response;
  }

  createSelectedCustomers(userIds: string[]): DiscountCustomerRequest[] {
    if (userIds == null) return null;
    let customerList: DiscountCustomerRequest[] = [];
    userIds.forEach(item => {
      let user = new DiscountCustomerRequest();
      user.CustomerID = item;
      user.DiscountCategoryEnum = DiscountCategoryEnum.Voucher;
      customerList.push(user);
    });
    return customerList;
  }

  IntialiazeFormsDiscount(request: VoucherDiscountRequest) {
    if (request.ID !== 0 && request.ID)
      this.getDiscountByID(request)
    else
      this.discountFormInitialization()
  }

  getDiscountByID(request: VoucherDiscountRequest) {
    try {
      this.showLoading = true;
      this.showLoadingUsers = true;
      this.errMsg = '';
      let generalRequest = new GeneralDiscount();
      generalRequest.ID = request.ID;
      this.queryService.GetDiscountVoucherByID(generalRequest)
        .pipe(tap(() => {
          this.showLoadingUsers = false;
          this.showLoading = false
        }))
        .subscribe(res => {
          if (res.HasError)
            this.errMsg = res.ErrorMessage;

          this.discountResponse = res.Result;
          this.discountFormEdit(res.Result)
          this.selectedUserList = this.mapCustomerEligibility(res.Result);
          this.userList = this.mapCustomerEligibility(res.Result);
        },
          Err => {
            this.errMsg = Err;
            this.showLoading = false;
            this.showLoadingUsers = false;
          })
    } catch (error) {
      this.errMsg = error;
      this.showLoading = false;
      this.showLoadingUsers = false;
    }
  }

  mapCustomerEligibility(request: DiscountResponse): UserInfoDto[] {
    let result: UserInfoDto[] = [];
    request.DiscountCustomers.forEach(item => {
      let user = new UserInfoDto();
      user.Name = item.CustomerName;
      user.UserId = item.CustomerID;
      user.PhoneNumber = item.CustomerPhone;
      result.push(user);
    });
    return result;
  }



}
