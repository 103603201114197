import { SalonTypeEnum } from './enum/SalonTypeEnum';
import { UserAddressDto } from './UserAddressDto.Model';
export class SalesPersonDto {
    Id: string;
    UserCode: number;
    UserCategoryId: number | null;
    UserName: string;
    Email:string;
    Name: string;
    PhoneNumber: string;
    Password: string;
    ConfirmPassword:string;
    IsActive: boolean | null;
    UserCategoryName : string;
    Notes : string;
    UserAddressDto: UserAddressDto;
    SalesName:string;
    SalesNumber:string;
    CreatedTime: Date;
    OrdersCount: number;
    OrdersTotal: number;
    LastOrderDate: Date;

    City: string;
    Area: string;
    AddressDescription: string;

    SalonType:SalonTypeEnum;
    SalonTypeName?:string;
    SalonName:string;
    IsRetail?:boolean;
}
