import { BehaviorSubject } from 'rxjs';
import { ProductPatchModel } from './../models/ProductPatch.model';
import { Injectable, EventEmitter, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';

import { ProductRequest } from '../models/ProductRequest.Model';
import { LookupsModel } from '../models/Lookups.model';
import { CityZoneDto } from '../models/CityZoneDto.Model';
//  import { ModalDirective } from 'ngx-bootstrap';
import { Action, ActionType } from '../models/Action';
import { UserAddressDto } from '../models/UserAddressDto.Model';
import { PatchDto } from '../models/PatchDto.Model';
import { AppState } from './app.state';

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {
  private product: ProductPatchModel = new ProductPatchModel();
  productSubject: BehaviorSubject<ProductPatchModel> =new BehaviorSubject<ProductPatchModel>(this.product);
  public state: AppState = new AppState();
  private subject: Subject<Action<any>> = new Subject<Action<any>>();
  categorySubj: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  isProductDetailsOpen: boolean = false;
  cartSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private newCategoryID: number;
  private cartItemsNumbers: number;

  constructor() { }

  getAction() {
    return this.subject;
  }
  updateCartNumber(quantity: number) {
    this.cartItemsNumbers = quantity;
    this.cartSubject.next(this.cartItemsNumbers);
  }

  UserAddressListClosed() {
    let action: Action<UserAddressDto> = new Action();
    action.actionType = ActionType.UserAddressListClosed;

    this.subject.next(action);
  }
  UserAddressSelected(selectedAddress: UserAddressDto) {
    let action: Action<UserAddressDto> = new Action();
    action.actionType = ActionType.UserAddressSelected;
    action.Result = selectedAddress;

    this.subject.next(action);
  }

  UserEditAddressClosed() {
    let action: Action<UserAddressDto> = new Action();
    action.actionType = ActionType.UserEditAddressClosed;

    this.subject.next(action);
  }
  UserEditAddressSaved() {
    let action: Action<UserAddressDto> = new Action();
    action.actionType = ActionType.UserEditAddressSaved;

    this.subject.next(action);
  }
  CityZoneSelected(city, zone) {

    var action = new Action<CityZoneDto>();

    action.actionType = ActionType.CityZoneSelected;

    action.Result = new CityZoneDto();
    action.Result.City = city;
    action.Result.ZoneList = [];
    action.Result.ZoneList.push(zone);

    this.subject.next(action);
  }

  CityZoneClosed() {


    var action = new Action<CityZoneDto>();

      action.actionType = ActionType.CityZoneClosed;

    this.subject.next(action);
  }

  showAlert(error){
    var action = new Action<boolean>();

    action.Result = error;
    action.actionType = ActionType.ShowAlert;

    this.subject.next(action);
  }

  showAlertInof(info) {
    var action = new Action<boolean>();

    action.Result = info;
    action.actionType = ActionType.ShowAlertInfo;

    this.subject.next(action);
  }

  closeAlertInfo() {
    var action = new Action<null>();

    action.actionType = ActionType.CloseAlertInof;

    this.subject.next(action);
  }
  showProductOnList(){
    var action = new Action<true>();


    action.actionType = ActionType.SendFromListToCart;

    this.subject.next(action);
  }
  closeAlert(){
    var action = new Action<null>();


    action.actionType = ActionType.ColseAlert;

    this.subject.next(action);
  }
  queryProducts() {
    let action : Action<string> = new Action();
    action.actionType = ActionType.QueryProducts;

    this.subject.next(action);
  }
  closeProductFilter() {
    let action : Action<string> = new Action();
    action.actionType = ActionType.CloseProductFilter;

    this.subject.next(action);
  }
  setCloseAdminSideMenu(flag){
    var action = new Action<boolean>();

    action.Result = flag;
    action.actionType = ActionType.AdminCloseSideMenu;

    this.subject.next(action);
  }


  updateProductPatch(patch : PatchDto){
    let action : Action<PatchDto> = new Action();
    action.actionType = ActionType.UpdateProductPatch;
    action.Result = patch;
    this.subject.next(action);
  }

  showSideMenuAction(flag:boolean) {

    var action = new Action<boolean>();

    action.Result = flag;
    action.actionType = ActionType.ShowHideSideMenu;

    this.subject.next(action);
  }


  setGeneralError(error){
    var action = new Action<boolean>();

    action.Result = error;
    action.actionType = ActionType.AlertMsg;

    this.subject.next(action);
  }

  categoryRedirection(id: number) {
    this.newCategoryID = id;
    this.categorySubj.next(this.newCategoryID);
  }
  // getAction(): Subject<Action>{
  //   return this.subject
  // }
  // searchProductRequest(sProductRequest :ProductRequest){

  //   let action=new Action();
  //   action.sProductRequest=sProductRequest;
  //   action.actionType=ActionType.FromFilterProductToProduct;
  //   this.subject.next(action);
  // }
}
