export class SalesPersonRequest {
  public UserId: string;
  UserCode: number;
  PageIndex: number;
  PageSize: number;
  Filter: string;
  Lang: string;
  UserName: string;
  PhoneNumber: string;
  SalesPersonIds: string[];
  CreatedTimeFrom: Date | null;
  CreatedTimeTo: Date | null;
  LastOrderDateFrom: Date | null;
  LastOrderDateTo: Date | null;
  Active: boolean;
  NotActive: boolean;
  FullName: string;
  Email: string;
  UserCategories: number[] = [];

  UserCategoryId: number;
  SortColumn: string;
  SortDirection: number;
  IsExcel: boolean = false;

  OrdersCount: number;
  OrdersTotal: number;
  LastOrderDate: Date;

  OrdersTotalPriceFrom: number;
  OrdersTotalPriceTo: number;

  OrdersCountFrom: number;
  OrdersCountTo: number;

  CityId: number;
  ZoneIdS: number[];
  OrdersStatusIds: number[];
  UserCategoryIds: number[];

  IsRetail?: boolean;
}
