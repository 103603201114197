<div class="d-flex flex-column w-100 h-100 p-2">
    <div class="bd-highlight">
        <div class="row">
            <div class="col-6">
                <div class="bd-highlight">
                    <h4>{{ title }}</h4>
                </div>
            </div>
            <div class="col-6 d-flex justify-content-end">
                <!-- <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
          <label class="form-check-label" for="flexSwitchCheckDefault">Default switch checkbox input</label>
        </div> -->

                <div class="checkbox switcher">
                    <label for="stopreload">
            <small class="p-2">{{ "stopreload" | translate }}</small>
            <input type="checkbox" id="stopreload" value="" [(ngModel)]="stopReload"/>
            <span><small></small></span>
          </label>
                </div>



                <a class="btn bg-red text-white align-self-end m-2" data-toggle="collapse" href="#advancedFilter" role="button" aria-expanded="false" aria-controls="advancedFilter">{{ "Filter" | translate }}</a
        >
        <button
          class="btn bg-danger text-white align-self-end m-2"
          style="margin-top: 35px"
          (click)="onSearchSubmit()"
        >
          {{ "Search" | translate }}
        </button>

        <button  
          (click)="exportCustomersToExcel()"
          class="btn bg-info text-white align-self-end m-2"
          type="button"  
        >
          {{ "excel" | translate }}
        </button>
      </div>
    </div>
  </div>

  <!-- start this to make loading center in page on any screen size -->
  <div
    *ngIf="showLoading"
    class="
      d-flex
      flex-column
      z-index-3
      position-fixed
      w-100
      h-100
      justify-content-center
      align-items-center
      bg-white
    "
    style="opacity: 0.5"
  >
    <div class="p-2 align-self-center">
      <span class="load-more-List text-center vw-100 vh-100">
        <span class="spinner-border text-primary" role="status"> </span>
      </span>
    </div>
  </div>

  <div class="bd-highlight">
    <div class="row">
      <div
        *ngIf="showDeleteInfo"
        class="alert alert-success fixed-top text-center m-1"
      >
        <app-alert-message [msg]="infoMsg | translate"></app-alert-message>
      </div>
    </div>
    <!-- #region Search  -->
    <!-- #region Search Criteria -->
    <div class="bd-highlight collapse multi-collapse" id="advancedFilter">
      <div class="card card-body">
        <div class="row pb-1">
          <div class="col-3">
            <label
              >{{ "ClientCreatedDate" | translate }} {{ "From" | translate }}
            </label>
            <input
              class="form-control col-9 rounded"
              type="text"
              [(ngModel)]="MobileUserRequest.CreatedTimeFrom"
              bsDatepicker
              [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MM-YYYY' }"
            />
          </div>

          <div class="col-3">
            <label>
              {{ "ClientCreatedDate" | translate }} {{ "To" | translate }}
            </label>
            <input
              class="form-control col-9 rounded"
              type="text"
              [(ngModel)]="MobileUserRequest.CreatedTimeTo"
              bsDatepicker
              [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MM-YYYY hh:mm' }"
            />
          </div>

          <div class="col-3">
            <label> {{ "Code" | translate }} </label>
            <input
              class="form-control col-9 rounded"
              [(ngModel)]="MobileUserRequest.UserCode"
              (keyup.enter)="onSearchSubmit()"
            />
          </div>

          <div class="col-3">
            <label> {{ "ClientPhoneNumber" | translate }} </label>
            <input
              class="form-control col-9 rounded"
              [(ngModel)]="MobileUserRequest.PhoneNumber"
              (keyup.enter)="onSearchSubmit()"
            />
          </div>

          <div class="col-3">
            <label> {{ "ClientName" | translate }} </label>
            <input
              class="form-control col-9 rounded"
              [(ngModel)]="MobileUserRequest.FullName"
              (keyup.enter)="onSearchSubmit()"
            />
          </div>

          <div class="col-3">
            <label> {{ "OrderCountFrom" | translate }} </label>
            <input
              type="number"
              onlynumber
              class="form-control col-9 rounded"
              [(ngModel)]="MobileUserRequest.OrdersCountFrom"
              (keyup.enter)="onSearchSubmit()"
            />
          </div>

          <div class="col-3">
            <label> {{ "OrderCountTo" | translate }} </label>
            <input
              type="number"
              onlynumber
              class="form-control col-9 rounded"
              [(ngModel)]="MobileUserRequest.OrdersCountTo"
              (keyup.enter)="onSearchSubmit()"
            />
          </div>

          <div class="col-3">
            <label> {{ "OrderTotalFrom" | translate }} </label>
            <input
              type="number"
              step="0.01"
              class="form-control col-9 rounded"
              [(ngModel)]="MobileUserRequest.OrdersTotalPriceFrom"
              (keyup.enter)="onSearchSubmit()"
            />
          </div>

          <div class="col-3">
            <label> {{ "OrderTotalTo" | translate }} </label>
            <input
              type="number"
              step="0.01"
              class="form-control col-9 rounded"
              [(ngModel)]="MobileUserRequest.OrdersTotalPriceTo"
              (keyup.enter)="onSearchSubmit()"
            />
          </div>

          <div class="col-3">
            <label> {{ "SalesPersonName" | translate }} </label>
            <kendo-multiselect
            class="form-control col-9 rounded"
            name="salesPersonDDL"
            [data]="salesPersonLst"
            [textField]="'Name'"
            [valueField]="'Id'"
            placeholder="{{ 'SalesPerson' | translate }}"
            [valuePrimitive]="true"
            [(ngModel)]="MobileUserRequest.SalesPersonIds"
            #salesPersonDDL="ngModel"
          >
          <ng-template kendoComboBoxNoDataTemplate>
            <h4>
              <span class="k-icon k-i-warning"></span><br /><br />
              {{ "NotAvailable" | translate }}
            </h4>
          </ng-template>
          </kendo-multiselect>
          </div>

          <div class="col-3">
            <label> {{ "City" | translate }} </label>
            <kendo-combobox
              class="form-control col-9 rounded"
              name="CityDDL"
              [data]="cityList"
              [valuePrimitive]="true"
              [filterable]="true"
              [loading]="cityDDLLoading"
              [valueField]="'ID'"
              [textField]="IsArabic ? 'NameAR' : 'NameEN'"
              [(ngModel)]="MobileUserRequest.CityId"
              [filterable]="true"
              #CityDDL="ngModel"
              placeholder="{{ 'City' | translate }}"
              (selectionChange)="SelectedCity($event)"
              [suggest]="true"

            >
              <ng-template kendoComboBoxNoDataTemplate>
                <h4>
                  <span class="k-icon k-i-warning"></span><br /><br />
                  {{ "NotAvailable" | translate }}
                </h4>
              </ng-template>
            </kendo-combobox>
          </div>

          <div class="col-3">
            <label> {{ "Zone" | translate }} </label>
            <kendo-multiselect
            class="form-control col-9 rounded"
            name="ZoneDDL"
            [data]="zoneList"
            [textField]="IsArabic ? 'NameAR' : 'NameEN'"
            [valueField]="'ID'"
            placeholder="{{ 'Zone' | translate }}"
            [valuePrimitive]="true"
            [(ngModel)]="MobileUserRequest.ZoneIdS"
            #ZoneDDL="ngModel"
          >
          <ng-template kendoComboBoxNoDataTemplate>
            <h4>
              <span class="k-icon k-i-warning"></span><br /><br />
              {{ "NotAvailable" | translate }}
            </h4>
          </ng-template>
          </kendo-multiselect>

          </div>

          <div class="col-2 offset-2">
            <label
              class="
                d-block
                w-100
                hold-checkbox
                position-relative
                align-self-end
              "
              style="padding-top: 45px"
            >
              <input
                class="form-control rounded"
                type="checkbox"
                name="IsActive"
                [(ngModel)]="MobileUserRequest.Active"
              />
              <span class="checkmark" style="margin-top: 45px"></span>
              <span class="d-inline-block">{{ "IsActive" | translate }}</span>
            </label>
          </div>

          <div class="col-2">
            <label
              class="
                d-block
                w-100
                hold-checkbox
                position-relative
                align-self-end
              "
              style="padding-top: 45px"
            >
              <input
                class="form-control rounded"
                type="checkbox"
                name="NotActive"
                [(ngModel)]="MobileUserRequest.NotActive"
              />
              <span class="checkmark" style="margin-top: 45px"></span>
              <span class="d-inline-block">{{ "NotActive" | translate }}</span>
            </label>
          </div>

          <div class="col-2">
            <button
              class="btn btn-info border-0 mr-1 ml-1"
              style="margin-top: 35px"
              (click)="clearSearchFilter()"
            >
              {{ "Clear" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Table Mobile Users-->
  <div class="h-100 overflow-auto p-2">
    <table class="table table-striped" id="header-fixed">
      <thead>
        <tr>

          <th scope="col">
            {{ "Code" | translate }}
            <i
              (click)="onSortClick($event, 'Code')"
              class="my-icon fa fa-chevron-up"
            ></i>
          </th>

          <th scope="col">
            {{ "ClientName2" | translate }}
            <i
              (click)="onSortClick($event, 'ClientName')"
              class="my-icon fa fa-chevron-up"
            ></i>
          </th>
          <th scope="col">
            {{ "ClientPhoneNumber2" | translate }}
            <i
              (click)="onSortClick($event, 'ClientPhoneNumber')"
              class="my-icon fa fa-chevron-up"
            ></i>
          </th>
          <th scope="col">
            {{ "SalesName2" | translate }}
            <i
              (click)="onSortClick($event, 'SalesName')"
              class="my-icon fa fa-chevron-up"
            ></i>
          </th>
          <th scope="col">
            {{ "CreatedTime" | translate }}
            <i
              (click)="onSortClick($event, 'CreatedTime')"
              class="my-icon fa fa-chevron-up"
            ></i>
          </th>
          <th scope="col">
            {{ "OrdersCount" | translate }}
            <i
              (click)="onSortClick($event, 'OrdersCount')"
              class="my-icon fa fa-chevron-down"
            ></i>
          </th>
          <th scope="col">
            {{ "OrdersTotal" | translate }}
            <i
              (click)="onSortClick($event, 'OrdersTotal')"
              class="my-icon fa fa-chevron-up"
            ></i>
          </th>
          <th scope="col">
            {{ "LastOrderDate" | translate }}
            <i
              (click)="onSortClick($event, 'LastOrderDate')"
              class="my-icon fa fa-chevron-up"
            ></i>
          </th>

          <th scope="col" style="width: 10%">
            {{ "Area" | translate }}
          </th>

          <th scope="col">
            {{ "ClientStatus" | translate }}
          </th>

          <th scope="col">
            {{ "Actions" | translate }}
            <i class="fa fa-bolt" aria-hidden="true"></i>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let customer of MobileUserList">
         <td>
           {{ customer.UserCode }}
         </td>
          <td>
            <a
              style="color: blue"
              href="/adminhome/adminorderlist/{{ customer.Id }}"
              target="_blank"
            >
              {{ customer.Name }}
            </a>
                </td>
                <td>{{ customer.PhoneNumber }}</td>
                <td>{{ customer.SalesName }}</td>
                <td class="text-wrap" style="width: 10rem;">
                    <span>{{ customer.CreatedTime | date: "dd-MM-yyyy" }}</span>
                    <br>
                    <span>{{ customer.CreatedTime | date: "h:mm a" }}</span>
                </td>
                <td>{{ customer.CreatedTime | date }}</td>
                <td>{{ customer.OrdersCount }}</td>
                <td>{{ customer.OrdersTotal }}</td>
                <td>{{ customer.LastOrderDate | date }}</td>
                <td style="width: 10%">
                    <span *ngIf="IsArabic && customer.UserAddressDto != null">
              {{ customer.UserAddressDto.City.NameAR }}
              <br />
              {{ customer.UserAddressDto.Zone.NameAR }}
            </span>
                    <span *ngIf="!IsArabic && customer.UserAddressDto != null">
              {{ customer.UserAddressDto.City.NameEN }}
              <br />
              {{ customer.UserAddressDto.Zone.NameEN }}
            </span>
                </td>
                <td>
                    <span *ngIf="customer.IsActive" style="color: green">
              {{ "ClientActive" | translate }}
            </span>
                    <span *ngIf="!customer.IsActive" style="color: red">
              {{ "ClientNotActive" | translate }}
            </span>
                </td>

                <td>
                    <div class="dropdown">
                        <a class="btn btn-primary" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fa fa-bars" aria-hidden="true" style="color: rgb(10, 10, 10)"></i>
                        </a>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">

                            <a class="dropdown-item" routerLink="/adminhome/client-details/{{ customer.Id }}">
                                <i class="fa fa-info-circle" aria-hidden="true" style="color: slategray !important"></i> {{ "Details" | translate }}
                            </a>

                            <a class="dropdown-item" routerLink="/adminhome/editmobileuser/{{ customer.Id }}">
                                <i class="fa fa-lg fa-pencil-square-o" aria-hidden="true" style="color: slategray !important"></i> {{ "Edit" | translate }}
                            </a>

                            <a class="dropdown-item" routerLink="/adminhome/change-password/{{ customer.Id }}">
                                <i class="fa fa-key" aria-hidden="true" style="color: slategray !important"></i> {{ "ChangePassword" | translate }}
                            </a>

                            <a class="dropdown-item" (click)="deleteClient(customer.Id)" style="cursor: pointer">
                                <i class="fa fa-lg fa-trash-o" aria-hidden="true" style="color: red !important"></i> {{ "Delete" | translate }}
                            </a>
                        </div>
                    </div>
                </td>
                </tr>
                </tbody>
                </table>

                <div *ngIf="MobileUserList?.length == 0" class="
        d-flex
        flex-column
        z-index-3
        w-100
        h-100
        justify-content-center
        align-items-center
        bg-white
      ">
                    <label class="la">{{ "NoDataFound" | translate }}</label>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-xs-12 col-12 p-0">
                    <app-pagination-component [ItemsCount]="Total" (changePage)="onPagingChange($event)">
                    </app-pagination-component>
                </div>
            </div>

            <!-- Address Modal -->
            <div class="modal fade" id="addressModal" tabindex="-1" role="dialog" aria-labelledby="addressModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="addressModalLabel">
                                {{ "ClientAddress" | translate }}
                            </h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
                        </div>
                        <div class="modal-body">
                            <div *ngIf="userAddressDto.ReceiverName != null">
                                <ul>
                                    <li>
                                        <label>
                  {{ "ReciverName" | translate }}:
                  {{ userAddressDto.ReceiverName }}
                </label>
                                    </li>
                                    <li>
                                        <label>
                  {{ "BuildNumber" | translate }}:
                  {{ userAddressDto.BuildNumber }}
                </label>
                                    </li>
                                    <li>
                                        <label>
                  {{ "Street" | translate }}:
                  {{ userAddressDto.Street }}
                </label>
                                    </li>
                                    <li>
                                        <label>
                  {{ "ReciverMobile" | translate }}:
                  {{ userAddressDto.ReciverMobile }}
                </label>
                                    </li>
                                    <li>
                                        <label>
                  {{ "CityName" | translate }}:
                  {{
                    IsArabic
                      ? userAddressDto.City.NameAR
                      : userAddressDto.City.NameEN
                  }}
                </label>
                                    </li>
                                    <li>
                                        <label>
                  {{ "ZoneName" | translate }}:
                  {{
                    IsArabic
                      ? userAddressDto.Zone.NameAR
                      : userAddressDto.Zone.NameEN
                  }}
                </label>
                                    </li>
                                    <li>
                                        <label>
                  {{ "Description" | translate }}:
                  {{ userAddressDto.Description }}
                </label>
                                    </li>
                                </ul>
                            </div>
                            <div *ngIf="userAddressDto.ReceiverName == null" class="
              d-flex
              flex-column
              z-index-3
              w-100
              h-100
              justify-content-center
              align-items-center
              bg-white
            ">
                                <label class="la">{{ "NoDataFound" | translate }}</label>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">
            {{ "Close" | translate }}
          </button>
                        </div>
                    </div>
                </div>
            </div>

            <!--End Table-->

            <!-- <div class="h-100 overflow-auto p-2">
    <div class="list-product w-100 row">
      <div
        *ngFor="let sp of MobileUserLst"
        class="p-2 col-lg-4 col-md-4 col-xs-12 col-sm-12"
      >
        <div
          *ngIf="sp.IsActive"
          [class]=" sp.IsActive ? 'border rounded h-100 d-flex flex-column p-1 each__user__info' : 'border border-danger rounded h-100 d-flex flex-column p-1 each__user__info'"
        >
          <div class="p-2 h-25 bd-highlight text-secondary">
            {{ sp.Email == null ? "No Email" : sp.Email }}
          </div>
          <div class="p-2 h-25 bd-highlight text-secondary">
            {{ sp.Name }}
          </div>
          <div class="p-2 h-50 bd-highlight text-secondary">
            {{ sp.PhoneNumber }}
          </div>
          <div
            class="p-2 h-50 bd-highlight text-secondary"
            *ngIf="sp.UserAddressDto"
          >
            {{ sp.UserAddressDto.City.NameAR }} -
            {{ sp.UserAddressDto.Zone.NameAR }}
          </div>

          <div class="p-2 h-50 bd-highlight text-secondary">
            {{ "SalesName" | translate }} : {{ sp.SalesName }}
          </div>
          <div class="p-2 h-50 bd-highlight text-secondary">
            {{ "SalesNumber" | translate }} : {{ sp.SalesNumber }}
          </div>

          <div class="p-2 h-50 bd-highlight text-secondary">
            {{ "ClientCreatedDate" | translate }} :
            {{ sp.CreatedTime | date: "medium" }}
          </div>

          <div
            class="
              p-2
              h-25
              d-flex
              align-self-end align-items-end
              justify-content-end
              bd-highlight
            "
          >
            <span
              class="text-secondary d-inline-block"
              routerLink="/adminhome/editmobileuser/{{ sp.Id }}"
            >
              <i class="fa fa-lg fa-pencil-square-o" aria-hidden="true"></i>
            </span>

            <span
              class="text-secondary d-inline-block ml-4"
              (click)="deleteClient(sp.Id)"
            >
              <i class="fa fa-lg fa-trash-o" aria-hidden="true"></i>
            </span>

            <span
              class="text-secondary d-inline-block ml-4"
              routerLink="/adminhome/change-password/{{ sp.Id }}"
            >
              <i class="fa fa-key" aria-hidden="true"></i>
            </span>
          </div>
          <div
            class="
              p-2
              h-25
              d-flex
              align-self-end align-items-end
              justify-content-end
              bd-highlight
            "
          >

            <a routerLink="/adminhome/addneworder/{{ sp.Id }}"
            target="_blank"
            class="btn btn-secondary bd-highlight border-0 mr-1 ml-1"
            >
            {{ "AddOrder" | translate }}
          </a>
            <a routerLink = "/adminhome/adminorderlist/{{ sp.Id }}"
            target="_blank"
            class="btn btn-secondary bg-red border-0 mr-1 ml-1">
            {{ "myOrder" | translate }}
          </a>
          </div>
        </div>

        <div
          *ngIf="!sp.IsActive"
          class="
            border border-danger
            rounded
            h-100
            d-flex
            flex-column
            p-1
            each__user__info
          "
        >
          <div class="p-2 h-25 bd-highlight text-secondary">
            {{ sp.Email == null ? "No Email" : sp.Email }}
          </div>
          <div class="p-2 h-25 bd-highlight text-secondary">
            {{ sp.Name }}
          </div>
          <div class="p-2 h-50 bd-highlight text-secondary">
            {{ sp.PhoneNumber }}
          </div>
          <div
            class="p-2 h-50 bd-highlight text-secondary"
            *ngIf="sp.UserAddressDto"
          >
            {{ sp.UserAddressDto.City.NameAR }} -
            {{ sp.UserAddressDto.Zone.NameAR }}
          </div>

          <div class="p-2 h-50 bd-highlight text-secondary">
            {{ "SalesName" | translate }} : {{ sp.SalesName }}
          </div>
          <div class="p-2 h-50 bd-highlight text-secondary">
            {{ "SalesNumber" | translate }} : {{ sp.SalesNumber }}
          </div>

          <div class="p-2 h-50 bd-highlight text-secondary">
            {{ "ClientCreatedDate" | translate }} :
            {{ sp.CreatedTime | date: "medium" }}
          </div>

          <div class="p-2 h-50 bd-highlight text-danger">
            {{ "UserNotActive" | translate }}
          </div>

          <div
            class="
              p-2
              h-25
              d-flex
              align-self-end align-items-end
              justify-content-end
              bd-highlight
            "
          >
            <span
              class="text-secondary d-inline-block"
              routerLink="/adminhome/editmobileuser/{{ sp.Id }}"
            >
              <i class="fa fa-lg fa-pencil-square-o" aria-hidden="true"></i>
            </span>

            <span
              class="text-secondary d-inline-block ml-4"
              (click)="deleteClient(sp.Id)"
            >
              <i class="fa fa-lg fa-trash-o" aria-hidden="true"></i>
            </span>

            <span
              class="text-secondary d-inline-block ml-4"
              routerLink="/adminhome/change-password/{{ sp.Id }}"
            >
              <i class="fa fa-key" aria-hidden="true"></i>
            </span>
          </div>
          <div
            class="
              p-2
              h-25
              d-flex
              align-self-end align-items-end
              justify-content-end
              bd-highlight
            "
          >


          <a routerLink="/adminhome/addneworder/{{ sp.Id }}"
          target="_blank"
          class="btn btn-secondary bd-highlight border-0 mr-1 ml-1"
          >
          {{ "AddOrder" | translate }}
        </a>
            <a routerLink = "/adminhome/adminorderlist/{{ sp.Id }}"
            target="_blank"
            class="btn btn-secondary bg-red border-0 mr-1 ml-1">
            {{ "myOrder" | translate }}
          </a>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="MobileUserLst?.length == 0"
      class="
        d-flex
        flex-column
        z-index-3
        w-100
        h-100
        justify-content-center
        align-items-center
        bg-white
      "
    >
      <label class="la">{{ "NoDataFound" | translate }}</label>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-xs-12 col-12 p-0">
      <app-pagination-component
        [ItemsCount]="Total"
        (changePage)="onPagingChange($event)"
      >
      </app-pagination-component>
    </div>
  </div> -->
        </div>