import { Pipe } from '@angular/core';
import { map, switchMap, tap, filter } from 'rxjs/operators';
import { QuantityDiscountRequest, QuantityPriceRequest } from '../../../../models/Discount/QuantityDiscountRequest';
import { ProductDiscountRequest } from './../../../../models/ProductRequest.Model';
import { ProductModel } from './../../../../models/Product.Model';
import { FormArray, FormGroup, Validators } from '@angular/forms';
import { Component, ElementRef, EventEmitter, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommandService } from 'src/app/services/command.service';
import { CommunicationService } from 'src/app/services/communication.service';
import { LoginAdminService } from 'src/app/services/login-admin.service';
import { QueryService } from 'src/app/services/query.services';
import { ProductRequest } from 'src/app/models/ProductRequest.Model';
import { debounceTime, distinct, distinctUntilChanged } from 'rxjs/operators';
import { configration } from 'src/app/shared/config';
import { fromEvent } from 'rxjs';
import { QuantityDiscountResponse } from 'src/app/models/Discount/QuantityDiscountResponse';
import { QuantityDiscountRequestPortal } from 'src/app/models/Discount/QuantityDiscountRequestPortal';

@Component({
  selector: 'app-quantity-discount-add-edit',
  templateUrl: './quantity-discount-add-edit.component.html',
  styleUrls: ['./quantity-discount-add-edit.component.scss']
})
export class QuantityDiscountAddEditComponent implements OnInit, AfterViewInit {

  @ViewChild('searchProductText', { static: false }) searchProductSelect: ElementRef;
  productTextSearch = new EventEmitter<string>();
  productRequest: ProductRequest = new ProductRequest();
  productList: ProductModel[] = [];
  totalProduct: number;
  quantityDiscountResponse: QuantityDiscountResponse[];
  quantityDiscountRequest: QuantityDiscountRequest = new QuantityDiscountRequest();
  discountID: number;
  isSuccessOperation: boolean = false;
  showLoading: boolean = false;
  showLoadingProduct = false;
  isLoading: boolean = false;
  isArabic: boolean = false;
  pageIndexProduct: number = 0;
  pageIndexUser: number = 0;
  errors: string[] = [];

  errorMsg: string = "";
  title: string;
  msg: string;
  errMsg: string = "";
  error: string = "";
  promoCodeTypeText = "PromoCodePercentage";
  discountTypeText: string = 'PromoCodePercentage';
  PercentageType;
  quantityDiscountForm: FormGroup;

  constructor(
    private queryService: QueryService,
    private route: ActivatedRoute,
    private _translate: TranslateService,
    private router: Router,
    private commandService: CommandService,
    private titleService: Title,
    private _communicationService: CommunicationService,
    private _adminLogin: LoginAdminService,
    private fb: FormBuilder,
    private _queryService: QueryService,
    private _commandService: CommandService,
  ) {
    _translate.onLangChange.subscribe(() => this.isArabic = this._translate.currentLang == 'ar');
  }

  ngOnInit() {
    this.getDiscountID();
    this.discountFormInitialization()
    this.loadProducts(this.quantityDiscountRequest.ProductID)
    this.loadAfterLang(this._translate.currentLang);
    this._translate.onLangChange.subscribe(res => this.loadAfterLang(res.lang));
    this.IntialiazeFormsDiscount(this.quantityDiscountRequest)
  }

  ngAfterViewInit(): void {
    this.searchProducts();
    // this.searchProduct();
  }

  getDiscountID = () => this.route.params.subscribe(params => this.quantityDiscountRequest.ProductID = +params['ID']);
  private checkTextAsNumber = (text: string) => new RegExp('^[0-9]+$').test(text);
  private checkTextAsEnglish = (text: string) => (/^[~`!@#$%^&*()_+=[\]\{}|;':",.\/<>?a-zA-Z0-9-]+$/).test(text);


  changePromoCodeLabel = (value: string) => this.promoCodeTypeText = value;
  clearSelectedProducts = () => {
    this.selectedProduct.setValue(null);
    this.productRequest.ID = null;
    this.searchProductList(this.productRequest)
  }

  loadAfterLang(lang: string) {
    this.isArabic = lang === "ar";
    this.route.params.subscribe(() => {
      this.title = this._translate.instant("QuantityDiscount");
      this.titleService.setTitle(this.title);
    });
  }

  // searchProduct() {
  //   fromEvent(this.searchProductSelect.nativeElement, 'keyup')
  //     .pipe(distinctUntilChanged(),
  //       debounceTime(3000),
  //       map(x => x['target'].value))
  //     .subscribe(res => {
  //       this.productList = null;
  //       this.productList = this.searchProductList(this.initializeProductRequestSearch(res))
  //     })
  // }

  private searchProducts() {
    this.productTextSearch.pipe(
      distinctUntilChanged(),
      debounceTime(3000)
    ).
      subscribe(res => {
        this.productList = null;
        this.productList = this.searchProductList(this.initializeProductRequestSearch(res))
      })
  }

  loadProducts(id: number) {
    let req = new ProductRequest();
    if (id != 0) {
      req.ID = id;
      this.searchProductList(req);
    } else
      this.searchProductList(req)
  }

  searchProductList(request: ProductRequest) {
    this.showLoadingProduct = true;
    try {
      this.queryService.GetProducts(request).pipe(
        distinct(x => x.Result.ItemList),
        tap(() => this.showLoadingProduct = false)
      ).subscribe(res => {
        if (res.HasError) this.errorMsg = res.ErrorMessage;
        this.productList = res.Result.ItemList;
        this.totalProduct = res.Result.Total;
      },
        (err) => {
          this.errorMsg = err;
          this.showLoadingProduct = false;
        });
    } catch (error) {
      this.errorMsg = error;
      this.showLoadingProduct = false;
    }

    return this.productList;
  }

  initializeProductRequestSearch(data: string): ProductRequest {
    let request = new ProductRequest();
    let isSearchInputNumber = this.checkTextAsNumber(data);
    if (isSearchInputNumber)
      request.ID = +data;
    else if (this.checkTextAsEnglish(data))
      request.NameEN = data;
    else
      request.NameAR = data;

    return request;
  }

  getProductsByIds(request: ProductDiscountRequest) {
    this.showLoadingProduct = true;
    try {
      this.queryService.GetProductsDiscountByIds(request).
        pipe(distinct(x => x.Result.ItemList)).
        subscribe(res => {
          this.showLoadingProduct = false;
          if (res.HasError) this.errorMsg = res.ErrorMessage;
          this.productList = res.Result.ItemList;
          this.totalProduct = res.Result.Total;
        },
          (err) => {
            this.errorMsg = err;
            this.showLoadingProduct = false;
          });

    } catch (error) {
      this.errorMsg = error;
      this.showLoadingProduct = false;
    }

  }

  onScrollToEnd = () => this.fetchMoreProduct();
  onScroll(a) {
    if (this.showLoadingProduct || this.productList.length >= this.totalProduct)
      return;
    if (a.start + this.productList.length >= configration.pageSize)
      this.fetchMoreProduct();

  }

  fetchMoreProduct() {
    let request = new ProductRequest();
    this.pageIndexProduct++;
    request.PageIndex += this.pageIndexProduct;
    let productList = this.productList;
    this.productList = productList.concat(this.searchProductList(request));
    if (productList.length == 0)
      this.totalProduct = this.productList.length;
  }


  IntialiazeFormsDiscount(request: QuantityDiscountRequest) {
    if (request.ProductID != 0) {
      let req = new QuantityDiscountRequestPortal();
      req.productID = request.ProductID;
      this.getDiscountByProductID(req);
    }

  }

  getDiscountByProductID(request: QuantityDiscountRequestPortal) {
    try {
      this.showLoading = true;
      this.showLoadingProduct = true;
      this.errMsg = '';
      this.queryService.GetQuantityDiscountBySearch(request).pipe(tap(() => this.showLoading = false)).subscribe(res => {
        if (res.HasError) {
          this.errMsg = res.ErrorMessage;
          return;
        }
        this.quantityDiscountResponse = res.Result.ItemList;
        this.discountFormEdit(res.Result.ItemList)
      },
        Err => {
          this.errMsg = Err;
          this.showLoading = false;
          this.showLoadingProduct = false;
        })
    } catch (error) {
      this.errMsg = error;
      this.showLoading = false;
      this.showLoadingProduct = false;
    }
  }

  setQuantityDiscount(discountResponse: QuantityDiscountResponse[]): FormArray {
    const formArray = new FormArray([]);
    discountResponse.forEach(item => {
      formArray.push(
        this.fb.group({
          Amount: item.Amount,
          QuantityFrom: item.QuantityFrom,
          PercentageType: item.PercentageType.toFixed()
        }))
    })
    return formArray;
  }

  discountFormEdit(discountResponse: QuantityDiscountResponse[]): void {
    let entity: QuantityDiscountRequest = new QuantityDiscountRequest();
    entity.ProductID = discountResponse.find(Boolean).ProductID;
    this.selectedProduct.patchValue(entity.ProductID);
    this.quantityDiscountForm.setControl('quantityDiscounts', this.setQuantityDiscount(discountResponse));
  }



  get selectedProduct() { return this.quantityDiscountForm.get('productId') }
  get quantityDiscountsFormArray(): FormArray { return this.quantityDiscountForm.get('quantityDiscounts') as FormArray; }
  addQuantityDiscount = () => this.quantityDiscountsFormArray.push(this.newQuantityAmount())
  QuantityAmount = (req: QuantityPriceRequest[]) => this.quantityDiscountsFormArray.patchValue(req)
  changeDiscountLabel = (value: string) => this.discountTypeText = value;

  deleteQuantityDiscount(index: number): void {
    this.quantityDiscountsFormArray.removeAt(index);
    this.quantityDiscountsFormArray.markAsDirty();
    this.quantityDiscountsFormArray.markAsTouched();
  }

  newQuantityAmount = (): FormGroup =>
    this.fb.group({
      PercentageType: ["", Validators.required],
      QuantityFrom: ["", [Validators.required, Validators.pattern('^[0-9]+(.[0-9]{0,3})?$')]],
      Amount: ["", [Validators.required, Validators.pattern('^[0-9]+(.[0-9]{0,3})?$')]],
    })




  discountFormInitialization() {
    this.quantityDiscountForm = this.fb.group({
      productId: ["", [Validators.required]],
      quantityDiscounts: this.fb.array([],[Validators.required])
    })
  }


  Save() {
    let request = new QuantityDiscountRequest();
    request = this.quantityDiscountRequest.ProductID != 0 ? this.editGeneralRequest() : this.createGeneralRequest();
    this.isLoading = true;
    this.commandService.AddEditQuantityDiscount(request)
      .pipe(tap(() => this.isLoading = false))
      .subscribe(res => {
        if (res.HasError || !res.Result) {
          this.errorMsg = res.ErrorMessage;
          this.isSuccessOperation = false;
          this.msg = this._translate.instant("inValidOperation");
        }
        else {
          this.isSuccessOperation = true;
          this.msg = this._translate.instant("validOperation");
          this.router.navigateByUrl("/adminhome/qytdiscount")
        }
      }, (err) => {
        this.errorMsg = err;
        this.isLoading = false;
      })
  }


  createGeneralRequest(): QuantityDiscountRequest {
    let request = new QuantityDiscountRequest();
    request.ProductID = this.quantityDiscountForm.value.productId;
    request.QuantityPrices = this.quantityDiscountForm.value.quantityDiscounts;
    return request;
  }

  editGeneralRequest(): QuantityDiscountRequest {
    let request = new QuantityDiscountRequest();
    request.ID = this.quantityDiscountForm.value.productId;
    request.ProductID = this.quantityDiscountForm.value.productId;
    request.QuantityPrices = this.quantityDiscountForm.value.quantityDiscounts;
    return request;
  }


}


