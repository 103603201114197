<div class="container">
    <div class="row justify-content-md-center">
        <!-- start this to make loading center in page on any screen size -->
        <div *ngIf="showLoading" class=" d-flex flex-column z-index-3 justify-content-center align-items-center bg-white m-5" style="opacity: 0.5">
            <div class="p-2 align-self-center">
                <span class="load-more-List text-center vw-100 vh-100">
                    <span class="spinner-border text-primary" role="status"> </span>
                </span>
            </div>
        </div>
    </div>
</div>

<div class="d-flex flex-column h-100 w-100 p-2" *ngIf="!showLoading">
    <h4>{{ title }}</h4>

    <div *ngIf="errMsg" class="text-danger mr-auto p-2 bd-highlight">
        {{ errMsg | translate }}
    </div>

    <!-- <input
    type="button"
    value="Download PDF"
    class="btn btn-success"
    (click)="exportToPDF()"
  /> -->


    <div class="page-tools pb-2">
        <div class="action-buttons">
            <button (click)="exportToPDF()" style="color: white;" class="btn bg-success mx-1px text-95">
                <i class="mr-1 fa fa-file-pdf-o text-white-m1 text-120 w-2"></i>Export</button>
        </div>
    </div>


    <table class="table table-bordered pb-2">
        <thead>
            <tr>
                <th scope="col">{{ "OrderID" | translate }}</th>
                <th scope="col">{{ "OrderDate" | translate }}</th>
                <th scope="col">{{ "orderstatus" | translate }}</th>
                <th scope="col">{{ "ClientName2" | translate }}</th>
                <th scope="col">{{ "ClientPhoneNumber2" | translate }}</th>
                <th scope="col">{{ "SalesName2" | translate }}</th>
                <th scope="col">{{ "SalesNumber" | translate }}</th>

                <th scope="col">{{ "OrderQuantity" | translate }}</th>
                <th scope="col">{{ "DeliveryFees" | translate }}</th>
                <th scope="col">{{ "OrderTotal" | translate }}</th>
                <th scope="col">{{ "DeliveryDate" | translate }}</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>
                    {{ orderDetails.ID}}
                </td>
                <td>
                    {{ orderDetails.OrderDate | date: "dd-MM-yyyy" }}
                </td>
                <td>
                    <span *ngIf="orderDetails.OrderStatus != orderStatusEnum.Cancelled" class="fa fa-circle text-success">
                        {{ "Status_" + orderDetails.OrderStatus | translate }}</span>
                    <span *ngIf="orderDetails.OrderStatus === orderStatusEnum.Cancelled" class="fa fa-ban text-danger">
                        {{ "Status_" + orderDetails.OrderStatus | translate }}</span>
                </td>
                <td>
                    {{ orderDetails.AspNetUser?.FullName }}
                </td>
                <td>
                    {{ orderDetails.AspNetUser?.PhoneNumber }}
                </td>
                <td>
                    {{ orderDetails.AspNetUser?.SalesName }}
                </td>
                <td>
                    {{ orderDetails.AspNetUser?.SalesNumber }}
                </td>
                <td>
                    {{ sumOrderItemQuantity() }}
                </td>

                <td>{{ orderDetails.DeliveryFees }} {{ "le" | translate }}</td>

                <td>{{ orderDetails.TotalAfterDiscount }} {{ "le" | translate }}</td>

                <td>
                    {{ orderDetails.DeliveryDate | date: "dd-MM-yyyy" }}
                </td>
            </tr>
        </tbody>
    </table>

    <table class="table table-bordered pb-2">
        <thead>
            <tr>
                <th scope="col">{{ "ReciverName" | translate }}</th>
                <th scope="col">{{ "ReciverMobile" | translate }}</th>
                <th scope="col">{{ "CityZone" | translate }}</th>
                <th scope="col">{{ "BuildNumber" | translate }}</th>
                <th scope="col">{{ "Street" | translate }}</th>
                <th scope="col">{{ "AddressDescription" | translate }}</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>
                    {{ orderDetails.Address?.ReceiverName }}
                </td>
                <td>
                    {{ orderDetails.Address?.ReciverMobile }}
                </td>
                <td>
                    {{ orderDetails.Address.City?.NameAR + '/' + orderDetails.Address.Zone?.NameAR}}
                </td>
                <td>
                    {{ orderDetails.Address?.BuildNumber }}
                </td>
                <td>
                    {{ orderDetails.Address?.Street }}
                </td>
                <td>
                    {{ orderDetails.Address?.Description }}
                </td>
            </tr>
        </tbody>
    </table>

    <div class="container">
        <div class="row">
            <div class="col-md-4 pb-2" *ngFor="let orderItem of orderDetails.OrderItems">
                <div class="card">
                    <img class="card-img-top" src="{{ getImageFullPath(   orderItem.Product.ImageExist,   orderItem.Product.Image,   orderItem.Product.LastModifiedTime)}}" alt="Card image cap" />
                    <div class="card-body">
                        <h5 *ngIf="IsArabic" class="card-title">
                            {{ orderItem.Product?.NameAR }}
                        </h5>
                        <h5 *ngIf="!IsArabic" class="card-title">
                            {{ orderItem.Product?.NameEN }}
                        </h5>

                        <span class="d-block gray-1" *ngIf="IsArabic">
                            {{ orderItem.Product.Brand?.NameAR }}
                        </span>
                        <span class="d-block gray-1" *ngIf="!IsArabic">
                            {{ orderItem.Product.Brand?.NameEN }}
                        </span>
                        <span class="d-block gray-1" *ngIf="IsArabic">
                            {{ orderItem.Product.Type?.NameAR }}
                        </span>
                        <span class="d-block gray-1" *ngIf="!IsArabic">
                            {{ orderItem.Product.Type?.NameEN }}
                        </span>
                        <span class="d-block gray-1 mb-1" *ngIf="IsArabic">
                            {{ orderItem.Product.Color?.NameAR }}
                        </span>
                        <span class="d-block gray-1 mb-1" *ngIf="!IsArabic">
                            {{ orderItem.Product.Color?.NameEN }}
                        </span>
                        <span class="d-block gray-1 mb-1">
                            <b>
                                {{
                                orderDetails.OrderStatus === orderStatusEnum.Open
                                ? orderItem.Product.Price
                                : orderItem.Price
                                }}
                            </b>
                            {{ "le" | translate }}
                        </span>
                        <span class="d-inline-block text-danger">
                            {{ orderItem.Quantity }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div #ExportedToPDF id="ExportedToPDF">
        <div class="page-content container">


            <div class="container px-0">
                <div class="row mt-4">
                    <div class="col-12 col-lg-10 offset-lg-1">
                        <div class="row">
                            <div class="col-6">
                                <h1 class="page-title text-secondary-d1">فاتورة بضاعة رقم: ({{orderDetails.ID}})</h1>
                            </div>
                            <div class="col-6 d-flex justify-content-end">
                                <div class="text-left text-150">
                                    <span class="text-default-d3" #2assarLog>
                                        <img alt="2assarLog"  style="width: 120px;" src="assets/images/mylerz-logo.png">
                                    </span>
                                </div>
                            </div>
                        </div>
                        <!-- .row -->

                        <hr class="row brc-default-l1 mx-n1 mb-4" />

                        <div class="row">
                            <div class="col-sm-6">
                                <div>
                                    <span class="text-sm text-grey-m2 align-middle">الي:</span>
                                    <span class="text-600 text-110 align-middle">
                                        {{ orderDetails.AspNetUser?.FullName }}
                                    </span>
                                </div>
                                <div class="text-grey-m2">
                                    <div class="my-1">شارع {{ orderDetails.Address.Street}}, منطقة {{orderDetails.Address.Zone?.NameAR}}
                                    </div>
                                    <div class="my-1">مدينة {{orderDetails.Address.City?.NameAR}}</div>
                                    <div class="my-1">
                                        <i class="fa fa-phone fa-flip-horizontal text-secondary"></i>
                                        <b class="text-600">موبايل العميل: {{orderDetails.AspNetUser?.PhoneNumber}}</b>
                                    </div>
                                </div>
                            </div>
                            <!-- /.col -->

                            <div class=" text-95 col-sm-6 align-self-start d-sm-flex justify-content-end ">
                                <hr class="d-sm-none" />
                                <div class="text-grey-m2">
                                    <div class="mt-1 mb-2 text-secondary-m1 text-600 text-125">
                                        رقم ضريبي : 623-307-618
                                    </div>

                                    <div class="my-2">
                                        <i class="fa fa-circle text-blue-m2 text-xs mr-1"></i>
                                        <span class="text-600 text-90">رقم الفاتورة:</span> {{ orderDetails.ID }}
                                    </div>

                                    <div class="my-2">
                                        <i class="fa fa-circle text-blue-m2 text-xs mr-1"></i>
                                        <span class="text-600 text-90">تاريخ:</span> {{orderDetails.OrderDate | date: "dd-MM-yyyy"}}
                                    </div>

                                    <div class="my-2">
                                        <i class="fa fa-circle text-blue-m2 text-xs mr-1"></i>
                                        <span class="text-600 text-90">تاريخ توصيل الطلب:</span> {{orderDetails.DeliveryDate | date: "dd-MM-yyyy"}}
                                    </div>
                                </div>
                            </div>
                            <!-- /.col -->
                        </div>

                        <div class="mt-4">
                            <!-- or use a table instead -->

                            <div class="table-responsive">
                                <table id="mytable" #mytable class=" table table-bordered table-hover border-0 border-b-2 brc-default-l1">
                                    <thead class="bg-none bgc-default-tp1">
                                        <tr class="text-dark">
                                            <th class="opacity-2">م</th>
                                            <th class="opacity-2">كود المنتج</th>
                                            <th>اسم الصنف</th>
                                            <th>الكمية</th>
                                            <th>السعر</th>
                                            <th width="140">الإجمالي</th>
                                        </tr>
                                    </thead>

                                    <tbody class="text-95 text-secondary-d3">
                                        <tr></tr>
                                        <tr *ngFor="let item of orderDetails.OrderItems; let i = index">
                                            <td>{{ i + 1}}</td>
                                            <td> {{item.Product?.ProductID }} </td>
                                            <td>{{ item.Product?.NameAR }}</td>
                                            <td> {{ item.Quantity }} </td>
                                            <td class="text-95">{{ item.Price }} </td>
                                            <td class="text-secondary-d2">{{item.Price * item.Quantity}}</td>
                                            <!-- <td class="text-secondary-d2">{{item.TotalPriceItem }}</td> -->
                                        </tr>
                                    </tbody>
                                </table>
                                <tfoot class="row mt-3">
                                    <div class="col-12 col-sm-7 text-grey-d2 text-95 mt-2 mt-lg-0"></div>
                                    <div class="col-12 col-sm-5 text-grey text-90 order-first order-sm-last">
                                        <table class="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>الاجمالي : </th>
                                                    <!-- <th>{{ orderDetails.GrossAmount}}</th> -->
                                                    <th>{{ sumOrderItemsTotal(orderDetails.OrderItems)}}</th>
                                                </tr>
                                                <tr *ngIf="orderDetails.DiscountAmount > 1">
                                                    <th>الخصم : </th>
                                                    <th>{{ orderDetails.DiscountAmount }}</th>
                                                </tr>
                                                <tr>
                                                    <th>الشحن : </th>
                                                    <th>{{ orderDetails.DeliveryFees}}</th>
                                                </tr>
                                                <tr>
                                                    <th>الصافي : </th>
                                                    <th>{{orderDetails.TotalAfterDeliveryFees}}</th>
                                                    <!-- <th>{{ orderDetails.NetAmount}} </th> -->
                                                </tr>
                                            </thead>
                                        </table>

                                    </div>


                                </tfoot>
                            </div>

                            <div class="p-4">
                                <p class="textTax">
                                    جميع الاسعار تشمل ١٤٪ ضريبة القيمة المضافة </p>
                            </div>
                            <hr />

                            <div>
                                <div class="d-flex">
                                    <div class="p-2">العنوان: الدور الثالث - عمارة 5 - شارع 270 المعادي</div>
                                    <div class="p-2"></div>
                                    <div class="ml-auto p-2"> تليفون: 0227043177</div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>