export class UserDto{
  UserId: string;
  Email: string;
  UserName: string;
  FullName: string;
  PhoneNumber: string;
  UserCategoryId: number;

  Password: string;
  ConfirmPassword: string;
}
