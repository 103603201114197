import { AppTypeEnum } from './../../../models/enum/AppTypeEnum';
import { environment } from './../../../../environments/environment.prod';
import { CommandService } from 'src/app/services/command.service';
import { Component, OnInit, ViewEncapsulation, OnDestroy, ViewChild, ElementRef } from "@angular/core";
import { QueryService } from "src/app/services/query.services";
import { ProductModel } from "src/app/models/Product.Model";
import { SearchResult } from "src/app/models/SearchResult.Model";
import { ProductRequest } from "src/app/models/ProductRequest.Model";
import { LookupsModel } from "src/app/models/Lookups.model";
import { LookupsRequest } from "src/app/models/lookups.Request";
import { TranslateService } from "@ngx-translate/core";
import { SearchStream } from "src/app/models/search-stream-object.model";
import { Title } from "@angular/platform-browser";
import { PageChangedEvent } from "ngx-bootstrap/pagination";
import { AddEditProductRequst } from "src/app/models/AddEditProductRequst.model";
import * as XLSX from "xlsx";
import { ProductExcelModel } from 'src/app/models/ProductExcelModel';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { OperatorFunction, Subject, Observable, merge, combineLatest, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, tap } from 'rxjs/operators';

@Component({
  selector: "app-product-admin-list",
  templateUrl: "./product-admin-list.component.html",
  styleUrls: ["./product-admin-list.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ProductAdminListComponent implements OnInit, OnDestroy {

  @ViewChild('instance', { static: true })
  instance!: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  clear$ = new Subject<string>()
  selectedProductRequest: ProductRequest | undefined;
  products: SearchResult<ProductModel> = new SearchResult<ProductModel>();
  ProductRequest: ProductRequest;
  ProductsListExcel: ProductModel[] = [];
  IsArabic = true;
  searchName = "";
  showListLoading = false;
  errMsg = "";
  title: string;
  CategoriesList: LookupsModel[];
  productTypes: LookupsModel[];
  BrandsList: LookupsModel[];
  ColorsList: LookupsModel[];
  SuppliersList: LookupsModel[];
  SearchStream: SearchStream = new SearchStream();
  categoryLoading = false;
  typeLoading = false;
  brandLoading = false;
  suppliersLoading = false;
  colorLoading = false;
  sortDir: number = 0;
  showLoading: boolean = false;
  productIamgeSrc: string;
  showProductImagePopup = false;
  @ViewChild('searchProductInput', { static: false }) inputProductSearch: ElementRef;

  constructor(
    private queryService: QueryService,
    private _commandService: CommandService,
    private translateService: TranslateService,
    private titleService: Title
  ) { }

  ngAfterViewInit() {
    fromEvent(this.inputProductSearch.nativeElement, 'keydown')
      .pipe(filter((event: any) => event.keyCode == 13), map(event => event['target'].value))
      .subscribe(text => this.searchCaller());
  }

  ngOnInit() {
    this.setDefaultSearchValues();
    this.loadAfterLang(this.translateService.currentLang);
    this.translateService.onLangChange.subscribe((res) => this.loadAfterLang(res.lang));
    this.searchCaller();
    this.getCategories();
    this.getProductType();
    this.getBrands();
    this.getColors();
    this.getSuppliers();
  }



  loadAfterLang(lang: string) {
    this.IsArabic = lang === "ar";
    this.title = this.translateService.instant("Product");
    this.titleService.setTitle(this.title);
  }

  onChanges = (data: any) => {
    // this.ProductRequest.IsRetail = data
    // this.searchCaller();

  }

  getSuppliers() {
    let search = new LookupsRequest();
    search.pageIndex = 1;
    search.pageSize = 100;
    search.Lang = this.translateService.currentLang;
    this.queryService.getAllSuppliers(search).subscribe(res => {
      this.SuppliersList = res.Result;
    });
  }

  getCategories(searchKeyword: string = "") {
    this.SearchStream.initStream("CategoryDDL_ProductList", (a) => {
      this.categoryLoading = true;
      let search = new LookupsRequest();
      search.Filter = a;
      search.pageIndex = 1;
      search.pageSize = 100;
      search.Lang = this.translateService.currentLang;

      this.queryService.getAllProductCategory(search)
        .pipe(tap(() => this.categoryLoading = false))
        .subscribe(
          (res) => {
            if (res.Result) this.CategoriesList = res.Result;
          },
          (err) => { }
        );
    }).next(searchKeyword);
  }

  getProductType(searchKeyword: string = "") {
    this.SearchStream.initStream("TypeDDL_ProductList", (a) => {
      this.typeLoading = true;
      let search = new LookupsRequest();
      search.Filter = a;
      search.pageIndex = 1;
      search.pageSize = 100;
      search.Lang = this.translateService.currentLang;

      this.queryService.getAllProductType(search).subscribe(
        (res) => {
          if (res.Result) this.productTypes = res.Result;
        },
        (err) => { },
        () => {
          this.typeLoading = false;
        }
      );
    }).next(searchKeyword);
  }

  getBrands(searchKeyword: string = "") {
    this.SearchStream.initStream("BrandDDL_ProductList", (a) => {
      this.brandLoading = true;
      let search = new LookupsRequest();
      search.Filter = a;
      search.pageIndex = 1;
      search.pageSize = 100;
      search.Lang = this.translateService.currentLang;

      this.queryService.getAllProductBrand(search).subscribe(
        (res) => {
          if (res.Result) this.BrandsList = res.Result;
        },
        (err) => { },
        () => {
          this.brandLoading = false;
        }
      );
    }).next(searchKeyword);
  }

  getColors(searchKeyword: string = "") {
    this.SearchStream.initStream("ColorDDL_ProductList", (a) => {
      this.colorLoading = true;
      let search = new LookupsRequest();
      search.Filter = a;
      search.pageIndex = 1;
      search.pageSize = 100;
      search.Lang = this.translateService.currentLang;

      this.queryService.getAllProductColor(search).subscribe(
        (res) => {
          if (res.Result) this.ColorsList = res.Result;
        },
        (err) => { },
        () => {
          this.colorLoading = false;
        }
      );
    }).next(searchKeyword);
  }

  //#region "table Pagination and Search"
  setDefaultSearchValues = () => this.ProductRequest = new ProductRequest();


  onSearchSubmit() {
    this.ProductRequest.PageIndex = 1;
    this.searchCaller();
  }

  onPagingChange(event: PageChangedEvent) {
    this.ProductRequest.PageIndex = event.page;
    this.ProductRequest.PageSize = event.itemsPerPage;
    this.searchCaller();
  }

  searchCaller() {
    try {
      this.errMsg = "";
      this.showListLoading = true;
      this.ProductRequest.isAdminPage = true;
      this.ProductRequest.Lang = this.translateService.currentLang;
      this.queryService.getProductsAdmin(this.ProductRequest).pipe(tap(x => this.showListLoading = false)).subscribe((res) => {
          if (res.HasError == false && res.Result != null) {
            this.products = res.Result;
          } else {
            this.products.ItemList = [];
            this.products.Total = 0;
            this.errMsg = res.ErrorMessage;
          }

        },
        (err) => {
          this.errMsg = err;
          this.showListLoading = false;
        }
      );
    } catch (err) {
      this.errMsg = err;
      this.showListLoading = false;
    }
  }

  clearSearch() {
    this.setDefaultSearchValues();
    this.searchCaller();
  }

  //#endregion "table Pagination and Search"

  ngOnDestroy(): void {
    this.SearchStream.DestroyStreams();
  }

  onSortClick(event: any, colName: string) {

    let target = event.currentTarget,
      classList = target.classList;

    /* sort DESC */
    if (classList.contains('fa-chevron-up')) {
      classList.remove('fa-chevron-up');
      classList.add('fa-chevron-down');
      this.sortDir = 0;

      /* sort ASC */
    } else {
      classList.add('fa-chevron-up');
      classList.remove('fa-chevron-down');
      this.sortDir = 1;
    }

    this.ProductRequest.Lang = this.translateService.currentLang;
    this.ProductRequest.SortColumn = colName;
    this.ProductRequest.SortDirection = this.sortDir;

    this.searchCaller();
  }

  getImageFullPath = (product: ProductModel): string => product.ImageExist ? `${environment.webApiEndPoint}Product/${product.Image}?date=${product.LastModifiedTime}` : 'assets/images/default.jpg';


  SetProductAvailability(product: ProductModel) {
    var check = confirm(this.translateService.instant("AreYouSureYouWantToChangeThisProduct"));
    if (check) {
      try {
        this.errMsg = "";
        this.showLoading = true;

        let request = new AddEditProductRequst();
        request.ProductDTO = new ProductModel()
        request.ProductDTO.ID = product.ID;
        request.ProductDTO.IsAvailable = !product.IsAvailable;
        this._commandService.SetProductAvailability(request).subscribe(res => {
          this.showLoading = false;
          if (res.HasError) {
            product.IsAvailable = !product.IsAvailable;
            this.errMsg = res.ErrorMessage;
            return;
          }

          if (res.Result) {
            this.products.ItemList.map(x => {
              if (x.ID == product.ID) {
                x.IsAvailable = !x.IsAvailable;
              }
            });
          }

        }, err => {
          product.IsAvailable = !product.IsAvailable;
          this.errMsg = err;
          this.showLoading = false;
        });
      } catch (err) {
        product.IsAvailable = !product.IsAvailable;
        this.errMsg = err;
        this.showLoading = false;
      }
    }
  }

  productImageClicked(product) {
    this.productIamgeSrc = this.getImageFullPath(product);
    this.showProductImagePopup = true;

  }

  checkLookUpExist = (item: LookupsModel): LookupsModel => item ? item : null;

  lookupName = (item: LookupsModel, lang: string): string => this.checkLookUpExist(item) && lang == "ar" ? item.NameAR : this.checkLookUpExist(item) && lang == "en" ? item.NameEN : "";

  exportToExcel() {
    try {

      this.errMsg = "";
      this.showListLoading = true;
      this.ProductRequest.IsExcel = true;
      this.queryService.getProductsAdmin(this.ProductRequest)
        .pipe(tap(() => this.showListLoading = false))
        .subscribe(res => {
          if (res.HasError) {
            this.errMsg = res.ErrorMessage;
            return;
          }
          let excelDate: ProductExcelModel[] = [];
          res.Result.ItemList.forEach(item => excelDate.push(this.mapToExcelModel(item)))
          this.exportExcelSheet(excelDate)

        },
          (err) => {
            this.errMsg = err;
            this.showListLoading = false;
          }
        );
    } catch (err) {
      this.errMsg = err;
      this.showListLoading = false;
    }
  }

  exportExcelSheet(result: ProductExcelModel[]) {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(result);
    const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

    this.showListLoading = false;
    /* save to file */
    XLSX.writeFile(workbook, "Products [" + this.getDateFormated() + "].xlsx");
    this.ProductRequest.IsExcel = false;
  }


  mapToExcelModel(item: ProductModel): ProductExcelModel {
    let productExcel = new ProductExcelModel();
    productExcel.Product_ID = item.ID;
    productExcel.Product_NameEN = item.NameEN;
    productExcel.Product_NameEN = item.NameAR;
    productExcel.Quantity = item.QuantitySum;
    productExcel.Booked = item.BookedSum;
    productExcel.Balance = item.BalanceSum;
    productExcel.PurchasingPrice = item.PurchasingPrice;
    productExcel.IsAvailable = item.IsAvailable;

    productExcel.Category_NameEN = this.lookupName(item.Category, "en");
    productExcel.Category_NameAR = this.lookupName(item.Category, "ar");

    productExcel.Brand_NameEN = this.lookupName(item.Brand, "en");
    productExcel.Brand_NameEN = this.lookupName(item.Brand, "ar");

    productExcel.Type_NameEN = this.lookupName(item.Type, "en");
    productExcel.Type_NameEN = this.lookupName(item.Type, "ar");

    productExcel.Color_NameEN = this.lookupName(item.Color, "en");
    productExcel.Color_NameEN = this.lookupName(item.Color, "ar");

    productExcel.Supplier_NameEN = this.lookupName(item.Supplier, "en");
    productExcel.Supplier_NameAR = this.lookupName(item.Supplier, "ar");

    productExcel.LocationTackingNumber = item.LocationTackingNumber;
    productExcel.ProductWeights = item.ProductWeights;
    productExcel.ProductLength = item.ProductLength;
    productExcel.ProductWidth = item.ProductWidth;
    productExcel.ProductOriginCountry = item.ProductOriginCountry;

    productExcel.Product_DescriptionEN = item.DescriptionEN;
    productExcel.Product_DescriptionAR = item.DescriptionAR;
    productExcel.ActualStock = item.ActualStock;
    return productExcel;
  }

  getDateFormated(): string {

    let dd = String(new Date().getDate()).padStart(2, '0');
    let mm = String(new Date().getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = new Date().getFullYear();
    let result = dd + '/' + mm + '/' + yyyy;
    return result;
  }

}
