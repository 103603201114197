import { QueryService } from 'src/app/services/query.services';
import { Component, OnInit } from "@angular/core";
import { userLogin } from "src/app/models/userlogin.Model";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { UserCategoryEnum } from "src/app/models/enum/usercategory.enum";
import { LoginAdminService } from "src/app/services/login-admin.service";
import { UserCategoryPagesRequest } from 'src/app/models/UserCategoryPagesRequest';

@Component({
  selector: "app-adminlogin",
  templateUrl: "./adminlogin.component.html",
  styleUrls: ["./adminlogin.component.scss"],
})
export class AdminloginComponent implements OnInit {
  userLogin: userLogin = new userLogin();
  error: string;
  isArabic: boolean;
  showLoading: boolean = false;
  userCatEnum: UserCategoryEnum;

  constructor(
    public _adminLogin: LoginAdminService,
    private router: Router,
    private _tran: TranslateService,
    private _queryService: QueryService
  ) {}

  ngOnInit() {
    // this._adminLogin.setCurrentCulture('ar')
  }

  Login() {
    this.showLoading = true;
    try {
      this._adminLogin.loginUser(this.userLogin).subscribe(
        (res: any) => {
          this.showLoading = false;

          this._adminLogin.setUserToken(res["access_token"]);
          if (this._adminLogin.getUserToken() != null) {
            this._adminLogin.getUserInfo().subscribe((res) => {

              if (res["isActive"]) {
                //this._adminLogin.setUserName(res["UserName"]);
                // debugger
                this._adminLogin.setUserCategoryId(res["UserCategoryId"]);
                this._adminLogin.userCategoryPages = [];
                this._adminLogin.setUserCategoryPages(res["UserCategoryPages"]);
                this._adminLogin.userCategoryPages = (res["UserCategoryPages"]);
                // console.log(this._adminLogin.userCategoryPages);
                // this.getUserCategoryPages(parseInt(res["UserCategoryId"]));
                if (
                  parseInt(this._adminLogin.getUserCategoryId()) ==
                  UserCategoryEnum.Client
                ) {
                  this.error = this._tran.instant("InvalidUser");
                  this.showLoading = false;
                  return;
                } else if (
                  parseInt(this._adminLogin.getUserCategoryId()) ==
                    UserCategoryEnum.Admin ||
                  parseInt(this._adminLogin.getUserCategoryId()) ==
                    UserCategoryEnum.DataEntry ||
                  parseInt(this._adminLogin.getUserCategoryId()) ==
                    UserCategoryEnum.Sales ||
                    parseInt(this._adminLogin.getUserCategoryId()) ==
                      UserCategoryEnum.InventoryPerson
                ) {
                  this.router.navigateByUrl("adminhome");
                } else {
                  this.error = this._tran.instant("InvalidUser");
                  this.showLoading = false;
                  return;
                }
              } else {
                this.error = this._tran.instant("InvalidUser");
                this.showLoading = false;
                return;
              }
            });
          }
        },
        (error) => {
          this.error = this._tran.instant("InvalidUser");
          this.showLoading = false;
        }
      );
    } catch (err) {
      this.error = err;
      this.showLoading = false;
    }
  }

  getUserCategoryPages(userCatId: number) {
    let request = new UserCategoryPagesRequest();
    request.UserCategoryId = userCatId;

    try {
      this._queryService.getAllUserCategoryPagesList(request).subscribe(
        (res) => {
          this.showLoading = false;
          if (res.HasError) {
            return;
          }
          this._adminLogin.userCategoryPages = res.Result;
        },
        (err) => {

        }
      );
    } catch (error) {

    }
  }
}
