import { EditUserDto } from './../../../models/EditUserDto';
import { CommunicationService } from 'src/app/services/communication.service';
import { Component, OnInit, ViewChild } from "@angular/core";
import { RegisterBindingModel } from "src/app/models/register.model";
import { LookupsModel } from "src/app/models/Lookups.model";
import { SalesPersonRequest } from "src/app/models/SalesPersonRequest.Model";
import { SalesPersonDto } from "src/app/models/SalesPersonDto.Model";
import { ActivatedRoute, Router, Params } from "@angular/router";
import { LoginAdminService } from "src/app/services/login-admin.service";
import { TranslateService } from "@ngx-translate/core";
import { QueryService } from "src/app/services/query.services";
import { Title } from "@angular/platform-browser";
import { MobileUserDto } from "src/app/models/MobileUserDto";
import { ModalDirective } from "ngx-bootstrap/modal";
import { ActionType } from 'src/app/models/Action';
import { CityZoneDto } from 'src/app/models/CityZoneDto.Model';

@Component({
  selector: "app-edit-mobile-user",
  templateUrl: "./edit-mobile-user.component.html",
  styleUrls: ["./edit-mobile-user.component.scss"],
})
export class EditMobileUserComponent implements OnInit {
  _userId: string;
  errMsg: string;
  errors: string[] = [];
  isPromoCode: boolean = false;
  showLoading: boolean;
  userRegister: EditUserDto = new EditUserDto();
  UserCategoryList: LookupsModel[] = [];
  request: SalesPersonRequest = new SalesPersonRequest();
  mobileUser: MobileUserDto = new MobileUserDto();
  promoCodeType = "0";
  salonType = "";
  IsArabic: boolean = true;
  title: string;
  promoCodeTypeText = "PromoCodePercentage";
  cityZone: string;
  addAddress: boolean = false;
  @ViewChild('cityModal', { static: false }) cityModal: ModalDirective;
  constructor(
    private _route: ActivatedRoute,
    private _translate: TranslateService,
    private router: Router,
    private titleService: Title,
    private _communicationService: CommunicationService,
    private _adminLogin: LoginAdminService,
    private _queryService: QueryService
  ) {

  }

  ngOnInit() {
    this.loadAfterLang(this._translate.currentLang);
    this._translate.onLangChange.subscribe((res) => {
      this.loadAfterLang(res.lang);
    });

    this._route.params.subscribe((params: Params) => {
      this._userId = params["ID"];
    });


    this.getUserId();

    this._communicationService.getAction().subscribe(res => {
      if (res.actionType == ActionType.CityZoneSelected) {

        var selected = (res.Result as CityZoneDto);
        this.mobileUser.UserAddress.City = selected.City;
        this.mobileUser.UserAddress.Zone = selected.ZoneList[0];
        this.cityZone = `${this.IsArabic ? this.mobileUser.UserAddress.City.NameAR : this.mobileUser.UserAddress.City.NameEN} / ${this.IsArabic ? this.mobileUser.UserAddress.Zone.NameAR : this.mobileUser.UserAddress.Zone.NameEN}`;

        this.cityModal.hide();
      }
      else if (res.actionType == ActionType.CityZoneClosed) {
        this.cityModal.hide();
      }

    });

  }


  getUserId() {
    try {
      this.errMsg = "";
      this.showLoading = true;
      this.request.UserId = this._userId;
      this._queryService.getMogileUserById(this.request).subscribe(
        (res) => {
          this.showLoading = false;
          if (res.HasError) {
            this.errMsg = res.ErrorMessage;
            return;
          }

          this.mobileUser = res.Result;

          if (this.mobileUser.SalonType != null)
            this.salonType = this.mobileUser.SalonType.toString();

          if (this.mobileUser.UserAddress.City.NameAR && this.mobileUser.UserAddress.Zone.NameAR) {
            this.cityZone =
              `${this.IsArabic ? this.mobileUser.UserAddress.City.NameAR : this.mobileUser.UserAddress.City.NameEN} / ${this.IsArabic ? this.mobileUser.UserAddress.Zone.NameAR : this.mobileUser.UserAddress.Zone.NameEN}`;
          }

          if (
            this.mobileUser.PromoCode != null &&
            this.mobileUser.PromoCode != ""
          ) {
            this.isPromoCode = true;
          }
          if (this.mobileUser.PromoCodeType) {
            this.promoCodeType = this.mobileUser.PromoCodeType.toString();
            if (this.mobileUser.PromoCodeType === 0) this.changePromoCodeLabel('PromoCodePercentage');
            if (this.mobileUser.PromoCodeType === 1) this.changePromoCodeLabel('PromoCodeAmount')
          }
        },
        (err) => {
          this.errMsg = err;
          this.showLoading = false;
        }
      );
    } catch (error) {
      this.errMsg = error;
      this.showLoading = false;
    }
  }

  loadAfterLang(lang: string) {
    this.IsArabic = lang === "ar";

    this._route.params.subscribe((params: Params) => {
      let id = params["ID"];

      this.title = this._translate.instant("EditClient");
      this.titleService.setTitle(this.title);
    });
  }

  SavesalesPerson() {
    this.showLoading = true;
    try {
      this.userRegister = this.converToUserRegisterModel(this.mobileUser);
      this.userRegister.PromoCodeType = +this.promoCodeType;
      this._adminLogin.editUser(this.userRegister).subscribe(
        (res) => {
          this.showLoading = false;
          this.router.navigateByUrl("adminhome/adminmobileuserlist");
          this._adminLogin.setUserIsActive(this.userRegister.IsActive);
        },
        (error) => {
          this.errors = [];
          let i = 0;
          // for (let key in error.error.ModelState) {
          //   let value = error.error.ModelState[key];

          //   // Use `key` and `value`
          //   let errorText: string = this._translate.instant(value[i]);

          //   if (errorText.indexOf("is already taken") > 0) {
          //     errorText = this._translate.instant("IsTaken");
          //   }
          //   this.errors.push(errorText);
          //   i++;
          // }
          this.showLoading = false;
        }
      );
    } catch (err) {
      this.errors[0] = err;
      this.showLoading = false;
    }
  }
  GeneratePromoCode() {
    let promoCode = Math.floor(Math.random() * (10000 - 1 + 1) + 1);
    this.mobileUser.PromoCode = promoCode.toString();
    this.mobileUser.PromoCodeAmount = "0";
    this.isPromoCode = true;
  }
  DeletePromoCode() {
    this.mobileUser.PromoCode = "";
    this.mobileUser.PromoCodeAmount = "0";
    this.isPromoCode = false;
  }
  converToUserRegisterModel(_mobileUser: MobileUserDto) {

    this.userRegister.UserId = this.mobileUser.Id;
    // //not used but required//need to inhance
    // this.userRegister.Password = "";
    // this.userRegister.ConfirmPassword = "";
    this.userRegister.IsRetail = _mobileUser.IsRetail;
    this.userRegister.PhoneNumber = _mobileUser.PhoneNumber;
    this.userRegister.UserCategoryId = _mobileUser.UserCategoryId;
    this.userRegister.UserName = _mobileUser.PhoneNumber;

    this.userRegister.PromoCode = _mobileUser.PromoCode;
    this.userRegister.PromoCodeAmount = _mobileUser.PromoCodeAmount;
    this.userRegister.Email = _mobileUser.Email;

    this.userRegister.Name = _mobileUser.Name;

    this.userRegister.SalonName = _mobileUser.SalonName;
    this.userRegister.SalonType = this.salonType == "" ? 0 : +this.salonType;
    this.userRegister.VoucherID = _mobileUser.VoucherID;
    this.userRegister.IsActive = _mobileUser.IsActive;

    if (this.addAddress) {
      _mobileUser.UserAddress.ID = 0;
      _mobileUser.UserAddress.UserID = this._userId;
      this.userRegister.UserAddress = _mobileUser.UserAddress;
    }
    // this.userRegister.isAdmin = true;
    return this.userRegister;
  }

  changePromoCodeLabel(value: string) {
    this.promoCodeTypeText = value;
  }

  EditCityZone() {
    this.cityModal.show();

  }


  getCredential = () => console.log(this._adminLogin.getUserCategoryPages());


}
