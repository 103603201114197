import { Injectable } from "@angular/core";
import * as XLSX from "xlsx";
import { Subject, BehaviorSubject } from "rxjs";
type AOA = any[][];

@Injectable({
  providedIn: "root",
})
export class ExcelService {
  data: AOA = [];
  _data: Subject<any>;

  constructor() {}

  importXLSX(selectedFile) {
    //debugger;
    this.data = [];
    let result = {};
    /* wire up file reader */
    this._data = new BehaviorSubject<any>([]);
    const target: DataTransfer = <DataTransfer>selectedFile.target;
    if (target.files.length !== 1) throw new Error("Cannot use multiple files");
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, {
        type: "binary",
        raw: false,
        cellDates: true,
        dateNF: "mm/dd/yyyy;@",
      });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.data = <AOA>(
        XLSX.utils.sheet_to_json(ws, { header: 1, blankrows: false })
      );
      result = {
        headers: this.data[0],
        rows: this.getDataWithoutRows(this.data),
      };
      //console.log(result);
      this._data.next(result);
      // return result;
    };
    reader.readAsBinaryString(target.files[0]);
    return this._data;
  }

  matchObjects(source, target, key) {
    let keyIndex = source.headers.findIndex((x) => x == key);
    source.rows.forEach((r) => {
      target.push(r[keyIndex]);
    });
  }

  private getDataWithoutRows(data) {
    let results = [];
    for (var i = 1; i < data.length; i++) {
      results.push(data[i]);
    }
    return results;
  }
}
