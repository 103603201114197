import { Component, OnInit } from "@angular/core";
import { QueryService } from "src/app/services/query.services";
import { TranslateService } from "@ngx-translate/core";
import { Title } from "@angular/platform-browser";
import { SearchResult } from "src/app/models/SearchResult.Model";
import { ProductTypeDTO } from "src/app/models/ProductTypeDTO.model";
import { ProductTypeRequest } from "src/app/models/ProductTypeRequest.model";
import { PageChangedEvent } from "ngx-bootstrap/pagination";
import { AddEditProductTypeRequest } from "src/app/models/AddEditProductTypeRequest.model";
import { CommandService } from "src/app/services/command.service";

@Component({
  selector: "app-product-type-admin-list",
  templateUrl: "./product-type-admin-list.component.html",
  styleUrls: ["./product-type-admin-list.component.scss"],
})
export class ProductTypeAdminListComponent implements OnInit {
  _AddEditProductCategoryRequst: AddEditProductTypeRequest = new AddEditProductTypeRequest();
  productTypies: SearchResult<ProductTypeDTO> = new SearchResult<ProductTypeDTO>();
  ProductTypeRequest: ProductTypeRequest;
  IsArabic = true;
  searchName = "";
  showListLoading = false;
  errMsg = "";
  pathOfAPI: string;
  title: string;
  sortDir: number = 0;

  constructor(
    private commandService: CommandService,
    private queryService: QueryService,
    private translateService: TranslateService,
    private titleService: Title
  ) { }

  ngOnInit() {
    this.setDefaultSearchValues();

    this.loadAfterLang(this.translateService.currentLang);
    this.translateService.onLangChange.subscribe(res => this.loadAfterLang(res.lang));

    // this.setDefaultSearchValues();
    // this.searchCaller();
  }

  loadAfterLang(lang: string) {
    this.IsArabic = lang === "ar";
    this.title = this.translateService.instant("ProductType");
    this.titleService.setTitle(this.title);

    this.searchCaller(this.ProductTypeRequest);
  }

  //#region "table Pagination and Search"
  setDefaultSearchValues() {
    this.ProductTypeRequest = new ProductTypeRequest();
    //this.ProductTypeRequest.Lang = this.translateService.currentLang;
  }

  onSearchSubmit() {
    this.ProductTypeRequest.PageIndex = 1;
    this.searchCaller(this.ProductTypeRequest);
  }

  onPagingChange(event: PageChangedEvent) {
    this.ProductTypeRequest.PageIndex = event.page;
    this.ProductTypeRequest.PageSize = event.itemsPerPage;
    this.searchCaller(this.ProductTypeRequest);
  }

  searchCaller(request: ProductTypeRequest) {
    try {
      this.errMsg = "";
      this.showListLoading = true;

      // if (this.IsArabic) {
      //   this.ProductTypeRequest.NameAR = this.searchName;
      //   this.ProductTypeRequest.NameEN = '';
      // } else {
      //   this.ProductTypeRequest.NameEN = this.searchName;
      //   this.ProductTypeRequest.NameAR = '';
      // }
      request.Lang = this.translateService.currentLang;

      this.queryService.SearchProductType(request).subscribe(
        (res) => {
          if (res.HasError == false && res.Result != null)
            this.productTypies = res.Result;
          else {
            this.productTypies.ItemList = [];
            this.productTypies.Total = 0;
            this.errMsg = res.ErrorMessage;
          }
        },
        (err) => {
          this.errMsg = err;
        }, () => this.showListLoading = false
      );
    } catch (err) {
      this.errMsg = err;
      this.showListLoading = false;
    }
  }

  clearSearch() {
    this.setDefaultSearchValues();
    this.searchCaller(this.ProductTypeRequest);
  }

  Delete(item: ProductTypeDTO) {
    var check = confirm(this.translateService.instant("AreYouSureDeleteType"));
    if (check) {
      try {
        this.errMsg = "";
        this.showListLoading = true;

        this._AddEditProductCategoryRequst.ProductTypeDTO = item;
        this._AddEditProductCategoryRequst.ProductTypeDTO.IsDeleted = true;
        this.commandService
          .AddEditProductType(this._AddEditProductCategoryRequst)
          .subscribe(
            (res) => {
              this.showListLoading = false;
              if (res.HasError) {
                if (res.ErrorMessage == "NotDeleted") {
                  var check = confirm(
                    this.translateService.instant("DeleteType")
                  );
                  return;
                }
                this.errMsg = res.ErrorMessage;
                return;
              }

              this.searchCaller(this.ProductTypeRequest);
            },
            (err) => {
              this.errMsg = err;
              this.showListLoading = false;
            }
          );
      } catch (err) {
        this.errMsg = err;
        this.showListLoading = false;
      }
    }
  }

  // on Clcik Sort
  onSortClick(event: any, colName: string) {
    let classList = event.currentTarget.classList;
    this.changeStyleOnSortCLicked(classList)
    let request = this.changeRequestOnSortClicked(this.ProductTypeRequest, colName);
    this.searchCaller(request);
  }

  changeRequestOnSortClicked(request: ProductTypeRequest, columnName: string) {
    let changedRequest = request;
    changedRequest.Lang = this.translateService.currentLang;
    changedRequest.SortColumn = columnName;
    changedRequest.SortDirection = this.sortDir;
    return changedRequest;
  }
  changeStyleOnSortCLicked(classList: any) {
    /* sort DESC */
    if (classList.contains('fa-chevron-up')) {
      classList.remove('fa-chevron-up');
      classList.add('fa-chevron-down');
      this.sortDir = 0;

      /* sort ASC */
    } else {
      classList.add('fa-chevron-up');
      classList.remove('fa-chevron-down');
      this.sortDir = 1;
    }
  }

  onChanges = (data: any) => {
    this.ProductTypeRequest.IsRetail = data;
    this.searchCaller(this.ProductTypeRequest);
  }

  //#endregion "table Pagination and Search"
}
