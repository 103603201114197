<div class="h-100 overflow-auto">
    <div class="bd-highlight p-2">
        <h4 class="p-2 mb-0">{{ title | translate }}</h4>
    </div>

    <div class="container mt-2">
        <div class="row">
            <div class="col-4">
                <div class="form-group">
                    <select class="form-control" id="pageTitle" (change)="onUserCategoryChange($event.target.value)">
              <option [value]="0">
                {{ "SelectUserCategory" | translate }}
              </option>
              <option *ngFor="let item of UserCategoryList" [value]="item.ID">
                <span *ngIf="isArabic()"> {{ item.NameAR }}</span>
                <span *ngIf="!isArabic()"> {{ item.NameEN }}</span>
              </option>
            </select>
                </div>
            </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngFor="let item of userCategoryPages">
            <label class="d-block w-100 hold-checkbox position-relative align-self-end">
          <input class="form-control rounded" type="checkbox"[(ngModel)]="item.Allow"/>
          <span class="checkmark"></span>
          <span class="d-inline-block" *ngIf="isArabic()"> {{ item.NameAR }}</span>
          <span class="d-inline-block" *ngIf="!isArabic()"> {{ item.NameEN }}</span>
        </label>
        </div>

        <button *ngIf="userCategoryPages?.length > 0" class="btn btn-primary" (click)="updateUserCategoryPages()">{{ "Save" | translate }}</button>

    </div>

</div>