<ng-container *ngIf="currentView == components.userAddressList">
  <div class="d-flex flex-column h-100 w-100">
    <!-- start header -->
    <div class="d-flex align-items-center headerBg">
      <div (click)="BackBtn()" class="wid-48px p-8px">
        <img class="  h-100 w-100 " [src]=" (isArabic ? ' assets/images/backArrowwhite-right.png ' : ' assets/images/backArrowwhite.png ' ) ">
      </div>
      <div class="w-100  text-white p-4px">
        {{'UserAddressList' | translate}}
      </div>
    </div>
    <!--end header-->
   <!-- start this to make loading center in page on any screen size -->
   <div *ngIf="showLoading"
   class=" d-flex flex-column z-index-3 position-fixed w-100 h-100 justify-content-center align-items-center bg-white"
   style="opacity: 0.5;">
   <div class="p-2  align-self-center">
     <span class="load-more-List text-center vw-100 vh-100">
       <span class="spinner-border text-primary" role="status">
       </span>
     </span>
   </div>
 </div>
    <!-- userAdressesLst - start -->
    <div class="h-100 overflow-auto p-1 addressList">

      <div class="w-100 row">

        <div *ngFor="let ua of userAdressesLst" class="p-1 col-lg-6 col-md-6 col-xs-12 col-sm-12">

          <div class="d-flex h-100 flex-column p-1 address">
            <!-- addressDetails - start -->
            <div class="addressDetails d-flex">
              <span class="icons d-flex flex-column align-items-center">
                <i class="fa fa-map-marker" aria-hidden="true"></i>
                <span class="text-success d-block" (click)="chooseAddress(ua)" *ngIf="isSelectionMode">
                  <i class="fa fa-check-circle-o" aria-hidden="true"></i>
                </span>
              </span>
              <div>
                <h6 class="addressTitle">{{ ua.ReceiverName }}</h6>
                <p class="addressDesc">{{ ua.Description }} </p>
              </div>
            </div>
            <!-- addressDetails - end -->
            <!-- buttons - start -->
            <div class="btnContainer d-flex justify-content-center align-items-center" *ngIf="isArabic">
              <span class="btn btn-primary border-0" (click)="EditAddress(ua.ID)">
                {{'Edit'|translate}}
              </span>
              <span class="btn btn-primary border-0 yellowBg" (click)="deleteAddress(ua)">
                {{'Delete'|translate}}
              </span>
            </div>
            <div class="btnContainer d-flex justify-content-center align-items-center" *ngIf="!isArabic">
              <span class="btn btn-primary border-0 yellowBg" (click)="deleteAddress(ua)">
                {{'Delete'|translate}}
              </span>
              <span class="btn btn-primary border-0" (click)="EditAddress(ua.ID)">
                {{'Edit'|translate}}
              </span>
            </div>
            <!-- buttons - end -->

          </div>
        </div>
      </div>
    </div>
    <!-- userAdressesLst - end -->

    <div class="modal-footer border-0 justify-content-start">
      <button class="btn btn-primary w-75 mx-auto addNewAddressBtn" (click)="GoToAddAddress()" skipLocationChange="true" routerLinkActive="active">
        <!-- <img class="wid-40px" src="assets/images/more.svg"> -->
        <i class="fa fa-plus p-1" aria-hidden="true"></i>
        <span>{{'AddAdress'|translate}}</span>
      </button>
    </div>

  </div>
</ng-container>

<ng-container *ngIf="currentView == components.addEditUserAddress">
  <app-user-address-addedit [UserId]='UserId' [addressId]='addressId'>
  </app-user-address-addedit>
</ng-container>
