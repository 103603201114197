import { OrdersSummaryDto } from './OrdersSummaryDto';
import { OrderModel } from 'src/app/models/Order.Model';
import { LookupsModel } from './Lookups.model';
import { ProductPatchModel } from './ProductPatch.model';
export class ProductDetailsDto {
  ID: number;
  NameEN: string;
  NameAR: string;
  DescriptionEN: string;
  DescriptionAR: string;
  IsAvailable: boolean;
  Image: string;
  LastModifiedTime: string;
  Category0: LookupsModel;
  Category1: LookupsModel;
  Category2: LookupsModel;
  Category3: LookupsModel;
  Brand: LookupsModel;
  Type: LookupsModel;
  Color: LookupsModel;
  Supplier: LookupsModel;
  PurchasingPrice: number;
  LocationTackingNumber: string;
  InventoryShelfNumber: string;
  ProductWeights: number;
  ProductLength: number;
  ProductWidth: number;
  ProductOriginCountry: string;
  ImageExist: boolean;
  ProductImages: string[];
  ProductPatchDTOs: ProductPatchModel[];
  QuantitySum: number;
  BookedSum: number;
  BalanceSum: number;
  PatchCount: number;
  CountOfOrders: number;
  CountOfOrdersPerItem: number;
  TotalOrders: number;

  OrdersList: OrderModel[];
  OrdersSummaryDto: OrdersSummaryDto[];
}
