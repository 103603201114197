import { DiscountCategoryEnum } from "../enum/DiscountCategoryEnum";
import { DiscountClientEligibility, DiscountCodeTypeEnum, DiscountProductEligibilityEnum } from "../enum/usercategory.enum";

export class GeneralDiscount {
    ID: number;
    Name: String;
    PercentageType: DiscountCodeTypeEnum
    Amount: number;
    ValidDateFrom: Date;
    ValidDateTo: Date;
    CustomersEligibility: DiscountClientEligibility;
    ProductEligibility: DiscountProductEligibilityEnum;
    IsDeleted: boolean;
    CreatedBy: string;
    CreatedTime: Date;
    MaxProdcutPrice:number;
    MinProdcutPrice:number;
    LastModifiedBy: string
    LastModifiedTime: Date;
    DiscountCategory: DiscountCategoryEnum;

    DiscountCustomers: DiscountCustomerRequest[] = []
    DiscountProducts: DiscountProductRequest[] = []

    VoucherCode: string;
    MinmumOrderAmount: number;
    IsRetail?:boolean;
}

export class DiscountCustomerRequest {
    ID: number;
    CustomerID: string;
    DiscountID: number;
    IsDeleted: boolean;
    CreatedBy?: string;
    CreatedTime?: Date;
    LastModifiedBy?: string;
    LastModifiedTime?: Date;
     DiscountCategoryEnum :DiscountCategoryEnum

}

export class DiscountProductRequest {
    ID: number;
    ProductID: number;
    DiscountID: number;
    IsDeleted: boolean;
    CreatedBy?: string;
    CreatedTime?: Date;
    LastModifiedBy?: string;
    LastModifiedTime?: Date;
    DiscountCategoryEnum :DiscountCategoryEnum

}
