<div class="d-flex w-100 flex-column bd-highlight p-2">
    <div class="bd-highlight p-2">
        <h4 class="p-2 mb-0">{{ title }}</h4>
    </div>
    <div *ngIf="errMsg" class="text-danger mr-auto p-2 bd-highlight">
        {{ errMsg }}
    </div>
    <!-- start this to make loading center in page on any screen size -->
    <div *ngIf="showLoading" class=" d-flex flex-column z-index-3 position-fixed w-100 h-100 justify-content-center align-items-center bg-white" style="opacity: 0.5">
        <div class="p-2 align-self-center">
            <span class="load-more-List text-center vw-100 vh-100">
                <span class="spinner-border text-primary" role="status"> </span>
            </span>
        </div>
    </div>
    <!-- #region Form -->
    <form #AddEditFrom="ngForm" autocomplete="off" (ngSubmit)="AddEditFrom.form.valid && saveProductImages()">
        <!-- #region form Body -->
        <div class="row mt-2">
            <div class="col-4">
                <label> {{ "NameEN" | translate }} </label>
                <input class="form-control rounded" name="NameEN" type="text" [(ngModel)]="product.NameEN" #NameEN="ngModel" placeholder="{{ 'NameEN' | translate }}" required />
                <div *ngIf="AddEditFrom.submitted && NameEN.errors?.required" class="text text-danger px-3">{{ "NameENisrequired" | translate }} </div>
            </div>
            <div class="col-4">
                <label> {{ "NameAR" | translate }} </label>
                <input class="form-control rounded" name="NameAR" type="text" [(ngModel)]="product.NameAR" #NameAR="ngModel" placeholder="{{ 'NameAR' | translate }}" required />
                <div *ngIf="AddEditFrom.submitted && NameAR.errors?.required" class="text text-danger px-3">
                    {{ "NameARisrequired" | translate }}
                </div>
            </div>
            <div class="col-4">
                <label> {{ "ProductSuppliers" | translate }} </label>
                <kendo-combobox class="form-control w-100 rounded" [allowCustom]="true" name="SupplierDDL" #SupplierDDL="ngModel" [data]="SuppliersList" [valueField]="'ID'" [textField]="IsArabic ? 'NameAR' : 'NameEN'" [(ngModel)]="product.Supplier" [filterable]="true"
                    [loading]="brandLoading" placeholder="{{ 'ProductSuppliers' | translate }}">
                    <ng-template kendoComboBoxNoDataTemplate>
                        <h4><span class="k-icon k-i-warning"></span><br /><br /> {{ "NotAvailable" | translate }}</h4>
                    </ng-template>
                </kendo-combobox>
                <!-- <div
          *ngIf="AddEditFrom.submitted && BrandDDL.errors?.required"
          class="text text-danger px-3"
        >
          {{ "ProductBrandisrequired" | translate }}
        </div> -->
            </div>

            <div class="col-4">
                <label> {{ "ProductCategory" | translate }} </label>
                <kendo-combobox class="w-100 form-control rounded" [allowCustom]="true" name="categoryDDL" [data]="CategoriesList" [valueField]="'ID'" [textField]="IsArabic ? 'NameAR' : 'NameEN'" [filterable]="true" [loading]="categoryLoading" (filterChange)="getCategories($event)"
                    [(ngModel)]="product.Category" #categoryDDL="ngModel" placeholder="{{ 'ProductCategory' | translate }} " required>
                    <ng-template kendoComboBoxNoDataTemplate>
                        <h4><span class="k-icon k-i-warning"></span><br /><br /> {{ "NotAvailable" | translate }}</h4>
                    </ng-template>
                </kendo-combobox>
                <!-- <kendo-multiselect
        class="w-100 form-control rounded"
        name="categoryDDL"
        [data]="CategoriesList"
        [textField]="IsArabic ? 'NameAR' : 'NameEN'"
        [valueField]="'ID'"
        [loading]="categoryLoading"
        (filterChange)="getCategories($event)"
        placeholder="{{ 'ProductCategory' | translate }}"
        [valuePrimitive]="true"
        [(ngModel)]="productCategoryIDs"
        #categoryDDL="ngModel"
        required
        >

        </kendo-multiselect> -->
                <div *ngIf="AddEditFrom.submitted && categoryDDL.errors?.required" class="text text-danger px-3">
                    {{ "Categoryisrequired" | translate }}
                </div>
            </div>

            <div class="col-4">
                <label> {{ "ProductCategory2" | translate }} </label>
                <kendo-combobox class="w-100 form-control rounded" [allowCustom]="true" name="categoryDDL2" [data]="CategoriesList" [valueField]="'ID'" [textField]="IsArabic ? 'NameAR' : 'NameEN'" [filterable]="true" [loading]="categoryLoading" (filterChange)="getCategories($event)"
                    [(ngModel)]="product.Category1" #categoryDDL2="ngModel" placeholder="{{ 'ProductCategory2' | translate }}">
                    <ng-template kendoComboBoxNoDataTemplate>
                        <h4>
                            <span class="k-icon k-i-warning"></span><br /><br /> {{ "NotAvailable" | translate }}
                        </h4>
                    </ng-template>
                </kendo-combobox>
                <!-- <kendo-multiselect
        class="w-100 form-control rounded"
        name="categoryDDL"
        [data]="CategoriesList"
        [textField]="IsArabic ? 'NameAR' : 'NameEN'"
        [valueField]="'ID'"
        [loading]="categoryLoading"
        (filterChange)="getCategories($event)"
        placeholder="{{ 'ProductCategory' | translate }}"
        [valuePrimitive]="true"
        [(ngModel)]="productCategoryIDs"
        #categoryDDL="ngModel"
        required
        >

        </kendo-multiselect> -->
            </div>

            <div class="col-4">
                <label> {{ "ProductCategory3" | translate }} </label>
                <kendo-combobox class="w-100 form-control rounded" [allowCustom]="true" name="categoryDDL3" [data]="CategoriesList" [valueField]="'ID'" [textField]="IsArabic ? 'NameAR' : 'NameEN'" [filterable]="true" [loading]="categoryLoading" (filterChange)="getCategories($event)"
                    [(ngModel)]="product.Category2" #categoryDDL3="ngModel" placeholder="{{ 'ProductCategory3' | translate }}">
                    <ng-template kendoComboBoxNoDataTemplate>
                        <h4>
                            <span class="k-icon k-i-warning"></span><br /><br /> {{ "NotAvailable" | translate }}
                        </h4>
                    </ng-template>
                </kendo-combobox>
            </div>

            <div class="col-4">
                <label> {{ "ProductCategory4" | translate }} </label>
                <kendo-combobox class="w-100 form-control rounded" [allowCustom]="true" name="categoryDDL4" [data]="CategoriesList" [valueField]="'ID'" [textField]="IsArabic ? 'NameAR' : 'NameEN'" [filterable]="true" [loading]="categoryLoading" (filterChange)="getCategories($event)"
                    [(ngModel)]="product.Category3" #categoryDDL4="ngModel" placeholder="{{ 'ProductCategory4' | translate }}">
                    <ng-template kendoComboBoxNoDataTemplate>
                        <h4>
                            <span class="k-icon k-i-warning"></span><br /><br /> {{ "NotAvailable" | translate }}
                        </h4>
                    </ng-template>
                </kendo-combobox>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-4">
                <label> {{ "ProductBrand" | translate }} </label>
                <kendo-combobox required class="form-control w-100 rounded" [allowCustom]="true" name="BrandDDL" #BrandDDL="ngModel" [data]="BrandsList" [valueField]="'ID'" [textField]="IsArabic ? 'NameAR' : 'NameEN'" [(ngModel)]="product.Brand" [filterable]="true" [loading]="brandLoading"
                    (filterChange)="getBrands($event)" placeholder="{{ 'ProductBrand' | translate }}">
                    <ng-template kendoComboBoxNoDataTemplate>
                        <h4>
                            <span class="k-icon k-i-warning"></span><br /><br /> {{ "NotAvailable" | translate }}
                        </h4>
                    </ng-template>
                </kendo-combobox>
                <div *ngIf="AddEditFrom.submitted && BrandDDL.errors?.required" class="text text-danger px-3">{{ "ProductBrandisrequired" | translate }}</div>
            </div>
            <div class="col-4">
                <label> {{ "ProductType" | translate }} </label>
                <kendo-combobox class="form-control w-100 rounded" [allowCustom]="true" required #TypeDDL="ngModel" name="TypeDDL" [data]="TypesList" [valueField]="'ID'" [textField]="IsArabic ? 'NameAR' : 'NameEN'" [(ngModel)]="product.Type" [filterable]="true" [loading]="typeLoading"
                    (filterChange)="getTypes($event)" placeholder="{{ 'ProductType' | translate }}">
                    <ng-template kendoComboBoxNoDataTemplate>
                        <h4><span class="k-icon k-i-warning"></span><br /><br /> {{ "NotAvailable" | translate }}</h4>
                    </ng-template>
                </kendo-combobox>
                <div *ngIf="AddEditFrom.submitted && TypeDDL.errors?.required" class="text text-danger px-3">
                    {{ "ProductTypeisrequired" | translate }}
                </div>
            </div>
            <div class="col-4">
                <label> {{ "ProductColor" | translate }} </label>
                <kendo-combobox name="ColorDDL" class="form-control w-100 rounded" [allowCustom]="true" [data]="ColorsList" [valueField]="'ID'" [textField]="IsArabic ? 'NameAR' : 'NameEN'" [(ngModel)]="product.Color" [filterable]="true" [loading]="colorLoading" (filterChange)="getColors($event)"
                    placeholder="{{ 'ProductColor' | translate }}">
                    <ng-template kendoComboBoxNoDataTemplate>
                        <h4>
                            <span class="k-icon k-i-warning"></span><br /><br /> {{ "NotAvailable" | translate }}
                        </h4>
                    </ng-template>
                </kendo-combobox>
            </div>
            <div class="col-4">
                <label> {{ "ProductPurchasingPrice" | translate }} </label>
                <input class="form-control rounded" type="text" name="Price" [(ngModel)]="product.PurchasingPrice" #ProductPurchasingPrice="ngModel" placeholder="{{ 'ProductPurchasingPrice' | translate }}" required pattern="^(\d+(\.\d+)*)$" />
                <div *ngIf=" AddEditFrom.submitted && ProductPurchasingPrice.errors?.required" class="text text-danger px-3"> {{ "ProductPurchasingPriceisrequired" | translate }}</div>
            </div>
            <div class="col-4">
                <input class="form-control rounded" type="text" name="ProductOriginCountry" [(ngModel)]="product.ProductOriginCountry" #ProductOriginCountry="ngModel" placeholder="{{ 'ProductOriginCountry' | translate }}" />
            </div>
            <div class="col-4">
                <label> {{ "ProductWeigths" | translate }} </label>
                <input class="form-control rounded" type="text" name="ProductWeigths" [(ngModel)]="product.ProductWeights" #ProductWeigths="ngModel" placeholder="{{ 'ProductWeigths' | translate }}" pattern="^(\d+(\.\d+)*)$" />
            </div>
            <div class="col-4">
                <label> {{ "ProductLength" | translate }} </label>
                <input class="form-control rounded" type="text" name="ProductLength" [(ngModel)]="product.ProductLength" #ProductLength="ngModel" placeholder="{{ 'ProductLength' | translate }}" pattern="^(\d+(\.\d+)*)$" />
            </div>
            <div class="col-4">
                <label> {{ "ProductWidth" | translate }} </label>
                <input class="form-control rounded" type="text" name="ProductWidth" [(ngModel)]="product.ProductWidth" #ProductWidth="ngModel" placeholder="{{ 'ProductWidth' | translate }}" pattern="^(\d+(\.\d+)*)$" />
            </div>
            <div class="col-4">
                <label> {{ "ProductOrder" | translate }} </label>
                <input class="form-control rounded" type="text" name="ProductOrder" [(ngModel)]="product.ProductOrder" #ProductOrder="ngModel" placeholder="{{ 'ProductOrder' | translate }}" pattern="^(\d+(\.\d+)*)$" />
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-4 d-inline-flex bd-highlight align-items-center">
                <!-- <label class="invisible"> test </label> -->
                <label class="d-block w-100 hold-checkbox position-relative align-self-end">
                    <input class="form-control rounded" type="checkbox" name="IsAvailable"
                        [(ngModel)]="product.IsAvailable" />
                    <span class="checkmark"></span>
                    <span class="d-inline-block">{{ "PublishStatus" | translate }}</span>
                </label>
            </div>

            <div class="col-8 d-inline-flex bd-highlight align-items-center">
                <div class="col-3">
                    <label for="isRetail">{{ "IsRetail"| translate}}</label>
                </div>
                <div class="col-2">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="IsRetail" id="Retail" required #IsRetailInput="ngModel" [value]="1" [(ngModel)]="product.IsRetail">
                        <label class="form-check-label" for="Retail">{{"Shine" | translate}}</label>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="IsRetail" id="Salon" required #IsRetailInput="ngModel" [value]="2" [(ngModel)]="product.IsRetail">
                        <label class="form-check-label" for="Salon">{{"2assar" | translate}}</label>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="IsRetail" id="Both" required #IsRetailInput="ngModel" [value]="3" [(ngModel)]="product.IsRetail">
                        <label class="form-check-label" for="Retail">{{"BothAppType" | translate}}</label>
                    </div>
                </div>
                <div *ngIf="AddEditFrom.submitted && IsRetailInput.errors?.required" class="text text-danger px-3">{{ "Retailisrequired" | translate }}</div>
            </div>

        </div>
        <div class="row mt-2">
            <div class="col-6">
                <label> {{ "DescriptionEN" | translate }} </label>
                <textarea placeholder="{{ 'DescriptionEN' | translate }}" class="form-control w-100 rounded" name="DescriptionEN" #DescriptionEN="ngModel" [(ngModel)]="product.DescriptionEN" required></textarea>
                <div *ngIf="AddEditFrom.submitted && DescriptionEN.errors?.required" class="text text-danger px-3">
                    {{ "ProductDescriptionisrequired" | translate }}
                </div>
            </div>
            <div class="col-6">
                <label> {{ "DescriptionAR" | translate }} </label>
                <textarea placeholder="{{ 'DescriptionAR' | translate }}" class="form-control w-100 rounded" name="DescriptionAR" #DescriptionAR="ngModel" [(ngModel)]="product.DescriptionAR" required></textarea>
                <div *ngIf="AddEditFrom.submitted && DescriptionAR.errors?.required" class="text text-danger px-3">
                    {{ "ProductDescriptionisrequired" | translate }}
                </div>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-6">
                <label> {{ "LocationTackingNumber" | translate }} </label>
                <textarea placeholder="{{ 'LocationTackingNumber' | translate }}" class="form-control w-100 rounded" name="LocationTackingNumber" #LocationTackingNumber="ngModel" [(ngModel)]="product.LocationTackingNumber" required></textarea>
                <div *ngIf="
            AddEditFrom.submitted && LocationTackingNumber.errors?.required
          " class="text text-danger px-3">
                    {{ "ProductLocationTackingNumberIsRequired" | translate }}
                </div>
            </div>
            <div class="col-6">
                <label> {{ "InventoryShelfNumber" | translate }} </label>
                <textarea placeholder="{{ 'InventoryShelfNumber' | translate }}" class="form-control w-100 rounded" name="InventoryShelfNumber" #InventoryShelfNumber="ngModel" [(ngModel)]="product.InventoryShelfNumber"></textarea>
                <!-- <div
          *ngIf="AddEditFrom.submitted && InventoryShelfNumber.errors?.required"
          class="text text-danger px-3"
        >
          {{ "ProductLocationTackingNumberIsRequired" | translate }}
        </div> -->
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-6">
                <label class="d-block"> {{ "MainProductImage" | translate }} </label>
                <!-- <input
          class="upload-box"
          id="fileUpload"
          type="file"
          accept="image/png, .jpeg, .jpg, .bmp"
          (change)="fileChangeEvent($event)"
          placeholder="{{ 'Upload a file...' | translate }}"
        /> -->
                <div class="file-input-wrapper">
                    <label for="upload-file" class="file-input-button">{{"UploadFile" | translate}}</label>
                    <input id="upload-file" type="file" accept="image/png, .jpeg, .jpg, .bmp" (change)="fileChangeEvent($event)" />
                </div>
                <img id="imgup" class="img-thumbnail align-self-center border-0 img-fluid mx-4" [src]="imageSource" />

                <div *ngIf="showProductImageReqMesg" class="text text-danger px-3">
                    {{ "ProductImageisrequired" | translate }}
                </div>
            </div>
        </div>

        <div class="row mt-3">
            <label> {{ "MultipleProductImage" | translate }} </label>
            <div class="col-4 file-input-wrapper">
                <label for="upload-files" class="file-input-button">
                    {{ "UploadFiles" | translate }}
                </label>
                <input type="file" id="upload-files" accept="image/png, .jpeg, .jpg" multiple (change)="onSelectFiles($event)" />
            </div>

            <div class="col-4">
                <div *ngIf="multiUploaderrMsg" class="text-danger mr-auto p-2 bd-highlight">
                    {{ multiUploaderrMsg }}
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <!-- Progress Bar -->
            <div class="progress form-group" *ngIf="progress > 0">
                <div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style.width.%]="progress">
                </div>
            </div>
        </div>

        <div class="row mt-3 imagesPreview">
            <div class="image-area" *ngFor="let image of productImagesToUpload">
                <img [src]="image.Value" alt="Preview" />
                <a class="remove-image" (click)="removeImagePreview(image)" style="display: inline">&#215;</a>
            </div>
        </div>

        <!--#endregion Form Body -->
        <!-- buttons -->
        <div class="row mt-2">
            <div class="col-6">
                <button class="btn btn-primary bg-blue border-0 m-1" type="submit">
                    {{ "Save" | translate }}
                </button>
                <button class="btn btn-primary bg-blue border-0 m-1" routerLink="/adminhome/ProductAdminList">
                    {{ "Cancel" | translate }}
                </button>
            </div>
        </div>
    </form>

    <!--#endregion Form -->
</div>