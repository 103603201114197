<div class="d-flex flex-column h-100 w-100">

  <div class="d-flex align-items-center headerBg">
    <div (click)="BackBtn()" class="wid-48px p-8px">
      <img class="  h-100 w-100 "
        [src]=" (isArabic ? ' assets/images/backArrowwhite-right.png ' : ' assets/images/backArrowwhite.png ' ) ">
    </div>
    <div class="w-100  text-white p-4px">
      {{'UserAddressList' | translate}}
    </div>
  </div>


  <div class="h-100 overflow-auto p-1">
    <div class="text text-danger offset-3  p-2">
      <span *ngFor="let o of errors">
        {{o}}
      </span>
    </div>
    <form #AddEditFrom="ngForm" (ngSubmit)="AddEditFrom.form.valid && SaveAddress()" autocomplete="off">
      <!-- row -->
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12  p-2 ">
          <input name="ReceiverName" type="text" class="position-relative form-control border-right-0 border-left-0 border-top-0" #ReceiverNames="ngModel" required
            [(ngModel)]="_userAdress.ReceiverName" placeholder="{{'ReciverName' | translate}}">
          <div *ngIf="AddEditFrom.submitted  && ReceiverNames.errors?.required" class="text text-danger offset-3">
            {{'ReceiverNameisrequired' | translate}}
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-2 ">
          <input name="ReceiverNumber" type="text" class="position-relative form-control border-right-0 border-left-0 border-top-0"
            [(ngModel)]="_userAdress.ReciverMobile" placeholder="{{'ReciverMobile' | translate}}"
            #ReciverMobile="ngModel" required>
          <div *ngIf="AddEditFrom.submitted  && ReciverMobile.errors?.required" class="text text-danger offset-3">
            {{'ReciverMobileisrequired' | translate}}
          </div>
        </div>
      </div>
      <!-- row -->
      <div class="row ">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-2 ">
          <a (click)="EditCityZone()">
            <input required #CityZones="ngModel" readonly name="City" type="text"
              placeholder="{{'CityZone' | translate }}" class="position-relative form-control border-right-0 border-left-0 border-top-0"
              [(ngModel)]="cityZone">
          </a>
          <div *ngIf="AddEditFrom.submitted  && cityZone == null " class="text text-danger offset-3">
            {{'CityZoneisrequired' | translate}}
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-2 ">
          <input name="Description" type="text" class="position-relative form-control border-right-0 border-left-0 border-top-0" #AddressDescription="ngModel"
            [(ngModel)]="_userAdress.Description" placeholder="{{'AddressDescription' | translate}}" required>
          <div *ngIf="AddEditFrom.submitted  && AddressDescription.errors?.required" class="text text-danger offset-3">
            {{'AddressDescriptionisrequired' | translate}}
          </div>
        </div>
      </div>
      <!-- row -->
      <div class="row ">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-2 ">
          <input name="BuildNumber" type="text" class="position-relative form-control border-right-0 border-left-0 border-top-0"
            [(ngModel)]="_userAdress.BuildNumber" placeholder="{{'BuildNumber' | translate}}">

        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-2 ">
          <input name="StreetName" type="text" class="position-relative form-control border-right-0 border-left-0 border-top-0"
            [(ngModel)]="_userAdress.Street" placeholder="{{'Street' | translate}}">

        </div>
      </div>
      <!-- row -->
      <div class="row ">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 text-center">
          <button class="btn btn-primary w-75 mx-auto" type="submit"> {{'Save' | translate}}</button>
        </div>
      </div>
    </form>
  </div>
</div>
<!-- ************************************************************************* -->
<div bsModal #cityModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
  <div class="modal-dialog modal-lg h-100 w-100 m-0 p-0">
    <div class="modal-content h-100 w-100">
      <div class="modal-body h-100 w-100">
        <app-city-zone-lookup [target]="'UserAddressADDEditComponent'"></app-city-zone-lookup>
      </div>
    </div>
  </div>
</div>
