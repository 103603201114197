import { ProductService } from './../../../services/product.service';
import { OrderStatus } from '../../../models/enum/OrderStatusEnum';
import { filter, map, tap } from 'rxjs/operators';
import { environment } from './../../../../environments/environment.prod';
import { LookupsModel } from './../../../models/Lookups.model';
import { OrdersSummaryDto } from './../../../models/OrdersSummaryDto';
import { UserAddressDto } from './../../../models/UserAddressDto.Model';
import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnChanges, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { QueryService } from "src/app/services/query.services";
import { OrderRequest } from "src/app/models/OrderRequest.Model";
import { configration } from "src/app/shared/config";
import {
  OrderModel,
  OrderItemModel,
  OrderExcelModel,
  OrderExcelMylerzModel,
} from "src/app/models/Order.Model";
import { CommunicationService } from "src/app/services/communication.service";
import { ActionType } from "src/app/models/Action";
import * as XLSX from "xlsx";
import { TranslateService } from "@ngx-translate/core";
import { Title } from "@angular/platform-browser";
import { VoucherDto } from "src/app/models/VoucherDto.Model";
import { SearchStream } from "src/app/models/search-stream-object.model";
import { SalesPersonRequest } from "src/app/models/SalesPersonRequest.Model";
import { UserCategoryEnum } from "src/app/models/enum/usercategory.enum";
import { SalesPersonDto } from "src/app/models/SalesPersonDto.Model";
import { OrderStatusEnum } from "src/app/models/enum/OrderStatusEnum";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { CommandService } from "src/app/services/command.service";
import { CancelOrderRequest } from "src/app/models/CancelOrderRequest.Model";
import { MobileUserDto } from "src/app/models/MobileUserDto";
import { PageChangedEvent } from "ngx-bootstrap/pagination";
import { ClientSummaryOrders } from 'src/app/models/ClientSummaryOrders';
import { jsPDF } from "jspdf";
import { CustomDatesEnum } from 'src/app/models/enum/CustomDatesEnum';
import { DateService } from 'src/app/services/date.service';
import { from, fromEvent, of, pipe } from 'rxjs';
import { ProductAvilability } from 'src/app/models/ProductAvilability';
import { ModalDirective } from "ngx-bootstrap/modal";
import { RESOURCE_CACHE_PROVIDER } from '@angular/platform-browser-dynamic';


@Component({
  selector: "app-order-list",
  templateUrl: "./admin-order-list.component.html",
  styleUrls: ["./admin-order-list.component.scss"],
})
export class adminOrderListComponent implements OnInit, AfterViewInit {
  @ViewChild("dateFrom", { static: false }) dateFrom: ElementRef;
  @ViewChild("dateTo", { static: false }) dateTo: ElementRef;
  @ViewChild('IsRetailInput', { static: false }) isRetailSearch: ElementRef;


  _showProductAvailabilityPopup = false;
  _productAvilabilityList: ProductAvilability[] = [];
  @ViewChild("ProductAvailabilityModal", { static: false })
  ProductAvailabilityModal: ModalDirective;

  customDatesEnum = CustomDatesEnum;
  showOrdersGridView: boolean = true;
  ClientSummaryOrdersPerStatus: ClientSummaryOrders[] = [];
  showOrderSummry = false;
  clientOrdersCount: number = 0;
  clientOrderTotalPrice: number = 0;

  orderRequest: OrderRequest = new OrderRequest();
  request: SalesPersonRequest = new SalesPersonRequest();
  public mobileUser: MobileUserDto = new MobileUserDto();
  showMenu: boolean = false;
  sOrderStatusEnum = OrderStatusEnum;
  from: Date = new Date("01/01/2021");
  // from: Date = new Date();
  to: Date = new Date();
  deliveryDateFrom: Date;
  deliveryDateTo: Date;
  OrderList: OrderModel[] = [];
  orderToExportPDF: OrderModel = new OrderModel();
  OrderExcelList: OrderModel[] = [];
  OrderStatusList: LookupsModel[] = [];
  VoucherList: VoucherDto[] = [];
  errMsg: string = "";
  showLoading: boolean;
  pathOfAPI: string;
  IsArabic = true;
  Total: number;
  SearchStream: SearchStream = new SearchStream();
  title: string;
  UserName: string;
  SalesPersonDDLLoading: boolean = false;
  salesPersonLst: SalesPersonDto[] = [];
  _userId: string;
  userCategoryId: number;
  ordersSummary: OrdersSummaryDto[] = [];
  userAddressDto: UserAddressDto = new UserAddressDto();
  sortDir = 1;//1= 'ASE' -1= DSC
  exportpdf = false;
  @ViewChild('ExportedToPDF', { static: false }) pdfTable: ElementRef;
  totalOrdersCount: number = 0;
  totalOrdersTotalPrice: number = 0;
  OrdersStatusIds: number[] = [];

  ProductID: number;

  constructor(
    private _queryService: QueryService,
    private translateService: TranslateService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _communcationServices: CommunicationService,
    private _command: CommandService,
    private _productService: ProductService,
    private titleService: Title,
    private _datepipe: DatePipe,
    private _dateTimeService: DateService
    // private ngxBootstrapConfirmService: NgxBootstrapConfirmService
  ) {


  }

  ngAfterViewInit(): void {
    fromEvent(this.isRetailSearch.nativeElement, 'keyup')
      .pipe(map(event => event['target'].value), tap((x) => console.log(x)))
      .subscribe(text => this.getOrders(this.orderRequest));
  }



  onChanges = (data: any) => {
    // this.orderRequest.IsRetail = data;
    // this.getOrders(this.orderRequest);
  }

  getProductID() {
    this._route.params.subscribe((params: Params) => {
      this.ProductID = +params["ProductID"];
      this._userId = params["ID"];
      this.userCategoryId = params["UserCategory"];
      let ordStats = params["OrdersStatusIds"];

      if (this.ProductID === 0) {
        if (ordStats) {
          if (ordStats != "0") {
            ordStats = ordStats.split(",");
            this.orderRequest.StatusIDs = []
            ordStats.forEach(item => this.orderRequest.StatusIDs.push(+item));
          }
        }
      }
    });

    if (this._userId != "0") {
      this.getUserId();
    }
  }

  ngOnInit() {

    this.orderRequest.PageSize = configration.pageSize;

    this.loadAfterLang(this.translateService.currentLang);
    this.translateService.onLangChange.subscribe((res) => this.loadAfterLang(res.lang));
    this.getProductID();
    this._communcationServices.getAction().subscribe((res) => {
      if (res.actionType == ActionType.ShowHideSideMenu) {
        this.showMenu = res.Result;
      }
    });
    if (this.ProductID != 0) {
      this.orderRequest.ProductID = this.ProductID;
      this.orderRequest.OrderId = null;
      this.pathOfAPI = environment.webApiEndPoint;
      this.orderRequest.From = this.from;
      this.orderRequest.To = this.to;
      this.orderRequest.StatusIDs = [];

      this.orderRequest.StatusIDs.push(OrderStatusEnum.Saved);
      this.orderRequest.StatusIDs.push(OrderStatusEnum.Delivered);
      this.orderRequest.StatusIDs.push(OrderStatusEnum.OnTheWay);
      this.orderRequest.StatusIDs.push(OrderStatusEnum.End);

      this.orderRequest.PageIndex = 1;
      this.orderRequest.PageSize = configration.pageSize;

      this.FillLookups();
      this.getOrders(this.orderRequest);

    } else {

      if (this._userId == "0") {

        this.orderRequest.OrderId = null;
        this.pathOfAPI = environment.webApiEndPoint;
        this.orderRequest.From = this.from;
        this.orderRequest.To = this.to;
        this.orderRequest.StatusIDs = [];
        this.orderRequest.StatusIDs.push(OrderStatusEnum.Saved);
        this.orderRequest.StatusIDs.push(OrderStatusEnum.Delivered);
        this.orderRequest.StatusIDs.push(OrderStatusEnum.OnTheWay);
        this.orderRequest.StatusIDs.push(OrderStatusEnum.End);
        this.orderRequest.PageIndex = 1;
        this.orderRequest.PageSize = configration.pageSize;

        this.FillLookups();
        this.getOrders(this.orderRequest);
      } else {
        if (+this.userCategoryId === UserCategoryEnum.Client) {
          this.orderRequest.UserID = this._userId;
        } else {
          this.orderRequest.SalesPersonId = this._userId;
        }
        this.orderRequest.OrderId = null;
        this.pathOfAPI = environment.webApiEndPoint;
        this.orderRequest.From = null;
        this.orderRequest.To = null;
        this.orderRequest.PageIndex = 1;
        this.orderRequest.PageSize = configration.pageSize;
        this.FillLookups();
        this.getOrders(this.orderRequest);
        this.showOrderSummry = true;
      }

    }

  }

  loadAfterLang(lang: string) {
    this.IsArabic = lang === "ar";
    this.title = this.translateService.instant("orderslist");
    this.titleService.setTitle(this.title);
  }
  getUserId() {
    try {
      this.errMsg = "";
      // this.showLoading = true;
      this.request.UserId = this._userId;
      this._queryService.getMogileUserById(this.request).subscribe(
        (res) => {
          // this.showLoading = false;
          if (res.HasError) {
            this.errMsg = res.ErrorMessage;
            return;
          }

          this.mobileUser = res.Result;
          this.UserName = this.mobileUser.UserName;
        },
        (err) => {
          this.errMsg = err;
          // this.showLoading = false;
        }
      );
    } catch (error) {
      this.errMsg = error;
      // this.showLoading = false;
    }
  }
  FillLookups() {
    this._queryService.getAllOrderStatus().subscribe(
      (res) => {
        if (res.HasError) {
          this.errMsg = res.ErrorMessage;
          return;
        }
        this.OrderStatusList = res.Result;
      },
      (err) => {
        this.errMsg = err;
      }
    );

    this.getUser();
  }
  getUser(searchKeyword: string = "") {

    this.SearchStream.initStream("UserDDL", (a) => {
      let search = new SalesPersonRequest();
      search.Filter = a;
      search.PageIndex = 1;
      search.PageSize = 100;
      search.UserCategories.push(UserCategoryEnum.Sales);

      this.SalesPersonDDLLoading = true;
      this._queryService.getAllSalesPersonList(search).subscribe(
        (res) => {
          if (res.HasError) {
            this.errMsg = res.ErrorMessage;
            return;
          }
          //populate PersonList
          this.salesPersonLst = res.Result.ItemList;
        },
        (err) => {
          this.errMsg = err;
        },
        () => {
          this.SalesPersonDDLLoading = false;
        }
      );
    }).next(searchKeyword);
  }

  InquiryOrderList() {
    this.orderRequest.From = this.from;
    this.orderRequest.To = this.to;
    this.orderRequest.DeliveryDateFrom = this.deliveryDateFrom;
    this.orderRequest.DeliveryDateTo = this.deliveryDateTo;

    this.orderRequest.PageIndex = 1;
    this.getOrders(this.orderRequest);
  }

  getOrders(filter: OrderRequest) {
    try {
      this.errMsg = "";
      this.showLoading = true;
      filter.IsArabic = this.IsArabic;

      this._queryService.getSearchOrderList(filter).subscribe(
        (res) => {
          if (res.HasError) {
            this.errMsg = res.ErrorMessage;
            this.showLoading = false;
            return;
          }

          this.OrderList = res.Result.ItemList;
          this.Total = res.Result.Total;

          if (this.OrderList.length > 0) {
            this.ordersSummary = this.OrderList[0].OrdersSummaryDto;
            this.totalOrdersCount = 0;
            this.totalOrdersTotalPrice = 0;
            this.totalOrdersCount = this.ordersSummary.reduce((partialTotal, x) => x.OrdersCount + partialTotal, 0);
            this.totalOrdersTotalPrice = this.ordersSummary.reduce((partialTotal, x) => x.OrdersTotalPrice + partialTotal, 0);

            // this.ordersSummary.forEach(x => {
            //   this.totalOrdersCount = this.totalOrdersCount + x.OrdersCount;
            //   this.totalOrdersTotalPrice = this.totalOrdersTotalPrice + x.OrdersTotalPrice;
            // })

          }

          // if(this._userId != "0")
          //this.calculateClientOrdersSummary(this.OrderList);

          this.showLoading = false;
        },
        (err) => {
          this.errMsg = err;
          this.showLoading = false;
        }
      );
    } catch (err) {
      this.errMsg = err;
      this.showLoading = false;
    }
  }


  MoreOrders() {
    if (this.showLoading) return;
    try {
      this.showLoading = true;
      this.orderRequest.PageIndex += 1;
      this._queryService.getSearchOrderList(this.orderRequest).subscribe(
        (res) => {
          if (res.HasError) {
            this.errMsg = res.ErrorMessage;
            this.showLoading = false;
            return;
          }
          let OrderList = res.Result.ItemList;
          this.OrderList = this.OrderList.concat(OrderList);
          this.showLoading = false;
        },
        (err) => {
          this.showLoading = false;
          this.errMsg = err;
        }
      );
    } catch (err) {
      this.errMsg = err;
      this.showLoading = false;
    }
  }

  getOrdersExcel(filter: OrderRequest) {
    try {
      this.errMsg = "";
      this.showLoading = true;
      this._queryService.getSearchOrderList(filter)
        .pipe(tap(() => this.showLoading = false))
        .subscribe(res => {
          if (res.HasError) {
            this.errMsg = res.ErrorMessage;
            return;
          }
          this.OrderExcelList = res.Result.ItemList;
          const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.mapOrdersToExcel(this.OrderExcelList));
          const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
          const excelBuffer: any = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

          /* save to file */
          XLSX.writeFile(workbook, "Order" + new Date().getTime() + ".xlsx");

        }, err => this.errMsg = err);
    } catch (err) {
      this.errMsg = err;
      this.showLoading = false;
    }
  }

  onexportAsXLSX = () => this.getOrdersExcel(this.getFilterCreitieria());


  onPagingChange(event: PageChangedEvent) {
    this.orderRequest.PageIndex = event.page;
    this.orderRequest.PageSize = event.itemsPerPage;
    this.getOrders(this.orderRequest);
  }

  UpdateOrderStatus(o: OrderRequest) {

    let check = confirm(this.translateService.instant("AreYouSureToChangeOrderStatus"));
    if (check) {
      try {
        this.errMsg = "";
        this.showLoading = true;

        this._queryService.getUpdateOrderStatus(o).subscribe(res => {
          this.showLoading = false;
          if (res.HasError) {
            this.errMsg = res.ErrorMessage;
            this.showLoading = false;
            return;
          }

          if (res.Result.ProductsAvilability.length > 0)
            this.showProductAvailabilityPopup(res.Result.ProductsAvilability);


          this.getOrders(this.orderRequest);
        },
          (err) => {
            this.errMsg = err;
            this.showLoading = false;
          }
        );
      } catch (err) {
        this.errMsg = err;
        this.showLoading = false;
      }
    }
  }
  CancelOrder(order: OrderModel) {
    var check = confirm(
      this.translateService.instant("AreYouSureCancelOrder")
    );
    if (check) {
      try {
        var request = new CancelOrderRequest();
        request.OrderToCancel = order;

        this.errMsg = "";
        this.showLoading = true;
        this._command.CancelOrder(request).subscribe(
          (res) => {

            this.showLoading = false;
            if (res.HasError) {
              this.errMsg = res.ErrorMessage;
              return;
            }
            this.getOrders(this.orderRequest);
          },
          (err) => {
            this.errMsg = err;
            this.showLoading = false;
          }
        );
      } catch (err) {
        this.errMsg = err;
        this.showLoading = false;
      }
    }
  }

  showSideMenu() {
    this.showMenu = !this.showMenu;
    this._communcationServices.showSideMenuAction(this.showMenu);
  }

  getImageFullPath = (imageExist: boolean, imageName: string, lastModifiedTime: Date): string => imageExist ?
    `${environment.webApiEndPoint}Product/${imageName}?date=${lastModifiedTime}` : 'assets/images/default.png';


  sumOrderItemQuantity = (OrderItemModel: OrderItemModel[]) => OrderItemModel.filter(x => x.isDeleted == false)
    .reduce((partialTotal, x) => partialTotal + x.Quantity, 0)


  getOrderTotal = (order: OrderModel) => order.OrderStatus === this.sOrderStatusEnum.Open ? order.Total :
    order.OrderItems.filter(x => x.isDeleted == false).reduce((partialTotal, x) => partialTotal + (x.Price * x.Quantity), 0);



  // confirmCancelOrder(orderModel: OrderModel) {
  //   let options ={
  //     title: 'Sure you want to delete this Order?',
  //     confirmLabel: 'Yes',
  //     declineLabel: 'Cancel'
  //   }
  //   this.ngxBootstrapConfirmService.confirm(options).then((res: boolean) => {
  //     if (res) {
  //       console.log('Okay', orderModel);
  //     } else {
  //       console.log('Cancel', orderModel);
  //     }
  //   });
  // }

  getOrderCityZoneNames(address: UserAddressDto) {
    let currentLang = this.translateService.currentLang;
    let cityName = currentLang == 'en' ? address.City.NameEN : address.City.NameAR;
    let zoneName = currentLang == 'en' ? address.Zone.NameEN : address.Zone.NameAR;

    if (cityName)
      return cityName + '/' + zoneName;
  }

  onSortClick(event: any, colName: string) {

    let target = event.currentTarget,
      classList = target.classList;

    /* sort DESC */
    if (classList.contains('fa-chevron-up')) {
      classList.remove('fa-chevron-up');
      classList.add('fa-chevron-down');
      this.sortDir = 0;

      /* sort ASC */
    } else {
      classList.add('fa-chevron-up');
      classList.remove('fa-chevron-down');
      this.sortDir = 1;
    }

    this.orderRequest.SortColumn = colName;
    this.orderRequest.SortDirection = this.sortDir;
    this.getOrders(this.orderRequest);
  }

  getUserAddress(address: UserAddressDto) {
    this.userAddressDto = new UserAddressDto();
    this.userAddressDto = address;

  }

  renderOrderDetails(order: OrderModel) {
    this.exportpdf = true;
    this.orderToExportPDF = order;
  }

  export($event) {
    let PDF = new jsPDF('p', 'mm', 'a4');
    let position = 0;
    PDF.addImage($event, 'PNG', 0, position, 208, 180)
    PDF.save('Order_Invoice_' + 5 + '.pdf');

  }

  searchOrderByCustomeDate(dateType: CustomDatesEnum) {
    let dayFrom = new Date();
    let dayTo = new Date();

    switch (dateType) {
      case this.customDatesEnum.Yesterday:
        dayFrom = new Date(this._dateTimeService.getYesterday());
        dayTo = new Date(this._dateTimeService.getYesterday());
        break;

      case this.customDatesEnum.CurrentWeek:
        dayFrom = new Date(this._dateTimeService.getFirstDayOfCurrentWeek());
        dayTo = new Date(this._dateTimeService.getLastDayOfCurrentWeek());
        break;

      case this.customDatesEnum.LastWeek:
        dayFrom = new Date(this._dateTimeService.getFirstDayOfPreviousWeek());
        dayTo = new Date(this._dateTimeService.getLastDayOfPreviousWeek());
        break;

      case this.customDatesEnum.CurrentMonth:
        dayFrom = new Date(this._dateTimeService.getFirstDayOfCurrentMonth());
        dayTo = new Date(this._dateTimeService.getLastDayOfCurrentMonth());
        break;

      case this.customDatesEnum.LastMonth:
        dayFrom = new Date(this._dateTimeService.getFirstDayOfPreviousMonth());
        dayTo = new Date(this._dateTimeService.getLastDayOfPreviousMonth());
        break;

      default:
        break;
    }

    this.from = new Date(dayFrom);
    this.to = new Date(dayTo);
    this.InquiryOrderList();
  }

  incrementOrderDayFrom() {
    this.from.setDate(this.from.getDate() + 1)
    this.dateFrom.nativeElement.value = this.getDateFormated(this.from);
  }

  decrementOrderDayFrom() {
    this.from.setDate(this.from.getDate() - 1)
    this.dateFrom.nativeElement.value = this.getDateFormated(this.from);
  }
  incrementOrderDayTo() {
    this.to.setDate(this.to.getDate() + 1)
    this.dateTo.nativeElement.value = this.getDateFormated(this.to);
  }
  decrementOrderDayTo() {
    this.to.setDate(this.to.getDate() - 1)
    this.dateTo.nativeElement.value = this.getDateFormated(this.to);
  }

  getDateFormated(date: Date): string {
    let dd = String(date.getDate()).padStart(2, '0');
    let mm = String(date.getMonth() + 1).padStart(2, '0');
    var yyyy = date.getFullYear();
    let result = dd + '-' + mm + '-' + yyyy;
    return result;
  }


  setOrderStatusPrevious(order: OrderModel) {
    let request = new OrderRequest();
    request.OrderStatusId = order.OrderStatus == OrderStatusEnum.Open ? OrderStatusEnum.Open : order.OrderStatus - 1;
    request.FromCurrentStatus = order.OrderStatus;
    request.ID = order.ID;
    this.UpdateOrderStatus(request);
  }

  setOrderStatusNext(order: OrderModel) {
    let request = new OrderRequest();
    request.OrderStatusId = order.OrderStatus == OrderStatusEnum.Delivered ? OrderStatusEnum.Delivered : order.OrderStatus + 1;
    request.FromCurrentStatus = order.OrderStatus;
    request.ID = order.ID;
    this.UpdateOrderStatus(request);
  }

  setOrderStatusEnd(order: OrderModel) {
    let request = new OrderRequest();
    request.OrderStatusId = OrderStatusEnum.End;
    request.ID = order.ID;
    this.UpdateOrderStatus(request);
  }

  getNextOrderStatus = (request: OrderModel) => this.loadOrderStatusList().find(x => x.ID > request.OrderStatus).NameEn;
  getPreviousOrderStatus = (request: OrderModel) => this.loadOrderStatusList().find(x => x.ID == (request.OrderStatus - 1)).NameEn;


  loadOrderStatusList(): OrderStatus[] {
    let result: OrderStatus[] = [];
    [
      { ID: OrderStatusEnum.Open, NameAr: "غير مؤكد", NameEn: "Status_1" },
      { ID: OrderStatusEnum.Saved, NameAr: " مؤكد", NameEn: "Status_2" },
      { ID: OrderStatusEnum.OnTheWay, NameAr: "فى الطريق", NameEn: "Status_3" },
      { ID: OrderStatusEnum.Delivered, NameAr: "تم التوصيل", NameEn: "Status_4" },
      // {ID:OrderStatusEnum.End, NameAr:"انتهاء", NameEn:"Status_6"},
      // {ID:OrderStatusEnum.Cancelled, NameAr:"منتهى", NameEn:"Status_5"},
    ].forEach(item => result.push(item))
    return result;
  }


  showProductAvailabilityPopup(ProductAvilabilityList: ProductAvilability[]) {
    this.ProductAvailabilityModal.show();
    this._productAvilabilityList = ProductAvilabilityList;
    this._showProductAvailabilityPopup = true;
  }

  closeProductAvailabilityModal() {
    this.ProductAvailabilityModal.hide();
    this._showProductAvailabilityPopup = false;
  }

  onexportAsXLSXMylerz = () => this.getOrdersExcelMylerz(this.getFilterCreitieria());


  getFilterCreitieria(): OrderRequest {
    let filter: OrderRequest = new OrderRequest();
    filter.IsExcel = true;
    filter.From = this.from;
    filter.To = this.to;
    filter.OrderStatusId = this.orderRequest.OrderStatusId;
    filter.VoucherId = this.orderRequest.VoucherId;
    filter.SalesPersonId = this.orderRequest.SalesPersonId;
    filter.OrderId = this.orderRequest.OrderId;
    filter.StatusIDs = this.orderRequest.StatusIDs;
    filter.IsRetail = this.orderRequest.IsRetail;
    return filter;
  }

  getOrdersExcelMylerz(filter: OrderRequest) {
    try {
      this.errMsg = "";
      this.showLoading = true;

      this._queryService.getSearchOrderList(filter).pipe(tap(() => this.showLoading = false)).subscribe(res => {
        if (res.HasError) {
          this.errMsg = res.ErrorMessage;
          return;
        }
        this.OrderExcelList = res.Result.ItemList;
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.mapOrderTOMylerzSystem(this.OrderExcelList));
        const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

        /* save to file */
        XLSX.writeFile(workbook, "MylerzOrder" + new Date().getTime() + ".xlsx");

      },
        (err) => this.errMsg = err
      );
    } catch (err) {
      this.errMsg = err;
      this.showLoading = false;
    }
  }

  mapOrderTOMylerzSystem(orderModels: OrderModel[]): OrderExcelMylerzModel[] {
    let orderitemRequests: OrderExcelMylerzModel[] = [];
    orderModels.forEach(order => {
      let orderExcel = new OrderExcelMylerzModel();
      orderExcel.OrderNumber = order.ID;
      orderExcel.OrderDate = new Date(order.OrderDate);
      orderExcel.Customer_Name = order.AspNetUser.FullName;
      orderExcel.Customer_Mobile_No = order.AspNetUser.PhoneNumber;
      orderExcel.ReciverName = order.Address.ReceiverName;
      orderExcel.Reciver_Mobile_No = order.Address.ReciverMobile;
      orderExcel.Quantity = this.sumOrderItemQuantity(order.OrderItems);
      orderExcel.DeliveryFees = order.DeliveryFees;
      orderExcel.TotalAfterDeliveryFees = order.NetAmount;
      orderExcel.Address_Description = `${order.Address.Description}, ${order.Zone.NameAR}, ${order.City.NameAR}`
      orderExcel.DeliveryDate = this._datepipe.transform(order.DeliveryDate, 'd-MMMM-y');
      orderExcel.Building_No = order.Address.BuildNumber;
      orderExcel.Street = order.Address.Street;
      orderExcel.Floor_No = order.Address.BuildNumber;
      orderExcel.Apartment_No = "";
      orderExcel.City = order.Address.City.NameAR;
      orderExcel.Neighborhood = order.Address.Zone.NameAR;
      orderitemRequests.push(orderExcel);
    });
    return orderitemRequests;
  }


  mapOrdersToExcel(OrderExcelList: OrderModel[]): OrderExcelModel[] {
    let orderitemRequests: OrderExcelModel[] = [];
    OrderExcelList.forEach((order) => {
      order.OrderItems.forEach((res) => {
        let orderExcel = new OrderExcelModel();
        orderExcel.ProductId = res.Product.ProductID;
        orderExcel.OrderNumber = res.OrderID;
        orderExcel.Package_Serial = res.ID;
        orderExcel.OrderDate = new Date(order.OrderDate)
        orderExcel.ProductNameEN = res.Product.NameEN;
        orderExcel.ProductNameAR = res.Product.NameAR;
        orderExcel.Description = res.Product.DescriptionAR;
        orderExcel.Total_Weight = " ";
        orderExcel.Service = "Same Day";
        orderExcel.Service_Type = "Counter-to-Door";
        orderExcel.Service_Category = "Delivery";
        orderExcel.Payment_Type = "Cash-on-Delivery";
        orderExcel.COD_Value = res.Price;
        orderExcel.Quantity = res.Quantity;
        orderExcel.COD_Total = res.Price * res.Quantity;
        orderExcel.DeliveryFees = order.DeliveryFees;
        orderExcel.Weight = " ";
        orderExcel.Dimensions = " ";
        orderExcel.Item_Category = res.Product.Category.NameAR;
        orderExcel.Item_Special_Notes = "";
        orderExcel.Customer_Name = order.AspNetUser.FullName;
        orderExcel.Customer_Mobile_No = order.AspNetUser.PhoneNumber;
        orderExcel.ReciverName = order.Address.ReceiverName;
        orderExcel.Reciver_Mobile_No = order.Address.ReciverMobile;
        orderExcel.DeliveryDate = this._datepipe.transform(order.DeliveryDate, 'd-MMMM-y');
        orderExcel.Building_No = order.Address.BuildNumber;
        orderExcel.Street = order.Address.Street;
        orderExcel.Floor_No = order.Address.BuildNumber;
        orderExcel.Apartment_No = "";
        orderExcel.City = order.Address.City.NameAR;
        orderExcel.Neighborhood = order.Address.Zone.NameAR;
        orderExcel.District = "";
        orderExcel.Address_Category = "";

        orderExcel.LocationTackingNumber = res.Product.LocationTackingNumber;
        orderExcel.InventoryShelfNumber = res.Product.InventoryShelfNumber;

        orderExcel.DiscountCode = order.PrmoCode;
        if (order.PromoCodeType) {
          orderExcel.DiscountType = order.PromoCodeType == 0 ? "Percentage" : "Value";
        }
        orderExcel.DiscountAmount = order.DiscountAmount;

        //orderExcel.OrderTotal = order.TotalAfterDiscount;
        orderitemRequests.push(orderExcel);
      });
    });

    return orderitemRequests;
  }


  EditOrder(order: OrderModel, orderNextStatus: number) {
    order.OrderStatus = this.sOrderStatusEnum.Saved;
    let request = new OrderRequest();
    request.OrderStatusId = this.sOrderStatusEnum.Saved;
    request.OrderPreviousStatus = orderNextStatus;
    request.OrderId = order.ID;
    request.UserID = order.UserID;
    request.ID = order.ID;
    request.IsExcel = false;
    order.OrderItems.forEach(item => {
      item.Product.AddedToCart = true;
      this._productService.changeProductState(item.Product);
    });
    this._communcationServices.updateCartNumber(order.OrderItems.length);
    this._queryService.getUpdateOrderStatus(request).subscribe(() =>
      this._router.navigateByUrl(`/adminhome/addneworder/${order.ID}`)
    );
  }



}
