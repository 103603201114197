<div class="d-flex justify-content-center mt-5">

  <h1>{{"WelcomeTo2ssar"| translate}}</h1>

</div>

<div class="d-flex justify-content-center mt-5">

  <img src="assets/images/mylerz-logo.png">

</div>
