<app-navbar-discount>
</app-navbar-discount>
<div class="container-fluid">
    <div class="bd-highlight p-2">
        <h4 class="p-2 mb-0">{{ title }}</h4>
    </div>

    <div *ngIf=" showLoading" class=" d-flex flex-column z-index-3 position-fixed w-100 h-100 justify-content-center align-items-center bg-white " style="opacity: 0.5">
        <div class="p-2 align-self-center">
            <span class="load-more-List text-center vw-100 vh-100">
        <span class="spinner-border text-primary" role="status"> </span>
            </span>
        </div>
    </div>
    <div class="text text-danger px-3" *ngIf="errors.length >0"> <span *ngFor="let o of errors"> {{ o }}</span></div>

    <!-- <div class="row">
        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12  mb-lg-2 mb-md-2 mb-sm-2">
            <div class="form-group">
                <label>{{ "ModuleType" | translate }}</label>
                <div class="form-control">
                    <label class="radio-inline ml-3">
            <input type="radio" name="moduleType" value="0" [(ngModel)]="isDiscountUsed" />
            {{ "VoucherSystem" | translate }}
          </label>
                    <label class="radio-inline ml-3">
            <input type="radio" name="moduleType" checked value="1" [(ngModel)]="isDiscountUsed" />
            {{ "DiscountSystem" | translate }}
          </label>
                </div>
            </div>
        </div>
    </div> -->


    <div class="row" *ngIf="!showLoading">
        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 ">

            <form novalidate (ngSubmit)="Save()" [formGroup]="discountForm">
                <div class="">
                    <!-- discountName -->
                    <div class="form-group mt-2">
                        <input type="text" class="form-control mb-2 rounded text-info" id="discountName" type="text" placeholder="{{ 'discountName' | translate }}" formControlName="discountName" [ngClass]="{
          'is-invalid':
            (discountForm.get('discountName')?.touched ||
              discountForm.get('discountName')?.dirty) &&
            !discountForm.get('discountName')?.valid
        }" />
                        <span class="invalid-feedback">
              <span *ngIf=" discountForm.get('discountName')?.errors?.required ||discountForm.invalid">{{
                "discountNameRequired" | translate }}</span>
                        </span>
                    </div>

                    <!-- discountType -->
                    <div class="form-group mt-2 mb-2">
                        <div class="form-control mt-2 mb-2">
                            <label>{{ "discountCodeType" | translate }}</label>
                            <label class="radio-inline ml-3 mr-3">
                <input type="radio" name="discountType" value="0" checked formControlName="discountType"
                  (change)="changeDiscountLabel('PromoCodePercentage')" /> {{ "Percentage" | translate }} </label>

                            <label class="radio-inline ml-3 mr-3">
                <input type="radio" name="discountType" value="1" formControlName="discountType"
                  (change)="changeDiscountLabel('PromoCodeAmount')" /> {{ "Amount" | translate }}
              </label>
                        </div>
                    </div>

                    <!-- AppType -->
                    <div class="form-group mt-2 mb-2">
                        <div class="form-control mt-2 mb-2">
                            <label>{{ "IsRetail" | translate }}</label>
                            <label class="radio-inline ml-3 mr-3">
                <input type="radio" name="appType" value="true" checked formControlName="appType" /> {{ "Shine"
                |translate}} </label>

                            <label class="radio-inline ml-3 mr-3">
                <input type="radio" name="appType" value="false" formControlName="appType" /> {{ "2assar" | translate }}
              </label>


                        </div>
                    </div>

                    <!-- discountValue -->
                    <div class="form-group mt-2 mb-2">
                        <label> {{ discountTypeText | translate }} </label>
                        <input class="form-control text-info" name="PromoCodeAmount" formControlName="discountValue" type="text" placeholder="{{ 'discountValue' | translate }}" [ngClass]="{
          'is-invalid':
            (discountForm.get('discountValue')?.touched || discountForm.get('discountValue')?.dirty) &&discountForm.get('discountValue')?.invalid }" />

                        <span class="invalid-feedback">
              <span *ngIf="
            discountForm.get('discountValue')?.errors?.required">{{ "discountValueRequired" | translate }}</span>
                        </span>
                        <span class="invalid-feedback" *ngIf="discountForm.get('discountValue')?.errors?.pattern">{{
              "acceptNumberOnly" | translate }}</span>
                    </div>




                    <!-- apply to min price product -->
                    <div class="form-group mt-2 mb-2">
                        <label> {{ "ApplyToMinProdcutPrice" | translate }} </label>
                        <input class="form-control text-info" name="PromoCodeAmount" formControlName="ApplyToMinProdcutPrice" type="text" placeholder="{{ 'ApplyToMinProdcutPrice' | translate }}" [ngClass]="{
                          'is-invalid': (discountForm.get('ApplyToMinProdcutPrice')?.touched || discountForm.get('ApplyToMinProdcutPrice')?.dirty) &&discountForm.get('ApplyToMinProdcutPrice')?.invalid }" />

                        <span class="invalid-feedback">
              <span *ngIf="discountForm.get('ApplyToMinProdcutPrice')?.errors?.required
       ">{{ "ApplyToMinProdcutPriceRequired" | translate }}</span>
                        </span>
                        <span class="invalid-feedback" *ngIf="discountForm.get('ApplyToMinProdcutPrice')?.errors?.pattern">{{"acceptNumberOnly" | translate
              }}</span>
                    </div>

                    <!-- apply to max price product -->
                    <div class="form-group mt-2 mb-2">
                        <label> {{ "ApplyToMaxProdcutPrice" | translate }} </label>
                        <input class="form-control text-info" name="ApplyToMaxProdcutPrice" formControlName="ApplyToMaxProdcutPrice" type="text" placeholder="{{ 'ApplyToMaxProdcutPrice' | translate }}" [ngClass]="{
                                    'is-invalid': (discountForm.get('ApplyToMaxProdcutPrice')?.touched || discountForm.get('ApplyToMaxProdcutPrice')?.dirty) &&discountForm.get('ApplyToMaxProdcutPrice')?.invalid }" />

                        <span class="invalid-feedback">
              <span *ngIf="discountForm.get('ApplyToMaxProdcutPrice')?.errors?.required
      ">{{ "ApplyToMaxProdcutPriceRequired" | translate }}</span>
                        </span>
                        <span class="invalid-feedback" *ngIf="discountForm.get('ApplyToMaxProdcutPrice')?.errors?.pattern">{{"acceptNumberOnly" | translate
              }}</span>
                    </div>

                    <!-- validDateFrom -->
                    <div class="form-group mt-2 mb-2">
                        <label> {{ "validDateFrom" | translate }} </label>

                        <input class="form-control mb-2 rounded text-info" name="validDateFrom" class="form-control mb-2 rounded text-info" formControlName="validDateFrom" type="datetime-local" placeholder="{{ 'validDateFrom' | translate }}" [ngClass]="{
          'is-invalid':
            (discountForm.get('validDateFrom')?.touched ||
              discountForm.get('validDateFrom')?.dirty) &&
            !discountForm.get('validDateFrom')?.valid
        }" />
                        <span class="invalid-feedback">
              <span *ngIf="
            discountForm.get('validDateFrom')?.errors?.required ||
            discountForm.get('validDateFrom')?.touched
          ">{{ "dateValidRequired" | translate }}</span>
                        </span>
                    </div>

                    <!-- validDateTo -->
                    <div class="form-group">
                        <label> {{ "validDateTo" | translate }} </label>
                        <input name="PromoCodeAmount" formControlName="validDateTo" type="datetime-local" class="form-control mb-2 rounded text-info" placeholder="{{ 'validDateTo' | translate }}" [ngClass]="{
          'is-invalid':
            (discountForm.get('validDateTo')?.touched ||
              discountForm.get('validDateTo')?.dirty) &&
            !discountForm.get('validDateTo')?.valid
        }" />
                        <span class="invalid-feedback">
              <span *ngIf="
            discountForm.get('validDateTo')?.errors?.required &&
            discountForm.get('validDateTo')?.touched
          ">{{ "dateValidRequired" | translate }}
              </span>
                        </span>
                        <span class="text-danger" *ngIf="
          discountForm?.errors?.dates &&
          (discountForm.get('validDateTo')?.touched ||
            discountForm.get('validDateTo')?.dirty)
        ">{{ "dateToMustBiggerThanFrom" | translate }}</span>
                    </div>

                    <!-- appliesToProduct -->
                    <div class="form-group">
                        <div class="form-control">
                            <label>{{ "appliesToProduct" | translate }}</label>
                            <label class="radio-inline ml-3 mr-3">
                <input type="radio" name="appliesToProduct" value="1" (change)="checkSelectedProductList()" checked
                  formControlName="appliesToProduct" />
                {{ "AllProducts" | translate }}
              </label>

                            <label class="radio-inline ml-3 mr-3">
                <input type="radio" name="appliesToProduct" value="2" (change)="checkSelectedProductList()"
                  formControlName="appliesToProduct" />
                {{ "SpecificProduct" | translate }}
              </label>
                            <label class="radio-inline ml-3 mr-3">
                <input type="radio" name="appliesToProduct" value="3" (change)="checkSelectedProductList()"
                  formControlName="appliesToProduct" />
                {{ "NoDiscount" | translate }}
              </label>
                        </div>
                    </div>

                    <!-- selectedProducts -->
                    <div class="form-group" *ngIf="
        discountForm.get('appliesToProduct').value != productEligibilityEnum.All">
                        <label>{{ "SelectSpecificProduct" | translate }}</label>
                        <ng-select [typeahead]="productTextSearch" [items]="productList" (clear)="clearSelectedProducts()" [loading]="showLoadingProduct" [virtualScroll]="true" [clearOnBackspace]="true" (scrollToEnd)="onScrollToEnd()" bindLabel="{{ 'NameEN' }}" bindValue="ID"
                            labelForId="selectedProducts" [multiple]="true" clearAllText="Clear" formControlName="selectedProducts">
                            <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                                <div>
                                    <span class="font font-weight-bold">{{ item.NameEN }}</span>
                                </div>
                                <small>{{ item.ID }} | {{ item.NameAR }} </small>
                            </ng-template>
                        </ng-select>
                        <span class="text-danger" *ngIf="
          discountForm?.errors?.productListError &&
          !showLoadingProduct &&
          (discountForm.get('selectedProducts')?.touched ||
            discountForm.get('selectedProducts')?.dirty)
        ">{{ "discountProductsEmpty" | translate }}</span>
                    </div>

                    <!-- customer Eligibility -->

                    <!-- <div class="form-group">
            <div class="form-control">
              <label>{{ "appliesToProduct" | translate }}</label>
              <label class="radio-inline ml-3 mr-3">
                <input type="radio" name="appliesToCustomer" value="1" checked (change)="checkSelectedUserLis()"
                  formControlName="appliesToCustomer" />
                {{ "AllUser" | translate }}
              </label>

              <label class="radio-inline ml-3 mr-3">
                <input type="radio" name="appliesToCustomer" value="2" (change)="checkSelectedUserLis()"
                  formControlName="appliesToCustomer" />
                {{ "SpecificUser" | translate }}
              </label>
              <label class="radio-inline ml-3 mr-3">
                <input type="radio" name="appliesToCustomer" (change)="checkSelectedUserLis()" value="3"
                  formControlName="appliesToCustomer" />
                {{ "NoDiscount" | translate }}
              </label>
            </div>
          </div> -->

                    <!-- selectedUsers -->
                    <!-- <div class="form-group"
            *ngIf=" discountForm.get('appliesToCustomer').value == clientEligibilityEnum.SpecificClients  ">
            <label>{{ "selecteSpecificUser" | translate }}</label>
            <ng-select [items]="userList" (clear)="clearSelectedUsers()" [typeahead]="userTextSearch"
              [loading]="showLoadingUsers" [virtualScroll]="true" [clearOnBackspace]="true"
              (scrollToEnd)="onScrollToEndUser()" bindLabel="Name" bindValue="Id" labelForId="selectedUsers"
              [multiple]="true" clearAllText="Clear" formControlName="selectedUsers">
              <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                <div>
                  <span class="font font-weight-bold">{{ item.Name }}</span>
                </div>
                <small>{{ item.PhoneNumber }} </small>
              </ng-template>
            </ng-select>
            <span class="text-danger"
              *ngIf="
          discountForm?.errors?.clientListError && !showLoadingUsers && (discountForm.get('selectedUsers')?.touched || discountForm.get('selectedUsers')?.dirty) ">{{
              "clientListEMpty" | translate }}</span>
          </div> -->

                </div>

                <div class="text-center mt-3">
                    <span class="text-danger">{{ errorMsg | translate }}</span>
                </div>

                <span class="text-danger m-3" *ngIf="
      discountForm?.errors?.discountProductOrUser &&
      (discountForm.get('appliesToProduct')?.touched ||
        discountForm.get('appliesToProduct')?.dirty) &&
      (discountForm.get('appliesToCustomer')?.touched ||
        discountForm.get('appliesToCustomer')?.dirty)
    ">{{ "discountProductOrUser" | translate }}</span>

                <div class="text-center mt-3">
                    <span class="text-success" *ngIf="isSuccessOperation">{{msg | translate}}</span>
                    <span class="text-danger" *ngIf="!isSuccessOperation">{{msg | translate}}</span>
                </div>

                <div class="row mb-1">
                    <div class=" m-1">
                        <button class="btn btn-primary bg-navy border-danger" type="submit" [disabled]="discountForm.invalid || isLoading">{{ "Save" | translate }}</button>
                        <button class="btn btn-primary bg-blue border-0 m-1" routerLink="/adminhome/discount">{{ "Cancel" |translate
              }}</button>
                    </div>
                </div>
            </form>
        </div>

        <!-- <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12 m-2" *ngIf="selectedUserList.length > 0 ">

            <table class="table table-hover">
                <thead>
                    <tr>
                        <th scope="col">
                            {{ "Name" | translate }}
                        </th>
                        <th scope="col">
                            {{ "PhoneNumber" | translate }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of selectedUserList">
                        <th scope="row"> {{item.Name}}</th>
                        <td>{{item.PhoneNumber}}</td>
                </tbody>
            </table>
        </div> -->

    </div>

</div>