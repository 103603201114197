import { environment } from './../../../../environments/environment.prod';
import { Component, OnInit } from "@angular/core";
import { SearchResult } from "src/app/models/SearchResult.Model";
import { QueryService } from "src/app/services/query.services";
import { TranslateService } from "@ngx-translate/core";
import { Title } from "@angular/platform-browser";
import { ProductBrandDTO } from "src/app/models/ProductBrandDTO.model";
import { ProductBrandRequest } from "src/app/models/ProductBrandRequest.model";
import { PageChangedEvent } from "ngx-bootstrap/pagination";
import { CommandService } from "src/app/services/command.service";
import { AddEditProductBrandRequest } from "src/app/models/AddEditProductBrandRequest.model";
import { configration } from "src/app/shared/config";

@Component({
  selector: "app-product-brand-admin-list",
  templateUrl: "./product-brand-admin-list.component.html",
  styleUrls: ["./product-brand-admin-list.component.scss"],
})
export class ProductBrandAdminListComponent implements OnInit {
  _AddEditProductBrandRequst: AddEditProductBrandRequest = new AddEditProductBrandRequest();

  productBrands: SearchResult<ProductBrandDTO> = new SearchResult<ProductBrandDTO>();
  ProductBrandRequest: ProductBrandRequest;
  IsArabic = true;
  searchName = "";
  showListLoading = false;
  errMsg = "";
  pathOfAPI: string;
  title: string;
  sortDir: number = 0;

  constructor(
    private commandService: CommandService,
    private queryService: QueryService,
    private translateService: TranslateService,
    private titleService: Title
  ) { }

  ngOnInit() {
    this.setDefaultSearchValues();
    this.loadAfterLang(this.translateService.currentLang);
    this.translateService.onLangChange.subscribe((res) => this.loadAfterLang(res.lang));
    this.searchCaller(this.ProductBrandRequest);
    //this.searchCaller();
  }

  loadAfterLang(lang: string) {
    this.IsArabic = lang === "ar";
    this.title = this.translateService.instant("ProductBrand");
    this.titleService.setTitle(this.title);
    // this.searchCaller(this.ProductBrandRequest);
  }

  Delete(item: ProductBrandDTO) {
    var check = confirm(this.translateService.instant("AreYouSureDeleteBrand"));
    if (check) {
      try {
        this.errMsg = "";
        this.showListLoading = true;
        this._AddEditProductBrandRequst.ProductBrandDTO = item;
        this._AddEditProductBrandRequst.ProductBrandDTO.IsDeleted = true;

        this.commandService
          .AddEditProductBrand(this._AddEditProductBrandRequst)
          .subscribe(
            (res) => {
              this.showListLoading = false;
              if (res.HasError) {
                if (res.ErrorMessage == "NotDeleted") {
                  var check = confirm(this.translateService.instant("DeleteBrand"));
                  return;
                }
                this.errMsg = res.ErrorMessage;
                return;
              }

              this.ngOnInit();
            },
            (err) => {
              this.errMsg = err;
              this.showListLoading = false;
            }
          );
      } catch (err) {
        this.errMsg = err;
        this.showListLoading = false;
      }
    }
  }

  //#region "table Pagination and Search"
  setDefaultSearchValues = () => this.ProductBrandRequest = new ProductBrandRequest();


  onSearchSubmit() {
    this.ProductBrandRequest.PageIndex = 1;
    this.searchCaller(this.ProductBrandRequest);
  }

  onPagingChange(event: PageChangedEvent) {
    this.ProductBrandRequest.PageIndex = event.page;
    this.ProductBrandRequest.PageSize = event.itemsPerPage;
    this.searchCaller(this.ProductBrandRequest);
  }


  searchCaller(request: ProductBrandRequest) {
    try {
      this.errMsg = "";
      this.showListLoading = true;

      // if (this.IsArabic) {
      //   this.ProductBrandRequest.NameAR = this.searchName;
      //   this.ProductBrandRequest.NameEN = '';
      // } else {
      //   this.ProductBrandRequest.NameEN = this.searchName;
      //   this.ProductBrandRequest.NameAR = '';
      // }
      request.Lang = this.translateService.currentLang;

      this.queryService.searchProductBrand(request).subscribe(res => {
        if (res.HasError == false && res.Result != null)
          this.productBrands = res.Result;
        else {
          this.productBrands.ItemList = [];
          this.productBrands.Total = 0;

          this.errMsg = res.ErrorMessage;
        }
        this.showListLoading = false;
      },
        (err) => {
          this.errMsg = err;
          // this.showListLoading = false;
        },
        () => this.showListLoading = false
      );
    } catch (err) {
      this.errMsg = err;
      this.showListLoading = false;
    }
  }

  clearSearch() {
    this.setDefaultSearchValues();
    this.searchCaller(this.ProductBrandRequest);
  }

  getImageFullPath = (imageExist: boolean, imageName: string, lastModifiedTime: Date): string => imageExist ?
    `${environment.webApiEndPoint}ProductBrand/${imageName}?date=${lastModifiedTime}` : 'assets/images/default.jpg';


  onSortClick(event: any, colName: string) {
    let classList = event.currentTarget.classList;
    this.changeStyleOnSortCLicked(classList);
    let request = this.changeRequestOnSortClicked(this.ProductBrandRequest, colName);
    this.searchCaller(request);
  }

  changeStyleOnSortCLicked(classList: any) {
    /* sort DESC */
    if (classList.contains('fa-chevron-up')) {
      classList.remove('fa-chevron-up');
      classList.add('fa-chevron-down');
      this.sortDir = 0;

      /* sort ASC */
    } else {
      classList.add('fa-chevron-up');
      classList.remove('fa-chevron-down');
      this.sortDir = 1;
    }
  }
  
  changeRequestOnSortClicked(request: ProductBrandRequest, columnName: string) {
    let changedRequest: ProductBrandRequest = request;
    changedRequest.Lang = this.translateService.currentLang;
    changedRequest.SortColumn = columnName;
    changedRequest.SortDirection = this.sortDir;
    return changedRequest;
  }

  onChanges = (data: any) => {
    this.ProductBrandRequest.IsRetail = data
    this.searchCaller(this.ProductBrandRequest);

  }
  //#endregion "table Pagination and Search"
}
