<div class="d-flex flex-column h-100 w-100 p-2">
    <div class="bd-highlight py-2">
        <div class="row">
            <div class="col-md-4">
                <h4>{{ title }}</h4>
            </div>
            <div class="col-md-2 offset-md-6">
                <button class="btn d-flex btn-primary ml-auto bg-red bg-blue border-0" routerLinkActive="active" routerLink="/adminhome/admingeneratevoucher">
          {{ "addnewvoucher" | translate }}
        </button>
            </div>
        </div>
    </div>

    <div *ngIf="showLoading" class="d-flex flex-column z-index-3 position-fixed w-100 h-100 justify-content-center align-items-center bg-white" style="opacity: 0.5;">
        <div class="p-2 align-self-center">
            <span class="load-more-List text-center vw-100 vh-100">
        <span class="spinner-border text-primary" role="status"> </span>
            </span>
        </div>
    </div>
    <!-- #region Form -->
    <form #queryvoucher="ngForm" (ngSubmit)="queryvoucher.form.valid && Inquiry()" autocomplete="off">
        <div class="row w-100 h-100">
            <!--Drop Dwone list-->
            <div class="col-4">
                <kendo-combobox class="w-100 form-control rounded" name="salesPersonDDL" [data]="salesPersonLst" (filterChange)="getUser($event)" [filterable]="true" [loading]="SalesPersonDDLLoading" [valueField]="'Id'" [textField]="'Name'" [(ngModel)]="SelectedsalesPerson"
                    #salesPersonDDL="ngModel" placeholder="{{ 'SelectSalesPerson' | translate }}" required>
                    <ng-template kendoComboBoxNoDataTemplate>
                        <h4>
                            <span class="k-icon k-i-warning"></span><br /><br /> {{ "NotAvailable" | translate }}
                        </h4>
                    </ng-template>
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                        <span class="k-icon k-i-arrow-s"></span> {{ dataItems.length }} item(s) selected
                    </ng-template>
                </kendo-combobox>
                <div *ngIf="queryvoucher.submitted && salesPersonDDL.errors?.required" class="text text-danger offset-3">
                    {{ "salespersonisrequired" | translate }}
                </div>
            </div>
            <div class="col-6">
                <button class="btn btn-primary bg-blue border-0" type="submit">
          {{ "Inquery" | translate }}
        </button>
            </div>
        </div>
    </form>
    <!-- ************************************************* -->

    <div class="w-100 p-2">
        <!-- header -->
        <div class="d-flex flex-row bg-secondary text-white">
            <div class="p-1 w-20">{{ "SalesPersonNumber" | translate }}</div>
            <div class="p-1 w-20">{{ "Creationdate" | translate }}</div>
            <div class="p-1 w-20">{{ "No.Voucher" | translate }}</div>
            <div class="p-1 w-20">{{ "Description" | translate }}</div>
            <div class="p-1 w-20"></div>
        </div>
    </div>

    <div class="h-100 overflow-auto">
        <div *ngFor="let voucherlog of voucherLogLst" class="d-flex flex-row row border-bottom border-info bg-white overflow-hidden p-1 mb-1 position-relative">
            <div *ngIf="showLoading" class="d-flex flex-column z-index-3 p-0 m-0 position-absolute bg-white w-100 p-20 h-100 justify-content-center align-items-center" style="opacity: 0.8;">
                <div class="align-self-center">
                    <span class="load-more-List text-center">
            <span class="spinner-border text-primary" role="status"> </span>
                    </span>
                </div>
            </div>

            <div class="p-1 w-20">
                {{ voucherlog.Username }}
            </div>

            <div class="p-1 w-20">
                {{ voucherlog.Creationdate | date: "yyyy-MM-dd HH:mm a" }}
            </div>
            <div class="p-1 w-20">{{ voucherlog.VoucherNumber }}</div>
            <div class="p-1 w-20">{{ voucherlog.Note }}</div>
            <div class="p-1 w-20">
                <i (click)="onexportAsXLSX(voucherlog)" title="Excel" class="fa fa-file-excel-o fa-lg"></i>
            </div>
        </div>
        <div *ngIf="voucherLogLst?.length==0" class="d-flex flex-column z-index-3  w-100 h-100 justify-content-center align-items-center bg-white">
            <label class="la">{{"NoDataFound"|translate}}</label>
        </div>
    </div>

    <!-- *********************************************************** -->

    <div class="row mt-2">
        <div class="col-xs-12 col-12">
            <app-pagination-component [ItemsCount]="Total" (changePage)="onPagingChange($event)">
            </app-pagination-component>
        </div>
    </div>
</div>