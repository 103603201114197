import { Component, OnInit } from '@angular/core';
import { QueryService } from 'src/app/services/query.services';
import { SalesPersonDto } from 'src/app/models/SalesPersonDto.Model';
import { VoucherRequest } from 'src/app/models/VoucherRequest.Model';
import { VoucherDto } from 'src/app/models/VoucherDto.Model';
import * as XLSX from 'xlsx';
import { SearchStream } from 'src/app/models/search-stream-object.model';
import { SalesPersonRequest } from 'src/app/models/SalesPersonRequest.Model';
import { VoucherLogDto } from 'src/app/models/VoucherLogDto.Model';
import { config } from 'rxjs';
import { configration } from 'src/app/shared/config';
import { UserCategoryEnum } from 'src/app/models/enum/usercategory.enum';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
@Component({
  selector: 'app-query-voucher',
  templateUrl: './query-voucher.component.html',
  styleUrls: ['./query-voucher.component.scss']
})
export class QueryVoucherComponent implements OnInit {
  showLoading: boolean;
  errMsg: string;
  salesPersonLst: SalesPersonDto[] = [];
  voucherLogLst: VoucherLogDto[] = [];
  voucherLst: VoucherDto[] = [];
  voucherRequest: VoucherRequest = new VoucherRequest();
  SelectedsalesPerson: SalesPersonDto;
  SearchStream: SearchStream = new SearchStream();
  Total: number;
  SalesPersonDDLLoading = false;
  title: string;

  constructor(
    private _queryService: QueryService,
    private translateService: TranslateService,
    private titleService: Title
  ) { }

  ngOnInit() {
    this.loadAfterLang(this.translateService.currentLang);
    this.translateService.onLangChange.subscribe(res => {
      this.loadAfterLang(res.lang);
    });

    this.voucherRequest.PageIndex = 1;
    this.voucherRequest.PageSize = configration.pageSize;

    this.getUser();
    this.Inquiry();
  }

  loadAfterLang(lang: string) {
    this.title = this.translateService.instant('voucher')
    this.titleService.setTitle(this.title);
  }


  getUser(searchKeyword: string = "") {
    this.SearchStream.initStream("UserDDL", (a) => {

      let search = new SalesPersonRequest();
      search.Filter = a;
      search.PageIndex = 1;
      search.PageSize = 100;
      search.UserCategories.push(UserCategoryEnum.Sales);

      this.SalesPersonDDLLoading = true;
      this._queryService.getAllSalesPersonList(search).subscribe(res => {
        if (res.HasError) {
          this.errMsg = res.ErrorMessage;
          return;
        }
        //populate PersonList
        if (res.Result.ItemList.length > 0) {
          res.Result.ItemList.forEach(x => {
            if (x.IsActive) {
              this.salesPersonLst.push(x);
            }
          })
        }
        // this.salesPersonLst = res.Result.ItemList;
      }, err => {
        this.errMsg = err;
      }, () => {
        this.SalesPersonDDLLoading = false;
      });

    }).next(searchKeyword);








  }


  Inquiry() {
    try {

      this.showLoading = true;
      if (this.SelectedsalesPerson)
        this.voucherRequest.UserId = this.SelectedsalesPerson.Id;
      else {
        this.voucherRequest.UserId = "";
      }
      this._queryService.QueryVoutcher(this.voucherRequest).subscribe(res => {
        if (res.HasError) {
          this.errMsg = res.ErrorMessage;
          this.showLoading = false;
          return;
        }
        //populate voucherLst
        this.voucherLogLst = res.Result.ItemList;
        this.Total = res.Result.Total;

        this.showLoading = false;
      }, err => {
        this.showLoading = false;
        this.errMsg = err;
      });
    }
    catch (err) {
      this.errMsg = err;
      this.showLoading = false;
    }
  }

  onPagingChange(event: PageChangedEvent) {
    this.voucherRequest.PageIndex = event.page;
    this.voucherRequest.PageSize = event.itemsPerPage;
    this.Inquiry();
  }

  onexportAsXLSX(voucher: VoucherLogDto) {

    try {

      this.showLoading = true;
      this.showLoading = true;

      this.voucherRequest.UserId = voucher.UserId;
      this.voucherRequest.VoucherLogId = voucher.Id;
      this._queryService.getUserVoutcherByLogId(this.voucherRequest).subscribe(res => {
        if (res.HasError) {
          this.errMsg = res.ErrorMessage;
          this.showLoading = false;
          return;
        }
        //populate voucherLst
        let vLst = res.Result;
        //map to ex
        this.voucherLst = [];
        vLst.forEach(i => {
          let iExcel = new VoucherDto();
          iExcel.ID = i.ID;
          iExcel.UserName = i.UserName;
          this.voucherLst.push(iExcel);
        })
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.voucherLst);
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        /* save to file */
        XLSX.writeFile(workbook, 'Voucher' + new Date().getDate() + '.xlsx');
        this.showLoading = false;

      }, err => {
        this.showLoading = false;
        this.errMsg = err;
      });

    } catch (err) {
      this.errMsg = err;
      this.showLoading = false;
    }

  }
}
