export let configration = {
  // webApiEndPoint: "https://ecommercemobileservicedev.softecinternational.com/",
  // webApiEndPoint: "https://ecommercemobileservicetest.softecinternational.com/",
  //webApiEndPoint: "http://41.33.122.51:2020/",
  // webApiEndPoint: "http://localhost:52076/",
  //webApiEndPoint: "http://localhost:2020/",
  // webApiEndPoint:"https://api.2assar.com/",



  pageSize: 25,
  urls: {
    apiUpdateProductPathcInOrder: "api/Command/UpdateProductPatchInOrder",
    apiSearchAllProductPatchesURL: "api/Query/SearchAllProductPatches",
    apiQueryVoutcherURL: "api/Query/QueryVoutcher",
    apiGetUserVoutcherByLogIdURL: "api/Query/GetUserVoutcherByLogId",
    apiGenerateUserVouchersURL: "api/Query/GenerateUserVouchers",
    apiGetSalesPersonByIdURL: "api/Query/GetSalesPersonById",
    apiGetMobileUserByIdURL: "api/Query/GetMobileUserById",
    apiGetUserInfoByIdURL: "api/Query/GetUserInfoById",
    apiGetUserByIdURL: "api/Query/GetUserById",
    apiGetAllSalesPersonListURL: "api/Query/GetAllSalesPerson",
    apiGetMobileUsersURL: "api/Query/GetMobileUsers",
    apiExcelMobileUsersURL: "api/Query/ExcelMobileUser",
    apiGetMobileUsersNotActiveURL: "api/Query/GetMobileUsersNotActive",
    apiSearchProductCategoryURL: "api/Query/SearchProductCategoryNewVersion",
    // apiSearchProductCategoryURL: "api/Query/SearchProductCategory",
    apiSearchProductsURL: "api/Query/SearchProduct",
    apiSearchProductsExcelURL: "api/Query/SearchProductExcel",
    apiSearchProductPatchesURL: "api/Query/SearchProductPatches",
    apiSearchPatchesURL: "api/Query/SearchPatches",
    SearchUserFavProductsURL: "api/Query/SearchUserFavProducts",
    apiSearchOrderListURL: "api/Query/SearchOrderList",
    apiSearchOrderURL: "api/Query/SearchOrder",
    apiUpdateOrderStatusURL: "api/Command/UpdateOrderStatus",
    apiAllUserAddrsessURL: "api/Query/GetAllUserAddress",
    apiUserAddrsessByIdURL: "api/Query/GetUserAddressById",
    apiUserAddrsessByUserIdURL: "api/Query/GetUserAddressByUserId",
    apiAddEditUserAddressURL: "api/Command/AddEditUserAddress",
    apiDeleteUserAddressURL: "api/Command/DeleteUserAddress",
    apiApplyPromoCode: "api/Command/ApplyPromoCode",
    apiPuplishPushNotificationURL: "api/Command/PublishPushNotification",
    apiSaveNotificationURL: "api/Command/SaveNotification",
    apiFindOpenCart: "api/Query/FindOpenCartNewVersion",
    // apiFindOpenCart: "api/Query/FindOpenCart",
    apiConfirmOrder: "api/Command/ConfirmOrder",
    apiUpdateAddressOrder: "api/Command/UpdateAddressOrder",
    apiUpdateOrder: "api/Command/UpdateOrderToCard",
    apiUpdateOrderWithPromo: "api/Command/UpdateOrderWithPromo",
    apiCancelOrder: "api/Command/CancelOrder",
    apiAddItemToCart: "api/Command/AddItemToCart",
    apiAddItemToFav: "api/Command/AddItemToFav",
    apiAddEditProduct: "api/Command/AddEditProduct",
    apiSetProductAvailability: "api/Command/SetProductAvailability",
    apiCancelOrderFromCart: "api/Command/CancelOrderFromCart",
    apiAddEditProductCategory: "api/Command/AddEditProductCategory",
    apiSearchProductBrandURL: "api/Query/SearchProductBrand",
    apiSearchProductTypeURL: "api/Query/SearchProductType",
    apiSearchProductColorURL: "api/Query/SearchProductColor",
    apiAddEditProductBrand: "api/Command/AddEditProductBrand",
    apiAddEditProductType: "api/Command/AddEditProductType",
    apiAddEditProductColor: "api/Command/AddEditProductColor",
    apiAddEditProductPatch: "api/Command/AddEditProductPatch",
    apiSaveProductsExcelURL: "api/Command/SaveProductsExcel",
    apiDeleteProductFromCart: "api/Command/DeleteProductFromCart",
    apiUpdateUserCategoryPages: "api/Command/UpdateUserCategoryPages",

    apiSearchOrdersConfigurations: "api/Query/OrdersConfigurations",
    apiAddOrdersConfigurations: "api/Command/AddOrderConfiguration",
    apiDeleteOrdersConfigurations: "api/Command/DeleteOrderConfiguration",

    apiGetClientDetails: "api/Query/GetClientDetails",
    apiGetProductDetails: "api/Query/GetProductDetails",
    apiGetMobileSearchKeyword: "api/Query/GetMobileSearchKeyword",
    apiGetProductSuppliers: "api/Query/SearchProductSuppliers",
    apiGetUsers: "api/Query/GetUsers",
    apiGetProducts: "api/Query/GetProducts",

    apiGetUsersByIds:"api/Query/GetUsersByIds",
    apiGetProductsByIds:"api/Query/GetProductsByIds",

    apiAddEditProductSupplier: "api/Command/AddEditProductSupplier",
    apiDeleteProductSupplier: "api/Command/DeleteProductSupplier",

    apiAddEditDiscountVoucher: "api/Command/AddEditDiscountVoucher",
    apiDeleteDiscountVoucher: "api/Command/DeleteDiscountVoucher",

    apiGetDiscountVoucherByID:"api/Query/GetDiscountVoucherByID",
    apiGetAllDiscountVoucher:"api/Query/GetAllDiscountVoucher",

    apiAddDiscount: "api/Command/AddEditDiscount",
    apiDeleteDiscount:"api/Command/DeleteDiscount",
    
    apiGetAllDiscount: "api/Query/GetAllDiscount",
    apiGetAllCustomerDiscount: "api/Query/GetAllCustomerDiscount",
    apiGetAllProductDiscount: "api/Query/GetAllProductDiscount",
    
    apiGetDiscountBySearch:"api/Query/GetDiscountBySearch",
    apiGetDiscountByID:"api/Query/GetDiscountByID",
    
    apiGetAllQuantityPatchPrice:"api/Query/GetAllQuantityPatchPrice",
    apiGetQuantityDiscountBySearch:"api/Query/GetQuantityDiscountBySearch",

    apiAddEditQuantityDiscount:"api/Command/AddEditQuantityDiscount",
    apiDeleteQuantityPatchPrice:"api/Command/DeleteQuantityPatchPrice",

    apiAddEditUserNotes:"api/Command/AddEditUserNotes",
    apiDeleteUserNotes:"api/Command/DeleteUserNotes",
    apiGetAllUserNotes:"api/Query/GetAllUserNotes",
    apiGetUserNotesBySearch:"api/Query/GetUserNotesBySearch",
    
    apiAddShineImage:"api/Command/AddShineImage",



    Accounts: {
      apiRegisterUrl: "api/Account/Register",
      apiEditUserUrl: "api/Account/EditUser",
      apiForgetPasswordUrl: "api/Account/FogetPassword",
      apiUserInfoUrl: "api/Account/UserInfo",
      apiUserDeleteUserUrl: "api/Account/DeleteUser",
      apiChangeUserPasswordByIdUrl: "api/Account/ChangePasswordByUserId",
      apiTokenUrl: "Token",
      apiSignOut:"api/Account/Logout"
    },
    Lookups: {
      apiGetAllSuppliersURL: "api/Query/GetAllSuppliers",
      apiGetAllProductCategoryURL: "api/Query/GetAllProductCategory",
      apiGetAllOrderStatusURL: "api/Query/GetAllOrderStatus",
      apiGetAllColorListURL: "api/Query/GetAllColorList",
      apiGetAllProductBrandURL: "api/Query/GetAllProductBrand",
      apiGetAllProductColorURL: "api/Query/GetAllProductColor",
      apiGetAllProductTypeURL: "api/Query/GetAllProductType",
      apiGetAllCitiesURL: "api/Query/GetAllCities",
      apiGetAllZonesURL: "api/Query/GetAllZones",
      apiGetAllCityZoneURL: "api/Query/GetAllCityZone",
      apiGetAllGetAllUserCategoriesURL: "api/Query/GetAllUserCategories",
      apiGetAllGetAllUserCategoryPagesURL: "api/Query/GetAllUserCategoryPages",
    },
    File: {
      apiUploadFilesUrl: "api/File/UploadFiles",
    },
  },
};
