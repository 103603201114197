import { environment } from './../../../../environments/environment.prod';
import { OrderItemModel } from 'src/app/models/Order.Model';
import { ProductDetailsDto } from './../../../models/ProductDetailsDto';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { QueryService } from 'src/app/services/query.services';
import { CommunicationService } from 'src/app/services/communication.service';
import { Title } from '@angular/platform-browser';
import { ProductDetailsRequest } from 'src/app/models/ProductDetailsRequest';
import { ProductModel } from 'src/app/models/Product.Model';
import { configration } from 'src/app/shared/config';
import { OrderStatusEnum } from 'src/app/models/enum/OrderStatusEnum';
import { from } from 'rxjs';
import { filter, groupBy, mergeMap, tap, toArray } from 'rxjs/operators';

@Component({
  selector: 'app-product-detials',
  templateUrl: './product-detials.component.html',
  styleUrls: ['./product-detials.component.scss']
})
export class ProductDetialsComponent implements OnInit {

  showLoading = false;
  IsArabic: boolean = true;
  title: string;
  productId: string;
  OrderStatusEnum = OrderStatusEnum;
  productDetails = new ProductDetailsDto();

  constructor(private _route: ActivatedRoute,
    private _translate: TranslateService,
    private _queryService: QueryService,
    private router: Router,
    private _communicationService: CommunicationService,
    private titleService: Title
  ) { }

  ngOnInit() {
    this.loadAfterLang(this._translate.currentLang);
    this._translate.onLangChange.subscribe((res) => this.loadAfterLang(res.lang));
    this._route.params.subscribe((params: Params) =>this.productId = params["ID"]);


    this.getProductDetails();
  }

  loadAfterLang(lang: string) {
    this.IsArabic = lang === "ar";

    this._route.params.subscribe((params: Params) => {
      let id = params["ID"];

      this.title = this._translate.instant("ProductDetails");
      this.titleService.setTitle(this.title);
    });
  }

  getProductDetails() {
    try {
      this.showLoading = true;
      let request = new ProductDetailsRequest();
      request.ProductId = +this.productId;

      this._queryService.getProductDetails(request).subscribe((res) => {
        this.productDetails = res.Result;
        this.showLoading = false;
      });

    } catch (error) {
      this.showLoading = false;
    }
  }

  getImageFullPath = (product: ProductDetailsDto): string => product.ImageExist ?
    `${environment.webApiEndPoint}Product/${product.Image}?date=${product.LastModifiedTime}` : 'assets/images/default.jpg';

  getProductImagesPath = (image, productId) => `${environment.webApiEndPoint}Product/${productId}/${image}?date=${this.productDetails.LastModifiedTime}`

  sumOrderItemQuantity = (OrderItemModel: OrderItemModel[]) => OrderItemModel
    .filter(x => x.isDeleted == false && x.Product.ProductID == +this.productId)
    .reduce((partialTotal, curr) => partialTotal + curr.Quantity, 0);


}
