import { CommandService } from 'src/app/services/command.service';
import { OrdersConfigurationsDto } from './../../../models/OrdersConfigurationsDto';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { OrdersConfigurationsRequest } from 'src/app/models/OrdersConfigurationsRequest';
import { QueryService } from 'src/app/services/query.services';
import { configration } from 'src/app/shared/config';

@Component({
  selector: 'app-orders-configuration',
  templateUrl: './orders-configuration.component.html',
  styleUrls: ['./orders-configuration.component.scss']
})
export class OrdersConfigurationComponent implements OnInit {


  title: string;
  IsArabic = true;
  total: number;
  ordersConfigurationsRequest = new OrdersConfigurationsRequest();
  ordersConfigList: OrdersConfigurationsDto[] = [];
  constructor(
    private _queryService: QueryService,
    private _commandService: CommandService,
    private translateService: TranslateService,
    private _router: Router,
    private titleService: Title,

  ) { }

  ngOnInit() {

    this.loadAfterLang(this.translateService.currentLang);
    this.translateService.onLangChange.subscribe((res) => this.loadAfterLang(res.lang));
    this.searchOrdersConfigurationsList();
  }

  loadAfterLang(lang: string) {
    this.IsArabic = lang === "ar";
    this.title = this.translateService.instant("AdminConfig");
    this.titleService.setTitle(this.title);
  }

  searchOrdersConfigurationsList() {
    try {
      this.ordersConfigurationsRequest.PageIndex = 1;
      this.ordersConfigurationsRequest.PageSize = configration.pageSize;

      this._queryService.searchOrdersConfigurations(this.ordersConfigurationsRequest).subscribe((res) => {
        this.ordersConfigList = res.Result.ItemList;
        this.total = res.Result.Total;
      })

    } catch (error) {

    }
  }

  saveOrderConfiguration() {
    try {
      let request = this.ordersConfigurationsRequest;
      this._commandService.AddOrderConfiguration(request).subscribe((res) => {
        location.reload(); 
        // this.searchOrdersConfigurationsList();

      })
    } catch (error) {

    }
  }

  deleteOrderConfig(id: number) {
    try {
      let request = new OrdersConfigurationsRequest();
      request.OrderConfigId = id;
      this._commandService.DeleteOrderConfiguration(request).subscribe((res) => {
        if (res) {
          this.searchOrdersConfigurationsList();
        }
      })
    } catch (error) {

    }
  }

  onPagingChange(event: PageChangedEvent) {
    this.ordersConfigurationsRequest.PageIndex = event.page;
    this.ordersConfigurationsRequest.PageSize = event.itemsPerPage;

    this.searchOrdersConfigurationsList();
  }

}
