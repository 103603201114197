import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
// import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { CommandService } from './services/command.service';
import { CommunicationService } from './services/communication.service';
import { ActionType } from './models/Action';
import { LoginAdminService } from './services/login-admin.service';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'Ecommerce';
  isRight: boolean = false;
  isLeft: boolean = false;
  modalRef: BsModalRef;
  @ViewChild('childModal', { static: false }) childModal: ModalDirective;
  constructor(public translate: TranslateService,
    private _communcationService: CommunicationService, private _adminLogin: LoginAdminService,
    private router: Router, private modalService: BsModalService, private route: ActivatedRoute) {
    translate.addLangs(['en', 'ar']);
    translate.setDefaultLang('ar')
    translate.use(this._adminLogin.getCurrentCulture());
  }




  swipeleft() {
    this.isLeft = true;

  }

  swiperight() {

    this.isRight = true;
  }

  ngOnInit(): void {
    //throw new Error("Method not implemented.");

  }

  get isAdmin() {
    return this.router.url.indexOf('adminhome') >= 0;
  }
}
