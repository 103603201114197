import { SalonTypeEnum } from "./enum/SalonTypeEnum";
import { UserAddressDto } from "./UserAddressDto.Model";

export class EditUserDto{
    IsAdmin: boolean;
    IsActive: boolean;
    UserId: string;
    VoucherID?: number;
    PromoCode: string;
    PromoCodeAmount: string;
    PromoCodeType: number;
    Notes: string;
    UserName: string;
    PhoneNumber: string;
    Name: string;
    UserCategoryId: number;
    Email: string;
    SalonType: SalonTypeEnum;
    SalonName: string;
    UserAddress: UserAddressDto;
    IsRetail?: boolean;
}
