<div id="mySidebar" [class]="
    (translate.currentLang == 'ar' ? ' rtl' : ' ') +
    (isCollapsed ? '   ' : ' collapsed') +
    '  row h-100 w-100'
  ">
    <div class="col-12 p-0 m-0 h-100 bg-white">
        <div class="row h-100">
            <div class="col-12 h-100 p-0 bg-petrol">
                <div class="d-flex flex-column bd-highlight scroll_menu">
                    <div class="p-2 justify-content-center d-flex bd-highlight">
                        <img class="wid-150px" src="assets/images/mylerz-logo.png" />
                    </div>


                    <div class="d-flex flex-column bd-highlight mb-3 h-100">
                        <div class="p-2 bd-highlight h-100">
                            <ul class="p-0 h-100 list-group-flush list-side">

                                <li *ngIf="isUserAllowed(UserCategoryPages.Orders)" class="p-2 border-0">
                                    <a routerLink="/adminhome/adminorderlist/0" routerLinkActive="active">
                                        <!-- <i class="fa   m-1 fa-shopping-bag   fa-2x wid-35px  " aria-hidden="true"></i> -->
                                        <img [src]="
                        activeCoellctios
                          ? 'assets/images/orderadminActive.svg'
                          : 'assets/images/orderadmin.svg'
                      " class="wid-35px mr-3" />

                                        <span [class]="
                        (isCollapsed ? '   ' : ' invisible') + ' text-white'
                      ">{{ "collections" | translate }}</span
                    >
                  </a>
                </li>

                <li *ngIf="isUserAllowed(UserCategoryPages.Products)" class="p-2 border-0">
                  <a routerLink="/adminhome/ProductAdminList" routerLinkActive="active">
                    <!-- <img class="wid-30px" src="assets/images/home-menu.svg"> -->
                    <img
                      [src]=" activeHome   ? 'assets/images/homeadminActive.svg'   : 'assets/images/homeadmin.svg'"
                      class="wid-35px mr-3"
                    />
                    <span
                      [class]="
                        (isCollapsed ? '   ' : ' invisible') + ' text-white'
                      "
                    >
                      {{ "Home" | translate }}</span
                    >
                  </a>
                </li>

                <li *ngIf="isUserAllowed(UserCategoryPages.AdminConfig)" class="p-2 border-0">
                  <a
                    routerLink="/adminhome/admin-config"
                    routerLinkActive="active"
                  >
                    <!-- <img class="wid-30px" src="assets/images/home-menu.svg"> -->
                    <img
                      [src]="
                        activeAdminSetting
                          ? 'assets/images/adminsettingsactive.svg'
                          : 'assets/images/adminsettings.svg'
                      "
                      class="wid-35px mr-3"
                    />
                    <span
                      [class]="
                        (isCollapsed ? '   ' : ' invisible') + ' text-white'
                      "
                    >
                      {{ "AdminConfig" | translate }}</span
                    >
                  </a>
                </li>

                <li *ngIf="isUserAllowed(UserCategoryPages.Vouchers)" class="p-2 border-0">
                  <!-- <img class="wid-30px" src="assets/images/product-menu.svg"> -->
                  <a
                    routerLink="/adminhome/adminqueryvoucher"
                    routerLinkActive="active"
                  >
                    <!-- <i class="fa   m-1 fa-tag   fa-2x wid-35px  " aria-hidden="true"></i> -->
                    <img
                      [src]="
                        activrVocher
                          ? 'assets/images/couponadminActive.svg'
                          : 'assets/images/couponadmin.svg'
                      "
                      class="wid-35px mr-3"
                    />

                    <span
                      [class]="
                        (isCollapsed ? '   ' : ' invisible') + ' text-white'
                      "
                    >
                      {{ "voucher" | translate }}</span
                    >
                  </a>
                </li>
                <li *ngIf="isUserAllowed(UserCategoryPages.Users)" class="p-2">
                  <!-- <img class="wid-30px" src="assets/images/order-menu.svg"> -->

                  <a
                    routerLink="/adminhome/adminuserlist"
                    routerLinkActive="active"
                  >
                    <!-- <i class="fa   m-1 fa-users   fa-2x wid-35px  " aria-hidden="true"></i> -->
                    <img
                      [src]="
                        activrUser
                          ? 'assets/images/useradminActive.svg'
                          : 'assets/images/useradmin.svg'
                      "
                      class="wid-35px mr-3"
                    />
                    <span
                      [class]="
                        (isCollapsed ? '   ' : ' invisible') + ' text-white'
                      "
                      >{{ "users" | translate }}</span
                    >
                  </a>
                </li>

                <li *ngIf="isUserAllowed(UserCategoryPages.ClientManagement)" class="p-2">
                  <!-- <img class="wid-30px" src="assets/images/order-menu.svg"> -->

                  <a
                    routerLink="/adminhome/adminmobileuserlist"
                    routerLinkActive="active"
                  >
                    <i
                      class="fa mr-3 fa-users fa-2x wid-35px"
                      aria-hidden="true"
                    ></i>
                    <!-- <img
                      [src]="
                        activrUser
                          ? 'assets/images/useradminActive.svg'
                          : 'assets/images/useradmin.svg'
                      "
                      class="wid-35px mr-3"
                    /> -->
                    <span
                      [class]="
                        (isCollapsed ? '   ' : ' invisible') + ' text-white'
                      "
                      >{{ "mobileusers" | translate }}</span
                    >
                  </a>
                </li>

                <li *ngIf="isUserAllowed(UserCategoryPages.ProductSuppliers)" class="p-2">
                  <a
                    routerLink="/adminhome/product-suppliers"
                    routerLinkActive="active"
                  >
                    <!-- <i class="fa   m-1 fa-users   fa-2x wid-35px  " aria-hidden="true"></i> -->
                    <img
                      [src]="
                        activeSuppliers
                          ? 'assets/images/suppliersActive.svg'
                          : 'assets/images/suppliers.svg'
                      "
                      class="wid-35px mr-3"
                    />
                    <span
                      [class]="
                        (isCollapsed ? '   ' : ' invisible') + ' text-white'
                      "
                      >{{ "ProductSuppliers" | translate }}</span
                    >
                  </a>
                </li>

                <li *ngIf="isUserAllowed(UserCategoryPages.Category)" class="p-2">
                  <a
                    routerLink="/adminhome/categoryadminlist"
                    routerLinkActive="active"
                  >
                    <!-- <i class="fa   m-1 fa-users   fa-2x wid-35px  " aria-hidden="true"></i> -->
                    <img
                      [src]="
                        activeCat
                          ? 'assets/images/categoryadminActive.svg'
                          : 'assets/images/categoryadmin.svg'
                      "
                      class="wid-35px mr-3"
                    />
                    <span
                      [class]="
                        (isCollapsed ? '   ' : ' invisible') + ' text-white'
                      "
                      >{{ "ProductCategory" | translate }}</span
                    >
                  </a>
                </li>
                <li *ngIf="isUserAllowed(UserCategoryPages.Brand)" class="p-2">
                  <a
                    routerLink="/adminhome/productbrandadminlist"
                    routerLinkActive="active"
                  >
                    <!-- <i class="fa   m-1 fa-users   fa-2x wid-35px  " aria-hidden="true"></i> -->
                    <img
                      [src]="
                        activeBrand
                          ? 'assets/images/brandadminActive.svg'
                          : 'assets/images/brandadmin.svg'
                      "
                      class="wid-35px mr-3"
                    />

                    <span
                      [class]="
                        (isCollapsed ? '   ' : ' invisible') + ' text-white'
                      "
                      >{{ "ProductBrand" | translate }}</span
                    >
                  </a>
                </li>
                <li *ngIf="isUserAllowed(UserCategoryPages.ProductType)" class="p-2">
                  <a
                    routerLink="/adminhome/producttypeadminlist"
                    routerLinkActive="active"
                  >
                    <!-- <i class="fa   m-1 fa-users   fa-2x wid-35px  " aria-hidden="true"></i> -->
                    <img
                      [src]="
                        activeType
                          ? 'assets/images/typeadminActive.svg'
                          : 'assets/images/typeadmin.svg'
                      "
                      class="wid-35px mr-3"
                    />
                    <span
                      [class]="
                        (isCollapsed ? '   ' : ' invisible') + ' text-white'
                      "
                      >{{ "ProductType" | translate }}</span
                    >
                  </a>
                </li>
                <li *ngIf="isUserAllowed(UserCategoryPages.ProductColor)" class="p-2">
                  <a
                    routerLink="/adminhome/productcoloradminlist"
                    routerLinkActive="active"
                  >

                    <img
                      [src]="
                        activeColor
                          ? 'assets/images/paintingadminActive.svg'
                          : 'assets/images/paintingadmin.svg'
                      "
                      class="wid-35px mr-3"
                    />
                    <span
                      [class]="
                        (isCollapsed ? '   ' : ' invisible') + ' text-white'
                      "
                      >{{ "ProductColor" | translate }}</span
                    >
                  </a>
                </li>
                <li *ngIf="isUserAllowed(UserCategoryPages.Inventory)" class="p-2">
                  <a
                    routerLink="/adminhome/inventory"
                    routerLinkActive="active"
                  >
                    <img
                      [src]="
                        activeInventory
                          ? 'assets/images/orderadminActive.svg'
                          : 'assets/images/orderadmin.svg'
                      "
                      class="wid-35px mr-3"
                    />
                    <span
                      [class]="
                        (isCollapsed ? '   ' : ' invisible') + ' text-white'
                      "
                      >{{ "Inventory" | translate }}</span
                    >
                  </a>
                </li>
                <li *ngIf="isUserAllowed(UserCategoryPages.UserCategoryPages)" class="p-2">
                  <a
                    routerLink="/adminhome/usercategorypages"
                    routerLinkActive="active"
                  >
                    <!-- <i
                      class="fa mr-3 fa-bell fa-2x wid-35px"
                      aria-hidden="true"
                    ></i> -->
                    <img
                    [src]="
                    activeUserCatPages
                        ? 'assets/images/managementActive.svg'
                        : 'assets/images/management.svg'
                    "
                    class="wid-35px mr-3"
                  />
                    <span
                      [class]="
                        (isCollapsed ? '   ' : ' invisible') + ' text-white'
                      "
                      >{{ "Permissions" | translate }}</span
                    >
                  </a>
                </li>
                <li *ngIf="isUserAllowed(UserCategoryPages.PushNotification)" class="p-2">
                  <a
                    routerLink="/adminhome/pushNotification"
                    routerLinkActive="active"
                  >
                    <i
                      class="fa mr-3 fa-bell fa-2x wid-35px"
                      aria-hidden="true"
                    ></i>
                    <span
                      [class]="
                        (isCollapsed ? '   ' : ' invisible') + ' text-white'
                      "
                      >{{ "PushNotification" | translate }}</span
                    >
                  </a>
                </li>

                <li *ngIf="isUserAllowed(UserCategoryPages.Discount)" class="p-2">
                  <a
                    routerLink="/adminhome/discount"
                    routerLinkActive="active"
                  >
                    <i
                      class="fa mr-3 fa-cubes fa-2x wid-35px"
                      aria-hidden="true"
                    ></i>
                    <span
                      [class]="
                        (isCollapsed ? '   ' : ' invisible') + ' text-white'
                      "
                      >{{ "DiscountModule" | translate }}</span
                    >
                  </a>
                </li>

                <li class="p-2 border-0">
                  <a (click)="GoToSignOut()">
                    <i
                      class="fa mr-3 fa-sign-out fa-2x wid-35px"
                      aria-hidden="true"
                    ></i>
                    <span
                      [class]="
                        (isCollapsed ? '   ' : ' invisible') + ' text-white'
                      "
                      >{{ "SignOut" | translate }}</span
                    >
                  </a>
                </li>

                <li class="p-2 border-0">
                  <a [class]="(isCollapsed ? '   ' : ' invisible') + '  '">
                    <i
                      class="fa m-1 fa-globe fa-2x wid-35px"
                      aria-hidden="true"
                    ></i>
                    <span
                      class="btn btn-link text-orange"
                      *ngIf="isEnglish"
                      (click)="changelang('en')"
                      >English</span
                    >
                    <span
                      class="btn btn-link text-orange"
                      *ngIf="!isEnglish"
                      (click)="changelang('ar')"
                      >عربي</span
                    >
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>