import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { UserAddressDto } from 'src/app/models/UserAddressDto.Model';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UserAddressRequest } from 'src/app/models/UserAddressRequest.Model';
import { QueryService } from 'src/app/services/query.services';
import { CommandService } from 'src/app/services/command.service';
import { AddEditUserAddressRequst } from 'src/app/models/AddEditUserAddressRequst.Model';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { CityZoneLookupComponent } from '../city-zone-lookup/city-zone-lookup.component';
import { CommunicationService } from 'src/app/services/communication.service';
import { CityZoneDto } from 'src/app/models/CityZoneDto.Model';
import { ActionType, Action } from 'src/app/models/Action';
import { ComponentEnum } from 'src/app/shared/component.enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-address-addedit',
  templateUrl: './user-address-addedit.component.html',
  styleUrls: ['./user-address-addedit.component.scss']
})
export class UserAddressADDEditComponent implements OnInit {
  //#region Properties
  errors: string[] = [];
  modalRef: BsModalRef;
  _userAdress: UserAddressDto = new UserAddressDto();
  _userAddressRequest: UserAddressRequest = new UserAddressRequest();
  _addEditUserAdressRequest: AddEditUserAddressRequst = new AddEditUserAddressRequst();
  errMsg: string;
  showLoading: boolean;
  cityZone: string;
  showMenu: boolean = false;
  isArabic:boolean = true;

  @ViewChild('cityModal', { static: false }) cityModal: ModalDirective;

  @Input() addressId: number;
  @Input() UserId: string ;
  //#endregion
  constructor(private _route: ActivatedRoute, private _router: Router, private _CommandService: CommandService,
    private _communcationServices: CommunicationService,
    private _queryService: QueryService,
    private _trans: TranslateService,
    private _commandService: CommandService,
    private _communicationService: CommunicationService,
    private modalService: BsModalService) {
      _trans.onLangChange.subscribe(()=>{
        this.isArabic = this._trans.currentLang == 'ar'

      } );
     }

  ngOnInit() {
    this.isArabic = this._trans.currentLang == 'ar' ;

    this.Init()
    this._communicationService.getAction().subscribe(res => {
      if (res.actionType == ActionType.CityZoneSelected) {

        var selected = (res.Result as CityZoneDto);
        this._userAdress.City = selected.City;
        this._userAdress.Zone = selected.ZoneList[0];
        this.cityZone = `${this.isArabic? this._userAdress.City.NameAR:this._userAdress.City.NameEN} / ${this.isArabic? this._userAdress.Zone.NameAR:this._userAdress.Zone.NameEN}`;

        this.cityModal.hide();
      }
      else if (res.actionType == ActionType.CityZoneClosed) {
        this.cityModal.hide();
      } else if (res.actionType == ActionType.ShowHideSideMenu) {
        this.showMenu = res.Result;
      }

    });
  }
  Init() {
    // this._route.params.subscribe((params: Params) => {
    //   this._userAddressRequest.AddressId = params['ID'];
    // });
    this._userAddressRequest.AddressId = this.addressId;
    this.getUserAddressById();
  }

  showSideMenu() {
    this.showMenu = !this.showMenu;
    this._communcationServices.showSideMenuAction(this.showMenu);

  }
  getUserAddressById() {

    try {
      this.errMsg = "";
      this.showLoading = true;

      this._queryService.getUserAddressById(this._userAddressRequest).subscribe(res => {
        this.showLoading = false;
        if (res.HasError) {
          this.errMsg = res.ErrorMessage;
          return;
        }
        this._userAdress = res.Result;
        if (this._userAdress.City.NameAR && this._userAdress.Zone.NameAR)
         this.cityZone = `${this.isArabic? this._userAdress.City.NameAR:this._userAdress.City.NameEN} / ${this.isArabic? this._userAdress.Zone.NameAR:this._userAdress.Zone.NameEN}`;

      }, err => {
        this.errMsg = err;
        this.showLoading = false;
      });
    } catch (err) {
      this.errMsg = err;
      this.showLoading = false;

    }
  }
  EditCityZone() {
    this.cityModal.show();

  }
  SaveAddress() {

    try {
      this.errMsg = "";
      this.showLoading = true;
      this._addEditUserAdressRequest.UserAddressDto = this._userAdress;
      this._addEditUserAdressRequest.UserAddressDto.UserID=this.UserId;
      this._commandService.AddEditUserAddress(this._addEditUserAdressRequest).subscribe(res => {

        this.showLoading = false;
        if (res.HasError) {
          this.errMsg = res.ErrorMessage;
          return;
        }
        if (res.Result) {
          this._communicationService.UserEditAddressSaved();
        }
      }, err => {
        this.errMsg = err;
        this.showLoading = false;
      });
    } catch (err) {
      this.errMsg = err;
      this.showLoading = false;

    }
  }

  BackBtn() {
    this._communicationService.UserEditAddressClosed();
  }


  get isPopup() {
    return this._route.routeConfig.outlet && this._route.routeConfig.outlet == "popupoutlet";
  }



}
