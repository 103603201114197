<div class="d-flex flex-column h-100 w-100 p-2">
    <div class="bd-highlight">
        <div class="row">
            <div class="col-6">
                <h4>{{ title }}</h4>
            </div>
        </div>

        <form #AddFrom="ngForm" (ngSubmit)="AddFrom.form.valid && saveOrderConfiguration()" autocomplete="off" class="mb-2">
            <div class="row">
                <div class="col-3">
                    <div class="form-group">
                        <label for="from">{{ "OrderPriceFrom" | translate }}</label>
                        <input type="number" class="form-control rounded" #from="ngModel" name="from" [(ngModel)]="ordersConfigurationsRequest.From" required />
                    </div>
                    <div *ngIf="AddFrom.submitted && from.errors?.required" class="text text-danger pl-2 pr-2">{{ "inputisrequired" | translate }}</div>
                </div>

                <div class="col-3">
                    <div class="form-group">
                        <label for="to">{{ "OrderPriceTo" | translate }}</label>
                        <input type="number" class="form-control rounded" #to="ngModel" name="to" [(ngModel)]="ordersConfigurationsRequest.To" required />
                    </div>
                    <div *ngIf="AddFrom.submitted && to.errors?.required" class="text text-danger pl-2 pr-2">
                        {{ "inputisrequired" | translate }}
                    </div>
                </div>

                <div class="col-3">
                    <div class="form-group">
                        <label for="charges">{{ "Charges" | translate }}</label>
                        <input type="number" class="form-control rounded" #charges="ngModel" name="charges" [(ngModel)]="ordersConfigurationsRequest.Charges" required />
                    </div>
                    <div *ngIf="AddFrom.submitted && charges.errors?.required" class="text text-danger pl-2 pr-2">
                        {{ "inputisrequired" | translate }}
                    </div>
                </div>


                <div class="col-8 d-inline-flex bd-highlight align-items-center">
                    <div class="col-3">
                        <label for="isRetail">{{ "IsRetail"| translate}}</label>
                    </div>
                    <div class="col-2">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="IsRetail" id="Salon" required #IsRetailInput="ngModel" [value]="0" [(ngModel)]="ordersConfigurationsRequest.IsRetail">
                            <label class="form-check-label" for="Salon">{{"Salon" | translate}}</label>
                        </div>
                    </div>
                    <div class="col-2">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="IsRetail" id="Retail" required #IsRetailInput="ngModel" [value]="1" [(ngModel)]="ordersConfigurationsRequest.IsRetail">
                            <label class="form-check-label" for="Retail">{{"Retail" | translate}}</label>
                        </div>
                    </div>
                    <div *ngIf="AddFrom.submitted && IsRetailInput.errors?.required" class="text text-danger px-3">{{ "Retailisrequired" | translate }}</div>
                </div>

            </div>

            <div class="row m-2">
                <button type="submit" class="btn btn-primary"> <span> <i class="fa fa-plus" aria-hidden="true"></i></span> {{ "Add" | translate }}</button>
            </div>
        </form>

        <table class="table table-striped" id="header-fixed">
            <thead>
                <tr>
                    <th scope="col">{{ "From" | translate }}</th>
                    <th scope="col">{{ "To" | translate }} </th>
                    <th scope="col">{{ "Charges" | translate }} </th>
                    <th scope="col">{{ "ChargeType" | translate }} </th>
                    <th scope="col">{{ "Actions" | translate }}<i class="fa fa-bolt" aria-hidden="true"></i></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let orConf of ordersConfigList">
                    <td> {{ orConf.OrderFrom }} </td>
                    <td> {{ orConf.OrderTo }} </td>
                    <td> {{ orConf.Charges }} </td>
                    <td *ngIf="orConf.IsRetail == null"> {{ "BothAppType" | translate}} </td>
                    <td *ngIf="orConf.IsRetail == true"> {{ "Shine" | translate}} </td>
                    <td *ngIf="orConf.IsRetail == false"> {{ "2assar" | translate}} </td>
                    <td> <button class="btn btn-danger" (click)="deleteOrderConfig(orConf.ID)">{{ "Delete" | translate }} </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="row mt-2">
        <div class="col-xs-12 col-12 p-0">
            <app-pagination-component [ItemsCount]="total" (changePage)="onPagingChange($event)"> </app-pagination-component>
        </div>
    </div>
</div>