import { HammerGestureConfig } from "@angular/platform-browser";
import * as hammer from "hammerjs";
import { Injectable } from "@angular/core";

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    swipe: { direction: hammer.DIRECTION_ALL },
    pinch: { enable: false },
    rotate: { enable: false }
  };
}