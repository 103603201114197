import { environment } from './../../../../environments/environment.prod';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { QueryService } from 'src/app/services/query.services';
import { CommandService } from 'src/app/services/command.service';
import { TranslateService } from '@ngx-translate/core';
import { FileService } from 'src/app/services/file.service';
import { Title } from '@angular/platform-browser';
import { configration } from 'src/app/shared/config';
import { ProductCategoryModel } from 'src/app/models/product-category.model';
import { ProductCategoryRequest } from 'src/app/models/ProductCategoryRequest.Model';
import { AddEditProductCategoryRequest } from 'src/app/models/AddEditProductCategoryRequest.model';

@Component({
  selector: 'app-category-add-edit',
  templateUrl: './category-add-edit.component.html',
  styleUrls: ['./category-add-edit.component.scss']
})
export class CategoryAddEditComponent implements OnInit {

  IsArabic = true;
  title: string;
  category: ProductCategoryModel = new ProductCategoryModel();
  _AddEditProductCategoryRequst: AddEditProductCategoryRequest = new AddEditProductCategoryRequest();
  errMsg: string;
  showLoading: boolean;
  imageSource = 'assets/images/default.png';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private queryService: QueryService,
    private commandService: CommandService,
    private translateService: TranslateService,
    private fileService: FileService,
    private titleService: Title
  ) { }

  ngOnInit() {
    this.loadAfterLang(this.translateService.currentLang);
    this.translateService.onLangChange.subscribe(res => this.loadAfterLang(res.lang));
    this.getCategoryById();
  }

  loadAfterLang(lang: string) {
    this.IsArabic = (lang === 'ar');

    this.route.params.subscribe((params: Params) => {
      let id = params['ID'];
      this.title = id == 0 ? this.translateService.instant("AddProductCategory") : this.translateService.instant("EditProductCategory");
      this.titleService.setTitle(this.title);
    });
  }

  getCategoryById() {
    try {
      this.route.params.subscribe((params: Params) => {
        this.category.ID = params['ID'];

        if (!this.category.ID || this.category.ID <= 0) {
          return;
        }

        this.errMsg = "";
        this.showLoading = true;
        let productRequest = new ProductCategoryRequest();
        productRequest.ID = this.category.ID;

        this.queryService.getProductCategory(productRequest).subscribe(res => {
          this.showLoading = false;
          if (res.HasError) {

            this.errMsg = res.ErrorMessage;
            return;
          }
          this.category = res.Result.ItemList[0];
          this.imageSource = this.getImageFullPath();

        }, err => {
          this.imageSource = "";
          this.errMsg = err;
          this.showLoading = false;
        });

      });

    } catch (err) {
      this.errMsg = err;
      this.showLoading = false;

    }
  }


  saveCategory() {
    try {
      this.errMsg = "";
      this.showLoading = true;
      this._AddEditProductCategoryRequst.ProductCategoryDTO = this.category;
      this.commandService.AddEditProductCategory(this._AddEditProductCategoryRequst).subscribe(res => {
        this.showLoading = false;
        if (res.HasError) {
          if (res.ErrorMessage == "NotUniquo") {

            var check = confirm(this.translateService.instant("Namealreadyexists"))
            return;
          }
          this.errMsg = res.ErrorMessage;
          return;
        }

        this.router.navigateByUrl('adminhome/categoryadminlist')

      }, err => {
        this.errMsg = err;
        this.showLoading = false;
      });
    } catch (err) {
      this.errMsg = err;
      this.showLoading = false;

    }

  }


  fileChangeEvent(fileInput: any) {
    this.errMsg = "";
    var validImageExtention = this.fileService.checkFileExtentionForImage(fileInput.target.files);
    if (!validImageExtention) {

      this.errMsg = this.translateService.instant("ImageErrorMessage");
      return;
    }
    var validImagesize = this.fileService.checkFileSize(fileInput.target.files);
    if (!validImagesize) {

      this.errMsg = this.translateService.instant("ImageSizeErrorMessage");
      return;
    }
    this.fileService.uploadFiles(fileInput.target.files).subscribe(res => {
      this.showLoading = false;
      if (res.HasError) {
        this.errMsg = res.ErrorMessage;
        return;
      }

      this.category.Image = res.Result[0].RelativePath;
      this.imageSource = `${environment.webApiEndPoint}Temp/${this.category.Image}`
      document.getElementById("imgup").style.display = 'inline';
    });
  }

  getImageFullPath = (): string => this.category.ImageExist ?
    `${environment.webApiEndPoint}ProductCategory/${this.category.Image}?date=${this.category.LastModifiedTime}` :
    'assets/images/default.png';


}
