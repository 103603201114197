export class UserInfoDto {

       UserId:string;
       Name :string;
       UserName :string;
       PhoneNumber :string;
       VoucherId :number|null;


}
