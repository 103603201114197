export class userLogin {

    UserName: string;
    Password: string;

}

export class ForgetPassword{
  Email : string;
}

