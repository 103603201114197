import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { QueryService } from "src/app/services/query.services";
import { SalesPersonRequest } from "src/app/models/SalesPersonRequest.Model";
import { SalesPersonDto } from "src/app/models/SalesPersonDto.Model";
import { UserCategoryEnum } from "src/app/models/enum/usercategory.enum";
import { RegisterBindingModel } from "src/app/models/register.model";
import { TranslateService } from "@ngx-translate/core";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { LookupsModel } from "src/app/models/Lookups.model";
import { Title } from "@angular/platform-browser";
import { LoginAdminService } from "src/app/services/login-admin.service";

@Component({
  selector: "app-addeditAdmin-User",
  templateUrl: "./addedit-Admin-User.component.html",
  styleUrls: ["./addedit-Admin-User.component.scss"],
})
export class AddeditAdminUserComponent implements OnInit {
  _userId: string;
  errMsg: string;
  errors: string[] = [];
  showLoading: boolean ;
  showLoadingPerson: boolean ;
  showLoadingCategory: boolean ;
  userRegister: RegisterBindingModel = new RegisterBindingModel();
  UserCategoryList: LookupsModel[] = [];
  request: SalesPersonRequest = new SalesPersonRequest();
  public salesPerson: SalesPersonDto = new SalesPersonDto();
  IsArabic: boolean = true;
  title: string;
  editMode: boolean = true;
  constructor(
    private _route: ActivatedRoute,
    private _adminLogin: LoginAdminService,
    private _translate: TranslateService,
    private _queryService: QueryService,
    private router: Router,
    private titleService: Title
  ) { }

  ngOnInit() {

    if (this.router.url.includes("/0")) {
      this.editMode = false;
    }

    this.loadAfterLang(this._translate.currentLang);
    this._translate.onLangChange.subscribe((res) => {
      this.loadAfterLang(res.lang);
    });

    this.Init();
  }

  Init() {
    this._route.params.subscribe((params: Params) => {
      this._userId = params["ID"];
    });

    this.getUserCategories();
    // this.UserCategoryList.push({
    //   ID: UserCategoryEnum.Admin,
    //   NameAR: "مدير",
    //   NameEN: "Admin",
    //   checked: false,
    // });
    // this.UserCategoryList.push({
    //   ID: UserCategoryEnum.DataEntry,
    //   NameAR: "مدخل بيانات",
    //   NameEN: "Data Entry",
    //   checked: false,
    // });
    // this.UserCategoryList.push({
    //   ID: UserCategoryEnum.Sales,
    //   NameAR: "مبيعات",
    //   NameEN: "Sales",
    //   checked: false,
    // });
    this.salesPerson.UserCategoryId = null;
    this.getUserId();
  }

  loadAfterLang(lang: string) {

    this.IsArabic = lang === "ar";

    this._route.params.subscribe((params: Params) => {
      let id = params["ID"];
      if (id == 0) {
        this.title = this._translate.instant("AddUser");
      } else {
        this.title = this._translate.instant("EditUser");
      }

      this.titleService.setTitle(this.title);
    });
  }

  getUserId() {
    if (this._userId == "0") {
      this.salesPerson.Id = "0";
      return;
    }
    try {
      this.errMsg = "";
      this.showLoading = true;
      this.request.UserId = this._userId;
      this._queryService.getSalesPersonById(this.request).subscribe(
        (res) => {
          this.showLoading = false;
          if (res.HasError) {
            this.errMsg = res.ErrorMessage;
            return;
          }
          this.salesPerson = res.Result;
        },
        (err) => {
          this.errMsg = err;
          this.showLoading = false;
        }
      );
    } catch (error) {
      this.errMsg = error;
      this.showLoading = false;
    }
  }

  getUserCategories() {
    try {
      this.errMsg = "";
      this.showLoadingCategory = true;

      this._queryService.getAllUserCategoriesList().subscribe(
        (res) => {
          this.showLoadingCategory = false;
          if (res.HasError) {
            this.errMsg = res.ErrorMessage;
            return;
          }
          res.Result.forEach(x => {
            this.UserCategoryList.push({
              ID: x.ID,
              NameAR: x.NameAR,
              NameEN: x.NameEN,
              checked: false,
            });
          });
        },
        (err) => {
          this.errMsg = err;
          this.showLoadingCategory = false;
        }
      );
    } catch (error) {
      this.errMsg = error;
      this.showLoadingCategory = false;
    }
  }

  SavesalesPerson() {

    this.showLoadingPerson = true;
    try {
      this.userRegister = this.converToUserRegisterModel(this.salesPerson);

      this._adminLogin.registerForm(this.userRegister).subscribe(
        (res) => {
          this.showLoadingPerson = false;
          this.router.navigateByUrl("adminhome/adminuserlist");
        },
        (error) => {
          this.errors = [];
          let i = 0;

          for (let key in error.error.ModelState) {
            let value: string = error.error.ModelState[key];
            if (key != "") {
              // Use `key` and `value`
              let errorText: string = this._translate.instant(value[i]);

              if (errorText.indexOf("is already taken") > 0) {
                errorText = this._translate.instant("IsTaken");
              }
              this.errors.push(errorText);

            }
            //Name 01551133781 is already taken.
            if (value[i] === "Name " + this.userRegister.PhoneNumber + " is already taken.") {
              let errTex = this._translate.instant("PhoneNumberIsTaken");
              this.errors.push(errTex);
            }

            i++;
          }

          this.showLoadingPerson = false;
        }
      );
    } catch (err) {
      this.errors[0] = err;
      this.showLoadingPerson = false;
    }
  }
  converToUserRegisterModel(salesPerson: SalesPersonDto) {
    if (this._userId == "0") {
      this.userRegister.UserId = null;
    } else {
      this.userRegister.UserId = this.salesPerson.Id;
    }

    this.userRegister.Email = salesPerson.Email;
    this.userRegister.Password = salesPerson.Password;
    this.userRegister.ConfirmPassword = salesPerson.ConfirmPassword;
    this.userRegister.UserCategoryId = salesPerson.UserCategoryId;
    this.userRegister.PhoneNumber = salesPerson.PhoneNumber;
    this.userRegister.UserName = salesPerson.PhoneNumber;
    this.userRegister.Name = salesPerson.Name;
    this.userRegister.IsActive = salesPerson.IsActive;
    this.userRegister.Notes = salesPerson.Notes;
    this.userRegister.isAdmin = true;
    return this.userRegister;
  }
}
