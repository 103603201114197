import { LookupsModel } from "./Lookups.model";
import { PatchDto } from "./PatchDto.Model";

export class ProductModel {
  ImageExist: boolean;
  ID: number;
  NameEN: string;
  BrandName: string;
  NameAR: string;
  QuantitySum: number;
  BookedSum: number;
  BalanceSum: number;
  Balance: number;
  PurchasingPrice: number;
  DescriptionEN: string;
  DescriptionAR: string;
  PatchObj: PatchDto;
  Image: string;
  ImageFile: [];
  AddedToCart: boolean;
  AddedToFav: boolean;
  IsAvailable: boolean;
  Supplier: LookupsModel = new LookupsModel();
  Category: LookupsModel = new LookupsModel();
  Category1: LookupsModel = new LookupsModel();
  Category2: LookupsModel = new LookupsModel();
  Category3: LookupsModel = new LookupsModel();
  Brand: LookupsModel = new LookupsModel();
  Type: LookupsModel = new LookupsModel();
  Color: LookupsModel = new LookupsModel();
  LastModifiedTime: Date;
  //patches
  Expanded: boolean;
  PatchLst: PatchDto[] = [];
  PatchPageIndex: number = 1;
  PatchCount: number;
  PatchPrice: number;
  PatchExpireDateSearch: string;
  checked: boolean = false;
  LocationTackingNumber: string;
  InventoryShelfNumber: string;

  ProductCategoryIDs: number[];

  ProductCategory1: number;
  ProductCategory2: number;
  ProductCategory3: number;

  ProductWeights: number;
  ProductLength: number;
  ProductWidth: number;
  ProductOriginCountry: string;

  ProductImages: string[];

  CreatedTime: Date;

  FullProductDescription: string;
  ProductOrder:number;
  ActualStock:number;
  IsRetail?:number;
}
