<div class="d-flex flex-column h-100">
    <!--  start navbar section   -->
    <!-- end navbar section  -->
    <!-- Products -->
    <div id="productsDiv" (scroll)="onProductsScroll($event)" class="d-flex flex-column vh-100 overflow-auto bd-highlight productList pb-5">
        <!-- start this to make loading center in page on any screen size -->
        <div *ngIf="showLoading" class="d-flex flex-column z-index-3 position-fixed w-100 h-100 justify-content-center align-items-center bg-white" style="opacity: 0.5">
            <div class="p-2 align-self-center">
                <span class="load-more-List text-center vw-100 vh-100">
          <span class="spinner-border text-primary" role="status"> </span>
                </span>
            </div>
        </div>

        <!--  start no data to show -->
        <div *ngIf="productList?.length == 0" class="p-0 w-100 bd-highlight d-flex flex-column text-center">

            <span class="text-info ">{{'NoProducts' | translate}}</span>
            <!-- <img class=" h-100 mx-auto" src="assets/images/emptycart.png"> -->
            <!-- <img class="img-fluid mx-auto" src="assets/images/emptycart.jpg" /> -->
            <!-- <div class="text-center">
        <button
          class="btn btn-primary border-0 w-75 forRout"
          type="button"
          (click)="GoToHome()"
        >
          {{ "goToShoppingBtn" | translate }}
        </button>
      </div> -->
        </div>
        <!--  end no data to show -->
        <!-- product list section -->
        <div *ngIf="productList?.length > 0" class="p-1 bd-highlight">
            <!--  start fillter section for products -->
            <!-- <div class="d-flex bd-highlight"> -->
            <!-- <div class="p-1 flex-grow-1 bd-highlight"> -->
            <!-- <span class="fa fa-exchange gray-2  fa-rotate-90   border-top border-dark mr-1 ml-1  pt-2 pb-2 fa-lg gray-1 d-inline-block"></span> -->
            <!-- </div> -->
            <!-- </div> -->
            <!--  End fillter section for products -->
            <!-- start list for products -->
            <div class="d-flex bd-highlight justify-content-center">
                <div class="row w-100">
                    <div *ngFor="let pd of productList" class="col-sm-12 col-xs-12 col-md-6 col-lg-6 p-1 mb-2">
                        <!-- <app-product [product]="pd" [UserId]="UserId"></app-product> -->
                        <app-product [patchId]="pd.PatchID" [orderId]="orderId" [UserId]="UserId"></app-product>
                    </div>
                </div>
            </div>
            <!-- end list for products -->

        </div>
    </div>
</div>
<!-- ****************************************************** -->