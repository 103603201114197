export class AspNetUser{
  UserId: string;
  FullName: string;
  PhoneNumber: string;
  UserName: string;

  SalesName: string;
  SalesNumber: string;

  IsRetail?:number;
}
