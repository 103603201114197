<div class="d-flex flex-column h-100 w-100 p-2">
    <div class="bd-highlight">
        <div class="row">
            <div class="col-2">
                <h4>{{ title }}</h4>
            </div>
            <div class="col-2">
                <a class="btn bg-red text-white" data-toggle="collapse" href="#clientSummary" role="button" aria-expanded="false" aria-controls="clientSummary">{{ "ClientOrdersSummary" | translate }}</a>
            </div>



            <div class="row col-8 row justify-content-end">
                <div class="col-2">
                    <button (click)="InquiryOrderList()" class="btn bg-red text-white" type="button"> {{ "Search" |
                        translate }}</button>
                </div>
                <div class="col-3">
                    <a class="btn bg-red text-white" data-toggle="collapse" href="#advancedFilter" role="button" aria-expanded="false" aria-controls="advancedFilter">{{ "Filter" | translate }}</a>
                </div>
                <div class="col-3">
                    <button (click)="onexportAsXLSX()" class="btn bg-info text-white" type="button">{{ "excel" | translate }}</button>
                </div>
                <div class="col-3">
                    <button (click)="onexportAsXLSXMylerz()" class="btn bg-info text-white" type="button">{{ "Mylerz" |translate }}</button>
                </div>
            </div>

        </div>
        <div class="row">
            <div class="bd-highlight collapse multi-collapse mb-2" id="clientSummary">
                <div class="card card-body">
                    <div>
                        <span *ngIf="_userId != '0'">{{ UserName }} :{{ mobileUser.Name }}</span>
                        <br />
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">{{ "orderstatus" | translate }}</th>
                                    <th scope="col">{{ "clientOrdersCount" | translate }}</th>
                                    <th scope="col">{{ "clientOrderTotalPrice" | translate }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let summary of ordersSummary">
                                    <td>{{ "Status_" + summary.OrderStatus | translate }}</td>
                                    <td>{{ summary.OrdersCount }}</td>
                                    <td>{{ summary.OrdersTotalPrice }}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr style="background-color: #ecec00;">
                                    <td>{{ "Total" | translate }}</td>
                                    <td>{{ totalOrdersCount }}</td>
                                    <td>{{totalOrdersTotalPrice | currency:'':''}} {{ "le" | translate }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>



    </div>


    <div *ngIf="errMsg" class="text-danger mr-auto p-2 bd-highlight"> {{ errMsg | translate }}</div>

    <div class=" custom-date-range-links mb-3 mt-3">
        <a (click)="searchOrderByCustomeDate(customDatesEnum.Today)">{{ "Today" | translate }}</a>
        <a (click)="searchOrderByCustomeDate(customDatesEnum.Yesterday)">{{ "Yesterday" | translate }}</a>
        <a (click)="searchOrderByCustomeDate(customDatesEnum.CurrentWeek)">{{ "Week" | translate }}</a>
        <a (click)="searchOrderByCustomeDate(customDatesEnum.LastWeek)">{{ "LastWeek" | translate }}</a>
        <a (click)="searchOrderByCustomeDate(customDatesEnum.CurrentMonth)">{{ "Month" | translate }}</a>
        <a (click)="searchOrderByCustomeDate(customDatesEnum.LastMonth)">{{ "LastMonth" | translate }}</a>
    </div>

    <div class="row mb-2">
        <!--Form-->
        <div class="col-1">{{ "From" | translate }}</div>
        <div class="col-2">
            <div class="btn-group mb-1" role="group" aria-label="Basic example">
                <button class="btn btn-primary m-1" (click)="incrementOrderDayFrom()"><i class="fa fa-plus-square-o"
                        aria-hidden="true"></i></button>
                <button class="btn btn-danger m-1" (click)="decrementOrderDayFrom()"><i class="fa fa-minus-square-o"
                        aria-hidden="true"></i></button>
            </div>
        </div>
        <div class="col-3">
            <input #dateFrom class="form-control rounded" type="text" placeholder="{{ 'From' | translate }}" [(ngModel)]="from" bsDatepicker [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MM-YYYY' }" />
        </div>
        <div class="col-1"> {{ "To" | translate }}</div>
        <div class="btn-group mb-1" role="group" aria-label="Basic example">
            <button class="btn btn-primary m-1" (click)="incrementOrderDayTo()"><i class="fa fa-plus-square-o"
                    aria-hidden="true"></i></button>
            <button class="btn btn-danger m-1" (click)="decrementOrderDayTo()"><i class="fa fa-minus-square-o"
                    aria-hidden="true"></i></button>
        </div>
        <!--To-->
        <div class="col-3">
            <input #dateTo class="form-control rounded" type="text" placeholder="{{ 'To' | translate }}" [(ngModel)]="to" bsDatepicker [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MM-YYYY' }" />
        </div>
    </div>

    <div class="bd-highlight collapse multi-collapse" id="advancedFilter">
        <div class="card card-body">
            <div class="row mb-2">
                <div class="col-2">{{ "OrderStatus" | translate }} </div>
                <div class="col-4">
                    <kendo-multiselect class="w-100 form-control rounded" name="categoryDDL" [data]="OrderStatusList" [textField]="IsArabic ? 'NameAR' : 'NameEN'" [valueField]="'ID'" placeholder="{{ 'SelectOrderStatus' | translate }}" [valuePrimitive]="true" [(ngModel)]="orderRequest.StatusIDs"
                        #categoryDDL="ngModel">
                    </kendo-multiselect>
                </div>
                <div class="col-2">{{ "voucher" | translate }}</div>
                <div class="col-4"> <input class="form-control rounded" [(ngModel)]="orderRequest.VoucherId" /></div>
            </div>

            <div class="row mb-2">
                <div class="col-2">{{ "SalesPerson" | translate }} </div>

                <div class="col-4">
                    <kendo-combobox class="w-100 form-control rounded" name="salesPersonDDL" [data]="salesPersonLst" [valuePrimitive]="true" (filterChange)="getUser($event)" [filterable]="true" [loading]="SalesPersonDDLLoading" [valueField]="'Id'" [textField]="'Name'" [(ngModel)]="orderRequest.SalesPersonId"
                        [filterable]="true" #salesPersonDDL="ngModel" placeholder="{{ 'SalesPerson' | translate }}">
                        <ng-template kendoComboBoxNoDataTemplate>
                            <h4>
                                <span class="k-icon k-i-warning"></span><br /><br /> {{ "NotAvailable" | translate }}
                            </h4>
                        </ng-template>
                    </kendo-combobox>
                </div>

                <div class="col-2">
                    {{ "orderno" | translate }}
                </div>
                <div class="col-4 d-flex justify-content-end">
                    <input class="form-control rounded" [(ngModel)]="orderRequest.OrderId" />
                </div>
            </div>

            <!--Form-->
            <div class="row mb-2">
                <div class="col-2">
                    {{ "orderDeliverydate" | translate }} {{ "From" | translate }}
                </div>
                <div class="col-4">
                    <input class="form-control rounded" type="text" placeholder="{{ 'From' | translate }}" [(ngModel)]="deliveryDateFrom" bsDatepicker [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MM-YYYY' }" />
                </div>

                <div class="col-2">
                    {{ "orderDeliverydate" | translate }} {{ "To" | translate }}
                </div>
                <!--To-->
                <div class="col-4">
                    <input class="form-control rounded" type="text" placeholder="{{ 'To' | translate }}" [(ngModel)]="deliveryDateTo" bsDatepicker [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MM-YYYY' }" />
                </div>
            </div>

            <div class="row mb-2">
                <div class="col-2">{{ "ClientCode" | translate }}</div>
                <div class="col-4 d-flex justify-content-end">
                    <input class="form-control rounded" [(ngModel)]="orderRequest.ClientCode" />
                </div>
                <div class="col-2">
                    {{ "ClientPhoneNumber" | translate }}
                </div>
                <div class="col-4 d-flex justify-content-end">
                    <input class="form-control rounded" [(ngModel)]="orderRequest.ClientNumber" />
                </div>

            </div>

            <div class="row">
                <div class="col-2">{{ "ClientName" | translate }} </div>
                <div class="col-4 d-flex justify-content-end">
                    <input class="form-control rounded" [(ngModel)]="orderRequest.ClientName" />
                </div>

                <div class="col-2">
                    <label for="isRetail">{{ "IsRetail"| translate}}</label>
                </div>
                <div class="col-1">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" #IsRetailInput (ngModelChange)="onChanges($event)" name="isRetail" id="Salon" value="false" [(ngModel)]="orderRequest.IsRetail" checked>
                        <label class="form-check-label" for="salon">{{"2assar" | translate}}</label>
                    </div>
                </div>
                <div class="col-1">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" #IsRetailInput (ngModelChange)="onChanges($event)" name="isRetail" id="Retail" value="true" [(ngModel)]="orderRequest.IsRetail">
                        <label class="form-check-label" for="retail">{{"Shine" | translate}}</label>
                    </div>
                </div>

                <div class="col-1">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" #IsRetailInput (ngModelChange)="onChanges($event)" name="isRetail" id="Retail" value="null" [(ngModel)]="orderRequest.IsRetail">
                        <label class="form-check-label" for="retail">{{"BothAppType" | translate}}</label>
                    </div>
                </div>


            </div>
        </div>
    </div>

    <!-- end header -->
    <div id="btnContainer pb-1">
        <button [class]="showOrdersGridView ? 'btnContainer' : 'btnContainer' + ' active'" (click)="showOrdersGridView = false">
            <i class="fa fa-bars"></i>
        </button>

        <button [class]="showOrdersGridView ? 'btnContainer' + ' active' : 'btnContainer'" (click)="showOrdersGridView = true">
            <i class="fa fa-th-large"></i>
        </button>
    </div>
    <!-- *********************************************************** -->

    <!-- start this to make loading center in page on any screen size -->
    <div *ngIf="showLoading" class=" d-flex flex-column z-index-3 justify-content-center align-items-center bg-white " style="opacity: 0.5">
        <div class="p-2 align-self-center">
            <span class="load-more-List text-center vw-100 vh-100">
                <span class="spinner-border text-primary" role="status"> </span>
            </span>
        </div>
    </div>
    <!-- end this to make loading center in page on any screen size -->
    <div class="d-flex flex-column mt-2 bg-light-gray h-100 overflow-auto" *ngIf="!showLoading">
        <!-- ********************************************************* -->

        <div class="row">
            <div class="col-12 p-0">
                <div id="orders_grid" *ngIf="showOrdersGridView">
                    <div class="table-responsive-sm">
                        <table class="table table-sm table-striped" id="header-fixed">
                            <thead>
                                <tr>
                                    <th scope="col">{{ "ID" | translate }}
                                        <i (click)="onSortClick($event, 'OrderID')" class="my-icon fa fa-chevron-down"></i>
                                    </th>
                                    <th scope="col">{{ "OrderDate" | translate }}
                                        <i (click)="onSortClick($event, 'OrderDate')" class="my-icon fa fa-chevron-up"></i>
                                    </th>
                                    <th scope="col">{{ "orderstatus" | translate }}
                                        <i (click)="onSortClick($event, 'OrderStatus')" class="my-icon fa fa-chevron-up"></i>
                                    </th>
                                    <th scope="col">{{ "ClientName2" | translate }}
                                        <i (click)="onSortClick($event, 'ClientName')" class="my-icon fa fa-chevron-up"></i>
                                    </th>
                                    <th scope="col">{{ "ClientPhoneNumber2" | translate }}
                                        <i (click)="onSortClick($event, 'ClientPhoneNumber')" class="my-icon fa fa-chevron-up"></i>
                                    </th>
                                    <th scope="col">{{ "SalesName2" | translate }}
                                        <i (click)="onSortClick($event, 'SalesName')" class="my-icon fa fa-chevron-up"></i>
                                    </th>

                                    <th scope="col">{{ "OrderQuantity" | translate }}
                                        <i (click)="onSortClick($event, 'OrderQuantity')" class="my-icon fa fa-chevron-up"></i>
                                    </th>
                                    <th scope="col">{{ "DeliveryFees" | translate }}
                                        <i (click)="onSortClick($event, 'Charges')" class="my-icon fa fa-chevron-up"></i>
                                    </th>
                                    <th scope="col">{{ "OrderTotal" | translate }}
                                        <i (click)="onSortClick($event, 'OrderTotal')" class="my-icon fa fa-chevron-up"></i>
                                    </th>

                                    <th scope="col">{{ "IsRetail" | translate }}
                                        <i (click)="onSortClick($event, 'IsRetail')" class="my-icon fa fa-chevron-up"></i>
                                    </th>

                                    <th scope="col">{{ "City" | translate }}
                                        <i (click)="onSortClick($event, 'City')" class="my-icon fa fa-chevron-up"></i>
                                    </th>

                                    <th scope="col">{{ "Zone" | translate }}
                                        <i (click)="onSortClick($event, 'Zone')" class="my-icon fa fa-chevron-up"></i>
                                    </th>

                                    <th scope="col">{{ "Actions" | translate }}
                                        <i class="fa fa-bolt" aria-hidden="true"></i>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let order of OrderList">
                                    <td>
                                        <a routerLink="/adminhome/order-details/{{ order.ID }}" target="_blank" style="color: blue">{{order.ID }}</a>
                                    </td>
                                    <td>
                                        {{ order.OrderDate | date: "dd-MM-yyyy h:mm a" }}
                                    </td>
                                    <td>
                                        <span *ngIf="order.OrderStatus != sOrderStatusEnum.Cancelled" class="fa fa-circle text-success">
                                            {{ "Status_" + order.OrderStatus | translate }}</span>
                                        <span *ngIf="order.OrderStatus === sOrderStatusEnum.Cancelled" class="fa fa-ban text-danger">
                                            {{ "Status_" + order.OrderStatus | translate }}</span>
                                    </td>
                                    <td>
                                        <a style="color: blue" href="/adminhome/client-details/{{order.AspNetUser.UserId}}" target="_blank">
                                            {{ order.AspNetUser.FullName }}
                                        </a>
                                    </td>
                                    <td>{{ order.AspNetUser.PhoneNumber }}</td>
                                    <td>{{ order.AspNetUser.SalesName }}</td>

                                    <td>
                                        {{ sumOrderItemQuantity(order.OrderItems) }}
                                    </td>

                                    <td>{{ order.DeliveryFees }} {{ "le" | translate }}</td>

                                    <td>{{ order.TotalAfterDiscount }} {{ "le" | translate }}</td>

                                    <td>
                                        <span *ngIf="order.AspNetUser?.IsRetail ==0 ">{{ "2assar" | translate}}</span>
                                        <span *ngIf="order.AspNetUser?.IsRetail ==1 ">{{ "shine" | translate}}</span>
                                    </td>

                                    <td>
                                        <span *ngIf="IsArabic">{{ order.City.NameAR }}</span>
                                        <span *ngIf="!IsArabic">{{ order.City.NameEN }}</span>
                                    </td>
                                    <td>
                                        <span *ngIf="IsArabic">{{ order.Zone.NameAR }}</span>
                                        <span *ngIf="!IsArabic">{{ order.Zone.NameEN }}</span>
                                    </td>

                                    <td>
                                        <div class="dropdown" *ngIf="order.OrderStatus == sOrderStatusEnum.Open || order.OrderStatus == sOrderStatusEnum.Saved || order.OrderStatus == sOrderStatusEnum.OnTheWay">
                                            <a class="btn btn-primary" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i class="fa fa-bars" aria-hidden="true" style="color: #f9f9f9 !important"></i></a>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <a style="margin: 10px" class="btn btn-primary" *ngIf="order.OrderStatus > sOrderStatusEnum.Open" (click)="setOrderStatusPrevious(order)">{{getPreviousOrderStatus(order)
                                                    | translate}}</a>
                                                <a style="margin: 10px" *ngIf="order.OrderStatus == sOrderStatusEnum.Saved && order.OrderStatus != sOrderStatusEnum.OnTheWay" class="btn btn-info" (click)="EditOrder(order, sOrderStatusEnum.OnTheWay)">{{
                                                    "EditOrder"|translate }}</a>
                                                <a style="margin: 10px" class="btn btn-primary" *ngIf="order.OrderStatus != 6" (click)="setOrderStatusNext(order)">{{getNextOrderStatus(order) |
                                                    translate}}</a>
                                                <!-- <a style="margin: 10px" *ngIf="order.OrderStatus == sOrderStatusEnum.OnTheWay" class="btn btn-primary bg-info" (click)="setOrderStatusEnd(order)">{{ "EndBtn" | translate }}</a> -->
                                                <a style="margin: 10px" class="btn btn-primary bg-red m-2" *ngIf=" order.OrderStatus == sOrderStatusEnum.Open || order.OrderStatus == sOrderStatusEnum.Saved ||order.OrderStatus == sOrderStatusEnum.OnTheWay" (click)="CancelOrder(order)">{{ "Cancel" | translate }}</a>
                                            </div>
                                        </div>

                                        <!-- <div class="btn btn-success" (click)="renderOrderDetails(order)">
                      Export
                    </div> -->
                                    </td>
                                    <!-- <td *ngIf="exportpdf" style="display: none">

                  </td> -->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div id="orders_card" *ngIf="!showOrdersGridView">
                    <div class=" p-1 border justify-content-center rounded bd-highlight m-1 p-2 bg-white " *ngFor="let o of OrderList">
                        <div (click)="o.Expanded = !o.Expanded" class="d-flex p-1 w-100 justify-content-between">
                            <div class="p-1 bd-highlight">
                                <span class="fa fa-circle text-success">
                                    {{ "Status_" + o.OrderStatus | translate }}</span>
                            </div>
                            <div class="p-1 bd-highlight"></div>
                            <div class="p-2 bd-highlight">
                                <span [class]="
                    (o.Expanded? '   fa fa-chevron-up': '   fa fa-chevron-down ') + ' text-primary  '"></span>
                            </div>
                        </div>
                        <div class="d-flex p-1 justify-content-between text-center" (click)="o.Expanded = !o.Expanded">
                            <div class="p-1 bd-highlight">
                                <ul class="list-group">
                                    <li class="list-group-item">
                                        {{ "orderno" | translate }}:<b class="text-danger">
                                            {{ o.ID }}
                                        </b>
                                    </li>
                                    <li class="list-group-item">
                                        {{ "Address" | translate }}:<b class="text-danger">
                                            {{ getOrderCityZoneNames(o.Address) }}
                                        </b>
                                    </li>
                                    <li class="list-group-item">
                                        {{ "orderDeliverydate" | translate }} :

                                        <b class="text-danger">
                                            {{ o.DeliveryDate | date: "dd-MM-yyyy" }}
                                        </b>
                                    </li>
                                    <li *ngIf="o.AddressLocation != null" class="list-group-item">
                                        <a [href]="o.AddressLocation" target="_blank" style="color: red">
                                            {{ "Location" | translate }}
                                        </a>
                                    </li>

                                    <li class="list-group-item">
                                        {{ "SalesName" | translate }}:<b class="text-danger">
                                            {{ o.AspNetUser.SalesName }}
                                        </b>
                                    </li>
                                    <li class="list-group-item">
                                        {{ "SalesNumber" | translate }}:<b class="text-danger">
                                            {{ o.AspNetUser.SalesNumber }}
                                        </b>
                                    </li>
                                </ul>
                            </div>
                            <div class="p-1 bd-highlight">


                                <div *ngIf="o.OrderStatus == sOrderStatusEnum.Open || o.OrderStatus == sOrderStatusEnum.Saved || o.OrderStatus == sOrderStatusEnum.OnTheWay">

                                    <button class="btn btn-info m-2" *ngIf="o.OrderStatus > sOrderStatusEnum.Open" (click)="setOrderStatusPrevious(o)">{{ getPreviousOrderStatus(o)
                                        |translate}}</button>

                                    <a class="btn btn-warning m-2" *ngIf="o.OrderStatus == sOrderStatusEnum.Saved && o.OrderStatus != sOrderStatusEnum.OnTheWay" class="btn btn-info" [routerLink]="['../../addneworder', o.ID]">{{
                                        "EditOrder"|translate }}</a>

                                    <button class="btn btn-primary m-2" *ngIf="o.OrderStatus != 6" (click)="setOrderStatusNext(o)">{{ getNextOrderStatus(o) | translate }}</button>

                                    <!-- <button class="btn btn-secondary m-2" *ngIf="o.OrderStatus == sOrderStatusEnum.OnTheWay" class="btn btn-primary bg-info" (click)="setOrderStatusEnd(o)">{{ "EndBtn" | translate }}</button> -->

                                    <button class="btn btn-danger m-2" *ngIf=" o.OrderStatus == sOrderStatusEnum.Open || o.OrderStatus == sOrderStatusEnum.Saved ||o.OrderStatus == sOrderStatusEnum.OnTheWay" (click)="CancelOrder(o)">{{ "Cancel" | translate }}</button>

                                    <br /> {{ "orderdate" | translate }}
                                </div>
                                <div>{{ o.OrderDate | date: "dd-MM-yyyy h:mm a" }}</div>
                            </div>

                            <div class="p-1 bd-highlight">
                                <ul class="list-group">
                                    <li class="list-group-item">
                                        {{ "CustomerName" | translate }} :
                                        <a href="/adminhome/client-details/{{ o.AspNetUser.UserId }}" target="_blank">
                                            <b class="text-primary"> {{ o.AspNetUser.FullName }} </b>
                                        </a>
                                    </li>
                                    <li class="list-group-item">
                                        {{ "phonenumber" | translate }} :
                                        <b class="text-danger"> {{ o.AspNetUser.PhoneNumber }} </b>
                                    </li>

                                    <li class="list-group-item">
                                        {{ "Quantity" | translate }} : <b class="text-danger">{{
                                            sumOrderItemQuantity(o.OrderItems) }}</b>
                                    </li>
                                    <li class="list-group-item">
                                        {{ "DeliveryFees" | translate }} :
                                        <b class="text-danger"> {{ o.DeliveryFees }} {{ "le" | translate }} </b>
                                    </li>
                                    <li class="list-group-item" *ngIf="o.DiscountAmount > 0">
                                        {{ "Discount" | translate }} :
                                        <b class="text-danger"> {{ o.DiscountAmount }} {{ "le" | translate }} </b>
                                    </li>
                                    <li class="list-group-item">
                                        {{ "Total" | translate }} : <b class="text-danger">{{ o.NetAmount }} {{ "le" |
                                            translate }}</b>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="row p-1 justify-content-center" *ngIf="o.Expanded">
                            <div *ngFor="let oi of o.OrderItems" class="col-sm-12 col-xs-12 col-md-4 col-lg-4 p-0">
                                <div class=" row border-top rounded-0 bg-white p-1 mb-1 position-relative h-100">
                                    <div class="col-5 d-flex justify-content-center p-0">
                                        <img class="img-thumbnail align-self-center border-0 img-fluid" src="{{getImageFullPath(oi.Product.ImageExist,oi.Product.Image,oi.Product.LastModifiedTime)}}" />
                                    </div>
                                    <div class="col-7">
                                        <span class="d-block gray-1 font-weight-bold" *ngIf="IsArabic">
                                            {{ oi.Product?.NameAR }}
                                        </span>
                                        <span class="d-block gray-1 font-weight-bold" *ngIf="!IsArabic">
                                            {{ oi.Product?.NameEN }}
                                        </span>
                                        <span class="d-block gray-1" *ngIf="IsArabic">
                                            {{ oi.Product.Brand?.NameAR }}
                                        </span>
                                        <span class="d-block gray-1" *ngIf="!IsArabic">
                                            {{ oi.Product.Brand?.NameEN }}
                                        </span>
                                        <span class="d-block gray-1" *ngIf="IsArabic">
                                            {{ oi.Product.Type?.NameAR }}
                                        </span>
                                        <span class="d-block gray-1" *ngIf="!IsArabic">
                                            {{ oi.Product.Type?.NameEN }}
                                        </span>
                                        <span class="d-block gray-1 mb-1" *ngIf="IsArabic">
                                            {{ oi.Product.Color?.NameAR }}
                                        </span>
                                        <span class="d-block gray-1 mb-1" *ngIf="!IsArabic">
                                            {{ oi.Product.Color?.NameEN }}
                                        </span>
                                        <span class="d-block gray-1 mb-1">
                                            <b> {{ o.OrderStatus === sOrderStatusEnum.Open ? oi.Product.Price : oi.Price
                                                }}
                                            </b>
                                            {{ "le" | translate }}
                                        </span>
                                        <span class="d-inline-block text-danger">
                                            {{ oi.Quantity }}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 text-center" *ngIf="o.OrderItems.length == 0">
                                {{ "NoProducts" | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="OrderList?.length == 0" class=" d-flex flex-column z-index-3  w-100 h-100 justify-content-center align-items-center bg-white ">
            <label class="la">{{ "NoDataFound" | translate }}</label>
        </div>

    </div>
    <div class="row mt-2">
        <div class="col-xs-12 col-12 p-1">
            <app-pagination-component [ItemsCount]="Total" (changePage)="onPagingChange($event)">
            </app-pagination-component>
        </div>
    </div>
    <!-- ***************************************************************** -->

    <!-- <app-generate-order-details style="visibility: hidden;" *ngIf="exportpdf"
  [orderDetails]="orderToExportPDF"
  (orderImage)="export($event)"
  ></app-generate-order-details> -->
</div>

<!-- ************************************************************************* -->



<div bsModal #ProductAvailabilityModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name" style="background: white; height: 80%;">

    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{ "ProductsNotAvailable" | translate }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeProductAvailabilityModal()">
            <span aria-hidden="true" style="color: red;">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container">
            <div class="row" *ngFor="let prod of _productAvilabilityList">
                <div *ngIf="IsArabic">
                    -{{"ProductPatch" | translate}} {{ prod.ProductPatch.NameAR }} :
                    <span *ngIf="prod.ProductPatch.Balance>0"> {{"AvailableOnly" | translate}}
                        ({{prod.ProductPatch.Balance}})</span>
                    <span *ngIf="prod.ProductPatch.Balance <= 0">{{"NotAvailable" | translate}}</span>
                </div>
                <div *ngIf="!IsArabic">
                    - {{"ProductPatch" | translate}} {{ prod.ProductPatch.NameEN }} : <span *ngIf="prod.ProductPatch.Balance>0">{{"AvailableOnly" | translate}}
                        ({{prod.ProductPatch.Balance}})
                    </span>
                    <span *ngIf="prod.ProductPatch.Balance <= 0"> {{"NotAvailable" | translate}}</span>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark">{{"Save"| translate}}</button>
      </div> -->
</div>