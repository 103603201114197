import { Router } from '@angular/router';
import { CommunicationService } from './../../../services/communication.service';
import { Component, OnInit } from "@angular/core";
import { QueryService } from "src/app/services/query.services";
import { SalesPersonDto } from "src/app/models/SalesPersonDto.Model";
import { SalesPersonRequest } from "src/app/models/SalesPersonRequest.Model";
import { configration } from "src/app/shared/config";
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { UserCategoryEnum } from 'src/app/models/enum/usercategory.enum';
import { PageChangedEvent } from "ngx-bootstrap/pagination";
import { DeleteUserModel } from "src/app/models/DeleteUserModel";
import { LoginAdminService } from "src/app/services/login-admin.service";
import { ActionType } from 'src/app/models/Action';
import { LookupsModel } from 'src/app/models/Lookups.model';
import { OrderStatusEnum } from 'src/app/models/enum/OrderStatusEnum';
import { adminOrderListComponent } from '../admin-order-list/admin-order-list.component';

@Component({
  selector: "app-sales-Admin-User",
  templateUrl: "./Admin-User-list.component.html",
  styleUrls: ["./Admin-User-list.component.scss"]
})
export class AdminUserListComponent implements OnInit {
  salesPersonLst: SalesPersonDto[] = [];
  OrderStatusList: LookupsModel[];
  UserCategoryList: LookupsModel[] = [];
  salesPersonRequest: SalesPersonRequest = new SalesPersonRequest();
  Total: number;
  errMsg: string;
  showLoading: boolean;
  title: string;
  IsArabic = true;
  infoMsg: string;
  showDeleteInfo = false;
  sortDir = 0;

  constructor(
    private _queryService: QueryService,
    private translateService: TranslateService,
    private titleService: Title,
    private _loginAdminService: LoginAdminService,
    private _communcationServices: CommunicationService,
    private _router: Router
  ) { }

  ngOnInit() {
    this.getOrdersStauts();
    this.getUserCategories();

    this.salesPersonRequest.OrdersStatusIds = [];
    this.salesPersonRequest.OrdersStatusIds.push(OrderStatusEnum.Saved);
    this.salesPersonRequest.OrdersStatusIds.push(OrderStatusEnum.OnTheWay);
    this.salesPersonRequest.OrdersStatusIds.push(OrderStatusEnum.Delivered);
    this._communcationServices.getAction().subscribe((res) => {
      if (res.actionType == ActionType.ShowAlertInfo) {
        this.showDeleteInfo = true;
        this.infoMsg = res.Result;
      } else if (res.actionType == ActionType.CloseAlertInof) {
        this.showDeleteInfo = false;
      }

    });

    this.loadAfterLang(this.translateService.currentLang);
    this.translateService.onLangChange.subscribe(res => {
      this.loadAfterLang(res.lang);
    });

    this.salesPersonRequest.PageIndex = 1;
    this.salesPersonRequest.PageSize = configration.pageSize;
    this.salesPersonRequest.UserCategories.push(UserCategoryEnum.Admin);
    this.salesPersonRequest.UserCategories.push(UserCategoryEnum.DataEntry);
    this.salesPersonRequest.UserCategories.push(UserCategoryEnum.Sales);
    this.searchUsers();
  }

  loadAfterLang(lang: string) {
    this.IsArabic = lang === "ar";

    this.title = this.translateService.instant("adminuserlist");
    this.titleService.setTitle(this.title);
  }

  searchUsers() {
    try {
      this.showLoading = true;


      this._queryService
        .getAllSalesPersonList(this.salesPersonRequest)
        .subscribe(
          res => {
            if (res.HasError) {
              this.errMsg = res.ErrorMessage;
              this.showLoading = false;
              return;
            }
            //populate PersonList
            this.salesPersonLst = res.Result.ItemList;
            this.Total = res.Result.Total;
            this.showLoading = false;
          },
          err => {
            this.showLoading = false;
            this.errMsg = err;
          }
        );
    } catch (err) {
      this.errMsg = err;
      this.showLoading = false;
    }
  }


  onSearchSubmit() {
    this.salesPersonRequest.PageIndex = 1;
    this.searchUsers();
  }

  onPagingChange(event: PageChangedEvent) {
    this.salesPersonRequest.PageIndex = event.page;
    this.salesPersonRequest.PageSize = event.itemsPerPage;
    this.searchUsers();
  }

  deleteUser(userId: string) {
    var confirmDelete = confirm(this.translateService.instant("AreYouSureDeleteUser"))
    if (confirmDelete) {
      try {
        this.showLoading = true;

        let deleteUserModel = new DeleteUserModel();
        deleteUserModel.UserId = userId;
        deleteUserModel.IsActive = false;

        this._loginAdminService
          .deleteUser(deleteUserModel)
          .subscribe(
            res => {
              if (res.HasError) {
                this.errMsg = res.ErrorMessage;
                this.showLoading = false;
                return;
              }

              if (res.Result) {
                debugger
                let userIndex = this.salesPersonLst.findIndex(x => x.Id == userId);
                if (userIndex > -1) {
                  this.salesPersonLst[userIndex].IsActive = false;

                  this._communcationServices.showAlertInof(
                    this.translateService.instant("UserDeletedSuccessfully")
                  );
                }
              }
            },
            err => {
              this.showLoading = false;
              this.errMsg = err;
            }

          );
      } catch (err) {
        this.errMsg = err;
        this.showLoading = false;
      }

    } else {
      return;
    }
  }


  onSortClick(event: any, colName: string) {

    let target = event.currentTarget,
      classList = target.classList;

    /* sort DESC */
    if (classList.contains('fa-chevron-up')) {
      classList.remove('fa-chevron-up');
      classList.add('fa-chevron-down');
      this.sortDir = 0;

      /* sort ASC */
    } else {
      classList.add('fa-chevron-up');
      classList.remove('fa-chevron-down');
      this.sortDir = 1;
    }

    this.salesPersonRequest.SortColumn = colName;
    this.salesPersonRequest.SortDirection = this.sortDir;
    this.salesPersonRequest.UserCategories.push(UserCategoryEnum.Admin);
    this.salesPersonRequest.UserCategories.push(UserCategoryEnum.DataEntry);
    this.salesPersonRequest.UserCategories.push(UserCategoryEnum.Sales);

    this.searchUsers();
  }

  getOrdersStauts() {
    this._queryService.getAllOrderStatus().subscribe(
      (res) => {
        if (res.HasError) {
          this.errMsg = res.ErrorMessage;
          return;
        }
        this.OrderStatusList = res.Result;
      },
      (err) => {
        this.errMsg = err;
      }
    );
  }

  goToOrdersPage(sales: SalesPersonDto) {
    let ordersStatus = this.salesPersonRequest.OrdersStatusIds.map(String);
    let navTo = `/adminhome/adminorderlist/${sales.Id}/${ordersStatus}`
    this._router.navigateByUrl[navTo];
    //this._router.navigate( [adminOrderListComponent, {id: sales.Id, ordersStatusIds: ordersStatus}]);
  }

  getUserCategories() {
    try {
      this.errMsg = "";
      this.showLoading = true;

      this._queryService.getAllUserCategoriesList().subscribe(
        (res) => {
          this.showLoading = false;
          if (res.HasError) {
            this.errMsg = res.ErrorMessage;
            return;
          }
          res.Result.forEach(x => {
            if (x.ID != UserCategoryEnum.Client) {
              this.UserCategoryList.push({
                ID: x.ID,
                NameAR: x.NameAR,
                NameEN: x.NameEN,
                checked: false,
              });
            }
          });


        },
        (err) => {
          this.errMsg = err;
          this.showLoading = false;
        }
      );
    } catch (error) {
      this.errMsg = error;
      this.showLoading = false;
    }
  }

}
