import { environment } from './../../../../environments/environment.prod';
import { CommandService } from './../../../services/command.service';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { ProductSupplierDto } from 'src/app/models/ProductSupplierDto';
import { ProductSupplierRequest } from 'src/app/models/ProductSupplierRequest';
import { QueryService } from 'src/app/services/query.services';
import { configration } from 'src/app/shared/config';

@Component({
  selector: 'app-product-suppliers',
  templateUrl: './product-suppliers.component.html',
  styleUrls: ['./product-suppliers.component.scss']
})
export class ProductSuppliersComponent implements OnInit {
  errMsg: string;
  title: string;
  IsArabic = true;
  sortDir = 0;
  suppliersRequest = new ProductSupplierRequest();
  suppliersList: ProductSupplierDto[] = []
  showLoading = false;
  Total = 0;

  constructor(
    private _queryService: QueryService,
    private _commandService: CommandService,
    private translateService: TranslateService,
    private _router: Router,
    private titleService: Title,

  ) { }

  ngOnInit() {

    this.suppliersRequest.PageIndex = 1;
    this.suppliersRequest.PageSize = configration.pageSize;

    this.loadAfterLang(this.translateService.currentLang);
    this.translateService.onLangChange.subscribe((res) =>  this.loadAfterLang(res.lang));

    this.getProductSuppliers();
  }

  loadAfterLang(lang: string) {
    this.IsArabic = lang === "ar";
    this.title = this.translateService.instant("ProductSuppliers");
    this.titleService.setTitle(this.title);
  }

  getProductSuppliers() {
    try {
      this.errMsg = "";
      this.showLoading = true;
      this._queryService.searchSuppliers(this.suppliersRequest).subscribe(res => {
        this.showLoading = false;
        if (res.HasError) {
          this.errMsg = res.ErrorMessage;
          return;
        }
        this.suppliersList = res.Result.ItemList;
        this.Total = res.Result.Total;
      }, err => {
        this.errMsg = err;
        this.showLoading = false;
      });
    } catch (err) {
      this.errMsg = err;
      this.showLoading = false;

    }
  }


  onSortClick(event: any, colName: string) {

    let target = event.currentTarget,
      classList = target.classList;

    /* sort DESC */
    if (classList.contains('fa-chevron-up')) {
      classList.remove('fa-chevron-up');
      classList.add('fa-chevron-down');
      this.sortDir = 0;

      /* sort ASC */
    } else {
      classList.add('fa-chevron-up');
      classList.remove('fa-chevron-down');
      this.sortDir = 1;
    }

    this.suppliersRequest.SortColumn = colName;
    this.suppliersRequest.SortDirection = this.sortDir;

    this.getProductSuppliers();
  }

  onPagingChange(event: PageChangedEvent) {
    this.suppliersRequest.PageIndex = event.page;
    this.suppliersRequest.PageSize = event.itemsPerPage;
    this.getProductSuppliers();
  }

  getImageFullPath=(supplier: ProductSupplierDto): string =>supplier.ImageExist?`${environment.webApiEndPoint}ProductSupplier/${supplier.Image}`:  'assets/images/default.jpg';

  deleteSupplier(id: number){
    let request = new ProductSupplierRequest();
    request.ID = id;
    this._commandService.deleteSuppliers(request).subscribe(res => {
      if (res) 
        this.getProductSuppliers();
    });
  }

  onChanges = (data: any) => {
    this.suppliersRequest.IsRetail = data
    this.getProductSuppliers();
  }


}
