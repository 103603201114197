<div
  *ngIf="showLoading"
  class="d-flex flex-column z-index-3 position-fixed w-100 h-100 justify-content-center align-items-center bg-white"
  style="opacity: 0.5;"
>
  <div class="p-2 align-self-center">
    <span class="load-more-List text-center vw-100 vh-100">
      <span class="spinner-border text-primary" role="status"> </span>
    </span>
  </div>
</div>
<div class="d-flex w-100">
  <div class="bd-highlight p-2">
    <h4 class="p-2 mb-0">{{ title }}</h4>
  </div>
</div>

<!-- #region Form -->
<form
  #generatevoucher="ngForm"
  (ngSubmit)="generatevoucher.form.valid && save()"
  autocomplete="off"
>
  <div class="row mt-2">
    <!--Drop Dwone list-->
    <div class="col-4">
      <label>{{ "SalesPersonName" | translate }}</label>
      <kendo-combobox
        class="w-100 form-control rounded mb-2"
        name="salesPersonDDL"
        [data]="salesPersonLst"
        (filterChange)="getUser($event)"
        [filterable]="true"
        [loading]="SalesPersonDDLLoading"
        [valueField]="'Id'"
        [textField]="'Name'"
        [(ngModel)]="SelectedsalesPerson"
        [filterable]="true"
        #salesPersonDDL="ngModel"
        placeholder="{{ 'SalesPersonName' | translate }}"
        required
      >
      <ng-template kendoComboBoxNoDataTemplate>
        <h4>
          <span class="k-icon k-i-warning"></span><br/>
          {{ "NotAvailable" | translate }}
        </h4>
      </ng-template>
      </kendo-combobox>
      <div
        *ngIf="generatevoucher.submitted && salesPersonDDL.errors?.required"
        class="text text-danger px-3"
      >
        {{ "salespersonisrequired" | translate }}
      </div>
    </div>
    <!--number of voucher input-->
    <div class="col-4">
      <label>{{ "NoVoucher" | translate }}</label>
      <input
        maxlength="2"
        minlength="1"
        name="NOvoucher"
        type="text"
        pattern="^[1-9][0-9]*$"
        class="form-control mb-2 rounded"
        #NOvouchers="ngModel"
        required
        [(ngModel)]="NoVoucher"
        placeholder="{{ 'NoVoucher' | translate }}"
      />
      <div
        *ngIf="generatevoucher.submitted && NOvouchers.errors?.required"
        class="text text-danger px-3"
      >
        {{ "NoVoucherisrequired" | translate }}
      </div>
      <div
        *ngIf="generatevoucher.submitted && NOvouchers.errors?.pattern"
        class="text text-danger px-3"
      >
        {{ "NoVoucherisNumberOnly" | translate }}
      </div>
    </div>
    <!--Note input-->
    <div class="col-8">
      <label>{{ "Description" | translate }}</label>
      <input
        name="Note"
        type="text"
        class="form-control mb-2 rounded"
        [(ngModel)]="Notes"
        placeholder="{{ 'VoutcherDescription' | translate }}"
      />
    </div>
    <!-- Save-->
  </div>
  <div class="row mt-2">
    <div class="col-6"></div>
    <div class="col-6 d-flex justify-content-end">
      <button
        class="btn btn-primary bg-red border-0 m-1 align-self-end"
        type="submit"
      >
        {{ "Save" | translate }}
      </button>
    </div>
  </div>
</form>
