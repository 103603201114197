import { Component, OnInit, Input } from '@angular/core';
import { CommunicationService } from 'src/app/services/communication.service';

@Component({
  selector: 'app-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss']
})
export class AlertMessageComponent implements OnInit {
  @Input() msg : string;
  constructor(private _communcationService : CommunicationService) { }

  ngOnInit() {
    setTimeout(() => {
        this._communcationService.closeAlert();
        this._communcationService.closeAlertInfo();
    }, 2000);
  }
  hideAlert(){
    this._communcationService.closeAlert();
    this._communcationService.closeAlertInfo();
  }

}
