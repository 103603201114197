import { DiscountCategoryEnum } from 'src/app/models/enum/DiscountCategoryEnum';

import { CommandService } from 'src/app/services/command.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { debounceTime } from 'rxjs/operators';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { LoginAdminService } from 'src/app/services/login-admin.service';
import { CommunicationService } from 'src/app/services/communication.service';
import { Router } from '@angular/router';
import { ProductModel } from 'src/app/models/Product.Model';
import { ProductDiscountRequest, ProductRequest } from 'src/app/models/ProductRequest.Model';
import { QueryService } from 'src/app/services/query.services';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";
import { Title } from "@angular/platform-browser";
import { distinct } from 'rxjs/operators';
import { DiscountDTO } from 'src/app/models/Discount/DiscountDto';
import { DiscountProductEligibilityEnum, DiscountClientEligibility } from 'src/app/models/enum/usercategory.enum';
import { UserInfoDto } from 'src/app/models/UserInfoDto.Model';
import { UserRequestSearch, UserDiscountRequestSearch } from 'src/app/models/UserRequest.Model';
import { configration } from 'src/app/shared/config';
import { DiscountCustomerRequest, DiscountProductRequest, GeneralDiscount } from 'src/app/models/Discount/GeneralDiscount';
import { DiscountResponse } from 'src/app/models/Discount/DiscountResponse';

@Component({
  selector: 'app-discount-add-edit',
  templateUrl: './discount-add-edit.component.html',
  styleUrls: ['./discount-add-edit.component.scss']
})
export class DiscountAddEditComponent implements OnInit, AfterViewInit {

  @ViewChild('searchProductText', { static: false }) searchProductSelect: ElementRef;
  productTextSearch = new EventEmitter<string>();
  userTextSearch = new EventEmitter<string>();
  productRequest: ProductRequest = new ProductRequest();
  userRequest: UserRequestSearch = new UserRequestSearch();
  discountRequest: DiscountDTO = new DiscountDTO();
  generalDiscountRequest: GeneralDiscount = new GeneralDiscount();

  discountResponse: DiscountResponse;
  isDiscountUsed: number = 1;
  showLoading: boolean = false;
  IsArabic: boolean = true;
  errors: string[] = [];
  promoCodeTypeText = "PromoCodePercentage";

  // userList: UserInfoDto[] = [];
  // selectedUserList: UserInfoDto[] = [];

  productList: ProductModel[] = [];
  productEligibilityEnum = DiscountProductEligibilityEnum;
  clientEligibilityEnum = DiscountClientEligibility;
  isSuccessOperation: boolean = false;
  showLoadingProduct = false;
  isLoading: boolean = false;
  isArabic: boolean = false;
  showLoadingUsers = false;
  totalProduct: number;
  totalUser: number;
  pageIndexProduct: number = 0;
  pageIndexUser: number = 0;
  discountTypeText: string = 'PromoCodePercentage';
  validDateFrom: string;
  validDateTo: string;
  errorMsg: string;
  title: string;
  msg: string;
  errMsg: string = "";
  error: string = "";
  discountForm: FormGroup;

  constructor(
    private queryService: QueryService,
    private route: ActivatedRoute,
    private _translate: TranslateService,
    private router: Router,
    private commandService: CommandService,
    private titleService: Title,
    private _communicationService: CommunicationService,
    private _adminLogin: LoginAdminService,
    private fb: FormBuilder,
    private _queryService: QueryService,
    private _commandService: CommandService,
  ) {
    _translate.onLangChange.subscribe(() => this.isArabic = this._translate.currentLang == 'ar');
  }

  ngAfterViewInit(): void {
    this.searchProducts()
    // this.searchUsers();
  }

  ngOnInit() {
    // this.searchUserList(this.userRequest);
    this.searchProductList(this.productRequest);
    this.loadAfterLang(this._translate.currentLang);
    this._translate.onLangChange.subscribe(res => this.loadAfterLang(res.lang));
    this.getDiscountID();
    this.IntialiazeFormsDiscount(this.generalDiscountRequest)
  }

  changePromoCodeLabel = (value: string) => this.promoCodeTypeText = value;
  setDefaultDiscountResponse = () => this.discountResponse = new DiscountResponse();


  // searchUserList(request: UserRequestSearch) {
  //   this.showLoadingUsers = true;
  //   try {
  //     this.queryService.GetUsers(request).subscribe(res => {
  //       if (res.HasError) this.errorMsg = res.ErrorMessage;
  //       this.userList = res.Result.ItemList
  //       this.showLoadingUsers = false;
  //     },
  //       (err) => {
  //         this.errorMsg = err;
  //         this.showLoadingUsers = false;
  //       });

  //   } catch (error) {
  //     this.errorMsg = error;
  //     this.showLoadingUsers = false;
  //   }
  //   return this.userList;
  // }

  searchProductList(request: ProductRequest) {
    this.showLoadingProduct = true;
    try {
      this.queryService.GetProducts(request).pipe(distinct(x => x.Result.ItemList)).subscribe(res => {
        this.showLoadingProduct = false;
        if (res.HasError) this.errorMsg = res.ErrorMessage;
        this.productList = res.Result.ItemList;
        this.totalProduct = res.Result.Total;
      },
        (err) => {
          this.errorMsg = err;
          this.showLoadingProduct = false;
        });

    } catch (error) {
      this.errorMsg = error;
      this.showLoadingProduct = false;
    }

    return this.productList;
  }

  getProductsByIds(request: ProductDiscountRequest) {
    this.showLoadingProduct = true;
    try {
      this.queryService.GetProductsDiscountByIds(request).pipe(distinct(x => x.Result.ItemList)).subscribe(res => {
        this.showLoadingProduct = false;
        if (res.HasError) this.errorMsg = res.ErrorMessage;
        this.productList = res.Result.ItemList;
        this.totalProduct = res.Result.Total;
      },
        (err) => {
          this.errorMsg = err;
          this.showLoadingProduct = false;
        });

    } catch (error) {
      this.errorMsg = error;
      this.showLoadingProduct = false;
    }

  }

  // getUserByIds(request: UserDiscountRequestSearch) {
  //   this.showLoadingUsers = true;
  //   try {
  //     this.queryService.GetUsersDiscountByIds(request).subscribe(res => {
  //       if (res.HasError) this.errorMsg = res.ErrorMessage;
  //       this.userList = res.Result.ItemList
  //       this.showLoadingUsers = false;
  //     },
  //       (err) => {
  //         this.errorMsg = err;
  //         this.showLoadingUsers = false;
  //       });

  //   } catch (error) {
  //     this.errorMsg = error;
  //     this.showLoadingUsers = false;
  //   }

  // }


  loadAfterLang(lang: string) {
    this.isArabic = lang === "ar";
    this.route.params.subscribe(() => {
      this.title = this._translate.instant("ProductSystem");
      this.titleService.setTitle(this.title);
    });
  }


  discountFormEdit(discountResponse: DiscountResponse) {
    this.discountForm = this.fb.group({
      discountName: [discountResponse.Name, [Validators.required]],
      discountType: [discountResponse.PercentageType.toFixed(), Validators.required],
      discountValue: [discountResponse.Amount, [Validators.required, Validators.pattern('^[0-9]+(.[0-9]{0,3})?$')]],
      validDateTo: [discountResponse.ValidDateTo, Validators.required],
      appType: [discountResponse.IsRetail? "true": "false", Validators.required],
      validDateFrom: [discountResponse.ValidDateFrom, Validators.required],
      ApplyToMaxProdcutPrice: [discountResponse.MaxProdcutPrice, Validators.required],
      ApplyToMinProdcutPrice: [discountResponse.MinProdcutPrice, Validators.required],
      appliesToProduct: [discountResponse.ProductsEligibility.toFixed(), Validators.required],
      selectedProducts: [this.mapProductEligibilty(discountResponse).map(x => x.ID)]
    })
  }


  discountFormInitialization() {
    this.discountForm = this.fb.group({
      discountName: ["", [Validators.required]],
      discountType: ["", Validators.required],
      discountValue: ["", [Validators.required, Validators.pattern('^[0-9]+(.[0-9]{0,3})?$')]],
      validDateTo: ["", Validators.required],
      validDateFrom: ["", Validators.required],
      appliesToProduct: ["", Validators.required],
      appType: ["", Validators.required],
      // appliesToCustomer: ["", Validators.required],
      ApplyToMinProdcutPrice: ["", [Validators.required, Validators.pattern('^[0-9]+(.[0-9]{0,3})?$')]],
      ApplyToMaxProdcutPrice: ["", [Validators.required, Validators.pattern('^[0-9]+(.[0-9]{0,3})?$')]],
      // selectedUsers: [],
      selectedProducts: []
    }, {
      validator: [
        this.dateLessThan('validDateFrom', 'validDateTo'),
        this.checkSelectedProduct('appliesToProduct', 'selectedProducts'),
        // this.checkSelectedCustomer('appliesToCustomer', 'selectedUsers'),
        // this.checkDiscountISProductOrCustomers('appliesToProduct', 'appliesToCustomer')
      ]
    })
  }

  Save() {
    let request = this.createGeneralRequest();
    this.isLoading = true;
    this.commandService.AddDiscount(request).subscribe(res => {
      this.isLoading = false;
      if (!res.Result) {
        this.errorMsg = res.ErrorMessage;
        this.isSuccessOperation = false;
        this.isLoading = false;
        this.msg = this._translate.instant("inValidOperation");
      }
      else {
        this.isLoading = false;
        this.isSuccessOperation = true;
        this.msg = this._translate.instant("validOperation");
        this.router.navigateByUrl("/adminhome/discount")

      }
    }, (err) => {
      this.errorMsg = err;
      this.isLoading = false;
    })
  }

  get selectedUsers() { return this.discountForm.get('selectedUsers') }
  get selectedProducts() { return this.discountForm.get('selectedProducts') }

  changeDiscountLabel = (value: string) => this.discountTypeText = value;

  onScrollToEnd = () => this.fetchMoreProduct();
  onScroll(a) {
    if (this.showLoadingProduct || this.productList.length >= this.totalProduct)
      return;
    if (a.start + this.productList.length >= configration.pageSize) {
      this.fetchMoreProduct();
    }
  }

  fetchMoreProduct() {
    let request = new ProductRequest();
    this.pageIndexProduct++;
    request.PageIndex += this.pageIndexProduct;
    let productList = this.productList;
    this.productList = productList.concat(this.searchProductList(request));
    if (productList.length == 0)
      this.totalProduct = this.productList.length;
  }


  // onScrollToEndUser = () => this.fetchMoreUser();
  // onScrollUser(a) {
  //   if (this.showLoadingUsers || this.userList.length >= this.totalUser)
  //     return;
  //   if (a.start + this.userList.length >= configration.pageSize) {
  //     this.fetchMoreUser();
  //   }
  // }

  // fetchMoreUser() {
  //   let request = new UserRequestSearch();
  //   this.pageIndexUser++;
  //   request.PageIndex = this.pageIndexUser;
  //   let userList = this.userList;
  //   // this.userList = userList.concat(this.searchUserList(request));
  // }


  initializeUserRequestSearch(data: any): UserRequestSearch {
    let request = new UserRequestSearch();
    if (this.checkTextAsNumber(data))
      request.PhoneNumber = data;
    else
      request.UserName = data;
    return request;
  }

  initializeProductRequestSearch(data: any): ProductRequest {
    let request = new ProductRequest();
    let isSearchInputNumber = this.checkTextAsNumber(data);
    if (isSearchInputNumber)
      request.ID = data;
    else if (this.isArabic)
      request.NameAR = data;
    else
      request.NameEN = data;
    return request;
  }

  private searchProducts() {
    this.productTextSearch.pipe(
      distinctUntilChanged(),
      debounceTime(3000)).subscribe(res => {
        this.productList = null;
        this.productList = this.searchProductList(this.initializeProductRequestSearch(res))
      })
  }

  // private searchUsers() {
  //   this.userTextSearch.pipe(
  //     distinctUntilChanged(),
  //     debounceTime(3000)).subscribe(res => {
  //       this.userList = null;
  //       this.userList = this.searchUserList(this.initializeUserRequestSearch(res))
  //     })
  // }

  private checkTextAsNumber = (text: string) => new RegExp('^[0-9]+$').test(text);

  private dateLessThan(from: string, to: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let f = group.controls[from];
      let t = group.controls[to];
      if (f.value > t.value)
        return { dates: "dateToMustBiggerThanFrom" };
      return {};
    }
  }

  private checkSelectedProduct(controlNameRadio: string, controlNameSelectedList: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let selectedValue = group.controls[controlNameRadio].value;
      let selectedList = group.controls[controlNameSelectedList].value;
      if (selectedValue != this.productEligibilityEnum.All && selectedList == null)
        return { productListError: "productListEMpty" }
      return {}
    }
  }

  private checkSelectedCustomer(controlNameRadio: string, controlNameSelectedList: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let selectedValue = group.controls[controlNameRadio].value;
      let selectedList = group.controls[controlNameSelectedList].value;
      if (selectedValue == this.clientEligibilityEnum.SpecificClients && selectedList == null)
        return { clientListError: "clientListEMpty" }
      return {}
    }
  }

  private checkDiscountISProductOrCustomers(SelectedProductcontrolName: string, SelectedUsercontrolName: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let selectedPRoduct = group.controls[SelectedProductcontrolName].value;
      let selectedUser = group.controls[SelectedUsercontrolName].value;
      if (selectedPRoduct == 3 && selectedUser == 3)
        return { discountProductOrUser: "discountProductOrUser" }
      return {};
    }
  }

  checkSelectedUserLis() {
    if (this.discountForm.get('appliesToCustomer').value != this.clientEligibilityEnum.SpecificClients)
      this.selectedUsers.setValue(null);
  }

  checkSelectedProductList() {
    if (this.discountForm.get('appliesToProduct').value == this.productEligibilityEnum.All)
      this.selectedProducts.setValue(null);
  }

  clearSelectedUsers = () => this.selectedUsers.setValue(null);
  clearSelectedProducts = () => this.selectedProducts.setValue(null);

  createGeneralRequest(): GeneralDiscount {
    let request = new GeneralDiscount();
    request.ID = this.generalDiscountRequest.ID != 0 ? this.generalDiscountRequest.ID : 0;
    request.Name = this.discountForm.value.discountName;
    request.PercentageType = this.discountForm.value.discountType;
    request.Amount = this.discountForm.value.discountValue;
    request.ValidDateFrom = this.discountForm.value.validDateFrom;
    request.ValidDateTo = this.discountForm.value.validDateTo;
    request.CustomersEligibility = this.discountForm.value.appliesToCustomer;
    request.ProductEligibility = this.discountForm.value.appliesToProduct;
    request.MinProdcutPrice = this.discountForm.value.ApplyToMinProdcutPrice;
    request.MaxProdcutPrice = this.discountForm.value.ApplyToMaxProdcutPrice;
    request.DiscountCategory = DiscountCategoryEnum.Product;
    request.IsRetail = this.discountForm.value.appType;

    if (request.ProductEligibility != DiscountProductEligibilityEnum.All) {
      let productList: DiscountProductRequest[] = [];
      let productIDs: number[] = this.selectedProducts.value;

      if (productIDs != null && productIDs.length > 0) {
        productIDs.forEach(item => {
          let product = new DiscountProductRequest();
          product.ProductID = item;
          product.DiscountCategoryEnum = DiscountCategoryEnum.Product;
          productList.push(product);
        });
      } else if (this.discountResponse != null && this.generalDiscountRequest.ID != 0) {
        let productIds = this.discountResponse.DiscountProducts.map(x => x.ProductID);
        productIDs.forEach(item => {
          let product = new DiscountProductRequest();
          product.ProductID = item;
          product.DiscountCategoryEnum = DiscountCategoryEnum.Product;
          productList.push(product);
        })
      }

      request.DiscountProducts = productList;

    } else
      request.DiscountProducts = null;

    // if (request.CustomersEligibility == DiscountClientEligibility.SpecificClients) {
    //   let customerList: DiscountCustomerRequest[] = [];
    //   let userIDS: string[] = this.selectedUsers.value;
    //   if (userIDS != null && userIDS.length > 0) {
    //     userIDS.forEach(item => {
    //       let user = new DiscountCustomerRequest();
    //       user.CustomerID = item;
    //       user.DiscountCategoryEnum  = DiscountCategoryEnum.Product;
    //       customerList.push(user);
    //     });
    //   } else if (this.discountResponse != null && this.generalDiscountRequest.ID != 0) {
    //     let userIds = this.discountResponse.DiscountCustomers.map(x => x.CustomerID);
    //     userIds.forEach(item => {
    //       let user = new DiscountCustomerRequest();
    //       user.CustomerID = item;
    //       user.DiscountCategoryEnum  = DiscountCategoryEnum.Product;
    //       customerList.push(user);
    //     });

    //   }

    //   request.DiscountCustomers = customerList;

    // } else request.DiscountCustomers = null;
    // if (this.generalDiscountRequest.ID != 0)
    //   request.ID = this.generalDiscountRequest.ID;

    return request;
  }



  mapFormValueToDiscountRequest(request: NgForm): DiscountDTO {
    let response = new DiscountDTO();
    response.DiscountPromoCode = request.value['PromoCode'];
    response.DiscountPromoCodeType = request.value['PromoCodeType'];
    response.DiscountPromoCodeAmount = request.value['PromoCodeAmount'];
    return response;
  }

  getDiscountID = () => this.route.params.subscribe(params => this.generalDiscountRequest.ID = +params['ID'])


  IntialiazeFormsDiscount(request: GeneralDiscount) {
    if (request.ID != 0) {
      this.getDiscountByID(request)
    }
    else
      this.discountFormInitialization()
  }

  getDiscountByID(request: GeneralDiscount) {
    try {
      this.showLoading = true;
      this.showLoadingProduct = true;
      this.showLoadingUsers = true;
      this.errMsg = '';
      this.queryService.GetDiscountByID(request).subscribe(res => {
        if (res.HasError) {
          this.errMsg = res.ErrorMessage;
          this.showLoading = false;
        }
        this.showLoading = false
        this.discountResponse = res.Result;

        this.discountFormEdit(res.Result)
        // this.selectedUserList = this.mapCustomerEligibility(res.Result);

        // this.userList = this.mapCustomerEligibility(res.Result);
        this.productList = this.mapProductEligibilty(res.Result);
        this.showLoadingProduct = false;
        this.showLoadingUsers = false;
      },
        Err => {
          this.errMsg = Err;
          this.showLoading = false;
          this.showLoadingProduct = false;
          this.showLoadingUsers = false;
        })
    } catch (error) {
      this.errMsg = error;
      this.showLoading = false;
      this.showLoadingProduct = false;
      this.showLoadingUsers = false;
    }
  }

  mapProductEligibilty(request: DiscountResponse): ProductModel[] {
    let result: ProductModel[] = [];
    request.DiscountProducts.forEach(item => {
      let product = new ProductModel();
      product.NameEN = item.ProductNameEN;
      product.ID = item.ProductID;
      product.NameAR = item.ProductNameAR;
      result.push(product);
    });
    return result;
  }

  mapCustomerEligibility(request: DiscountResponse): UserInfoDto[] {
    let result: UserInfoDto[] = [];
    request.DiscountCustomers.forEach(item => {
      let user = new UserInfoDto();
      user.Name = item.CustomerName;
      user.UserId = item.CustomerID;
      user.PhoneNumber = item.CustomerPhone;
      result.push(user);
    });
    return result;
  }



}
