import { environment } from './../../../../environments/environment.prod';

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { QueryService } from 'src/app/services/query.services';
import { ProductCategoryModel } from 'src/app/models/product-category.model';
import { configration } from 'src/app/shared/config';
import { TranslateService } from '@ngx-translate/core';
import { CommunicationService } from 'src/app/services/communication.service';
import { Router } from '@angular/router';
import { ProductCategoryRequest } from 'src/app/models/ProductCategoryRequest.Model';

@Component({
  selector: 'app-product-category',
  templateUrl: './product-category.component.html',
  styleUrls: ['./product-category.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductCategoryComponent implements OnInit {
  //#region
  filter: ProductCategoryRequest = new ProductCategoryRequest();
  ProductCategoryList: ProductCategoryModel[] = [];
  errMsg: string = "";
  showLoading: boolean;
  pathOfAPI: string;
  //#endregion
  isArabic: boolean = true;
  total: number = 0;
  isThereMoreCat: boolean = false;
  constructor(private _queryService: QueryService,
    private _trans: TranslateService,
    private _comm: CommunicationService,
    private _router: Router) {
    _trans.onLangChange.subscribe(() => {
      this.isArabic = this._trans.currentLang == 'ar';

    });
  }
  ngOnInit() {
    this.isArabic = this._trans.currentLang == 'ar';

    this.pathOfAPI = environment.webApiEndPoint;
    this.filter.PageIndex = 1;
    this.filter.PageSize = configration.pageSize;
    this.getProductCategory(this.filter);

    //clear previous serach and filter
    this._comm.state.productListState.clearFilterAndResult();

    // this._comm.getAction().subscribe(a=> {
    //   if(a.actionType == ActionType.QueryProducts)
    //     this._router.navigate(['home/productlist']);
    // })

  }


  searchProductCategory() {
    this.getProductCategory(this.filter);
  }
  //#endregion Method
  getProductCategory(filter: ProductCategoryRequest) {

    try {
      this.errMsg = "";
      this.showLoading = true;
      filter.inAdminPage = false;
      this._queryService.getProductCategory(filter).subscribe(res => {
        this.showLoading = false;
        if (res.HasError) {
          this.errMsg = res.ErrorMessage;
          return;
        }

        this.ProductCategoryList = res.Result.ItemList
        this.total = res.Result.Total;

        this.isThereMoreCat = this.total == this.ProductCategoryList.length;
      }, err => {
        this.errMsg = err;
        this.showLoading = false;
      });
    } catch (err) {
      this.errMsg = err;
      this.showLoading = false;

    }
  }


  MoreProductCategory() {

    // if (this.showLoading)
    //   return;

    try {
      this.showLoading = true;
      this.filter.PageIndex += 1;
      this.filter.PageSize = configration.pageSize;
      this._queryService.getProductCategory(this.filter).subscribe(res => {
        if (res.HasError) {
          this.errMsg = res.ErrorMessage;
          this.showLoading = false;
          return;
        }
        let ProductCategoryList = res.Result.ItemList;
        this.ProductCategoryList = this.ProductCategoryList.concat(ProductCategoryList);
        this.showLoading = false;
      }, err => {
        this.showLoading = false;
        this.errMsg = err;
      });
    } catch (err) {
      this.errMsg = err;
      this.showLoading = false;

    }
  }

  categoryClick(prodCat: ProductCategoryModel) {
    this._comm.state.productListState.clearFilterAndResult();
    this._comm.state.productListState.filter.productCatIds[0] = prodCat.ID;
    this._comm.queryProducts();
  }

  onProductsScroll(a) {
    debugger
    let pos = a.srcElement.scrollTop + a.srcElement.clientHeight;
    let h = a.srcElement.scrollHeight;
    let prcnt = Math.round((pos / h) * 100);

    if (prcnt > 95)
      this.MoreProductCategory();
  }

  getImageFullPath(category: ProductCategoryModel): string {
    return category.ImageExist ?
    `${environment.webApiEndPoint}ProductCategory/${category.Image}?date=${category.LastModifiedTime}`:"assets/images/default.jpg"
  }

  


}
