import { OrderModel } from './Order.Model';
import { OrdersSummaryDto } from './OrdersSummaryDto';
import { SalonTypeEnum } from './enum/SalonTypeEnum';
import { UserAddressDto } from "./UserAddressDto.Model";

export class  ClientDetailsDto {
    Id: string;
    UserCode: number;
    Name: string;
    PhoneNumber: string;
    SalonName: string;
    SalonType: SalonTypeEnum;
    RegisterDate: string;
    VoucherId: number;
    IsActive: boolean;
    SalesId: string;
    SalesName: string;
    SalesPhoneNumber: string;
    UserAddressList: UserAddressDto[];
    OrdersList: OrderModel[];
    LastOrderDate: Date;
    OrdersTotal: number;
    OrdersCount: number;
    OrdersSummaryDto: OrdersSummaryDto[];
    UserNotes: UserNotesDTO[];
    IsRetail?:boolean;
}

export class UserNotesDTO {
    ID: number;
    Notes: string;
    UserID: string;
    DateNotes: string | null;
    CreatedBy: string;
    LastModifiedTime: string | null;
    CreatedTime: string;
    LastModifiedBy: string;
}
