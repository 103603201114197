import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { QueryService } from 'src/app/services/query.services';


@Component({
  selector: 'app-admin-config',
  templateUrl: './admin-config.component.html',
  styleUrls: ['./admin-config.component.scss']
})
export class AdminConfigComponent implements OnInit {

  title: string;
  IsArabic = true;
  constructor(
    private _queryService: QueryService,
    private translateService: TranslateService,
    private _router: Router,
    private titleService: Title,

    ) { }

  ngOnInit() {

    this.loadAfterLang(this.translateService.currentLang);
    this.translateService.onLangChange.subscribe((res) => {
      this.loadAfterLang(res.lang);
    });

  }

  loadAfterLang(lang: string) {
    this.IsArabic = lang === "ar";
    this.title = this.translateService.instant("AdminConfig");
    this.titleService.setTitle(this.title);
  }

}
