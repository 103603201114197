export enum UserCategoryEnum {
  Client = 1,
  DataEntry = 2,
  Admin = 3,
  Sales = 4,
  InventoryPerson = 5

}

export enum DiscountCodeTypeEnum {
  percentage = 0,
  Value = 1
}

export enum DiscountClientEligibility {
  All=1,
  SpecificClients=2,
  NoDiscount=3
}

export enum DiscountProductEligibilityEnum{
  All=1,
  SpecificProducts=2,
  NoDiscount=3

}