import { CommandService } from 'src/app/services/command.service';
import { ImageShineType } from './../../../../models/Shine/ImageShineType.enum';
import { shineImageRequest } from './../../../../models/Shine/shineImage';
import { environment } from './../../../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProductModel } from 'src/app/models/Product.Model';
import { FileService } from 'src/app/services/file.service';

@Component({
  selector: 'app-shineImages',
  templateUrl: './shineImages.component.html',
  styleUrls: ['./shineImages.component.scss']
})
export class ShineImagesComponent implements OnInit {
  errMsg: string;
  showLoadingTodaySpecial: boolean = false;
  showLoadingSalesDiscount: boolean = false;
  requestTodaySpecial: shineImageRequest = new shineImageRequest();
  requestSalesDiscount: shineImageRequest = new shineImageRequest();
  imageSourceTodaySpecial = 'assets/images/default.png';
  imageSourceSalesDiscount = 'assets/images/default.png';
  showProductImageReqMesg: boolean = false;
  successMsg: string = ''
  isSuccessSalesDiscount: boolean = false;
  isSuccessTodaySpecial: boolean = false;
  showLoading: boolean = false;

  constructor(private fileService: FileService,
    private commandService: CommandService,
    private translateService: TranslateService,) { }

  ngOnInit() {
  }

  checkImageValidation = (files: File[]): boolean => {
    let validImageExtention = this.fileService.checkFileExtentionForImage(files);
    if (!validImageExtention) {
      this.errMsg = this.translateService.instant("ImageErrorMessage");
      return false;
    } else
      return true;
  }

  checkImageSize = (files: File[]) => {
    let validImagesize = this.fileService.checkFileSize(files);
    if (!validImagesize) {
      this.errMsg = this.translateService.instant("ImageSizeErrorMessage");
      return false
    } else
      return true;
  }




  fileChangeEvent(fileInput: any) {
    this.errMsg = "";
    this.showLoadingSalesDiscount = true;
    if (!this.checkImageValidation(fileInput.target.files)) return;
    if (!this.checkImageSize(fileInput.target.files)) return;
    this.fileService.uploadFiles(fileInput.target.files).subscribe(res => {
      this.showLoadingTodaySpecial = false;
      if (res.HasError) {
        this.errMsg = res.ErrorMessage;
        return;
      }
      this.requestTodaySpecial.ImagePath = res.Result[0].RelativePath;
      this.imageSourceTodaySpecial = `${environment.webApiEndPoint}Temp/${this.requestTodaySpecial.ImagePath}`
    });
    document.getElementById("imgup").style.display = 'inline';
  }

  fileChangeEventSales(fileInput: any) {
    this.errMsg = "";
    this.showLoadingSalesDiscount = true;
    if (!this.checkImageValidation(fileInput.target.files)) return;
    if (!this.checkImageSize(fileInput.target.files)) return;
    this.fileService.uploadFiles(fileInput.target.files).subscribe(res => {
      this.showLoadingSalesDiscount = false;
      if (res.HasError) {
        this.errMsg = res.ErrorMessage;
        return;
      }

      this.requestSalesDiscount.ImagePath = res.Result[0].RelativePath;
      this.imageSourceSalesDiscount = `${environment.webApiEndPoint}Temp/${this.requestSalesDiscount.ImagePath}`
    });
    document.getElementById("imgup").style.display = 'inline';
  }


  calcStringLength = (text: string) => text.length;

  SaveSalesDiscount() {
    this.errMsg = '';
    this.successMsg = '';
    this.showLoading = true;
    if (this.calcStringLength(this.requestSalesDiscount.ImagePath) < 2)
      return;
    this.showLoadingSalesDiscount = true;
    this.requestSalesDiscount.ImageName = `salesDiscount_${new Date().toJSON().slice(0, 10).replace(/-/g, '/')}`;
    this.requestSalesDiscount.ImageType = ImageShineType.Discount;
    try {
      this.commandService.SaveShineImage(this.requestSalesDiscount).subscribe(res => {
        this.showLoadingSalesDiscount = false;
        this.showLoading = false;

        if (res.HasError) {
          this.errMsg = 'tryAgain';
          return;
        }
        this.successMsg = 'Done'
        this.isSuccessSalesDiscount = true;
      })
    } catch (error) {
      this.errMsg = error;
      this.showLoadingSalesDiscount = false;
    }
  }

  SaveTodaySpecial() {
    this.errMsg = ''
    this.successMsg = '';
    if (this.calcStringLength(this.requestTodaySpecial.ImagePath) < 2)
      return;
    this.showLoadingTodaySpecial = true;
    this.requestTodaySpecial.ImageName = `TodaySpecial_${new Date().toJSON().slice(0, 10).replace(/-/g, '/')}`;
    this.requestTodaySpecial.ImageType = ImageShineType.Offer;
    try {
      this.commandService.SaveShineImage(this.requestTodaySpecial).subscribe(res => {
        this.showLoadingTodaySpecial = false;
        if (res.HasError) {
          this.errMsg = 'tryAgain';
          return;
        }
        this.successMsg = 'Done'
        this.isSuccessTodaySpecial = true;
      })
    } catch (error) {
      this.errMsg = error;
      this.showLoadingTodaySpecial = false;
    }
  }


}
