<!-- 
<div class="row overflow-hidden mb-2 position-relative product">

    <div *ngIf="errMsg" class="text-danger mr-auto p-2 bd-highlight">{{ errMsg }}</div>

    <div *ngIf="showLoading" class="d-flex flex-column z-index-3 p-0 m-0 position-absolute bg-white w-100 h-100 justify-content-center align-items-center" style="opacity: 0.5">
        <div class="align-self-center">
            <span class="load-more-List text-center">
                <span class="spinner-border text-primary" role="status"> </span>
            </span>
        </div>
    </div>

    <div class="col-4 p-0">
        <div class="productImgContainer d-flex justify-content-center align-items-center h-100">

            <img class="align-self-center border-0 img-fluid productImg h-100" [src]="imageSource" />
        </div>
    </div>

    <div class="col-8 px-1">
        <div class="row no-gutters">
            <div class="col-12">
                <div class="productDetails">
                    <div class="row no-gutters">
                        <div class="col-12">
                            <div class="product-title">
                                <h6 class="productName" *ngIf="isArabic">{{ product.NameAR }}</h6>
                                <h6 class="productName" *ngIf="!isArabic">{{ product.NameEN }}</h6>
                            </div>
                            <div class="product-brand my-1">
                                <p class="saller">
                                    <span *ngIf="isArabic">{{ product.Brand.NameAR | TrimString: 20 }}</span>
                                    <span *ngIf="!isArabic">{{ product.Brand.NameEN | TrimString: 20 }}</span>
                                </p>
                            </div>
                            <div class="product-price">
                                <div class="discountPrice d-flex align-items-center">
                                    <div class="discountPrice">
                                        <h5 *ngIf="product.Discount > 0">
                                            {{ product.Price }} {{"le" | translate}}
                                        </h5>
                                    </div>
                                    <h5 [class]="product.Discount > 0? 'newPrice mx-2 d-inline-block': 'oldPrice d-inline-block'">
                                        {{ product.OriginalPrice }} {{"le" | translate}}

                                        <span class="dashedPrice"></span>
                                    </h5>
                                </div>
                            </div>

                            <div class="row no-gutters mt-2">
                                <div class="col-6 d-flex align-items-center">
                                    <div class="quantity-wrapper my-1" *ngIf=" product.Quantity > 0 ">
                                        <span class="icon fa fa-plus" (click)="incQty($event)"></span>
                                        <span class="quantityVal"> {{ product.Quantity }}</span>
                                        <span class="icon fa fa-minus" (click)="decQty($event)"></span>
                                    </div>
                                </div>
                                <div class="
                        col-6
                        d-flex
                        justify-content-end
                        align-items-center
                        AddedToCartContainer
                      ">
                                    <div *ngIf="product.Balance <= 0 ">
                                        <span class="badge badge-light">{{
                                            "OutOfStock" | translate
                                            }}</span>
                                    </div>

                                    <div *ngIf="product.Balance > 0 ">
                                        <button *ngIf="!product.AddedToCart" (click)="
                        $event.stopPropagation() &&
                            product.AddedToCart
                              ? deleteItemFromCart($event)
                              : addItemToCart($event)
                          " [class]="
                            product.AddedToCart
                              ? 'alert_icon icon btn'
                              : 'default_icon icon btn'
                          " [disabled]="isBusy">
                                            <img *ngIf="!product.AddedToCart" alt=" {{ 'AddItemToCart' | translate }}"
                                                src="assets/images/addcart.svg" class="img-fluid" />

                                            <img *ngIf="product.AddedToCart" alt=" {{ 'AddedToCart' | translate }}"
                                                src="assets/images/adeddtocartt.svg" class="img-fluid" />
                                        </button>
                                        <span class="icon" *ngIf="product.AddedToCart" (click)="deleteItemFromCart($event)">
                                            <i class="fa fa-trash fa-lg text-danger" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                    <span (click)="addItemToFav($event)" class="icon">
                                        <i *ngIf="product.AddedToFav" class="fa fa-heart active fa-lg"></i>
                                        <i *ngIf="!product.AddedToFav" class="fa fa-heart-o fa-lg"></i>
                                    </span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div bsModal #productDetailsModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">

</div> -->

<!-- new style -->
<div class="row overflow-hidden position-relative product">

    <div *ngIf="errMsg" class="text-danger mr-auto p-2 bd-highlight">
        {{ errMsg }}
    </div>

    <div *ngIf="showLoading" class="
        d-flex
        flex-column
        z-index-3
        p-0
        m-0
        position-absolute
        bg-white
        w-100
        h-100
        justify-content-center
        align-items-center
      " style="opacity: 0.5" (click)="stopProp($event)">
        <div class="align-self-center">
            <span class="load-more-List text-center">
                <span class="spinner-border text-primary" role="status"> </span>
            </span>
        </div>
    </div>

    <div class="col-4 p-0">
        <div class="
          productImgContainer
          d-flex
          justify-content-center
          align-items-center
          h-100
        ">
            <img class="align-self-center border-0 img-fluid productImg h-100" [src]="imageSource" />
            <!-- <img class="align-self-center border-0 img-fluid productImg h-100" [src]="imageSource" (click)="showProductDetails($event)" /> -->
        </div>
    </div>

    <div class="col-8 px-1">
        <div class="row no-gutters">
            <div class="col-12">
                <div class="productDetails">
                    <div class="row no-gutters">
                        <div class="col-12">
                            <div class="product-title">
                                <h6 class="productName" *ngIf="isArabic">
                                    {{ product.NameAR }}
                                </h6>
                                <h6 class="productName" *ngIf="!isArabic">
                                    {{ product.NameEN }}
                                </h6>
                            </div>
                            <div class="product-brand my-1">
                                <p class="saller">

                                    <span *ngIf="isArabic">
                                        {{ product.Brand.NameAR | TrimString: 20 }}
                                    </span>
                                    <span *ngIf="!isArabic">
                                        {{ product.Brand.NameEN | TrimString: 20 }}
                                    </span>
                                </p>
                            </div>
                            <div class="product-price">
                                <div class="discountPrice d-flex align-items-center">
                                    <div class="discountPrice">
                                        <h5 *ngIf="product.Discount > 0">
                                            {{ product.Price }} {{"le" | translate}}
                                        </h5>
                                    </div>
                                    <h5 [class]="product.Discount > 0? 'newPrice mx-2 d-inline-block': 'oldPrice d-inline-block'">
                                        {{ product.OriginalPrice }} {{"le" | translate}}

                                        <span class="dashedPrice"></span>
                                    </h5>
                                </div>


                            </div>

                            <div class="row no-gutters mt-2" *ngIf="!showLoading">
                                <div class="col-6 d-flex align-items-center" *ngIf=" product.Quantity > 0">
                                    <div class="quantity-wrapper my-1">
                                        <span class="icon fa fa-plus" (click)="incQty($event)"></span>
                                        <span class="quantityVal"> {{ product.Quantity }}</span>
                                        <span class="icon fa fa-minus" (click)="decQty($event)"></span>
                                    </div>
                                </div>
                                <div class=" col-6 d-flex justify-content-end align-items-center AddedToCartContainer ">
                                    <div *ngIf="product.Balance <= 0 ">
                                        <span class="badge badge-light">{{"OutOfStock" | translate}}</span>
                                    </div>

                                    <div *ngIf="product.Balance > 0 ">
                                        <button *ngIf="product.Quantity == 0" (click)=" $event.stopPropagation() && product.Quantity > 0 ? deleteItemFromCart() : addItemToCart()
                                                " [class]=" product.Quantity > 0? 'alert_icon icon btn' : 'default_icon icon btn'">
                                                <img *ngIf="product.Quantity == 0" alt=" {{ 'AddItemToCart' | translate }}" src="assets/images/addcart.svg" class="img-fluid" />

                                            <!-- <img *ngIf="product.Quantity > 0" alt=" {{ 'AddedToCart' | translate }}" src="assets/images/adeddtocartt.svg" class="img-fluid" /> -->
                                        </button>

                                        <!-- <button class="icon" *ngIf=" product.Quantity > 0" (click)="deleteItemFromCart($event)">
                                            <i class="fa fa-trash fa-lg text-danger" aria-hidden="true"></i>
                                        </button> -->
                                    </div>
                                    <!-- 
                                    <span (click)="addItemToFav($event)" class="icon">
                                        <i *ngIf="product.AddedToFav" class="fa fa-heart active fa-lg"></i>
                                        <i *ngIf="!product.AddedToFav" class="fa fa-heart-o fa-lg"></i>
                                    </span> -->
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- ************************************************************************* -->
<!-- Pop Up -->
<div class="popup-wrapper" [class.show]="false">
    <div class="row no-gutters h-100 align-items-center"></div>
</div>
<div bsModal #productDetailsModal="bs-modal" class="modal fade test" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-lg h-100 w-100 m-0 p-0 overflow-hidden">
        <div class="modal-content h-100 w-100">
            <div class="modal-header p-2 border-0 bg-white">
                <h6 class="modal-title pull-left">
                    {{ "ProductDetails" | translate }}
                </h6>
                <div class="close-btn" (click)="hideModal()">
                    <i class="fa fa-times-circle" aria-hidden="true"></i>
                </div>
            </div>
            <div class="product_details_body h-100 w-100">
                <app-product-detials *ngIf="showPopup" (closeDetailsModal)="closeDetailsModalHandeler($event)" (outputProduct)="changeProductState($event)"></app-product-detials>
            </div>
        </div>
    </div>
</div>