import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ProductModel } from "src/app/models/Product.Model";
import { ExcelService } from "../../../services/excel.service";
import { TranslateService } from "@ngx-translate/core";
import { PageChangedEvent } from "ngx-bootstrap/pagination";
import { Title } from "@angular/platform-browser";
import { AddEditProductRequst } from "src/app/models/AddEditProductRequst.model";
import { CommandService } from "../../../services/command.service";
import {
  ProductListRequest,
  ProductRequest,
} from "../../../models/ProductRequest.Model";
import { FileService } from "src/app/services/file.service";
import { Router } from "@angular/router";
import { ModalDirective } from "ngx-bootstrap/modal";
import { LookupsModel } from "src/app/models/Lookups.model";
import { SearchStream } from "src/app/models/search-stream-object.model";
import { QueryService } from "../../../services/query.services";
import { LookupsRequest } from "src/app/models/lookups.Request";

@Component({
  selector: "app-product-add-bulk",
  templateUrl: "./product-add-bulk.component.html",
  styleUrls: ["./product-add-bulk.component.scss"],
})
export class ProductAddBulkComponent implements OnInit {
  @ViewChild("autoShownModal", { static: false })
  autoShownModal: ModalDirective;

  CategoriesList: LookupsModel[];
  BrandsList: LookupsModel[];
  TypesList: LookupsModel[];
  SearchStream: SearchStream = new SearchStream();
  product: ProductModel = new ProductModel();

  importProducts: ProductModel[] = [];
  exportProducts: ProductModel[] = [];
  files: File[] = [];
  fileName: string;
  IsArabic: boolean;
  title: any;
  tempId: number;
  data: ProductModel[] = [];
  req: AddEditProductRequst = new AddEditProductRequst();
  excelData: ProductModel[] = [];
  excelReq: ProductListRequest = new ProductListRequest();
  isModalShown = false;
  isErrorModal = false;
  categoryLoading = false;
  typeLoading = false;
  brandLoading = false;
  errMsg: any;
  showLoading: boolean;
  selectedItems = [];
  AllChecked: boolean = false;
  constructor(
    private excelSrv: ExcelService,
    private translate: TranslateService,
    private titleService: Title,
    private _commandService: CommandService,
    private fileService: FileService,
    private router: Router,
    private query: QueryService
  ) {}

  ngOnInit() {
    this.loadAfterLang(this.translate.currentLang);
    this.translate.onLangChange.subscribe((res) => {
      this.loadAfterLang(res.lang);
    });
  }

  // Categories
  getCategories(searchKeyword: string = "") {
    this.SearchStream.initStream("CategoryDDL_ProductAddEdit", (a) => {
      this.categoryLoading = true;
      let search = new LookupsRequest();
      search.Filter = a;
      search.pageIndex = 1;
      search.pageSize = 100;
      search.Lang = this.translate.currentLang;

      this.query.getAllProductCategory(search).subscribe(
        (res) => {
          if (res.Result) this.CategoriesList = res.Result;
        },
        (err) => {},
        () => {
          this.categoryLoading = false;
        }
      );
    }).next(searchKeyword);
  }
  categoryChange(event) {
    this.data.forEach((c) => {
      c.Category.ID = event;
    });
  }
  // Brands
  getBrands(searchKeyword: string = "") {
    this.SearchStream.initStream("BrandDDL_ProductAddEdit", (a) => {
      this.brandLoading = true;
      let search = new LookupsRequest();
      search.Filter = a;
      search.pageIndex = 1;
      search.pageSize = 100;
      search.Lang = this.translate.currentLang;

      this.query.getAllProductBrand(search).subscribe(
        (res) => {
          if (res.Result) this.BrandsList = res.Result;
        },
        (err) => {},
        () => {
          this.brandLoading = false;
        }
      );
    }).next(searchKeyword);
  }
  brandChange(event) {
    this.data.forEach((c) => {
      c.Brand.ID = event;
    });
  }
  // Types
  getTypes(searchKeyword: string = "") {
    this.SearchStream.initStream("TypeDDL_ProductAddEdit", (a) => {
      this.typeLoading = true;
      let search = new LookupsRequest();
      search.Filter = a;
      search.pageIndex = 1;
      search.pageSize = 100;
      search.Lang = this.translate.currentLang;

      this.query.getAllProductType(search).subscribe(
        (res) => {
          if (res.Result) this.TypesList = res.Result;
        },
        (err) => {},
        () => {
          this.typeLoading = false;
        }
      );
    }).next(searchKeyword);
  }
  typeChange(event) {
    this.data.forEach((c) => {
      c.Type.ID = event;
    });
  }
  // Reset Button
  resetWindow(input) {
    input = null;
    this.fileName = null;
    input.target.value = "";
    this.data = [];
  }

  //Modal
  showModal(): void {
    if (this.data.length > 0) {
      this.isModalShown = true;
      setTimeout(() => {
        this.isModalShown = false;
        this.router.navigate(["/adminhome"]);
      }, 3000);
      return;
    }
    this.isErrorModal = true;
    setTimeout(() => {
      this.isErrorModal = false;
    }, 3000);
  }

  // Translate
  loadAfterLang(lang: string) {
    this.IsArabic = lang === "ar";
    this.title = this.translate.instant("AddBulkProduct");
    this.titleService.setTitle(this.title);
    this.getCategories();
    this.getBrands();
    this.getTypes();
  }

  // Import Excel TS
  changeExcelFile(event) {
    this.tempId = 0;
    this.fileName = event.target.files[0]["name"];
    if (this.fileName.length) {
      var res = this.excelSrv.importXLSX(event);
      let products = {
        ID: [],
        NameEN: [],
        NameAR: [],
        DescriptionAR: [],
        DescriptionEN: [],
        Color: [],
        Price: [],
        CategoryID: [],
        BrandID: [],
        TypeID: [],
        LocationTackingNumber: []
      };

      res.subscribe((r) => {
        if (r.rows) {
          this.excelSrv.matchObjects(r, products.ID, "ID");
          this.excelSrv.matchObjects(r, products.NameEN, "NameEN");
          this.excelSrv.matchObjects(r, products.NameAR, "NameAR");
          this.excelSrv.matchObjects(
            r,
            products.DescriptionAR,
            "DescriptionAR"
          );
          this.excelSrv.matchObjects(
            r,
            products.DescriptionEN,
            "DescriptionEN"
          );
          this.excelSrv.matchObjects(r, products.Color, "Color");
          this.excelSrv.matchObjects(r, products.Price, "Price");
          this.excelSrv.matchObjects(r, products.CategoryID, "Category");
          this.excelSrv.matchObjects(r, products.BrandID, "Brand");
          this.excelSrv.matchObjects(r, products.TypeID, "Type");
          this.excelSrv.matchObjects(r, products.LocationTackingNumber, "LocationTackingNumber");

          this.req = new AddEditProductRequst();
          for (let i = 0; i < products.ID.length; i++) {
            let v: ProductModel = new ProductModel();
            v.ID = products.ID[i];
            v.NameAR = products.NameAR[i];
            v.NameEN = products.NameEN[i];
            v.DescriptionAR = products.DescriptionAR[i];
            v.DescriptionEN = products.DescriptionEN[i];
            v.Color.ID = products.Color[i];
            v.Color.NameEN = products.Color[i];
            v.Color.NameAR = products.Color[i];
            v.Category.ID = products.CategoryID[i] || null;
            v.Brand.ID = products.BrandID[i] || null;
            v.Type.ID = products.TypeID[i] || null;
            //v.Price = products.Price[i];
            v.LocationTackingNumber = products.LocationTackingNumber[i];

            if (v.Category.ID && v.Brand.ID && v.Type.ID) {
              this.req.ProductRequestExcel.push(v);
            } else if (!v.Category.ID || !v.Brand.ID || !v.Type.ID) {
              alert("Category, Brand, Type is Missing in product " + (i + 1));
              this.fileName = null;
              event.target.value = "";
            }
          }
          this.excelData = this.req.ProductRequestExcel;
          this.data = this.data.concat(this.excelData);
          //console.log(this.data);
        }
      });
    }
  }

  //Remove from table
  removeItem(i) {
    //console.log(i);
    this.data.splice(i, 1);
  }

  //Save form
  SaveBlukProducts() {
    //debugger;
    try {
      this.showLoading = true;
      this.excelReq.ProductList = this.data;
      this._commandService
        .saveProductsExcelURL(this.excelReq)
        .subscribe((r) => {
          this.showLoading = false;
          this.showModal();
          if (r.HasError) {
            if (r.ErrorMessage == "NotUniquo") {
              var check = confirm(this.translate.instant("Namealreadyexists"));
              return;
            }
            this.errMsg = r.ErrorMessage;
            return;
          }
        });
    } catch (err) {
      this.showLoading = false;
      this.errMsg = err;
    }
  }
  // Drag and Drop //
  onSelect(event, i) {
    this.data[i].ImageFile = event.addedFiles;

    this.fileService.uploadFiles(event.addedFiles).subscribe((res) => {
      this.data[i].Image = res.Result[0].RelativePath;
    });
  }

  onRemove(i) {
    this.data[i].Image = null;
    this.data[i].ImageFile = null;
  }

  CheckAllOptions() {
    if (this.AllChecked) {
      this.data.forEach((item, i) => {
        item.checked = true;
        this.selectedItems.push(i);
      });
    } else {
      this.data.forEach((item) => {
        item.checked = false;
        this.selectedItems = [];
      });
    }
    //console.log(this.selectedItems);
  }

  changeSelected(i) {
    //debugger;
    if (this.data[i].checked) {
      this.selectedItems.push(i);
    } else {
      let inserted = this.selectedItems.indexOf(i);
      this.selectedItems.splice(inserted, 1);
    }
  }

  deleteSelected() {
    //debugger;
    this.selectedItems.forEach((res) => this.data.splice(res, 1));
    if (this.selectedItems.length == this.data.length) {
      this.selectedItems = [];
    }
   // console.log(this.selectedItems);
  }
}
