import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ProductPatchModel } from '../models/ProductPatch.model';
import { ProductRequest } from '../models/ProductRequest.Model';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  private requeryRequired: boolean = true;
  private productList: ProductPatchModel[] = [];
  productListSubject: BehaviorSubject<ProductPatchModel[]> = new BehaviorSubject<ProductPatchModel[]>(this.productList);

  currentProductList = this.productListSubject.asObservable();

  filter: ProductRequest;
  public;
  scrollPosition: number = 0;

  totalProductCount: number;
  totalProductCategoryCount: number;
  totalProductBrandCount: number;
  constructor() {
    this.filter = new ProductRequest();
  }

  productListSubjectChange(updatedProductList: ProductPatchModel[]) {
    this.productList = updatedProductList;
    this.productListSubject.next(this.productList);
  }

  clearFilterAndResult() {
    this.filter.clear();
    this.clearResult();
  }

  clearResult() {
    this.productList = [];
    this.requeryRequired = true;
    this.scrollPosition = 0;
    this.filter.PageIndex = 1;
  }

  isRequeryRequired(): boolean {
    return this.requeryRequired;
  }

  setSearchResult(productList: ProductPatchModel[]) {
    this.productList = productList;
    this.requeryRequired = false;
    this.scrollPosition = 0;
    this.filter.PageIndex = 1;
    this.productListSubjectChange(this.productList);
  }

  concatSearchResult(productList: ProductPatchModel[]) {
    this.productList = this.productList.concat(productList);
    this.productListSubjectChange(this.productList);
  }

  updateSearchResult(productList: ProductPatchModel[]) {
    this.productList = this.productList.concat(productList);
    this.productListSubjectChange(this.productList);
    return this.productList;
  }

  getProductList(): ProductPatchModel[] {
    return this.productList;
  }

  changeProductState(updatedProduct: ProductPatchModel) {
    this.productList.map((product) => {
      if (product.PatchID === updatedProduct.PatchID) {
        product.AddedToCart = updatedProduct.AddedToCart;
        product.AddedToFav = updatedProduct.AddedToFav;

        product.Quantity = updatedProduct.Quantity;
        product.Balance = updatedProduct.Balance;
      } else {
        return product;
      }
    });

    this.productListSubjectChange(this.productList);
  }

  getProduct(product: ProductPatchModel) {
    let productIndex = this.productList.findIndex((obj) => obj === product);
    return this.productList[productIndex];
  }

  getProductByPatchId(patchId: number) {
    let productIndex = this.productList.findIndex(x => x.PatchID === patchId);
    return this.productList[productIndex];
  }
}
