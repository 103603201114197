import { CommandService } from 'src/app/services/command.service';
import { UpdateUserCategoryPagesRequest } from './../../models/UpdateUserCategoryPagesRequest';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { LookupsModel } from 'src/app/models/Lookups.model';
import { UserCategoryPagesDto } from 'src/app/models/UserCategoryPages';
import { UserCategoryPagesRequest } from 'src/app/models/UserCategoryPagesRequest';
import { QueryService } from 'src/app/services/query.services';

@Component({
  selector: 'app-user-category-pages',
  templateUrl: './user-category-pages.component.html',
  styleUrls: ['./user-category-pages.component.scss']
})
export class UserCategoryPagesComponent implements OnInit {
  errMsg: string;
  errors: string[] = [];
  showLoading: boolean;
  userCategoryId: number;
  UserCategoryList: LookupsModel[] = [];
  userCategoryPages: UserCategoryPagesDto[] = [];
  IsArabic: boolean = true;
  arabic = /[\u0600-\u06FF]/;
  title: string;
  editMode: boolean = true;
  constructor(
    private _translate: TranslateService,
    private _queryService: QueryService,
    private _commandService: CommandService,
    private titleService: Title
  ) {

    _translate.onLangChange.subscribe(() => {
      this.IsArabic = this._translate.currentLang == "ar";
    });
  }

  ngOnInit() {
    this.IsArabic = this._translate.currentLang == "ar";
    this.title = this._translate.instant("UserCategoryManagement");
    this.getUserCategories();
    this.onLanguageChange();

  }

  isArabic() {
    return this._translate.currentLang === "ar" ? true : false;
  }

  onLanguageChange(){
    this._translate.onLangChange.subscribe(() => {
      this.title = this._translate.instant("UserCategoryManagement");
      this.titleService.setTitle(this.title);
    });
  }

  getUserCategories() {
    try {
      this.errMsg = "";
      this.showLoading = true;

      this._queryService.getAllUserCategoriesList().subscribe(
        (res) => {
          this.showLoading = false;
          if (res.HasError) {
            this.errMsg = res.ErrorMessage;
            return;
          }
          res.Result.forEach(x => {
            this.UserCategoryList.push({
              ID: x.ID,
              NameAR: x.NameAR,
              NameEN: x.NameEN,
              checked: false,
            });
          });
        },
        (err) => {
          this.errMsg = err;
          this.showLoading = false;
        }
      );
    } catch (error) {
      this.errMsg = error;
      this.showLoading = false;
    }
  }

  onUserCategoryChange(selectedValue: string) {
    this.userCategoryId = +selectedValue;
    if (this.userCategoryId === 0) {
      this.userCategoryPages = [];
      return;
    } else {
      this.getUserCategoryPages(this.userCategoryId);
    }
  }

  getUserCategoryPages(userCatId: number) {
    let request = new UserCategoryPagesRequest();
    request.UserCategoryId = userCatId;

    try {
      this.errMsg = "";
      this.showLoading = true;

      this._queryService.getAllUserCategoryPagesList(request).subscribe(
        (res) => {
          this.showLoading = false;
          if (res.HasError) {
            this.errMsg = res.ErrorMessage;
            return;
          }

          this.userCategoryPages = [];
          this.userCategoryPages = res.Result;
        },
        (err) => {
          this.errMsg = err;
          this.showLoading = false;
        }
      );
    } catch (error) {
      this.errMsg = error;
      this.showLoading = false;
    }
  }

  updateUserCategoryPages(){
    var check = confirm(
      this._translate.instant("AreYouSureToSaveThisChanges")
    );
    if (check){
      let request = new UpdateUserCategoryPagesRequest();
      request.UserCategoryId = this.userCategoryId;
      request.UserCategoryPagesDtos = this.userCategoryPages;

      try {
        this.errMsg = "";
        this.showLoading = true;

        this._commandService.UpdateUserCategoryPages(request).subscribe(
          (res) => {
            this.showLoading = false;
            if (res.HasError) {
              this.errMsg = res.ErrorMessage;
              return;
            }
            alert(res.Result);
          },
          (err) => {
            this.errMsg = err;
            this.showLoading = false;
          }
        );
      } catch (error) {
        this.errMsg = error;
        this.showLoading = false;
      }
    }
  }


}
