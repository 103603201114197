import { Component, Input, Output, EventEmitter } from "@angular/core";
import { PageChangedEvent } from "ngx-bootstrap/pagination";
import { configration } from "../../config";

@Component({
  selector: "app-pagination-component",
  templateUrl: "./pagination.component.html"
})
export class PaginationsComponent {

  @Input() pageIndex: number = 1;
  @Input() PageSize: number = configration.pageSize;
  @Input()
  public set ItemsCount(val: number) {
    this._itemsCount = val;
  }

  @Output() changePage = new EventEmitter<PageChangedEvent>();

  pagescount: number = 0;
  _itemsCount: number = 0;
  isLastPageInPaging = false;

  onPageChanged(event: PageChangedEvent) {
    // please keep if conditions order as it.
    if (this.isLastPageInPaging) {
      this.pageIndex = 1;
      this.isLastPageInPaging = false;
      return;
    }
    if (this.pageIndex === event.page) return;

    this.pageIndex = event.page;
    this.changePage.emit(event);
  }

  changePageSize(event) {
    let newSize = +event.currentTarget.value;

    if (
      newSize > this.PageSize &&
      Math.ceil(this._itemsCount / this.PageSize) == this.pageIndex
    ) {
      this.isLastPageInPaging = true;
    }

    this.pageIndex = 1;
    this.PageSize = newSize;
    let a: PageChangedEvent = {
      page: this.pageIndex,
      itemsPerPage: this.PageSize
    };
    this.changePage.emit(a);
  }
}
