import { UserAddressDto } from './../../../models/UserAddressDto.Model';
import { environment } from './../../../../environments/environment.prod';
import { ProductService } from './../../../services/product.service';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { OrderModel, OrderItemModel } from 'src/app/models/Order.Model';
import { QueryService } from 'src/app/services/query.services';
import { configration } from 'src/app/shared/config';
import { OrderRequest } from 'src/app/models/OrderRequest.Model';
import { ConfirmOrderRequest } from 'src/app/models/ConfirmOrderRequest.Model';
import { CommandService } from 'src/app/services/command.service';
import { OrderStatusEnum } from 'src/app/models/enum/OrderStatusEnum';
import { CancelOrderRequest } from 'src/app/models/CancelOrderRequest.Model';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';

import { CityZoneDto } from 'src/app/models/CityZoneDto.Model';
import { CommunicationService } from 'src/app/services/communication.service';

import { Action, ActionType } from 'src/app/models/Action';

import { TranslateService } from '@ngx-translate/core';

import { OrderItemRequest } from 'src/app/models/OrderItemRequest.Model';
import { ComponentEnum } from 'src/app/shared/component.enum';

import { OrderAddressRequest } from 'src/app/models/orderAddressRequest.model';
import { AddItemToFavouritRequest } from 'src/app/models/AddItemToFavouritRequest';
import { PromoCodeRequest } from 'src/app/models/PromoCodeRequest';
import { UpdateOrderRquest } from 'src/app/models/UpdateOrderRquest';
import { ProductPatchModel } from 'src/app/models/ProductPatch.model';
import { CartService } from 'src/app/services/cart.service';
import { tap } from 'rxjs/operators';
import { ProductAvilability } from 'src/app/models/ProductAvilability';


@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
  promoCodeRequest: PromoCodeRequest = new PromoCodeRequest()
  PromoCodeText: string;
  isPromoCodeApplied: boolean = false;
  order: OrderModel = new OrderModel();
  showLoading: boolean = false;
  errMsg: string = "";
  pathOfAPI: string;
  modalRef: BsModalRef;
  showTab: boolean = false;
  isArabic: boolean = true;
  showLoadingPromo: boolean = false;
  @ViewChild('cityModal', { static: false }) cityModal: ModalDirective;
  @ViewChild('promoCodeModal', { static: false }) promoCodeModal: ModalDirective;
  private _selectedCityZone: CityZoneDto = new CityZoneDto();
  cityZone: string;
  showMenu: boolean = false;
  isSaveAddressInList: boolean = false;
  isSubmitted: boolean = false;
  isShowPoupCityZone: boolean = false;
  isShowPoupPromoCode: boolean = false;
  currentView: ComponentEnum = ComponentEnum.cart;
  _showProductAvailabilityPopup = false;
  _productAvilabilityList: ProductAvilability[] = [];

  UserId: string
  @Input() orderId: string;
  @ViewChild("ProductAvailabilityModal", { static: false }) ProductAvailabilityModal: ModalDirective;

  constructor(
    private query: QueryService,
    private command: CommandService,
    private _trans: TranslateService,
    private router: Router,
    private location: Location,
    private _communcationServices: CommunicationService,
    private _productService: ProductService,
    private _cartService: CartService) {

    _trans.onLangChange.subscribe(() => {
      this.isArabic = this._trans.currentLang == 'ar'

    });
  }

  ngOnInit() {


    this.pathOfAPI = environment.webApiEndPoint;
    this.isArabic = this._trans.currentLang == 'ar'

    this.init();

    this._communcationServices.getAction().subscribe(res => {
      if (res.actionType == ActionType.CityZoneSelected && this.currentView == ComponentEnum.cart) {

        var selected = (res.Result as CityZoneDto);
        this.order.Address.City = selected.City;
        this.order.Address.Zone = selected.ZoneList[0];
        this.cityZone = `${this.isArabic ? this.order.Address.City.NameAR : this.order.Address.City.NameEN} / ${this.isArabic ? this.order.Address.Zone.NameAR : this.order.Address.Zone.NameEN}`;

        this.cityModal.hide();
      }
      else if (res.actionType == ActionType.CityZoneClosed && this.currentView == ComponentEnum.cart) {
        this.cityModal.hide();
      }
      else if (res.actionType == ActionType.UserAddressSelected) {
        this.order.Address = Object.assign({}, res.Result);;
        this.cityZone = `${this.order.Address.City.NameAR} / ${this.order.Address.Zone.NameAR}`;
        this.currentView = ComponentEnum.cart;
      } else if (res.actionType == ActionType.UserAddressListClosed) {
        this.currentView = ComponentEnum.cart;
      } else if (res.actionType == ActionType.ShowHideSideMenu) {
        this.showMenu = res.Result
      } else if (res.actionType == ActionType.SendFromListToCart) {
        this.init();
      }
    });


    this._cartService.currentOrderList.subscribe(result => this.order = result)
    //this.setAddressFormAddressValues();


  }

  init() {

    try {

      this.errMsg = "";
      this.showLoading = true;
      var request = new OrderRequest();
      request.ID = +this.orderId;

      this.query.getSearchOrder(request).pipe(tap(() => this.showLoading = false)).subscribe(res => {
        if (res.HasError) {
          this.errMsg = res.ErrorMessage;
          return;
        }
        this.order = res.Result;
        this.UserId = res.Result.UserID;

        this._cartService.orderSubjectChange(res.Result);
        if (this.order.Address.City.NameAR != null)
          this.cityZone = `${this.isArabic ? this.order.Address.City.NameAR : this.order.Address.City.NameEN} / ${this.isArabic ? this.order.Address.Zone.NameAR : this.order.Address.Zone.NameEN}`;

      }, err => {
        this.errMsg = err;
        this.showLoading = false;
      });
    } catch (err) {
      this.errMsg = err;
      this.showLoading = false;

    }

  }
  showSideMenu() {
    this.showMenu = !this.showMenu;
    this._communcationServices.showSideMenuAction(this.showMenu);

  }
  showPromoCodePopup() {
    this.isShowPoupPromoCode = true;
    this.promoCodeModal.show();
  }
  hideModal() {
    this.isShowPoupPromoCode = false
    this.promoCodeModal.hide();
  }

  updateOrderAfterApplyPromoCode(request: UpdateOrderRquest) {
    this.showLoadingPromo = true;
    this.command.UpdateOrderWithPromo(request).pipe(tap(() => {
      this.showLoadingPromo = false;
      this.showLoading = false;
      this.hideModal();
    })).subscribe((res) => {
      this.order = res.Result;
      this.isPromoCodeApplied = this.order.DiscountAmount > 0
    }, err => {
      this.errMsg = err.ErrorMessage;
      this.showLoadingPromo = false;
      this.showLoading = false;
    });
  }
  ApplyVoucherbtn() {
    try {
      this.errMsg = "";
      this.showLoading = true;
      this.promoCodeRequest.UserId = this.UserId;
      this.promoCodeRequest.PromoCode = this.PromoCodeText;
      this.command.ApplyPromoCode(this.promoCodeRequest).pipe(tap(() => { this.showLoading = false; })).subscribe(res => {
        if (res.HasError) {
          if (res.ErrorMessage == "PromoCodeNotMatch") {
            this.errMsg = this._trans.instant("PromoCodeNotMatch");
            this.hideModal()
          }
        }
        else {
          let updateOrderRquest = new UpdateOrderRquest();
          updateOrderRquest.OrderId = this.order.ID;
          updateOrderRquest.PromoCode = res.Result.PromoCode;
          updateOrderRquest.Amount = parseInt(res.Result.PromoCodeAmount);
          this.updateOrderAfterApplyPromoCode(updateOrderRquest);
        }

      });
    } catch (error) {
      this.showLoading = false;
      this.hideModal();
    }
  }


  calculatePromoCode = (request: OrderModel) => request.PrmoCode == null ? null :
    request.PromoCodeType == 0 ? (request.GrossAmount * request.PromoCodeAmount * 0.01).toFixed(2) : request.PromoCodeAmount.toFixed(2);


  removePromoCode() {
    try {
      this.errMsg = "";
      this.showLoading = true;
      let updateOrderRquest = new UpdateOrderRquest();
      updateOrderRquest.OrderId = this.order.ID;
      updateOrderRquest.PromoCode = "";
      updateOrderRquest.Amount = null;

      this.command.UpdateOrderWithPromo(updateOrderRquest).pipe(tap(() => this.isPromoCodeApplied = false)).subscribe((res) => {
        this.order = res.Result;
        this.showLoading = false
      }, err => {
        this.errMsg = err.ErrorMessage;
        this.showLoading = false;
      });
    } catch (err) {
      this.errMsg = err.ErrorMessage;
      this.showLoading = false;
    }
  }

  incQty(oi: OrderItemModel) {

    if (oi.Product.Balance <= oi.Quantity)
      return;
    oi.Quantity++;

    this.updateOrderToCard(oi);
  }

  decQty(oi: OrderItemModel) {
    if (oi.Quantity <= 0) {
      oi.Quantity = 0;
      return;
    }
    oi.Quantity--;

    this.updateOrderToCard(oi);
  }


  updateOrderToCard(oi: OrderItemModel) {
    try {

      var request = new OrderItemRequest();
      request.order = oi;

      this.errMsg = "";
      this.showLoading = true;
      this.command.UpdateOrder(request).pipe(tap(() => this.showLoading = false)).subscribe(res => {
        if (res.HasError) {
          this.errMsg = res.ErrorMessage;
          return;
        }
        //this.order = res.Result;
        this._cartService.orderSubjectChange(res.Result);
        // if (this.order.OrderItems.filter(a => a.isDeleted == false).length == 0)
        //   this.BackBtn();

        this.updateProductState(oi.Product);
      }, err => {
        this.errMsg = err;
        this.showLoading = false;
      });
    } catch (err) {
      this.errMsg = err;
      this.showLoading = false;

    }
  }

  createConfirmOrderRequest(order: OrderModel): ConfirmOrderRequest {
    let result = new ConfirmOrderRequest();
    result.OrderToConfirm = order;
    result.OrderToConfirm.Address = order.Address;
    return result;
  }

  confirmOrder() {

    try {


      this.isSubmitted = true;
      if (this.order.Address.ReceiverName == null) {
        this.errMsg = this._trans.instant('addressisrequired');
        // this._communcationServices.showAlert(this._trans.instant('addressisrequired'));
        this.showTab = !this.showTab;
        return;
      }
      let request = this.createConfirmOrderRequest(this.order)
      request.OrderToConfirm.UserID = this.UserId;


      this.errMsg = "";
      this.showLoading = true;
      this.command.ConfirmOrder(request).pipe(tap(() => this.showLoading = false)).subscribe(res => {
        if (res.HasError) {
          this.errMsg = res.ErrorMessage;
          return;
        }
        //this.order = res.Result;
        this._cartService.orderSubjectChange(res.Result);
        if (res.Result.ProductsAvilability.length > 0)
          this.showProductAvailabilityPopup(res.Result.ProductsAvilability);
        else
          this.router.navigateByUrl("/adminhome/adminorderlist/0")

        // this.BackBtn();


      }, err => {
        this.errMsg = err;
        this._communcationServices.showAlert(this._trans.instant('openLocation'));
        this.showLoading = false;
        return;
      });
    } catch (err) {
      this.errMsg = err;
      this.showLoading = false;

    }
  }
  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {

      navigator.geolocation.getCurrentPosition(resp => {

        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
      },
        err => {
          reject(err);
        });
    });

  }
  cancelOrder() {
    try {

      var request = new CancelOrderRequest();
      request.OrderToCancel = this.order;

      this.errMsg = "";
      this.showLoading = true;
      this.command.CancelOrder(request).pipe(tap(() => this.showLoading = false)).subscribe(res => {
        if (res.HasError) {
          this.errMsg = res.ErrorMessage;
          return;
        }
        //this.order = res.Result;
        this._cartService.orderSubjectChange(res.Result);
        this.BackBtn();
      }, err => {
        this.errMsg = err;
        this.showLoading = false;
      });
    } catch (err) {
      this.errMsg = err;
      this.showLoading = false;

    }
  }

  SaveAddressOrder() {
    try {
      this.isSubmitted = true;

      if (this.order.Address.ReceiverName == null) {
        // this.errMsg = this._trans.instant('addressisrequired');
        this._communcationServices.showAlert(this._trans.instant('addressisrequired'))
        return;
      }
      var request = new OrderAddressRequest();

      request.address = this.order.Address;
      request.address.UserID = this.UserId;
      request.orderId = this.order.ID;

      this.errMsg = "";
      this.showLoading = true;
      this.command.UpdateAddressOrder(request).pipe(tap(() => this.showLoading = false)).subscribe(res => {
        if (res.HasError) {
          this.errMsg = res.ErrorMessage;
          return;
        }
        this._cartService.orderSubjectChange(res.Result);
        this.showTab = false;

      }, err => {
        this.errMsg = err;
        this.showLoading = false;
      });
    } catch (err) {
      this.errMsg = err;
      this.showLoading = false;

    }


  }



  get isEditable() {
    return this.order != null && this.order.ID > 0;
  }

  get isCancellable() {
    return this.order != null && this.order.ID > 0 &&
      (this.order.OrderStatus == OrderStatusEnum.Open || this.order.OrderStatus == OrderStatusEnum.Saved || this.order.OrderStatus == OrderStatusEnum.OnTheWay);
  }


  EditCityZone() {
    this.isShowPoupCityZone = true;
    this.cityModal.show();

  }



  ImportAddress = () => this.currentView = ComponentEnum.userAddressList;


  addItemToFav(product) {

    try {
      var request = new AddItemToFavouritRequest();
      debugger;
      request.ProductID = product.ProductID;


      this.errMsg = "";
      this.showLoading = true;
      this.command.AddItemToFav(request).pipe(tap(() => this.showLoading = false)).subscribe(res => {
        if (res.HasError) {
          this.errMsg = res.ErrorMessage;
          return;
        }
        this.ngOnInit();
        this.showTab = false;

      }, err => {
        this.errMsg = err;
        this.showLoading = false;
      });
    } catch (err) {
      this.errMsg = err;
      this.showLoading = false;

    }

  }

  updateProductState(product: ProductPatchModel) {
    this._productService.changeProductState(product);
  }

  DeleteOrderItem(oi: OrderItemModel) {
    oi.isDeleted = true;
    this.updateOrderToCard(oi);

    this.updateProductState(oi.Product);
  }
  GoToHome() {
    this.router.navigateByUrl("home");
    this._communcationServices.showSideMenuAction(false);
  }

  BackBtn = () => this.location.back();

  get components() {
    return ComponentEnum;
  }

  getImageFullPath(imageExist: boolean, imageName: string, lastModifiedTime: Date): string {
    if (imageExist) {
      return `${environment.webApiEndPoint}Product/${imageName}?date=${lastModifiedTime}`
    }
    return 'assets/images/default.png';
  }

  toggelTabs = () => this.showTab = !this.showTab



  get _address() {
    this._cartService.address = this.order.Address;
    return this._cartService.address;
  }

  set _address(value) {
    this._address = value;
  }

  closeProductAvailabilityModal() {
    this.ProductAvailabilityModal.hide();
    this._showProductAvailabilityPopup = false;
    this.BackBtn()
  }

  showProductAvailabilityPopup(ProductAvilabilityList: ProductAvilability[]) {
    this.ProductAvailabilityModal.show();
    this._productAvilabilityList = ProductAvilabilityList;
    this._showProductAvailabilityPopup = true;
  }


}
