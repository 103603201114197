export class ProductBrandDTO
{
    ID: number;
    NameAR : string;
    NameEN : string;
    IsDeleted : boolean = false;
    Image: string;
    ImageExist: boolean;
    LastModifiedTime: Date;
    IsRetail?: number;
}

