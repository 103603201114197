import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { QueryService } from 'src/app/services/query.services';
import { CommandService } from 'src/app/services/command.service';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { ProductColorDTO } from 'src/app/models/ProductColorDTO.model';
import { AddEditProductColorRequest } from 'src/app/models/AddEditProductColorRequest.model';
import { ProductColorRequest } from 'src/app/models/ProductColorRequest.model';
import {  ColorLookupsModel } from 'src/app/models/Lookups.model';

@Component({
  selector: 'app-product-color-add-edit',
  templateUrl: './product-color-add-edit.component.html',
  styleUrls: ['./product-color-add-edit.component.scss']
})
export class ProductColorAddEditComponent implements OnInit {
  ColorList : ColorLookupsModel[] = [];
  IsArabic = true;
  title: string;
  productColor: ProductColorDTO = new ProductColorDTO();

  _AddEditProductColorRequst: AddEditProductColorRequest = new AddEditProductColorRequest();
  errMsg: string;
  showLoading: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private queryService: QueryService,
    private commandService: CommandService,
    private translateService: TranslateService,
    private titleService: Title
  ) { }


  ngOnInit() {
    this.loadAfterLang(this.translateService.currentLang);
    this.translateService.onLangChange.subscribe(res => this.loadAfterLang(res.lang));
    this.FillLookup();
    this.getProductColorById();
  }
  FillLookup() {
    try {


        this.queryService.getAllColorList().subscribe(res => {
          this.showLoading = false;
          if (res.HasError) {
            this.errMsg = res.ErrorMessage;
            return;
          }
          this.ColorList = res.Result;

        }, err => {
          this.errMsg = err;
          this.showLoading = false;
        });



    } catch (err) {
      this.errMsg = err;
      this.showLoading = false;

    }
  }

  loadAfterLang(lang: string) {
    this.IsArabic = (lang === 'ar');

    this.route.params.subscribe((params: Params) => {
      let id = params['ID'];
      if (id == 0) {
        this.title = this.translateService.instant("AddProductNewColor");
      }
      else {
        this.title = this.translateService.instant("EditProductColor");
      }

      this.titleService.setTitle(this.title);
    });


  }

  getProductColorById() {
    try {
      this.route.params.subscribe((params: Params) => {
        this.productColor.ID = params['ID'];

        if (!this.productColor.ID || this.productColor.ID <= 0) {
          return;
        }

        this.errMsg = "";
        this.showLoading = true;
        let productRequest = new ProductColorRequest();
        productRequest.ID = this.productColor.ID;

        this.queryService.SearchProductColor(productRequest).subscribe(res => {
          this.showLoading = false;
          if (res.HasError) {
            this.errMsg = res.ErrorMessage;
            return;
          }
          this.productColor = res.Result.ItemList[0];

        }, err => {
          this.errMsg = err;
          this.showLoading = false;
        });

      });

    } catch (err) {
      this.errMsg = err;
      this.showLoading = false;

    }
  }

  saveProductColor() {
    try {
      this.errMsg = "";
      this.showLoading = true;
      this._AddEditProductColorRequst.ProductColorDTO = this.productColor;
      this.commandService.AddEditProductColor(this._AddEditProductColorRequst).subscribe(res => {
        this.showLoading = false;
        if (res.HasError) {
          if(res.ErrorMessage=="NotUniquo")
          {

            var check = confirm(this.translateService.instant("Namealreadyexists"))
            return;
          }
          this.errMsg = res.ErrorMessage;
          return;
        }

        this.router.navigateByUrl('adminhome/productcoloradminlist')

      }, err => {
        this.errMsg = err;
        this.showLoading = false;
      });
    } catch (err) {
      this.errMsg = err;
      this.showLoading = false;

    }

  }

}
