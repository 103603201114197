import { VoucherDiscountListComponent } from './component/portal/discount/voucher-discount-list/voucher-discount-list.component';
import { ProductSuppliersComponent } from './component/portal/product-suppliers/product-suppliers.component';
import { MobileSearchKeywordComponent } from './component/portal/mobile-search-keyword/mobile-search-keyword.component';
import { ClientDetialsComponent } from './component/portal/client-detials/client-detials.component';
import { OrdersConfigurationComponent } from './component/portal/orders-configuration/orders-configuration.component';
import { AdminConfigComponent } from './component/portal/admin-config/admin-config.component';
import { NotActiveUsersComponent } from './component/portal/not-active-users/not-active-users.component';
import { OrderDetailsComponent } from './component/portal/order-details/order-details.component';
import { ChangePasswordComponent } from './component/portal/change-password/change-password.component';
import { HomeComponent } from './component/portal/home/home.component';
import { UserCategoryPagesComponent } from './component/user-category-pages/user-category-pages.component';
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ProductAdminListComponent } from "./component/portal/product-admin-list/product-admin-list.component";
import { ProductAddEditComponent } from "./component/portal/product-add-edit/product-add-edit.component";
import { adminOrderListComponent } from "./component/portal/admin-order-list/admin-order-list.component";
import { GenerateVoucherComponent } from "./component/portal/generate-voucher/generate-voucher.component";
import { LoginAdminService } from "./services/login-admin.service";
import { AdminloginComponent } from "./component/portal/adminlogin/adminlogin.component";
import { AdminUserListComponent } from "./component/portal/Admin-User-list/Admin-User-list.component";
import { AddeditAdminUserComponent } from "./component/portal/addedit-Admin-User/addedit-Admin-User.component";
import { QueryVoucherComponent } from "./component/portal/query-voucher/query-voucher.component";
import { CategoryAdminListComponent } from "./component/portal/category-admin-list/category-admin-list.component";
import { CategoryAddEditComponent } from "./component/portal/category-add-edit/category-add-edit.component";
import { ProductTypeAdminListComponent } from "./component/portal/product-type-admin-list/product-type-admin-list.component";
import { ProductTypeAddEditComponent } from "./component/portal/product-type-add-edit/product-type-add-edit.component";
import { ProductBrandAdminListComponent } from "./component/portal/product-brand-admin-list/product-brand-admin-list.component";
import { ProductBrandAddEditComponent } from "./component/portal/product-brand-add-edit/product-brand-add-edit.component";
import { ProductColorAdminListComponent } from "./component/portal/product-color-admin-list/product-color-admin-list.component";
import { ProductColorAddEditComponent } from "./component/portal/product-color-add-edit/product-color-add-edit.component";
import { InventoryComponent } from "./component/portal/inventory/inventory.component";
import { ProductPatchAddComponent } from "./component/portal/product-patch-add/product-patch-add.component";
import { AdminHomeComponent } from "./component/portal/admin-home/admin-home.component";
import { AdminMobileUserListComponent } from "./component/portal/admin-mobile-user-list/admin-mobile-user-list.component";
import { PublishPushNotificationComponent } from "./component/portal/publish-push-notification/publish-push-notification.component";
import { EditMobileUserComponent } from "./component/portal/edit-mobile-user/edit-mobile-user.component";
import { AddNewOrderComponent } from "./component/portal/add-new-order/add-new-order.component";
import { ProductAddBulkComponent } from "./component/portal/product-add-bulk/product-add-bulk.component";
import { AccessDeniedComponent } from './component/portal/access-denied/access-denied.component';
import { ProductDetialsComponent } from './component/portal/product-detials/product-detials.component';
import { AddEditProductSuppliersComponent } from './component/portal/add-edit-product-suppliers/add-edit-product-suppliers.component';
// import { DiscountListComponent } from './component/portal/discount-list/discount-list.component';
import { DiscountListComponent } from './component/portal/discount/discount-list/discount-list.component';
import { QuantityDiscountListComponent } from './component/portal/discount/quantity-discount-list/quantity-discount-list.component';
import { DiscountAddEditComponent } from './component/portal/discount/discount-add-edit/discount-add-edit.component';
import { QuantityDiscountAddEditComponent } from './component/portal/discount/quantity-discount-add-edit/quantity-discount-add-edit.component';
import { UserCategoryPages } from './models/enum/UserPagesEnum';
import { VoucherDiscountAddEditComponent } from './component/portal/discount/voucher-discount-add-edit/voucher-discount-add-edit.component';
import { CustomerDiscountListComponent } from './component/portal/discount/customer-discount-list/customer-discount-list.component';
import { CustomerDiscountAddEditComponent } from './component/portal/discount/customer-discount-add-edit/customer-discount-add-edit.component';
import { ShineImagesComponent } from './component/portal/Shine/shineImages/shineImages.component';

const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    component: AdminloginComponent,
  },
  {
    path: "loginadmin",
    pathMatch: "full",
    component: AdminloginComponent,
  },

  // {
  //   path: "home",
  //   component: HomeComponent,
  //   canActivate: [LoginAdminService],
  // },

  {
    path: "adminhome",
    component: AdminHomeComponent,
    children: [
      {
        path: "",
        component: HomeComponent,
        canActivate: [LoginAdminService],
       // data: { pageId: UserCategoryPages.Products }
      },
      {
        path: "productaddbulk",
        component: ProductAddBulkComponent,
        canActivate: [LoginAdminService],
        data: { pageId: UserCategoryPages.Products }
      },
      {
        path: "productaddedit/:ID",
        component: ProductAddEditComponent,
        canActivate: [LoginAdminService],
        data: { pageId: UserCategoryPages.Products }
      },
      {
        path: "product-details/:ID",
        component: ProductDetialsComponent,
        canActivate: [LoginAdminService],
        data: { pageId: UserCategoryPages.Products }
      },
      {
        path: "ProductAdminList",
        component: ProductAdminListComponent,
        canActivate: [LoginAdminService],
        data: { pageId: UserCategoryPages.Products }
        //outlet: "AdeminOutLet"
      },
      {
        path: "product-suppliers",
        component: ProductSuppliersComponent,
        canActivate: [LoginAdminService],
        data: { pageId: UserCategoryPages.ProductSuppliers }
      },
      {
        path: "add-edit-suppliers/:ID",
        component: AddEditProductSuppliersComponent,
        canActivate: [LoginAdminService],
        data: { pageId: UserCategoryPages.ProductSuppliers }
      },
      {
        path: "adminuserlist",
        component: AdminUserListComponent,
        canActivate: [LoginAdminService],
        data: { pageId: UserCategoryPages.Users }
        //outlet: "AdeminOutLet"
      },
      {
        path: "addeditadminuser/:ID",
        component: AddeditAdminUserComponent,
        canActivate: [LoginAdminService],
        data: { pageId: UserCategoryPages.Users }
        //outlet: "AdeminOutLet"
      },
      {
        path: "adminorderlist/:ID/:UserCategory/:OrdersStatusIds/:ProductID",
        component: adminOrderListComponent,
        canActivate: [LoginAdminService],
        data: { pageId: UserCategoryPages.ClientManagement }
        //outlet: "AdeminOutLet"
      },
      {
        path: "adminorderlist/:ID",
        component: adminOrderListComponent,
        canActivate: [LoginAdminService],
        data: { pageId: UserCategoryPages.ClientManagement }
        //outlet: "AdeminOutLet"
      },
      {
        path: "adminorderlist",
        component: adminOrderListComponent,
        canActivate: [LoginAdminService],
        data: { pageId: UserCategoryPages.Orders }
        //outlet: "AdeminOutLet"
      },
      {
        path: "order-details/:OrderId",
        component: OrderDetailsComponent,
        canActivate: [LoginAdminService],
        data: { pageId: UserCategoryPages.Orders }
        //outlet: "AdeminOutLet"
      },
      {
        path: "admin-config",
        component: AdminConfigComponent,
        canActivate: [LoginAdminService],
        data: { pageId: UserCategoryPages.AdminConfig }
        //outlet: "AdeminOutLet"
      },
      {
        path: "orders-config",
        component: OrdersConfigurationComponent,
        canActivate: [LoginAdminService],
        data: { pageId: UserCategoryPages.AdminConfig }
        //outlet: "AdeminOutLet"
      },
      {
        path: "mobile-search-keyword",
        component: MobileSearchKeywordComponent,
        canActivate: [LoginAdminService],
        data: { pageId: UserCategoryPages.AdminConfig }
        //outlet: "AdeminOutLet"
      },
      {
        path: "admingeneratevoucher",
        component: GenerateVoucherComponent,
        canActivate: [LoginAdminService],
        data: { pageId: UserCategoryPages.Vouchers }
        //outlet: "AdeminOutLet"
      },
      {
        path: "adminqueryvoucher",
        component: QueryVoucherComponent,
        canActivate: [LoginAdminService],
        data: { pageId: UserCategoryPages.Vouchers }
        //outlet: "AdeminOutLet"
      },
      {
        path: "categoryadminlist",
        component: CategoryAdminListComponent,
        canActivate: [LoginAdminService],
        data: { pageId: UserCategoryPages.Category }
      },
      {
        // path: "addneworder",
        path: "addneworder/:id",
        component: AddNewOrderComponent,
        canActivate: [LoginAdminService],
        data: { pageId: UserCategoryPages.ClientManagement }
      },
      {
        path: "categoryaddedit/:ID",
        component: CategoryAddEditComponent,
        canActivate: [LoginAdminService],
        data: { pageId: UserCategoryPages.Category }
      },
      {
        path: "producttypeadminlist",
        component: ProductTypeAdminListComponent,
        canActivate: [LoginAdminService],
        data: { pageId: UserCategoryPages.ProductType }
      },
      {
        path: "producttypeaddedit/:ID",
        component: ProductTypeAddEditComponent,
        canActivate: [LoginAdminService],
        data: { pageId: UserCategoryPages.ProductType }
      },
      {
        path: "productbrandadminlist",
        component: ProductBrandAdminListComponent,
        canActivate: [LoginAdminService],
        data: { pageId: UserCategoryPages.Brand }
      },
      {
        path: "productbrandaddedit/:ID",
        component: ProductBrandAddEditComponent,
        canActivate: [LoginAdminService],
        data: { pageId: UserCategoryPages.Brand }
      },
      {
        path: "productcoloradminlist",
        component: ProductColorAdminListComponent,
        canActivate: [LoginAdminService],
        data: { pageId: UserCategoryPages.ProductColor }
      },
      {
        path: "productcoloraddedit/:ID",
        component: ProductColorAddEditComponent,
        canActivate: [LoginAdminService],
        data: { pageId: UserCategoryPages.ProductColor }
      },
      {
        path: "inventory",
        component: InventoryComponent,
        canActivate: [LoginAdminService],
        data: { pageId: UserCategoryPages.Inventory }
      },
      {
        path: "addproductpatch/:PID/:Price",
        component: ProductPatchAddComponent,
        canActivate: [LoginAdminService],
        data: { pageId: UserCategoryPages.Inventory }
      },
      {
        path: "adminmobileuserlist",
        component: AdminMobileUserListComponent,
        canActivate: [LoginAdminService],
        data: { pageId: UserCategoryPages.ClientManagement }
      },
      {
        path: "adminmobileNotAcitveuserlist",
        component: NotActiveUsersComponent,
        canActivate: [LoginAdminService],
        data: { pageId: UserCategoryPages.ClientManagement }
      },
      {
        path: "editmobileuser/:ID",
        component: EditMobileUserComponent,
        canActivate: [LoginAdminService],
        data: { pageId: UserCategoryPages.ClientManagement }
      },
      {
        path: "client-details/:ID",
        component: ClientDetialsComponent,
        canActivate: [LoginAdminService],
        data: { pageId: UserCategoryPages.ClientManagement }
      },
      {
        path: "change-password/:ID",
        component: ChangePasswordComponent,
        canActivate: [LoginAdminService],
        data: { pageId: UserCategoryPages.ClientManagement }
      },
      {
        path: "pushNotification",
        component: PublishPushNotificationComponent,
        canActivate: [LoginAdminService],
        data: { pageId: UserCategoryPages.PushNotification }
      },
      {
        path: "shineImage",
        component: ShineImagesComponent,
        canActivate: [LoginAdminService],
        data: { pageId: UserCategoryPages.AdminConfig }
      },
      {
        path: "usercategorypages",
        component: UserCategoryPagesComponent,
        canActivate: [LoginAdminService],
        data: { pageId: UserCategoryPages.UserCategoryPages }
      },
      {
        path: "discount",
        component: DiscountListComponent,
        canActivate: [LoginAdminService],
        data: { pageId: UserCategoryPages.Discount }
      },
      {
        path: "discountaddedit/:ID",
        component: DiscountAddEditComponent,
        canActivate: [LoginAdminService],
        data: { pageId: UserCategoryPages.Discount }
      },
      {
        path: "qytdiscount",
        component: QuantityDiscountListComponent,
        canActivate: [LoginAdminService],
        data: { pageId: UserCategoryPages.Discount }
      },
      {
        path: "qytdiscountaddedit/:ID",
        component: QuantityDiscountAddEditComponent,
        canActivate: [LoginAdminService],
        data: { pageId: UserCategoryPages.Discount }
      },
      {
        path: "voucherdiscount",
        component: VoucherDiscountListComponent,
        canActivate: [LoginAdminService],
        data: { pageId: UserCategoryPages.Discount }
      },
      {
        path: "voucherdiscount/:ID",
        component: VoucherDiscountAddEditComponent,
        canActivate: [LoginAdminService],
        data: { pageId: UserCategoryPages.Discount }
      },
      {
        path: "customerdiscount",
        component: CustomerDiscountListComponent,
        canActivate: [LoginAdminService],
        data: { pageId: UserCategoryPages.Discount }
      },
      {
        path: "customerdiscount/:ID",
        component: CustomerDiscountAddEditComponent,
        canActivate: [LoginAdminService],
        data: { pageId: UserCategoryPages.Discount }
      },
      {
        path: "accessdenied",
        component: AccessDeniedComponent,
        canActivate: [LoginAdminService],
        data: { pageId: UserCategoryPages.AccessDenied}
      },
    ],
    ////data: { pageId: UserCategoryPages.Products }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
